import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
  CardImg,
  CardText,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  UncontrolledTooltip,
  TabPane,
} from "reactstrap"

import Notifications from "./Notifications"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { SaveToast } from "components/Common/SaveToast"
import { getFreelanceTeacherById } from "helpers/backendHelpers/freelanceTeacher"

import { IMAGE_URL } from "helpers/urlHelper"
import default_profile from "../../assets/images/default_profile.webp"

const ViewDetails = props => {
  const [freelanceTeacher, setFreelanceTeacher] = useState({})

  const [activeAreaTab, setActiveAreaTab] = useState(1)
  const [isEffects, setisEffects] = useState(false)

  useEffect(() => {
    let { ft_id } = props.match.params

    if (!ft_id) {
      return SaveToast({ message: "Invalid ID", type: "error" })
    }

    fetchFreelanceTeacherDetails(ft_id)

    return
  }, [])

  const fetchFreelanceTeacherDetails = async ft_id => {
    try {
      let response = await getFreelanceTeacherById(ft_id)
      if (!response?.status) {
        let message =
          response?.message ||
          "There was a problem fetching freelance teacher details"

        setFreelanceTeacher({})

        return SaveToast({ message, type: "error" })
      }

      let { freelanceTeacher } = response.data || {}
      freelanceTeacher = freelanceTeacher || {}
      return setFreelanceTeacher(freelanceTeacher)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching freelance teacher details"

      setFreelanceTeacher({})

      return SaveToast({ message, type: "error" })
    }
  }

  return (
    <React.Fragment>
      {isEffects ? (
        <Lightbox
          mainSrc={`${IMAGE_URL}/${freelanceTeacher?.ft_degreeCertificate}`}
          enableZoom={false}
          onCloseRequest={() => {
            setisEffects(!isEffects)
          }}
        />
      ) : null}
      <Row className="mt-3">
        <Col>
          <span className="text-info" style={{ fontSize: "2rem" }}>
            {freelanceTeacher.ft_fullName}
          </span>
        </Col>
        <Col className="text-end">
          <Button
            color="info"
            onClick={() => {
              props.history.push("/freelance-teacher")
            }}
          >
            Back
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} className="mb-3">
          <Card>
            <Row className="no-gutters align-items-center">
              <Col md={3}>
                <CardImg
                  className="img-fluid"
                  style={{ objectFit: "cover", maxHeight: "200px" }}
                  src={
                    freelanceTeacher.ft_profilePic
                      ? `${IMAGE_URL}/${freelanceTeacher.ft_profilePic}`
                      : default_profile
                  }
                  alt={freelanceTeacher.ft_fullName}
                />
              </Col>
              <Col md={9}>
                <CardBody>
                  <Row>
                    <Col md={4}>
                      <CardText>{freelanceTeacher.ft_phoneNumber}</CardText>
                      <CardText>{freelanceTeacher.ft_email}</CardText>
                      <CardText>
                        {`${
                          freelanceTeacher?.ft_circuit
                            ? freelanceTeacher?.ft_circuit + ", "
                            : ""
                        }${
                          freelanceTeacher?.ft_district
                            ? freelanceTeacher?.ft_district + ", "
                            : ""
                        }${
                          freelanceTeacher?.ft_region
                            ? freelanceTeacher?.ft_region + ""
                            : ""
                        }`}
                      </CardText>
                    </Col>
                    <Col md={4}>
                      <CardText>{freelanceTeacher.ft_altPhoneNumber}</CardText>
                      <CardText>
                        Education: {freelanceTeacher.ft_education}
                      </CardText>
                      <CardText>{freelanceTeacher.ft_address}</CardText>
                    </Col>
                    <Col md={4}>
                      <img
                        src={`${IMAGE_URL}/${freelanceTeacher?.ft_degreeCertificate}`}
                        alt="Degree Certificate"
                        id={`degree_certificate_${freelanceTeacher.ft_id}`}
                        onClick={() => {
                          setisEffects(true)
                        }}
                        style={{ cursor: "pointer" }}
                        className="avatar-lg img-thumbnail"
                      />
                      <UncontrolledTooltip
                        target={`degree_certificate_${freelanceTeacher.ft_id}`}
                      >
                        View Certificate
                      </UncontrolledTooltip>
                      <CardText className="mt-1">
                        Status:{" "}
                        <i
                          className={`mdi mdi-circle text-${
                            freelanceTeacher.ft_status ? "success" : "danger"
                          } align-middle me-1`}
                        />
                        {freelanceTeacher.ft_status ? "Active" : "Inactice"}
                      </CardText>
                    </Col>
                  </Row>
                </CardBody>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeAreaTab === 1 && "active"}
            onClick={() => {
              setActiveAreaTab(1)
            }}
          >
            Notifications
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeAreaTab}>
        <TabPane tabId={1}>
          <Row className="mt-3">
            <Col>
              <Card>
                <CardBody>
                  <Notifications />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </React.Fragment>
  )
}

export default ViewDetails
