import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Table,
} from "reactstrap"
import { getGESOffice } from "helpers/backendHelpers/GESOffice"

const ViewDetails = props => {
  const [officeData, setOfficeData] = useState(props.officeData || {})
  const [officeId, setGESOfficeId] = useState(0)

  useEffect(() => {
    let { id } = props.match.params || {}
    setGESOfficeId(parseInt(id))

    if (id) {
      let gesOffice = {}
      getGESOffice(id)
        .then(resp => {
          gesOffice = resp.data.gesOffice

          let { go_region, go_district, go_circuit } = gesOffice

          go_region = 2
          go_district = 3
          go_circuit = 4

          Object.keys(gesOffice).forEach(key => {
            if (gesOffice[key] === null) {
              delete gesOffice[key]
            }
          })
          setOfficeData(gesOffice)
        })
        .catch(err => {})
    }
  }, [])

  return (
    <React.Fragment>
      <Row className="mt-3">
        <Col className="text-end">
          <Button
            color="info"
            onClick={() => {
              props.history.push("/gesoffices")
            }}
          >
            Back
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xl="6">
          <Card>
            <CardBody className="border-bottom">
              <div>
                <CardTitle className="d-flex">
                  <i className="bx bx-buildings h1 text-secondary me-1" />
                  <span style={{ fontSize: "1.4rem" }} className="text-info">
                    {officeData.go_officeTitle}{" "}
                  </span>
                </CardTitle>

                <div>
                  <Row>
                    <Col xl="6">
                      <CardText>
                        Status:{" "}
                        <i
                          className={`mdi mdi-circle text-${
                            officeData.go_status ? "success" : "danger"
                          } align-middle me-1`}
                        />
                        {officeData.go_status ? "Active" : "Inactive"}
                      </CardText>
                      <CardText>Region: {officeData.go_region}</CardText>
                      <CardText>District: {officeData.go_district}</CardText>
                      <CardText>
                        Director: {officeData.go_directorName}
                      </CardText>

                      <CardText>Circuit: {officeData.go_circuit}</CardText>
                      <CardText>
                        Circuit Head: {officeData.go_circuitHeadName}
                      </CardText>
                      <CardText>Level: {officeData.go_officeLevel}</CardText>
                      <CardText>
                        Description: {officeData.go_description}
                      </CardText>
                    </Col>
                    <Col xl="6 mt-3">
                      <CardText>
                        Phone Number: {officeData.go_phoneNumber}
                      </CardText>
                      <CardText>Address: {officeData.address}</CardText>
                      <CardText>{officeData.go_email}</CardText>
                      <CardText>
                        Alt. Phone Number: {officeData.go_altPhoneNumber}
                      </CardText>

                      {/* <CardText>
                        Area:{" "}
                        {`${officeData.go_region}, ${officeData.go_district}, ${officeData.go_circuit}`}
                      </CardText> */}
                      <CardText>Town: {officeData.go_town}</CardText>
                      <CardText>Address: {officeData.go_address}</CardText>
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

ViewDetails.propTypes = {
  officeData: PropTypes.object.isRequired,
}

export default ViewDetails
