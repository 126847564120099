// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import DeleteModal from "../../components/Common/DeleteModal"

// //import components
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  getAllRegion,
  getAllCircuit,
  getAllDistrict,
  createArea,
  deleteArea,
} from "helpers/backendHelpers/area"

import TableContainer from "../../components/Common/TableContainer"
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  Card,
  CardBody,
} from "reactstrap"
import { SimpleStringValue } from "helpers/common_helper_functions"

import Select from "react-select"

import Swal from "sweetalert2"

import { SaveToast } from "components/Common/SaveToast"
import SubmitLoader from "components/Common/SubmitLoader"

function Areas() {
  //meta title
  document.title = "Areas | LMS Ghana"

  const [activeAreaTab, setActiveAreaTab] = useState(1)

  const [regions, setRegions] = useState([])
  const [districts, setDistricts] = useState([])
  const [circuits, setCircuits] = useState([])

  const [saved, setSaved] = useState(false)

  const [deleteModal, setDeleteModal] = useState(false)

  const [loadingRegion, setLoadingRegion] = useState(false)
  const [submitLoading, setsubmitLoading] = useState(false)

  useEffect(() => {
    getAllRegions()
    getAllDistricts()
    getAllCircuits()
  }, [saved])

  const getAllRegions = async () => {
    try {
      setLoadingRegion(true)
      let response = await getAllRegion()
      let { regions } = response.data || {}
      regions = regions || []
      setRegions(regions)
      setLoadingRegion(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching regions"
      setRegions([])
      setLoadingRegion(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const getAllDistricts = async () => {
    try {
      let response = await getAllDistrict()
      let { districts } = response.data || {}

      districts = districts || []

      let fillDistricts = []

      districts.map(region => {
        const { district } = region
        district.map(district => {
          const districtObj = {
            ar_id: district.districtId,
            ar_district: district.districtTitle,
            ar_region: region.regionTitle,
          }
          fillDistricts.push(districtObj)
        })
      })
      setDistricts(fillDistricts)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching districts"
      setDistricts([])
      return SaveToast({ message, type: "error" })
    }
  }

  const getAllCircuits = async () => {
    try {
      let response = await getAllCircuit()
      let { circuits } = response.data || {}

      circuits = circuits || []
      let fillCircuits = []

      circuits.map(regionItem => {
        const { district } = regionItem

        district.map(districtItem => {
          const { circuit } = districtItem

          circuit.map(circuitItem => {
            const circuitObj = {
              ar_id: circuitItem.circuitId,
              ar_district: districtItem.districtTitle,
              ar_region: regionItem.regionTitle,
              ar_circuit: circuitItem.circuitTitle,
            }
            fillCircuits.push(circuitObj)
          })
        })
      })
      setCircuits(fillCircuits)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching circuits"
      setCircuits([])
      return SaveToast({ message, type: "error" })
    }
  }

  const districtRegionDropDownOptions = (() => {
    let data = []
    districts.forEach(dist => {
      let { ar_id, ar_district, ar_region } = dist

      ar_district = ar_region + ", " + ar_district
      // data.push({
      //   value: ar_id + "-" + ar_id,
      //   label: ar_region,
      //   isDisabled: true,
      // })
      data.push({
        value: ar_id,
        label: ar_district,
      })
    })
    return data
  })()

  const [isEdit, setIsEdit] = useState(false)
  const [regionModal, setRegionModal] = useState(false)
  const [districtModal, setDistrictModal] = useState(false)
  const [circuitModal, setCircuitModal] = useState(false)
  const [area, setArea] = useState({})
  const [form, setForm] = useState({})

  const toggleRegionModal = () => {
    if (regionModal) {
      setForm({})
      setIsEdit(false)
    }
    setRegionModal(!regionModal)
  }
  const toggleDistrictModal = () => {
    if (districtModal) {
      setForm({})
      setIsEdit(false)
    }
    setDistrictModal(!districtModal)
  }
  const toggleCircuitModal = () => {
    if (circuitModal) {
      setForm({})
      setIsEdit(false)
    }
    setCircuitModal(!circuitModal)
  }

  const handleRegionSubmit = async e => {
    e.preventDefault()
    try {
      let { ar_region: regionValue } = form || {}
      let regionValueType = {
        ar_title: regionValue,
        ar_type: "region",
      }
      setsubmitLoading(true)
      let response = await createArea(regionValueType)
      let message = response?.message || "Region Created Successfully"
      SaveToast({ message, type: "success" })
      toggleRegionModal()
      setSaved(prevSaved => !prevSaved)
      setsubmitLoading(false)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem creating Region"
      setsubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleDistrictSubmit = async e => {
    e.preventDefault()
    try {
      let {
        ar_id: districtId,
        ar_district: districtValue,
        ar_regionId,
      } = form || {}

      const districtValues = {
        ar_title: districtValue,
        ar_type: "district",
        ar_parentId: ar_regionId,
      }
      setsubmitLoading(true)
      let response = await createArea(districtValues)
      let message = response?.message || "District Created Successfully"
      SaveToast({ message, type: "success" })
      toggleDistrictModal()
      setSaved(prevSaved => !prevSaved)
      setsubmitLoading(false)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem creating District"
      setsubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleCircuitSubmit = async e => {
    e.preventDefault()
    try {
      let { ar_circuit: circuitValue, ar_districtId } = form || {}

      const circuitValues = {
        ar_title: circuitValue,
        ar_type: "circuit",
        ar_parentId: ar_districtId,
      }
      setsubmitLoading(true)
      let response = await createArea(circuitValues)
      let message = response?.message || "Circuit Created Successfully"
      SaveToast({ message, type: "success" })
      toggleCircuitModal()
      setSaved(prevSaved => !prevSaved)
      setsubmitLoading(false)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem creating Circuit"
      setsubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const onClickDelete = areaId => {
    setArea(areaId)
    setDeleteModal(true)
  }

  const handleDeleteArea = async () => {
    try {
      let response = await deleteArea(area)
      let message = response?.message || "Area Delete Successfully"
      SaveToast({ message, type: "success" })
      setSaved(prevSaved => !prevSaved)
      setDeleteModal(false)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem area delete"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleDeletePopup = cbFunc => {
    Swal.fire({
      title: "Warning!",
      text: "Are you sure you want to delete?",
      icon: "warning",
      showConfirmButton: true,
      confirmButtonText: "Yes",
      showCancelButton: true,
      cancelButtonText: "No",
    }).then(result => {
      if (result.isConfirmed) {
        cbFunc()
      }
    })
  }

  const regionColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "ar_id",
        disableFilters: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Region",
        accessor: "ar_region",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {/* <Link
                to="#"
                className="text-info"
                onClick={() => {
                  let region = cellProps.row.original
                  setForm(region)
                  setIsEdit(true)
                  toggleRegionModal()
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link> */}
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const region = cellProps.row.original.ar_id
                  onClickDelete(region)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const districtColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "ar_id",
        disableFilters: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "District",
        accessor: "ar_district",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Region",
        accessor: "ar_region",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {/* <Link
                to="#"
                className="text-info"
                onClick={() => {
                  let district = cellProps.row.original
                  setForm(district)
                  setIsEdit(true)
                  toggleDistrictModal()
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link> */}
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const district = cellProps.row.original.ar_id
                  onClickDelete(district)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const circuitColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "ar_id",
        disableFilters: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Circuit",
        accessor: "ar_circuit",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "District",
        accessor: "ar_district",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Region",
        accessor: "ar_region",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {/* <Link
                to="#"
                className="text-info"
                onClick={() => {
                  let circuit = cellProps.row.original
                  setForm(circuit)
                  setIsEdit(true)
                  toggleCircuitModal()
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link> */}
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const circuit = cellProps.row.original.ar_id
                  onClickDelete(circuit)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const printAreaColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "ar_id",
        disableFilters: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Circuit",
        accessor: "ar_circuit",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "District",
        accessor: "ar_district",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Region",
        accessor: "ar_region",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteArea}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Areas" breadcrumbItem="Areas" /> */}

          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeAreaTab === 1 ? "active" : ""}
                onClick={() => {
                  setActiveAreaTab(1)
                }}
              >
                Regions
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeAreaTab === 2 ? "active" : ""}
                onClick={() => {
                  setActiveAreaTab(2)
                }}
              >
                Districts
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeAreaTab === 3 ? "active" : ""}
                onClick={() => {
                  setActiveAreaTab(3)
                }}
              >
                Circuits
              </NavLink>
            </NavItem>
          </Nav>

          <Card>
            <CardBody>
              <TabContent activeTab={activeAreaTab}>
                <TabPane tabId={1}>
                  <Row className="mt-3">
                    <Col>
                      <TableContainer
                        columns={regionColumns}
                        data={regions}
                        isGlobalFilter={true}
                        isAddOptions={true}
                        addButtonLabel="Add New Region"
                        handleAddButtonClick={() => {
                          toggleRegionModal()
                        }}
                        customPageSize={10}
                        className="custom-header-css"
                        dataFetchLoading={loadingRegion}
                        noDataMessage="No Regions Found"
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={2}>
                  <Row className="mt-3">
                    <Col>
                      <TableContainer
                        columns={districtColumns}
                        data={districts}
                        isGlobalFilter={true}
                        isAddOptions={true}
                        addButtonLabel="Add New District"
                        handleAddButtonClick={() => {
                          toggleDistrictModal()
                        }}
                        customPageSize={10}
                        className="custom-header-css"
                        dataFetchLoading={loadingRegion}
                        noDataMessage="No Districts Found"
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={3}>
                  <Row className="mt-3">
                    <Col>
                      <TableContainer
                        columns={circuitColumns}
                        data={circuits}
                        isGlobalFilter={true}
                        isAddOptions={true}
                        addButtonLabel="Add New Circuit"
                        handleAddButtonClick={() => {
                          toggleCircuitModal()
                        }}
                        customPageSize={10}
                        className="custom-header-css"
                        dataFetchLoading={loadingRegion}
                        noDataMessage="No Circuits Found"
                        canPrint={true}
                        printColumns={printAreaColumns}
                      />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>

          {/* Region Modal */}
          <Modal
            size="md"
            isOpen={regionModal}
            toggle={toggleRegionModal}
            role="dialog"
            style={submitLoading ? { position: "relative" } : {}}
            autoFocus
            centered
          >
            {submitLoading ? <SubmitLoader /> : <></>}
            <ModalHeader toggle={toggleRegionModal}>
              {`${isEdit ? "Edit" : "Add"} Region`}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col>
                  <Label className="form-label">
                    Region <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="region"
                    type="text"
                    placeholder="Enter Region"
                    onChange={e => {
                      setForm({ ...form, ar_region: e.target.value })
                    }}
                    value={form?.ar_region}
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="text-end">
                  <Button
                    type="submit"
                    color={isEdit ? "primary" : "success"}
                    size="sm"
                    onClick={handleRegionSubmit}
                    disabled={submitLoading}
                  >
                    {isEdit ? "Update" : "Save"}
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          {/* District Modal */}
          <Modal
            size="md"
            isOpen={districtModal}
            toggle={toggleDistrictModal}
            role="dialog"
            style={submitLoading ? { position: "relative" } : {}}
            autoFocus
            centered
          >
            {submitLoading ? <SubmitLoader /> : <></>}
            <ModalHeader toggle={toggleDistrictModal}>
              {`${isEdit ? "Edit" : "Add"} District`}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col>
                  <Label className="form-label">
                    Region <span className="text-danger">*</span>
                  </Label>
                  <Select
                    name="region"
                    placeholder="Select Region"
                    options={regions.map(region => ({
                      value: region.ar_id,
                      label: region.ar_region,
                    }))}
                    onChange={e => {
                      setForm({ ...form, ar_regionId: e?.value })
                    }}
                    value={
                      regions
                        .map(region =>
                          region.ar_id === form?.regionId
                            ? { value: region.ar_id, label: region.region }
                            : null
                        )
                        .filter(data => data)[0]
                    }
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col>
                  <Label className="form-label">
                    District <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="district"
                    type="text"
                    placeholder="Enter District"
                    onChange={e => {
                      setForm({ ...form, ar_district: e.target.value })
                    }}
                    value={form?.ar_district}
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="text-end">
                  <Button
                    type="submit"
                    color={isEdit ? "primary" : "success"}
                    size="sm"
                    onClick={handleDistrictSubmit}
                    disabled={submitLoading}
                  >
                    {isEdit ? "Update" : "Save"}
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          {/* Circuit Modal */}
          <Modal
            size="md"
            isOpen={circuitModal}
            toggle={toggleCircuitModal}
            role="dialog"
            style={submitLoading ? { position: "relative" } : {}}
            autoFocus
            centered
          >
            {submitLoading ? <SubmitLoader /> : <></>}
            <ModalHeader toggle={toggleCircuitModal}>
              {`${isEdit ? "Edit" : "Add"} Circuit`}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col>
                  <Label className="form-label">
                    District <span className="text-danger">*</span>
                  </Label>
                  <Select
                    name="district"
                    placeholder="Select District"
                    options={districtRegionDropDownOptions}
                    onChange={e => {
                      setForm({ ...form, ar_districtId: e?.value })
                    }}
                    value={districtRegionDropDownOptions.find(
                      value => value?.value === form?.regionId
                    )}
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col>
                  <Label className="form-label">
                    Circuit <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="circuit"
                    type="text"
                    placeholder="Enter Circuit"
                    onChange={e => {
                      setForm({ ...form, ar_circuit: e.target.value })
                    }}
                    value={form?.ar_circuit}
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="text-end">
                  <Button
                    type="submit"
                    color={isEdit ? "primary" : "success"}
                    size="sm"
                    onClick={handleCircuitSubmit}
                    disabled={submitLoading}
                  >
                    {isEdit ? "Update" : "Save"}
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

Areas.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Areas
