import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Container,
  Row,
  Button,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap"
import { getSchoolStaff } from "helpers/backendHelpers/schoolStaff"
const ViewDetails = props => {
  const [staffId, setStaffId] = useState(0)
  const [staffData, setStaffData] = useState(props.staffData || {})
  const [schoolName, setSchoolName] = useState("")
  useEffect(() => {
    let { id } = props.match.params || {}
    setStaffId(parseInt(id))

    if (id) {
      let staffData = {}
      getSchoolStaff(id)
        .then(resp => {
          staffData = resp.data.schoolStaff

          Object.keys(staffData).forEach(key => {
            if (staffData[key] === null) {
              delete staffData[key]
            }
          })
          const schoolName = staffData.ss_school.sc_schoolName
          setSchoolName(schoolName)
          setStaffData(staffData)
        })
        .catch(err => {})
    }
  }, [])

  return (
    <React.Fragment>
      <Row className="mt-3">
        <Col className="text-end">
          <Button
            color="info"
            onClick={() => {
              props.history.push("/school-staff")
            }}
          >
            Back
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xl="6">
          <Card>
            <CardBody className="border-bottom">
              <div>
                <CardTitle className="d-flex">
                  <i className="mdi mdi-account-circle h1 text-secondary me-1" />
                  <span style={{ fontSize: "1.4rem" }} className="text-info">
                    {staffData.ss_fullName}{" "}
                  </span>
                </CardTitle>

                <div>
                  <Row>
                    <Col xl="6">
                      <CardText>
                        Status:{" "}
                        <i
                          className={`mdi mdi-circle text-${
                            staffData.ss_status ? "success" : "danger"
                          } align-middle me-1`}
                        />
                        {staffData.ss_status ? "Active" : "Inactive"}
                      </CardText>
                      <CardText>Staff Id: {staffData.ss_staffId}</CardText>
                      <CardText>
                        Phone Number: {staffData.ss_phoneNumber}
                      </CardText>
                      <CardText>Role: {staffData.ss_staffRole}</CardText>
                    </Col>
                    <Col xl="6">
                      <CardText>School Name: {schoolName}</CardText>
                      <CardText>Email: {staffData.ss_email}</CardText>
                      <CardText>
                        Alt. Phone Number: {staffData.ss_altPhoneNumber}
                      </CardText>
                    </Col>
                  </Row>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

ViewDetails.propTypes = {
  staffData: PropTypes.object.isRequired,
}

export default ViewDetails
