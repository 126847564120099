import { getApiConfig } from "helpers/authHelper"
import { get, post, put, del } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllTutors = () => {
  return get(url.TUTOR_MANAGEMENT, getApiConfig())
}

export const getTutorManagement = id => {
  return get(`${url.TUTOR_MANAGEMENT}/${id}`, getApiConfig())
}

export const createTutor = data => {
  return post(url.TUTOR_MANAGEMENT, data, getApiConfig())
}

export const updateTutor = (id, data) => {
  return put(`${url.TUTOR_MANAGEMENT}/${id}`, data, getApiConfig())
}

export const deleteTutor = id => {
  return del(`${url.TUTOR_MANAGEMENT}/${id}`, getApiConfig())
}
