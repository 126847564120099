import { getApiConfig } from "helpers/authHelper"
import { get, post, put, del } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllTrainingPrograms = () => {
  return get(url.TRAINING_PROGRAM, getApiConfig())
}

export const getTrainingProgram = id => {
  return get(`${url.TRAINING_PROGRAM}/${id}`, getApiConfig())
}

export const createTrainingProgram = data => {
  return post(url.TRAINING_PROGRAM, data, getApiConfig(true))
}

export const updateTrainingProgram = (id, data) => {
  return put(`${url.TRAINING_PROGRAM}/${id}`, data, getApiConfig(true))
}

export const deleteTrainingProgram = id => {
  return del(`${url.TRAINING_PROGRAM}/${id}`, getApiConfig())
}
