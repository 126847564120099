import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import ViewDetails from "./ViewDetails"
import { SaveToast } from "components/Common/SaveToast"
import UpdateModal from "../../components/Common/UpdateModal"

import {
  updateContentTeamMember,
  getContentTeamMember,
  createContentTeamMember,
} from "helpers/backendHelpers/contentTeam"
import { IMAGE_URL } from "helpers/urlHelper"
import SubmitLoader from "components/Common/SubmitLoader"

const TeamMemberModal = props => {
  const [isEdit, setIsEdit] = useState(false)
  const [isView, setIsView] = useState(false)
  const [memberId, setmemberId] = useState(0)
  const [updateModal, setUpdateModal] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const [form, setForm] = useState({
    ct_fullName: "",
    ct_email: "",
    ct_education: "",
    ct_phoneNumber: "",
    ct_altPhoneNumber: "",
    ct_address: "",
    ct_profilePic: { fileName: "", file: {} },
    ct_degreeCertificate: { fileName: "", file: {} },
    ct_status: true,
  })

  useEffect(() => {
    document.getElementById("content-team").classList.add("mm-active")
    let { type, id } = props.match.params || {}

    switch (type) {
      case "edit":
        setIsEdit(true)
        setIsView(false)
        setmemberId(parseInt(id))
        break
      case "view":
        setIsView(true)
        setIsEdit(false)
        setmemberId(parseInt(id))
        break
      case "add":
      default:
        setIsView(false)
        setIsEdit(false)
        setmemberId(0)
        break
    }

    if (id) {
      fetchTeamDetailsForEdit(id)
    }
  }, [])

  const fetchTeamDetailsForEdit = async ct_id => {
    try {
      let response = await getContentTeamMember(ct_id)

      let { contentTeamMember } = response.data || {}
      contentTeamMember = contentTeamMember || {}

      contentTeamMember["ct_profilePic_old"] =
        contentTeamMember["ct_profilePic"]
      contentTeamMember["ct_degreeCertificate_old"] =
        contentTeamMember["ct_degreeCertificate"]
      contentTeamMember["ct_profilePic"] = { fileName: "", file: {} }
      contentTeamMember["ct_degreeCertificate"] = { fileName: "", file: {} }

      return setForm(contentTeamMember)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Content Team Details"

      setForm(form)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddTeamMemeberSubmit = async data => {
    try {
      setSubmitLoading(true)
      const response = await createContentTeamMember(data)
      let message = response?.message || "Team Added Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)
      props.history.push("/content-team")
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Adding Team"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleEditContentTeamMemberSubmit = async (id, data) => {
    if (!id) {
      return SaveToast({
        message: "Please enter Team Id",
        type: "error",
      })
    }
    try {
      setSubmitLoading(true)
      const response = await updateContentTeamMember(id, data)
      let message = response?.message || "Team Updated Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)
      props.history.push("/content-team")
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Updateing Team"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleUpdateTeamMember = () => {
    setUpdateModal(false)
    SaveToast({ message: "Member Updated Successfully", type: "success" })
    props.history.push("/content-team")
  }

  return (
    <React.Fragment>
      <UpdateModal
        show={updateModal}
        onUpdateClick={handleUpdateTeamMember}
        onCloseClick={() => setUpdateModal(false)}
      />
      <div className="page-content">
        <div
          className="container-fluid"
          style={submitLoading ? { position: "relative", opacity: "0.8" } : {}}
        >
          {/* <Breadcrumbs title="Content" breadcrumbItem="Team" /> */}
          {submitLoading ? <SubmitLoader /> : <></>}
          {isView ? (
            <>
              <ViewDetails
                teamMemebrId={memberId}
                teamMemberData={form}
                {...props}
              />
            </>
          ) : (
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={form}
                  validationSchema={Yup.object({
                    ct_fullName: Yup.string().required(
                      "Please Enter Your Full Name"
                    ),
                    ct_email: Yup.string()
                      .required("Please Enter Your Email")
                      .email("Please Enter Valid Email"),
                    ct_education: Yup.string().required(
                      "Please Enter Your Education"
                    ),
                    ct_address: Yup.string().required(
                      "Please Enter Your Address"
                    ),
                    ct_phoneNumber: Yup.string()
                      .required("Please Enter Your Phone Number")
                      .matches(
                        /^[0-9]{10}$/,
                        "Please Enter Valid Phone Number"
                      ),
                    ct_altPhoneNumber: Yup.string()
                      .nullable()
                      .notRequired()
                      .matches(
                        /^[0-9]{10}$/,
                        "Please Enter Valid Phone Number"
                      ),
                    ct_profilePic: Yup.mixed()
                      .test(
                        "fileNotSelected",
                        "Please Select Profile Picture",
                        value => {
                          return isEdit || !!form?.["ct_profilePic"]?.file?.type
                        }
                      )
                      .test("fileFormat", "Unsupported Format", value => {
                        if (!form?.["ct_profilePic"]?.file?.type)
                          return isEdit || false

                        return [
                          "image/png",
                          "image/jpg",
                          "image/jpeg",
                          "image/gif",
                        ].includes(form?.["ct_profilePic"].file.type)
                      }),
                    ct_degreeCertificate: Yup.mixed()
                      .test(
                        "fileNotSelected",
                        "Please Select Degree Certificate",
                        value => {
                          return (
                            isEdit ||
                            !!form?.["ct_degreeCertificate"]?.file?.type
                          )
                        }
                      )
                      .test("fileFormat", "Unsupported Format", value => {
                        if (!form?.["ct_degreeCertificate"]?.file?.type)
                          return isEdit || false

                        return [
                          "image/png",
                          "image/jpg",
                          "image/jpeg",
                          "image/gif",
                        ].includes(form?.["ct_degreeCertificate"].file.type)
                      }),
                  })}
                  onSubmit={values => {
                    let teamMemberData = values

                    if (isEdit) {
                      teamMemberData["ct_profilePic_old"] =
                        form.ct_profilePic_old
                      teamMemberData["ct_degreeCertificate_old"] =
                        form.ct_degreeCertificate_old
                      teamMemberData["ct_profilePic"] = form.ct_profilePic.file
                      teamMemberData["ct_degreeCertificate"] =
                        form.ct_degreeCertificate.file
                      delete teamMemberData["ct_id"]
                      return handleEditContentTeamMemberSubmit(
                        memberId,
                        teamMemberData
                      )
                    } else {
                      teamMemberData["ct_profilePic"] = form.ct_profilePic.file
                      teamMemberData["ct_degreeCertificate"] =
                        form.ct_degreeCertificate.file
                      return handleAddTeamMemeberSubmit(teamMemberData)
                    }
                  }}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                  }) => (
                    <>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          handleSubmit(e)
                          return false
                        }}
                      >
                        <Row>
                          <Col>
                            <Row className="mb-3">
                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">
                                  Full Name{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="ct_fullName"
                                  type="text"
                                  placeholder="Enter Full Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.ct_fullName && errors.ct_fullName
                                  }
                                  defaultValue={form.ct_fullName}
                                />
                                {touched.ct_fullName && errors.ct_fullName && (
                                  <FormFeedback>
                                    {errors.ct_fullName}
                                  </FormFeedback>
                                )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Email <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="ct_email"
                                  type="email"
                                  placeholder="Enter Email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={touched.ct_email && errors.ct_email}
                                  defaultValue={form.ct_email}
                                />
                                {touched.ct_email && errors.ct_email && (
                                  <FormFeedback>{errors.ct_email}</FormFeedback>
                                )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Education{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="ct_education"
                                  type="text"
                                  placeholder="Enter Education"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.ct_education && errors.ct_education
                                  }
                                  defaultValue={form.ct_education}
                                />
                                {touched.ct_education &&
                                  errors.ct_education && (
                                    <FormFeedback>
                                      {errors.ct_education}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col md={4} sm={6} xs={12} className="mt-2 mb-3">
                                <Label className="form-label">
                                  Phone Number{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="ct_phoneNumber"
                                  type="tel"
                                  placeholder="Enter Phone Number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.ct_phoneNumber &&
                                    errors.ct_phoneNumber
                                  }
                                  defaultValue={form.ct_phoneNumber}
                                />
                                {touched.ct_phoneNumber &&
                                  errors.ct_phoneNumber && (
                                    <FormFeedback>
                                      {errors.ct_phoneNumber}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col md={4} sm={6} xs={12} className="mt-2 mb-3">
                                <Label className="form-label">
                                  Alt. Phone Number
                                </Label>
                                <Input
                                  name="ct_altPhoneNumber"
                                  type="tel"
                                  placeholder="Enter Alt. Phone Number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.ct_altPhoneNumber &&
                                    errors.ct_altPhoneNumber
                                  }
                                  defaultValue={form.ct_altPhoneNumber}
                                />
                                {touched.ct_altPhoneNumber &&
                                  errors.ct_altPhoneNumber && (
                                    <FormFeedback>
                                      {errors.ct_altPhoneNumber}
                                    </FormFeedback>
                                  )}
                              </Col>
                            </Row>

                            <Row className="mb-3">
                              <Col sm={12} md={6}>
                                <Row>
                                  <Col>
                                    <Label className="form-label">
                                      Address{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="ct_address"
                                      type="textarea"
                                      rows={6}
                                      placeholder="Enter Address"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      invalid={
                                        touched.ct_address && errors.ct_address
                                      }
                                      defaultValue={form.ct_address}
                                    />
                                    {touched.ct_address &&
                                      errors.ct_address && (
                                        <FormFeedback>
                                          {errors.ct_address}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>

                              <Col sm={12} md={6} className="mt-2 mt-md-0">
                                <Row>
                                  <Col>
                                    <Label className="form-label">
                                      Profile Pic
                                      <span className="text-danger">*</span>
                                      {isEdit && form?.ct_profilePic_old && (
                                        <>
                                          <span className="ms-2">
                                            (
                                            <a
                                              href={`${IMAGE_URL}/${
                                                form?.ct_profilePic_old || ""
                                              }`}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              Saved Profile Picture
                                            </a>
                                            )
                                          </span>
                                        </>
                                      )}
                                    </Label>
                                    <Input
                                      name="ct_profilePic"
                                      type="file"
                                      accept=".png, .jpg, .jpeg, .gif"
                                      placeholder="Select Profile Pic"
                                      onChange={e => {
                                        let tempForm = form
                                        tempForm["ct_profilePic"]["fileName"] =
                                          e.target.value
                                        tempForm["ct_profilePic"]["file"] =
                                          e.target.files[0]
                                        setForm(tempForm)
                                      }}
                                      invalid={
                                        touched.ct_profilePic &&
                                        errors.ct_profilePic
                                      }
                                      defaultValue={form.ct_profilePic.fileName}
                                    />
                                    {touched.ct_profilePic &&
                                      errors.ct_profilePic && (
                                        <FormFeedback>
                                          {errors.ct_profilePic}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>

                                <Row className="mt-2">
                                  <Col>
                                    <Label className="form-label">
                                      Degree Certificate
                                      <span className="text-danger">*</span>
                                      {isEdit &&
                                        form?.ct_degreeCertificate_old && (
                                          <>
                                            <span className="ms-2">
                                              (
                                              <a
                                                href={`${IMAGE_URL}/${
                                                  form?.ct_degreeCertificate_old ||
                                                  ""
                                                }`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                Saved Degree Certificate
                                              </a>
                                              )
                                            </span>
                                          </>
                                        )}
                                    </Label>
                                    <Input
                                      name="ct_degreeCertificate"
                                      type="file"
                                      accept=".png, .jpg, .jpeg, .gif, .pdf"
                                      placeholder="Select Degree Certificate"
                                      onChange={e => {
                                        let tempForm = form
                                        tempForm["ct_degreeCertificate"][
                                          "fileName"
                                        ] = e.target.value
                                        tempForm["ct_degreeCertificate"][
                                          "file"
                                        ] = e.target.files[0]
                                        setForm(tempForm)
                                      }}
                                      invalid={
                                        touched.ct_degreeCertificate &&
                                        errors.ct_degreeCertificate
                                      }
                                      defaultValue={
                                        form.ct_degreeCertificate.fileName
                                      }
                                    />
                                    {touched.ct_degreeCertificate &&
                                      errors.ct_degreeCertificate && (
                                        <FormFeedback>
                                          {errors.ct_degreeCertificate}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="mb-3 text-center">
                              <Col>
                                <Button
                                  size="md"
                                  color="danger"
                                  type="button"
                                  disabled={submitLoading}
                                  className="mx-2"
                                  onClick={() => {
                                    props.history.push("/content-team")
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  size="md"
                                  color="info"
                                  type="submit"
                                  disabled={submitLoading}
                                >
                                  {!isEdit ? "Save" : "Update"}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </Formik>
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

TeamMemberModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default TeamMemberModal
