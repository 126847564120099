import React, { useMemo, useState, useEffect } from "react"
import { Link, useHistory } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import DeleteModal from "../../components/Common/DeleteModal"

import { SimpleStringValue } from "helpers/common_helper_functions"
import { SaveToast } from "components/Common/SaveToast"
import { Col, Row, UncontrolledTooltip, Card, CardBody } from "reactstrap"

import {
  deleteTrainingQuestions,
  getAllTrainingQuestions,
  updateTrainingQuestions,
} from "helpers/backendHelpers/trainingQuestions"

const AssigmentQuestion = props => {
  document.title = "Assignments | LMS Ghana"

  const [deleteModal, setDeleteModal] = useState(false)
  const [trainingQuestionList, setTrainingQuestionList] = useState([])
  const [trainingQuestion, setTrainingQuestion] = useState({})
  const [saved, setSaved] = useState(false)
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    fetchAssigmentQuestions()
  }, [saved])

  const fetchAssigmentQuestions = async () => {
    try {
      setLoading(true)
      const response = await getAllTrainingQuestions()
      let { allTrainingQue } = response.data || {}
      allTrainingQue = allTrainingQue || []
      setTrainingQuestionList(allTrainingQue)
      setLoading(false)
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Assignments"
      setTrainingQuestionList([])
      setLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const onClickDelete = TrainingQuestionData => {
    setTrainingQuestion(TrainingQuestionData)
    setDeleteModal(true)
  }

  const handleUpdateAssigmentQuestionStatus = async (id, status) => {
    if (!id) {
      return SaveToast({
        message: "Please Enter Assignments Id",
        type: "error",
      })
    }
    try {
      const response = updateTrainingQuestions(id, { atq_status: status })
      const message =
        response?.message || "Training Question Status Updated Successfully"
      SaveToast({ message, type: "success" })

      setSaved(prevSaved => !prevSaved)
      return
    } catch (error) {
      const message =
        error?.message || "There Was A Problem Updating Assignments Status"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleDeleteAssigmentQuestion = async () => {
    if (!trainingQuestion.atq_id) {
      return SaveToast({ message: "Invalid Assignments ID", type: "error" })
    }
    try {
      const response = await deleteTrainingQuestions(trainingQuestion.atq_id)
      const message = response?.message || "Assignments Deleted Successfully"
      SaveToast({ message, type: "success" })

      setSaved(prevSaved => !prevSaved)
      setDeleteModal(false)
      return
    } catch (error) {
      const message =
        error?.message || "There was a problem deleting TrainingQuestion"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddButtonClick = () => {
    history.push({
      pathname: "/training-questions/add",
      state: {
        // 1 = Assigment,2 = Past Questions
        type: 1,
      },
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "atq_title",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Marks",
        accessor: "atq_mark",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Answer Type",
        accessor: "atq_answerType",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "atq_status",
        disableFilters: true,
        Cell: cellProps => {
          const cellData = cellProps.row.original
          return (
            <div className="form-check form-switch form-switch-md">
              <input
                type="checkbox"
                className="form-check-input bg-dark"
                id={`tc_status_checkbox-${cellData.atq_id}`}
                name={`tc_status_checkbox-${cellData.atq_id}`}
                defaultChecked={cellData.atq_status}
                onChange={e => {
                  let { checked, name } = e.target
                  document.getElementById(name).checked = checked
                  return handleUpdateAssigmentQuestionStatus(
                    cellData.atq_id,
                    checked
                  )
                }}
              />
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  props.history.push(
                    "/training-questions/edit/" + cellProps.row.original.atq_id
                  )
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const TrainingQuestionData = cellProps.row.original
                  onClickDelete(TrainingQuestionData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const printColumns = useMemo(() => [
    {
      Header: "Title",
      accessor: "aq_title",
      filterable: false,
      Cell: cellProps => {
        return <SimpleStringValue {...cellProps} />
      },
    },
    {
      Header: "Marks",
      accessor: "aq_mark",
      filterable: true,
      Cell: cellProps => {
        return <SimpleStringValue {...cellProps} />
      },
    },
    {
      Header: "Answer Type",
      accessor: "aq_answerType",
      filterable: false,
      Cell: cellProps => {
        return <SimpleStringValue {...cellProps} />
      },
    },
    // {
    //   Header: "Correct Answer",
    //   accessor: "aq_correntAns",
    //   filterable: false,
    //   Cell: cellProps => {
    //     return <SimpleStringValue {...cellProps} />
    //   },
    // },
    {
      Header: "Status",
      accessor: "aq_status",
      disableFilters: true,
      Cell: cellProps => {
        return cellProps?.row?.original?.tc_status ? "Active" : "Inactive"
      },
    },
  ])

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteAssigmentQuestion}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    canExportCsv={true}
                    csvFileName={`training-questions.csv`}
                    csvLink="/api/v1/admin/training-Questions/download"
                    columns={columns}
                    data={trainingQuestionList}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    addButtonLabel="Add Training Questions"
                    handleAddButtonClick={handleAddButtonClick}
                    customPageSize={10}
                    className="custom-header-css"
                    dataFetchLoading={loading}
                    canPrint={true}
                    printColumns={printColumns}
                    noDataMessage="No system activity found."
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AssigmentQuestion
