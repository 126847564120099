import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import Select from "react-select"

// import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { countryCodes, officeLevel } from "../../common/data/dropdownVals"
import ViewDetails from "./ViewDetails"
import RegionDistrictCircuitDropDownAllSelectable from "components/Common/RegionDistrictCircuitDropDownAllSelectable"
import { SaveToast } from "components/Common/SaveToast"
import UpdateModal from "../../components/Common/UpdateModal"
import {
  updateGESOffice,
  createGESOffice,
  getGESOffice,
} from "helpers/backendHelpers/GESOffice"
import { defaultRDCSeparator } from "helpers/common_helper_functions"
import SubmitLoader from "components/Common/SubmitLoader"

const GESOfficeModal = props => {
  const [isEdit, setIsEdit] = useState(false)
  const [isView, setIsView] = useState(false)
  const [officeId, setOfficeId] = useState(0)
  const [updateModal, setUpdateModal] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [selectedCountryCode, setSelectedCountryCode] = useState({})

  const [form, setForm] = useState({
    go_officeLevel: "",
    go_officeTitle: "",
    go_region: "reg1",
    go_district: "des3",
    go_circuit: "circuit",
    go_address: "",
    go_email: "",
    go_phoneNumber: "",
    go_altPhoneNumber: "",
    go_status: true,
    go_town: "",
    go_latitude: "",
    go_longitude: "",
    go_directorName: "",
    go_circuitHeadName: "",
    go_description: "",
    go_countryCode: 0,
  })

  useEffect(() => {
    document.getElementById("ges-offices").classList.add("mm-active")
    let { type, id } = props.match.params || {}
    switch (type) {
      case "edit":
        setIsEdit(true)
        setIsView(false)
        setOfficeId(parseInt(id))
        break
      case "view":
        setIsView(true)
        setIsEdit(false)
        setOfficeId(parseInt(id))
        break
      case "add":
      default:
        setIsView(false)
        setIsEdit(false)
        setOfficeId(0)
        break
    }

    if (id) {
      fetchEditGESOfficeDetails(id)
    }
  }, [isEdit])

  const fetchEditGESOfficeDetails = async go_id => {
    try {
      const response = await getGESOffice(go_id)

      let { gesOffice } = response.data || {}
      gesOffice = gesOffice || {}

      let { go_region, go_district, go_circuit } = gesOffice

      let areaValue = `${go_region || ""}${defaultRDCSeparator}${
        go_district || ""
      }${defaultRDCSeparator}${go_circuit || ""}`
      gesOffice["go_areaValue"] = areaValue
      setSelectedCountryCode(
        countryCodes.find(
          countryCode => countryCode.value === gesOffice["go_countryCode"]
        )
      )

      return setForm(gesOffice)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching freelance gesOffice details"

      setForm(form)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddGESOfficeSubmit = async data => {
    try {
      setSubmitLoading(true)
      const response = await createGESOffice(data)
      let message = response?.message || "GESOffice Added Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)
      props.history.push("/gesoffices")
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Adding GESOffice"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleEditGESOfficeSubmit = async (id, data) => {
    if (!id) {
      return SaveToast({
        message: "Please enter GESOffice Id",
        type: "error",
      })
    }
    try {
      setSubmitLoading(true)
      const response = await updateGESOffice(id, data)
      let message = response?.message || "GESOffice Updated Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)
      props.history.push("/gesoffices")
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Updating GESOffice"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const onClickUpdate = values => {
    setUpdateModal(true)
  }

  const handleUpdateOffice = () => {
    setUpdateModal(false)
    SaveToast({ message: "Office Updated Successfully", type: "success" })
    props.history.push("/gesoffices")
  }

  return (
    <React.Fragment>
      <UpdateModal
        show={updateModal}
        onUpdateClick={handleUpdateOffice}
        onCloseClick={() => setUpdateModal(false)}
      />
      <div className="page-content">
        <div
          className="container-fluid"
          style={submitLoading ? { position: "relative", opacity: "0.8" } : {}}
        >
          {/* <Breadcrumbs title="GES" breadcrumbItem="Office" /> */}
          {submitLoading ? <SubmitLoader /> : <></>}
          {isView ? (
            <>
              <ViewDetails officeData={form} {...props} />
            </>
          ) : (
            <Card>
              {/* <CardHeader>
              <CardTitle>
                {isView ? (
                  <Label>GES Office Details</Label>
                ) : (
                  <Label>{!isEdit ? "Enter" : "Update"} Details</Label>
                )}
              </CardTitle>
            </CardHeader> */}
              <CardBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={form}
                  validationSchema={Yup.object({
                    go_officeLevel: Yup.string().notRequired().nullable(),
                    go_officeTitle: Yup.string().notRequired().nullable(),
                    go_address: Yup.string().notRequired().nullable(),
                    go_email: Yup.string()
                      .required("Please Enter Office Email")
                      .email("Please Enter Valid Email"),
                    go_phoneNumber: Yup.string()
                      .required("Please Enter Office Phone Number")
                      .matches(
                        /^[0-9]{10}$/,
                        "Please Enter Valid Phone Number"
                      ),
                    go_altPhoneNumber: Yup.string()
                      .nullable()
                      .notRequired()
                      .matches(
                        /^[0-9]{10}$/,
                        "Please Enter Valid Phone Number"
                      ),
                    go_areaValue: Yup.mixed().test(
                      "invalidInput",
                      "Please Select Region-District-Circuit",
                      value => {
                        return !!value
                      }
                    ),
                    go_town: Yup.string().notRequired().nullable(),
                    go_latitude: Yup.string().notRequired().nullable(),
                    go_longitude: Yup.string().notRequired().nullable(),
                    go_directorName: Yup.string().notRequired().nullable(),
                    go_circuitHeadName: Yup.string().notRequired().nullable(),
                    go_description: Yup.string().notRequired().nullable(),
                    go_countryCode: Yup.number().required(
                      "Please Select Country Code"
                    ),
                  })}
                  onSubmit={values => {
                    let [go_region, go_district, go_circuit] =
                      (values?.go_areaValue + "" || "")?.split(
                        defaultRDCSeparator
                      ) || []
                    go_region = go_region || null
                    go_district = go_district || null
                    go_circuit = go_circuit || null

                    let gesOfficeData = values
                    gesOfficeData.go_altPhoneNumber =
                      gesOfficeData.go_altPhoneNumber === ""
                        ? null
                        : gesOfficeData.go_altPhoneNumber
                    if (isEdit) {
                      gesOfficeData["go_region"] = go_region
                      gesOfficeData["go_district"] = go_district
                      gesOfficeData["go_circuit"] = go_circuit
                      delete gesOfficeData["go_id"]
                      return handleEditGESOfficeSubmit(officeId, gesOfficeData)
                    } else {
                      gesOfficeData["go_region"] = go_region
                      gesOfficeData["go_district"] = go_district
                      gesOfficeData["go_circuit"] = go_circuit
                      return handleAddGESOfficeSubmit(gesOfficeData)
                    }
                  }}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleSubmit,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                    handleChange,
                  }) => (
                    <>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          handleSubmit(e)
                          return false
                        }}
                      >
                        <Row>
                          <Col>
                            <Row className="mb-3">
                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">
                                  Office Level{" "}
                                </Label>
                                <Input
                                  name="go_officeLevel"
                                  type="select"
                                  className="form-select"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.go_officeLevel || 0}
                                  invalid={
                                    touched.go_officeLevel &&
                                    errors.go_officeLevel
                                      ? true
                                      : false
                                  }
                                >
                                  <option disabled value={0}>
                                    Select Office Level
                                  </option>
                                  {officeLevel.map(val => {
                                    return (
                                      <option key={val} value={val}>
                                        {val}
                                      </option>
                                    )
                                  })}
                                </Input>
                                {touched.go_officeLevel &&
                                  errors.go_officeLevel && (
                                    <FormFeedback>
                                      {errors.go_officeLevel}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Office Title{" "}
                                </Label>
                                <Input
                                  name="go_officeTitle"
                                  type="text"
                                  placeholder="Enter office Title"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.go_officeTitle &&
                                    errors.go_officeTitle
                                  }
                                  defaultValue={form.go_officeTitle}
                                />
                                {touched.go_officeTitle &&
                                  errors.go_officeTitle && (
                                    <FormFeedback>
                                      {errors.go_officeTitle}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">Town</Label>
                                <Input
                                  name="go_town"
                                  type="text"
                                  placeholder="Enter Town Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={touched.go_town && errors.go_town}
                                  defaultValue={form.go_town}
                                />
                                {touched.go_town && errors.go_town && (
                                  <FormFeedback>{errors.go_town}</FormFeedback>
                                )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <RegionDistrictCircuitDropDownAllSelectable
                                  isRequired={true}
                                  fieldName="go_areaValue"
                                  hasTouched={touched.go_areaValue}
                                  hasErrors={errors.go_areaValue}
                                  areaValue={values.go_areaValue}
                                  setFieldValue={setFieldValue}
                                  setFieldTouched={setFieldTouched}
                                />
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">Latitude</Label>
                                <Input
                                  name="go_latitude"
                                  type="text"
                                  placeholder="Enter Area Latitude"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.go_latitude && errors.go_latitude
                                  }
                                  defaultValue={form.go_latitude}
                                />
                                {touched.go_latitude && errors.go_latitude && (
                                  <FormFeedback>
                                    {errors.go_latitude}
                                  </FormFeedback>
                                )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">Longitude</Label>
                                <Input
                                  name="go_longitude"
                                  type="text"
                                  placeholder="Enter Area Longitude"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.go_longitude && errors.go_longitude
                                  }
                                  defaultValue={form.go_longitude}
                                />
                                {touched.go_longitude &&
                                  errors.go_longitude && (
                                    <FormFeedback>
                                      {errors.go_longitude}
                                    </FormFeedback>
                                  )}
                              </Col>

                              {/* <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Director Name
                                </Label>
                                <Input
                                  name="go_directorName"
                                  type="text"
                                  placeholder="Enter Director Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.go_directorName &&
                                    errors.go_directorName
                                  }
                                  defaultValue={form.go_directorName}
                                />
                                {touched.go_directorName &&
                                  errors.go_directorName && (
                                    <FormFeedback>
                                      {errors.go_directorName}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  CircuitHead Name
                                </Label>
                                <Input
                                  name="go_circuitHeadName"
                                  type="text"
                                  placeholder="Enter CircuitHead Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.go_circuitHeadName &&
                                    errors.go_circuitHeadName
                                  }
                                  defaultValue={form.go_circuitHeadName}
                                />
                                {touched.go_circuitHeadName &&
                                  errors.go_circuitHeadName && (
                                    <FormFeedback>
                                      {errors.go_circuitHeadName}
                                    </FormFeedback>
                                  )}
                              </Col> */}

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Email <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="go_email"
                                  type="email"
                                  placeholder="Enter Email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={touched.go_email && errors.go_email}
                                  defaultValue={form.go_email}
                                />
                                {touched.go_email && errors.go_email && (
                                  <FormFeedback>{errors.go_email}</FormFeedback>
                                )}
                              </Col>

                              <Col
                                md={2}
                                sm={4}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Country Code{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Select
                                  name="go_countryCode"
                                  placeholder="Select Country Code"
                                  value={selectedCountryCode}
                                  onChange={value => {
                                    setSelectedCountryCode(value)
                                    setFieldValue(
                                      "go_countryCode",
                                      value ? value.value : ""
                                    )
                                  }}
                                  onBlur={evt => {
                                    setFieldTouched(
                                      "go_countryCode",
                                      true,
                                      true
                                    )
                                  }}
                                  options={countryCodes.map(countryCode => {
                                    return {
                                      label: `${countryCode.label} (+${countryCode.value})`,
                                      value: countryCode.value,
                                    }
                                  })}
                                  isClearable
                                  invalid={
                                    touched.go_countryCode &&
                                    errors.go_countryCode
                                  }
                                />
                                {touched.go_countryCode &&
                                  errors.go_countryCode && (
                                    <div className="invalid-react-select-dropdown">
                                      {errors.go_countryCode}
                                    </div>
                                  )}
                              </Col>

                              <Col
                                md={2}
                                sm={4}
                                xs={6}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Phone Number{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="go_phoneNumber"
                                  type="tel"
                                  placeholder="Enter Phone Number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.go_phoneNumber &&
                                    errors.go_phoneNumber
                                  }
                                  defaultValue={form.go_phoneNumber}
                                />
                                {touched.go_phoneNumber &&
                                  errors.go_phoneNumber && (
                                    <FormFeedback>
                                      {errors.go_phoneNumber}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Alt. Phone Number
                                </Label>
                                <Input
                                  name="go_altPhoneNumber"
                                  type="tel"
                                  placeholder="Enter Alt. Phone Number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.go_altPhoneNumber &&
                                    errors.go_altPhoneNumber
                                  }
                                  defaultValue={form.go_altPhoneNumber}
                                />
                                {touched.go_altPhoneNumber &&
                                  errors.go_altPhoneNumber && (
                                    <FormFeedback>
                                      {errors.go_altPhoneNumber}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Description
                                </Label>
                                <Input
                                  name="go_description"
                                  type="text"
                                  placeholder="Enter Description"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.go_description &&
                                    errors.go_description
                                  }
                                  defaultValue={form.go_description}
                                />
                                {touched.go_description &&
                                  errors.go_description && (
                                    <FormFeedback>
                                      {errors.go_description}
                                    </FormFeedback>
                                  )}
                              </Col>
                            </Row>

                            <Row className="mb-3">
                              <Col sm={12} md={6}>
                                <Row>
                                  <Col>
                                    <Label className="form-label">
                                      Address{" "}
                                    </Label>
                                    <Input
                                      name="go_address"
                                      type="textarea"
                                      rows={6}
                                      placeholder="Enter Address"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      invalid={
                                        touched.go_address && errors.go_address
                                      }
                                      defaultValue={form.go_address}
                                    />
                                    {touched.go_address &&
                                      errors.go_address && (
                                        <FormFeedback>
                                          {errors.go_address}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="mb-3"></Row>

                            <Row className="mb-3 text-center">
                              <Col>
                                <Button
                                  size="md"
                                  color="danger"
                                  type="button"
                                  disabled={submitLoading}
                                  className="mx-2"
                                  onClick={() => {
                                    props.history.push("/gesoffices")
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  size="md"
                                  color="info"
                                  type="submit"
                                  disabled={submitLoading}
                                >
                                  {!isEdit ? "Save" : "Update"}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </Formik>
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

GESOfficeModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default GESOfficeModal
