import * as url from "helpers/urlHelper"
import { get, post, put, del } from "helpers/apiHelper"

import { getApiConfig } from "helpers/authHelper"

export const getAllFreelanceTeachers = () => {
  return get(url.FREELANCE_TEACHER, getApiConfig())
}

export const getFreelanceTeacherById = ft_id => {
  return get(`${url.FREELANCE_TEACHER}/${ft_id}`, getApiConfig())
}

export const createNewFreelanceTeacher = reqBody => {
  if (!Object.keys(reqBody).length) {
    throw new Error("Invalid Data")
  }
  return post(url.FREELANCE_TEACHER, reqBody, getApiConfig(true))
}

export const updateFreelanceTeacherStatus = (ft_id, ft_status) => {
  return put(
    `${url.FREELANCE_TEACHER}/${ft_id}/${ft_status}`,
    {},
    getApiConfig()
  )
}

export const updateFreelanceTeacherDetails = (ft_id, reqBody) => {
  if (!Object.keys(reqBody).length) {
    throw new Error("Invalid Data")
  }

  return put(`${url.FREELANCE_TEACHER}/${ft_id}`, reqBody, getApiConfig(true))
}

export const deleteFreelanceTeacher = ft_id => {
  return del(`${url.FREELANCE_TEACHER}/${ft_id}`, getApiConfig())
}
