import { getTrainingProgram } from "helpers/backendHelpers/trainingProgram"
import { IMAGE_URL } from "helpers/urlHelper"
import MarkdownIt from "markdown-it"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import Lightbox from "react-image-lightbox"
import "react-markdown-editor-lite/lib/index.css"
import {
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap"

const mdParser = new MarkdownIt()

const ViewDetails = props => {
  const [programData, setProgramData] = useState(props.programData || {})
  const [programId, setProgramId] = useState(0)
  const [isEffects, setisEffects] = useState(false)
  const [activeAreaTab, setActiveAreaTab] = useState(1)
  const [description, setDescription] = useState("")
  let { id } = props.match.params || {}
  useEffect(() => {
    setProgramId(parseInt(id))

    if (id) {
      let programData = {}
      getTrainingProgram(id)
        .then(resp => {
          programData = resp.data.trainingProgram
          setDescription(programData.tp_description)
          setProgramData(programData)
        })
        .catch(err => {})
    }
  }, [])
  return (
    <React.Fragment>
      {isEffects ? (
        <Lightbox
          mainSrc={`${IMAGE_URL}/${programData?.tp_certificateTemplate}`}
          enableZoom={false}
          onCloseRequest={() => {
            setisEffects(!isEffects)
          }}
        />
      ) : null}
      <Row className="mt-3">
        <Col>
          <span className="text-info" style={{ fontSize: "2rem" }}>
            {programData.tp_programTitle}{" "}
          </span>
        </Col>
        <Col className="text-end">
          <Button
            color="info"
            onClick={() => {
              props.history.push("/training-program")
            }}
          >
            Back
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} className="mb-3">
          <Card>
            <Row className="no-gutters align-items-center">
              <Col md={12}>
                <CardBody>
                  <Row>
                    <Col md={4}>
                      <img
                        src={`${IMAGE_URL}/${programData?.tp_certificateTemplate}`}
                        alt="Degree Certificate"
                        onClick={() => {
                          setisEffects(true)
                        }}
                        style={{ cursor: "pointer" }}
                        className="avatar-lg img-thumbnail"
                        id={`degree_certificate_${programData.tp_id}`}
                      />
                      <UncontrolledTooltip
                        placement="top"
                        target={`degree_certificate_${programData.tp_id}`}
                      >
                        View Certificate
                      </UncontrolledTooltip>

                      <CardText className="mt-2">
                        <span>
                          {" "}
                          Is Free? : {programData.tp_isFree ? "Yes" : "No"}
                        </span>
                        <span>Duration: {programData.tp_duration}</span>
                      </CardText>
                    </Col>
                    <Col md={4}>
                      <CardText>Type: {programData.tp_typeOfProgram}</CardText>
                      <CardText>
                        Who Can Attend? :
                        <span
                          dangerouslySetInnerHTML={{
                            __html: programData.tp_whoCanAttend,
                          }}
                        ></span>
                      </CardText>
                      <CardText>
                        Benefits:
                        <span
                          dangerouslySetInnerHTML={{
                            __html: programData.tp_benefitsOfProgram,
                          }}
                        ></span>
                      </CardText>
                    </Col>

                    <Col md={4}>
                      <img
                        src={`${IMAGE_URL}/${programData?.tp_programImage}`}
                        alt="Program Image"
                        className="avatar-lg img-thumbnail"
                      />
                      <CardText className="mt-2">
                        <span>
                          {" "}
                          Is Free? : {programData.tp_isFree ? "Yes" : "No"}
                        </span>
                        <span>Program Image</span>
                      </CardText>
                    </Col>
                    <Col md={12}>
                      <CardText>
                        Description:
                        <span
                          dangerouslySetInnerHTML={{
                            __html: programData.tp_description,
                          }}
                        ></span>
                      </CardText>
                    </Col>
                  </Row>
                </CardBody>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      {/* <Nav tabs>
        <NavItem>
          {" "}
          <NavLink
            className={activeAreaTab === 3 ? "active" : ""}
            onClick={() => {
              setActiveAreaTab(3)
            }}
          >
            Participants
          </NavLink>
        </NavItem>
        <NavItem>
          {" "}
          <NavLink
            className={activeAreaTab === 1 ? "active" : ""}
            onClick={() => {
              setActiveAreaTab(1)
            }}
          >
            Content
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeAreaTab === 2 ? "active" : ""}
            onClick={() => {
              setActiveAreaTab(2)
            }}
          >
            Batch
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeAreaTab}>
        <TabPane tabId={1}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <ContentManagement />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={2}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Batches />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={3}>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Participants id={id} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent> */}
    </React.Fragment>
  )
}

ViewDetails.propTypes = {
  programData: PropTypes.object.isRequired,
}

export default ViewDetails
