import React, { useMemo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

//import components
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  SimpleStringValue,
  FreePaidValue,
  ArrayToStringValue,
  fetchImageAndConvertToBase64,
} from "helpers/common_helper_functions"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Button,
} from "reactstrap"
import { SaveToast } from "components/Common/SaveToast"

import {
  getAllTrainingPrograms,
  deleteTrainingProgram,
} from "helpers/backendHelpers/trainingProgram"
import {
  createCertificate,
  getAllParticipantsByStatusWithPag,
  getAllParticipantsByTPWithStatus,
  updateTrainingParticipantData,
} from "helpers/backendHelpers/participants"
import PDFModal from "pages/ContentManagement/ViewModal"
import html2pdf from "html2pdf.js"
import certificate from "../../assets/images/certificate.png"
import moment from "moment"

const TrainingProgram = props => {
  //meta title
  document.title = "Training Program | LMS Ghana"
  const tp_id = props.location?.state?.tp_id
  const tps_trainingStatus = props.location?.state?.tps_trainingStatus
  //delete order
  const [deleteModal, setDeleteModal] = useState(false)
  const [saved, setSaved] = useState(false)
  const [loading, setLoading] = useState(false)
  const [trainingPrograms, setTrainingPrograms] = useState([])
  const [trainingParticipantData, setTrainingParticipantData] = useState({})
  const [trainingParticipants, setTrainingParticipants] = useState([])
  const [columnsToExport, setColumnsToExport] = useState([])
  const [pdfFile, setPdfFile] = useState("")
  const [modal1, setModal1] = useState(false)
  const [pageIndex, setPageIndex] = useState(1)
  const [pageCount, setPageCount] = useState(30)
  const [limit, setLimit] = useState(100)
  const [totalRecords, setTotalRecords] = useState(0)
  const [temp, setTemp] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [currentDate, setCurrentDate] = useState("")

  useEffect(() => {
    fetchAllTrainingPrograms()
    fetchAllTrainingParticipantsWithPag()
  }, [saved])

  useEffect(() => {
    fetchAllTrainingParticipantsWithPag()
  }, [pageIndex, limit, searchValue])

  useEffect(() => {
    const currentDate = new Date()
    const day = currentDate.getDate()
    const month = currentDate.toLocaleString("default", { month: "long" })
    const year = currentDate.getFullYear()
    const formattedDate = `${day}_${month}_${year}`
    setCurrentDate(formattedDate)
    console.log(formattedDate)
  }, [])

  useEffect(() => {
    const updatedParticipants = trainingParticipants.map(
      ({
        tps_updatedAt,
        tb_id,
        tps_OldDBId,
        tps_certificate,
        tps_createdAt,
        tps_id,
        tps_signFontFamily,
        tps_signText,
        tps_status,
        tps_tp_id,
        tps_trainingStatus,
        tps_userType,
        tps_userId,
        userType,
        ...rest
      }) => rest
    )

    const reArrangedParticipants = updatedParticipants.map(obj => ({
      username: obj.username,
      userEmail: obj.userEmail,
      userRegion: obj.userRegion,
      userDistrict: obj.userDistrict,
      scpre: obj.tps_score,
      examDate: obj.tps_examDate,
      "Staff Id": obj.staffId,
      tps_trainingOption: obj.tps_trainingOption,
      tps_isTrainingCompleted: obj.tps_isTrainingCompleted,
      tps_signedForm: obj.tps_signedForm,
      tps_attentionFormDate: obj.tps_attentionFormDate,
      userId: obj.userId,
    }))
    setColumnsToExport(reArrangedParticipants)
  }, [trainingParticipants])

  const fetchAllTrainingPrograms = async () => {
    try {
      setLoading(true)
      const response = await getAllTrainingPrograms()
      let { trainingPrograms } = response.data || {}
      trainingPrograms = trainingPrograms || []
      setTrainingPrograms(trainingPrograms)
      setLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Schools"

      setTrainingPrograms([])
      setLoading(false)

      return SaveToast({ message, type: "error" })
    }
  }

  const fetchAllTrainingParticipantsWithPag = async () => {
    try {
      setLoading(true)
      const response = await getAllParticipantsByStatusWithPag(
        tp_id,
        tps_trainingStatus,
        pageIndex,
        limit
      )
      let { trainingParticipants } = response.data || {}
      setTotalRecords(trainingParticipants.count)
      let pageCount = Math.ceil(trainingParticipants.count / limit)
      setPageCount(pageCount)
      setTrainingParticipants(trainingParticipants.rows)
      setLoading(false)
    } catch (error) {
      const message = error?.message || "There Was A Problem Fetching Content"

      setLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const fetchColumnWiseSearchAPI = async query => {
    try {
      // setLoading(true)
      // const response = await getAllTeacherColumnWiseSearchAPI(
      //   pageIndex,
      //   limit,
      //   query
      // )
      // let { teachers } = response.data || {}
      // teachers = teachers || []
      // setTeachers(teachers.rows)
      // setTotalRecords(teachers.count)
      // let pageCount = Math.ceil(teachers.count / limit)
      // setPageCount(pageCount)
      // setTemp(!temp)
      // setLoading(false)
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Teachers"
      setLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const toggleViewModal = () => {
    setModal1(!modal1)
    if (pdfFile) {
      setPdfFile(null)
    }
  }

  const onClickUpdate = program => {
    setTrainingParticipantData(program)
    setDeleteModal(true)
  }

  const handleUpdateTrainingStatus = async id => {
    if (!id) {
      return SaveToast({ message: "Invalid Participant ID", type: "error" })
    }
    try {
      // 5 = Approve Certificate
      // let body = {
      //   tps_trainingStatus: 5,
      // }
      const certificateResponse = await createCertificate(id)
      if (certificateResponse?.status) {
        const message = "Certificate Approved Successfully"
        SaveToast({ message, type: "success" })
        setSaved(prevSaved => !prevSaved)
        setDeleteModal(false)
      }
    } catch (error) {
      const message = error?.message || "There Was A Problem Deleting Program"
      return SaveToast({ message, type: "error" })
    }
  }

  const updateTrainingParticipants = async (tps_id, tps_userId, pdf) => {
    try {
      const fileName = `Certificate_of_Completion_${tps_userId}.pdf`
      const certificateFile = new Blob([pdf], {
        type: "application/pdf",
      })
      certificateFile.name = fileName
      // 3 = Admin Approved
      let body = {
        tps_trainingStatus: 5,
        tps_certificate: certificateFile,
      }
      const response = await updateTrainingParticipantData(tps_id, body)
      if (response.status) {
        const message = "Certificate Approved Successfully"
        SaveToast({ message, type: "success" })
        setSaved(prevSaved => !prevSaved)
        setDeleteModal(false)
      }
    } catch (error) {
      const message = error?.message || "There Was A Problem Deleting Program"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddButtonClick = () => {
    props.history.push("/training-program/add")
  }

  const handleBackButtonClick = () => {
    // props.history.push("/batch-creation/add")
    props.history.push({
      pathname: `/training-program`,
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "username",
        filterable: true,
        Cell: cellProps => {
          return <ArrayToStringValue {...cellProps} />
        },
      },
      {
        Header: "Type",
        accessor: "userType",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "userEmail",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Score",
        accessor: "tps_score",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "",
        filterable: true,
        Cell: cellProps => {
          return (
            <Button
              color="info"
              onClick={async () => {
                const participantData = cellProps.row.original
                console.log("participantData12", participantData)
                // handleUpdateTrainingStatus(participantData?.tps_id)
                const pdf = await convertToPdf(participantData)
                updateTrainingParticipants(
                  participantData?.tps_id,
                  participantData?.tps_userId,
                  pdf
                )
              }}
            >
              Approve Certificate
            </Button>
          )
        },
      },
    ],
    []
  )

  const generateCertificate = async data => {
    const certificateImg = await fetchImageAndConvertToBase64(`${certificate}`)
    const username = data.username
    const formatedDate = moment(
      data.tps_examDate,
      "YYYY-MM-DD HH:mm:ss.SSS Z"
    ).format("D MMM YYYY")
    return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    
        <title>certificate
        </title>
        <style>
        body{
          font-family: "Arial", sans-serif;
        }
            .input-1 {
              position: absolute;
              top: 44.5%;
              right: 0%;
              left:0%;
              width: 85%;
              border: none;
              text-align: center;
              display: block;
              justify-content: center;
              margin-left:auto;
              margin-right:auto;
              font-size:25px;
              color:black;
              font-family: "Arial", sans-serif;
            }
    
            .input-2 {
                position: absolute;
                top: 57%;
                right: 50%;
                left:0%;
                width: 85%;
                border: none;
                font-weight: bolder;
                text-align: center;
                display: block;
                justify-content: center;
                margin-left:auto;
                margin-right:auto;
            }

            .input-3 {
                position: absolute;
                top: 67%;
                right: 0%;
                left:0%;
                width: 85%;
                border: none;
                text-align: center;
                display: block;
                justify-content: center;
                margin-left:auto;
                margin-right:auto;
                font-size:25px;
                color:black;
                font-family: "Arial", sans-serif;
            }
            .border-none{
                border:none;
            }
    
            input:focus {
                outline: none;
            }
        </style>
    </head>
    
    <body>
        <div class="container">
            <div class="contant position-relative d-flex justify-content-center">
                <img src='${certificateImg}' alt="altttt"
                style="height:780px;width: 1920px;" >
                    <h3 style="font-weight:bolder;" class="w-100 text-center border-none input-1">${username}</h3>
                    <h3 style="font-weight:bolder;" class="w-100 text-center border-none input-3">${formatedDate}</h3>
                
            </div>
        </div>
    </body>
    
    </html>
    `
  }

  const convertToPdf = async data => {
    const element = await generateCertificate(data)
    const fileName = `Certificate_of_Completion_${data?.tps_userId}.pdf`
    var opt = {
      filename: fileName,
      jsPDF: { format: "A4", orientation: "portrait" },
    }

    const pdf = await html2pdf().from(element).set(opt).output("arraybuffer")

    return pdf
  }

  return (
    <React.Fragment>
      <PDFModal isOpen={modal1} toggle={toggleViewModal} pdf={pdfFile} />
      <DeleteModal
        deleteMsg="Yes, Approve!"
        show={deleteModal}
        onDeleteClick={handleUpdateTrainingStatus}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Training Program" breadcrumbItem="Program" /> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    canExportCsv={true}
                    csvFileName={`Exam_Pass_Participants.csv`}
                    csvLink="/api/v1/admin/training-participants/download?tps_trainingStatus=4"
                    data={trainingParticipants}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    isBackOption={true}
                    handleAddButtonClick={handleAddButtonClick}
                    handleBackButtonClick={handleBackButtonClick}
                    customPageSize={10}
                    className="custom-header-css"
                    canPrint={false}
                    dataFetchLoading={loading}
                    noDataMessage="No system activity found."
                    pageIndexFromApi={pageIndex}
                    setPageIndexFromApi={setPageIndex}
                    pageCountFromApi={pageCount}
                    setPageCount1={setPageCount}
                    totalRecords={totalRecords}
                    limit={limit}
                    setLimit={setLimit}
                    customPagination={true}
                    setSearchValue={setSearchValue}
                    fetchColumnWiseSearchAPI={fetchColumnWiseSearchAPI}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TrainingProgram
