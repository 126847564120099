import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  CardImg,
  CardText,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"

import Notifications from "./Notifications"
import AttendanceChart from "./StatasticsChart"
import ImageSlider from "./ImageSlider"
import { getSchool } from "helpers/backendHelpers/school"

const ViewDetails = props => {
  const [schoolId, setSchoolId] = useState(0)
  const [schoolData, setSchoolData] = useState(props.schoolData || {})
  const [activeAreaTab, setActiveAreaTab] = useState(1)
  useEffect(() => {
    let { id } = props.match.params || {}
    setSchoolId(parseInt(id))

    if (id) {
      let schoolData = {}
      getSchool(id)
        .then(resp => {
          schoolData = resp.data.school

          let { sc_region, sc_district, sc_circuit } = schoolData

          sc_region = 2
          sc_district = 3
          sc_circuit = 4

          Object.keys(schoolData).forEach(key => {
            if (schoolData[key] === null) {
              delete schoolData[key]
            }
          })
          setSchoolData(schoolData)
        })
        .catch(err => {})
    }
  }, [])

  return (
    <React.Fragment>
      <Row className="mt-3">
        <Col>
          <span className="text-info" style={{ fontSize: "2rem" }}>
            {schoolData.sc_schoolName}
          </span>
        </Col>
        <Col className="text-end">
          <Button
            color="info"
            onClick={() => {
              props.history.push("/schools")
            }}
          >
            Back
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} className="mb-3">
          <Card>
            <Row className="no-gutters align-items-center">
              <Col md={3}>
                <ImageSlider />
              </Col>
              <Col md={9}>
                <CardBody>
                  <Row>
                    <Col md={4}>
                      <CardText>
                        Status:{" "}
                        <i
                          className={`mdi mdi-circle text-${
                            schoolData.sc_status ? "success" : "danger"
                          } align-middle me-1`}
                        />
                        {schoolData.sc_status ? "Active" : "Inactive"}
                      </CardText>
                      <CardText>School Id: {schoolData.sc_schoolId}</CardText>
                      <CardText>
                        Head Name: {schoolData.sc_schoolHeadName}
                      </CardText>
                    </Col>
                    <Col md={4}>
                      <CardText>
                        Mobile No.: {schoolData.sc_phoneNumber}
                      </CardText>
                      <CardText>{schoolData.sc_email}</CardText>
                      <CardText>{schoolData.sc_address}</CardText>
                    </Col>
                    <Col md={4}>
                      <CardText>
                        Alt. Mobile No.: {schoolData.sc_altPhoneNumber}
                      </CardText>
                      <CardText>
                        {`${schoolData.sc_circuit}, ${schoolData.sc_district}, ${schoolData.sc_region}`}
                      </CardText>
                    </Col>
                  </Row>
                </CardBody>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeAreaTab === 1 && "active"}
            onClick={() => {
              setActiveAreaTab(1)
            }}
          >
            Statastics
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={activeAreaTab === 2 && "active"}
            onClick={() => {
              setActiveAreaTab(2)
            }}
          >
            Notifications
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeAreaTab}>
        <TabPane tabId={1}>
          <Row className="mt-3">
            <Col>
              <Card>
                <CardBody>
                  <AttendanceChart />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={2}>
          <Row className="mt-3">
            <Col>
              <Card>
                <CardBody>
                  <Notifications />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </React.Fragment>
  )
}

ViewDetails.propTypes = {
  schoolData: PropTypes.object.isRequired,
}

export default ViewDetails
