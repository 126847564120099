import React from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap"

const GESOfficeStaffModal = props => {
  const { isOpen, toggle, staffMember } = props
  {
    if (staffMember !== null)
      return (
        <>
          <Modal
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal"
            tabIndex="-1"
            toggle={toggle}
          >
            <ModalHeader toggle={toggle}>OfficeStaff Details</ModalHeader>
            <ModalBody>
              <Table>
                <tbody>
                  <tr>
                    <th>Full Name</th>
                    <td>{staffMember.gs_fullName}</td>
                  </tr>
                  <tr>
                    <th>Staff-Role</th>
                    <td>{staffMember.gs_staffRole}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{staffMember.gs_email}</td>
                  </tr>
                  <tr>
                    <th>Phone Number</th>
                    <td>{staffMember.gs_phoneNumber}</td>
                  </tr>
                  <tr>
                    <th>Alt. Phone Number</th>
                    <td>{staffMember.gs_altPhoneNumber}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>{staffMember.gs_status ? "Active" : "Inactive"}</td>
                  </tr>
                </tbody>
              </Table>
            </ModalBody>
          </Modal>
        </>
      )
  }
}

GESOfficeStaffModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default GESOfficeStaffModal
