import React from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap"

const TutorModal = props => {
  const { isOpen, toggle, tutor } = props
  {
    if (tutor !== null)
      return (
        <>
          <Modal
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="tutorModal"
            tabIndex="-1"
            toggle={toggle}
          >
            <ModalHeader toggle={toggle}>Tutor Details</ModalHeader>
            <ModalBody>
              <Table>
                <tbody>
                  <tr>
                    <th>Full Name</th>
                    <td>{tutor.tu_fullName}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{tutor.tu_email}</td>
                  </tr>
                  <tr>
                    <th>Phone Number</th>
                    <td>{tutor.tu_phoneNumber}</td>
                  </tr>
                  <tr>
                    <th>Region</th>
                    <td>{tutor.tu_region}</td>
                  </tr>
                  <tr>
                    <th>District</th>
                    <td>{tutor.tu_district}</td>
                  </tr>
                  <tr>
                    <th>Circuit</th>
                    <td>{tutor.tu_circuit}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>{tutor.tu_status ? "Active" : "Inactive"}</td>
                  </tr>
                </tbody>
              </Table>
            </ModalBody>
          </Modal>
        </>
      )
  }
}

TutorModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default TutorModal
