import {
  getSubjectWiseEBooks,
  getSubjectWiseVideos,
} from "helpers/backendHelpers/books"
import {
  getLevelWiseSchoolCount,
  getRegionWiseSchoolCount,
} from "helpers/backendHelpers/school"
import React, { useEffect, useState } from "react"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"

const DashboardCards = () => {
  const [subjectWiseEBooks, setSubjectWiseEBooks] = useState([])
  const [subjectWiseVideos, setSubjectWiseVideos] = useState([])
  const [regionWiseSchools, setRegionWiseSchools] = useState([])
  const [levelWiseSchools, setLevelWiseSchools] = useState([])

  // Loading subject wise e-books
  useEffect(() => {
    const loadSubjectWiseEBooks = async () => {
      try {
        const data = await getSubjectWiseEBooks()
        setSubjectWiseEBooks(data.data.bookCount)
      } catch (error) {
        console.log(error)
      }
    }

    loadSubjectWiseEBooks()
  }, [])

  // Loading subject wise videos
  useEffect(() => {
    const loadSubjectWiseVideos = async () => {
      try {
        const data = await getSubjectWiseVideos()
        setSubjectWiseVideos(data.data.bookCount)
      } catch (error) {
        console.log(error)
      }
    }

    loadSubjectWiseVideos()
  }, [])

  // Loading region wise schools
  useEffect(() => {
    const loadRegionWiseSchools = async () => {
      try {
        const data = await getRegionWiseSchoolCount()
        setRegionWiseSchools(data.data.schoolCount)
      } catch (error) {
        console.log(error)
      }
    }

    loadRegionWiseSchools()
  }, [])

  // Loading level wise schools
  useEffect(() => {
    const loadLevelWiseSchools = async () => {
      try {
        const data = await getLevelWiseSchoolCount()
        setLevelWiseSchools(data.data.schoolCount)
      } catch (error) {
        console.log(error)
      }
    }

    loadLevelWiseSchools()
  }, [])

  return (
    <Row>
      <Col xl="6">
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Subject-wise E-Books</CardTitle>
            <div className="container">
              <table className="table table-hover">
                <tbody>
                  {subjectWiseEBooks.map(data => (
                    <tr key={data.subject}>
                      <td>{data.subject}</td>
                      <td>{data.count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <CardTitle className="mb-4">Region-wise Schools</CardTitle>
            <div className="container">
              <table className="table table-hover">
                <tbody>
                  {regionWiseSchools.map(data => (
                    <tr key={data.region}>
                      <td>{data.region}</td>
                      <td>{data.count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col xl="6">
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Subject-wise Videos</CardTitle>
            <div className="container">
              <table className="table table-hover">
                <tbody>
                  {subjectWiseVideos.map(data => (
                    <tr key={data.subject}>
                      <td>{data.subject}</td>
                      <td>{data.count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Level-wise Schools</CardTitle>
            <div className="container">
              <table className="table table-hover">
                <tbody>
                  {levelWiseSchools.map(data => (
                    <tr key={data.level}>
                      <td>{data.level}</td>
                      <td>{data.count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default DashboardCards
