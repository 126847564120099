import { getApiConfig } from "helpers/authHelper"
import { get, post, put, del } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllGenre = () => {
  return get(url.BOOK_GENRE, getApiConfig())
}

export const getGenre = id => {
  return get(`${url.BOOK_GENRE}/${id}`, getApiConfig())
}

export const createGenre = data => {
  return post(url.BOOK_GENRE, data, getApiConfig())
}

export const updateGenre = (id, data) => {
  return put(`${url.BOOK_GENRE}/${id}`, data, getApiConfig())
}

export const deleteGenre = id => {
  return del(`${url.BOOK_GENRE}/${id}`, getApiConfig())
}
