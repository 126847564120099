import { getApiConfig } from "helpers/authHelper"
import { get, post, put, del } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllTrainingBatch = () => {
  return get(url.TRAINING_BATCH, getApiConfig())
}

export const getTrainingBatch = id => {
  return get(`${url.TRAINING_BATCH}/${id}`, getApiConfig())
}

export const getTrainingBatchByTP = id => {
  return get(`${url.TRAINING_BATCH}/getAllTrainingBatchByTP/${id}`, getApiConfig())
}

export const createTrainingBatch = data => {
  return post(url.TRAINING_BATCH, data, getApiConfig(true))
}

export const updateTrainingBatch = (id, data) => {
  return put(`${url.TRAINING_BATCH}/${id}`, data, getApiConfig(true))
}

export const deleteTrainingBatch = id => {
  return del(`${url.TRAINING_BATCH}/${id}`, getApiConfig())
}
