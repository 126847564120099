import { getApiConfig } from "helpers/authHelper"
import { get, post, put, del } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllTrainingExam = () => {
  return get(url.TRAINING_EXAM, getApiConfig())
}

export const createTrainingExam = data => {
  return post(url.TRAINING_EXAM, data, getApiConfig(true))
}

export const updateTrainingExam = (id,data) => {
  return put(`${url.TRAINING_EXAM}/${id}`, data, getApiConfig())
}

export const deleteTrainingExam = id => {
  return del(`${url.TRAINING_EXAM}/${id}`, getApiConfig())
}

export const getTrainingExam = id => {
  return get(`${url.TRAINING_EXAM}/${id}`, getApiConfig())
}
