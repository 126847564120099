import { getApiConfig } from "helpers/authHelper"
import { get, post, put, del } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllTeacher = () => {
  return get(url.TEACHER, getApiConfig())
}

export const getAllTeacherWithFullSearchAPI = (
  page,
  limit,
  value,
  tc_userType
) => {
  return get(
    `${url.TEACHER}/pagination?page=${page}&limit=${limit}&tc_region=${value}&tc_fullName=${value}&tc_staffId=${value}&tc_district=${value}&tc_email=${value}&tc_phoneNumber=${value}&tc_education=${value}&tc_userType=${tc_userType}`,
    getApiConfig()
  )
}

export const getAllTeacherColumnWiseSearchAPI = (
  page,
  limit,
  query,
  tc_userType
) => {
  return get(
    `${url.TEACHER}/pagination?page=${page}&limit=${limit}${query}&tc_userType=${tc_userType}`,
    getApiConfig()
  )
}

export const getTeacher = id => {
  return get(`${url.TEACHER}/${id}`, getApiConfig())
}

export const createTeacher = data => {
  return post(url.TEACHER, data, getApiConfig(true))
  // return post(`${url.TEACHER}1`, data, getApiConfig(true))
}

export const updateTeacher = (id, data) => {
  return put(`${url.TEACHER}/${id}`, data, getApiConfig(true))
}

export const deleteTeacher = id => {
  return del(`${url.TEACHER}/${id}`, getApiConfig())
}

export const getTeachersCount = () => {
  return get(`${url.TEACHER}/count`, getApiConfig())
}