import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import ViewDetails from "./ViewDetails"
import { SaveToast } from "components/Common/SaveToast"
import UpdateModal from "../../components/Common/UpdateModal"

import {
  updateContentTeamMember,
  getContentTeamMember,
  createContentTeamMember,
} from "helpers/backendHelpers/contentTeam"
import { IMAGE_URL } from "helpers/urlHelper"
import SubmitLoader from "components/Common/SubmitLoader"
import {
  createTermlySchemeMaster,
  getTermlySchemeMaster,
  updateTermlySchemeMaster,
} from "helpers/backendHelpers/TermlySchemeMaster"
import DatePicker from "react-datepicker"
import Select from "react-select"
import { classRoomType1 } from "common/data/dropdownVals"
import moment from "moment"
import {
  getAllCategories,
  getAllContentCategory,
  getSubCategories,
} from "helpers/backendHelpers/books"

const TeamMemberModal = props => {
  const [isEdit, setIsEdit] = useState(false)
  const [isView, setIsView] = useState(false)
  const [memberId, setmemberId] = useState(0)
  const [updateModal, setUpdateModal] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [selectedYear, setSelectedYear] = useState("")
  const [selectedDate, setSelectedDate] = useState("")
  const [selectedClass, setSelectedClass] = useState({})
  const [termlySchemeId, setTermlySchemeId] = useState(0)
  const [termNumber, setTermNumber] = useState(0)

  const [form, setForm] = useState({
    tsm_year: "",
    tsm_termNumber: "",
    tsm_date: "",
    tsm_classId: "",
    tsm_subject: "",
    tsm_weekNumber: "",
    tsm_strand: "",
    tsm_subStrand: "",
    tsm_contentStandards: "",
    tsm_indicators: "",
    tsm_resources: "",
    tsm_allData: {},
  })
  const loopArray = [
    { id: 0 },
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
    { id: 10 },
    { id: 11 },
  ]
  const [categoryValues, setCategoryValues] = useState([])
  const [subCategoryValues, setSubCategoryValues] = useState([])

  useEffect(() => {
    document.getElementById("termlySchemeMaster").classList.add("mm-active")
    let { type, id } = props.match.params || {}
    fetchCategoryDropDownValues()
    getAllSubCategoriesFromApi()
    document.getElementById("firstTermNumber").checked = true
    switch (type) {
      case "edit":
        setIsEdit(true)
        setIsView(false)
        setTermlySchemeId(parseInt(id))
        break
      case "view":
        setIsView(true)
        setIsEdit(false)
        setTermlySchemeId(parseInt(id))
        break
      case "add":
      default:
        setIsView(false)
        setIsEdit(false)
        setmemberId(0)
        setTermlySchemeId(0)
        break
    }
    if (id) {
      fetchTermlySchemeMasterForEdit(id)
    }
  }, [])

  const handleUpdateTermlySchemeMaster = () => {
    setUpdateModal(false)
    SaveToast({
      message: "Termly SchemeMaster Updated Successfully",
      type: "success",
    })
    props.history.push("/termlySchemeMaster")
  }

  const [termlySchemeMasterForm, setTermlySchemeMasterForm] = useState({
    id: 1,
    tsc_weekNumber0: 1,
    tsc_strand0: "",
    tsc_subStrand0: "",
    tsc_contentStandards0: "",
    tsc_indicators0: "",
    tsc_resources0: "",
    id: 2,
    tsc_weekNumber1: 2,
    tsc_strand1: "",
    tsc_subStrand1: "",
    tsc_contentStandards1: "",
    tsc_indicators1: "",
    tsc_resources1: "",
    id: 3,
    tsc_weekNumber2: 3,
    tsc_strand2: "",
    tsc_subStrand2: "",
    tsc_contentStandards2: "",
    tsc_indicators2: "",
    tsc_resources2: "",
    id: 4,
    tsc_weekNumber3: 4,
    tsc_strand3: "",
    tsc_subStrand3: "",
    tsc_contentStandards3: "",
    tsc_indicators3: "",
    tsc_resources3: "",
    id: 5,
    tsc_weekNumber4: 5,
    tsc_strand4: "",
    tsc_subStrand4: "",
    tsc_contentStandards4: "",
    tsc_indicators4: "",
    tsc_resources4: "",
    id: 6,
    tsc_weekNumber5: 6,
    tsc_strand5: "",
    tsc_subStrand5: "",
    tsc_contentStandards5: "",
    tsc_indicators5: "",
    tsc_resources5: "",
    id: 7,
    tsc_weekNumber6: 7,
    tsc_strand6: "",
    tsc_subStrand6: "",
    tsc_contentStandards6: "",
    tsc_indicators6: "",
    tsc_resources6: "",
    id: 8,
    tsc_weekNumber7: 8,
    tsc_strand7: "",
    tsc_subStrand7: "",
    tsc_contentStandards7: "",
    tsc_indicators7: "",
    tsc_resources7: "",
    id: 9,
    tsc_weekNumber8: 9,
    tsc_strand8: "",
    tsc_subStrand8: "",
    tsc_contentStandards8: "",
    tsc_indicators8: "",
    tsc_resources8: "",
    id: 10,
    tsc_weekNumber9: 10,
    tsc_strand9: "",
    tsc_subStrand9: "",
    tsc_contentStandards9: "",
    tsc_indicators9: "",
    tsc_resources9: "",
    id: 11,
    tsc_weekNumber10: 11,
    tsc_strand10: "",
    tsc_subStrand10: "",
    tsc_contentStandards10: "",
    tsc_indicators10: "",
    tsc_resources10: "",
    id: 12,
    tsc_weekNumber11: 12,
    tsc_strand11: "",
    tsc_subStrand11: "",
    tsc_contentStandards11: "",
    tsc_indicators11: "",
    tsc_resources11: "",
  })

  const handleChangeForm = e => {
    setTermlySchemeMasterForm(pre => ({
      ...pre,
      [e.target.name]: e.target.value,
    }))
  }

  const handleAddTermlySchemeMasterSubmit = async data => {
    try {
      setSubmitLoading(true)
      const response = await createTermlySchemeMaster(data)
      let message = response?.message || "Termly Schema Added Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)
      props.history.push("/termlySchemeMaster")
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Adding Termly Schema"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleEditTermlySchemeMasterSubmit = async (id, data) => {
    if (!id) {
      return SaveToast({
        message: "Please enter Termly Schema Id",
        type: "error",
      })
    }
    try {
      setSubmitLoading(true)
      const response = await updateTermlySchemeMaster(id, data)
      let message = response?.message || "Termly Schema Updated Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)
      props.history.push("/termlySchemeMaster")
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Adding Termly Schema"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const fetchTermlySchemeMasterForEdit = async id => {
    try {
      let response = await getTermlySchemeMaster(id)
      let { termlySchemeMaster } = response.data || {}
      termlySchemeMaster = termlySchemeMaster || {}
      setSelectedYear(new Date().setFullYear(termlySchemeMaster.tsm_year, 1, 1))

      setSelectedDate(moment(termlySchemeMaster.tsm_date).format("YYYY-MM-DD"))
      setSelectedClass({
        label: termlySchemeMaster.tsm_classId,
        value: termlySchemeMaster.tsm_classId,
      })
      setTermlySchemeMasterForm(termlySchemeMaster?.tsm_allData)
      if (termlySchemeMaster?.tsm_termNumber === 0) {
        document.getElementById("firstTermNumber").checked = true
      } else if (termlySchemeMaster.tsm_termNumber === 1) {
        document.getElementById("secondTermNumber").checked = true
      }
      return setForm(termlySchemeMaster)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching Notification details"

      setForm(form)
      return SaveToast({ message, type: "error" })
    }
  }

  const fetchCategoryDropDownValues = async () => {
    try {
      let response = await getAllContentCategory()
      let { categories } = response.data || {}
      categories = categories || []
      let allCategory = []
      categories.map(category => {
        let item = category.category.map(data => {
          return {
            id: data.CategoryId,
            value: data.CategoryName,
          }
        })
        allCategory.push(...item)
      })
      setCategoryValues(allCategory)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching mainCategories"

      return SaveToast({ message, type: "error" })
    }
  }

  const getAllSubCategoriesFromApi = async (filterCategory = "") => {
    try {
      let response = await getSubCategories()

      let { subCategories } = response.data
      let vals = []
      if (filterCategory) {
        subCategories.map(mainCategory => {
          let { category } = mainCategory
          category.map(Category => {
            let { subCategory } = Category
            if (Category.categoryName === filterCategory) {
              subCategory.map(cat => {
                vals.push({ name: cat.subCateName, value: cat.subCateName })
              })
            }
          })
        })
      } else {
        subCategories.map(mainCategory => {
          let { category } = mainCategory
          category.map(Category => {
            let { subCategory } = Category
            subCategory.map(cat => {
              vals.push({ name: cat.subCateName, value: cat.subCateName })
            })
          })
        })
        let tempVals = []
        vals.length > 0 &&
          vals.map((data, i) => {
            if (i < 4) {
              tempVals.push(data.value)
            }
          })
      }
      setSubCategoryValues(vals)
    } catch (error) {
      setError(error)
    }
  }

  return (
    <React.Fragment>
      <UpdateModal
        show={updateModal}
        onUpdateClick={handleUpdateTermlySchemeMaster}
        onCloseClick={() => setUpdateModal(false)}
      />
      <div className="page-content">
        <div
          className="container-fluid"
          style={submitLoading ? { position: "relative", opacity: "0.8" } : {}}
        >
          {/* <Breadcrumbs title="Content" breadcrumbItem="Team" /> */}
          {submitLoading ? <SubmitLoader /> : <></>}
          {isView ? (
            <>
              <ViewDetails
                teamMemebrId={memberId}
                teamMemberData={form}
                {...props}
              />
            </>
          ) : (
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={form}
                  validationSchema={Yup.object({
                    tsm_date: Yup.string().required("Please Select Date"),
                    tsm_classId: Yup.string().required("Please Select Class"),
                    tsm_subject: Yup.string().required("Please Enter Subject"),
                  })}
                  onSubmit={values => {
                    let termlyScheme = values
                    let yearDate = new Date(selectedYear).getFullYear()
                    if (isEdit) {
                      termlyScheme["tsm_year"] = yearDate
                      termlyScheme["tsm_allData"] = JSON.stringify(
                        termlySchemeMasterForm
                      )
                      termlyScheme["tsm_termNumber"] = termNumber

                      delete termlyScheme["tsm_id"]
                      return handleEditTermlySchemeMasterSubmit(
                        termlySchemeId,
                        termlyScheme
                      )
                    } else {
                      termlyScheme["tsm_termNumber"] = termNumber
                      termlyScheme["tsm_year"] = yearDate
                      termlyScheme["tsm_allData"] = JSON.stringify(
                        termlySchemeMasterForm
                      )
                      return handleAddTermlySchemeMasterSubmit(termlyScheme)
                    }
                  }}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    setFieldTouched,
                  }) => (
                    <>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          handleSubmit(e)
                          return false
                        }}
                      >
                        <Row>
                          <Col>
                            <Row className="mb-3">
                              <Col md={4} sm={6} xs={4} className="mb-3">
                                <Label className="form-label">
                                  Enter Year{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <DatePicker
                                  name="tsm_year"
                                  onChange={e => {
                                    setSelectedYear(e)
                                  }}
                                  placeholderText="Select Year"
                                  selected={
                                    selectedYear != undefined && selectedYear
                                  }
                                  invalid={touched.tsm_year && errors.tsm_year}
                                  dateFormat="yyyy"
                                  showYearPicker
                                />
                                {touched.tsm_year && errors.tsm_year && (
                                  <FormFeedback>{errors.tsm_year}</FormFeedback>
                                )}
                              </Col>
                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label>Term Number</Label>
                                <br />
                                <Label
                                  htmlFor="firstTermNumber"
                                  className="form-label"
                                >
                                  First Term
                                </Label>
                                &nbsp;&nbsp;
                                <Input
                                  id="firstTermNumber"
                                  name="tsm_termNumber"
                                  type="radio"
                                  onChange={event => {
                                    if (event.target.checked) {
                                      let temp = form
                                      temp["tsm_termNumber"] = 0
                                      values["tsm_termNumber"] = 0
                                      setTermNumber(0)
                                      setForm(temp)
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.tsm_termNumber &&
                                    errors.tsm_termNumber
                                  }
                                  value={form.tsm_termNumber}
                                />
                                &nbsp;&nbsp;
                                <Label
                                  htmlFor="secondTermNumber"
                                  className="form-label"
                                >
                                  Second Term
                                </Label>
                                &nbsp;&nbsp;
                                <Input
                                  id="secondTermNumber"
                                  name="tsm_termNumber"
                                  type="radio"
                                  // value={false}
                                  placeholder="Enter Title"
                                  onChange={event => {
                                    if (event.target.checked) {
                                      let temp = form
                                      temp["tsm_termNumber"] = 1
                                      values["tsm_termNumber"] = 1
                                      setTermNumber(1)
                                      setForm(temp)
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.tsm_termNumber &&
                                    errors.tsm_termNumber
                                  }
                                  value={form.tsm_termNumber}
                                  // defaultChecked
                                />
                                {touched.tsm_termNumber &&
                                  errors.tsm_termNumber && (
                                    <FormFeedback>
                                      {errors.tsm_termNumber}
                                    </FormFeedback>
                                  )}
                              </Col>
                              {/* <Col md={4} sm={6} xs={4} className="mb-3">
                                <Label className="form-label">
                                  Term Number{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="tsm_termNumber"
                                  type="number"
                                  placeholder="Enter Term Number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.tsm_termNumber &&
                                    errors.tsm_termNumber
                                  }
                                  value={values.tsm_termNumber}
                                />
                                {touched.tsm_termNumber &&
                                  errors.tsm_termNumber && (
                                    <FormFeedback>
                                      {errors.tsm_termNumber}
                                    </FormFeedback>
                                  )}
                              </Col> */}
                              <Col md={4} sm={6} xs={4} className="mb-3">
                                <Label className="form-label">
                                  Select Date{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="tsm_date"
                                  type="date"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={touched.tsm_date && errors.tsm_date}
                                  defaultValue={
                                    selectedDate != undefined && selectedDate
                                  }
                                />
                                {touched.tsm_date && errors.tsm_date && (
                                  <FormFeedback>{errors.tsm_date}</FormFeedback>
                                )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Class/Grade
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="tsm_classId"
                                  type="select"
                                  className="form-select"
                                  onChange={e => {
                                    handleChange(e)
                                    getAllSubCategoriesFromApi(e.target.value)
                                    values.asn_subCategory = ""
                                  }}
                                  onBlur={handleBlur}
                                  value={values.tsm_classId || 0}
                                  invalid={
                                    touched.tsm_classId && errors.tsm_classId
                                      ? true
                                      : false
                                  }
                                >
                                  <option value="0" disabled>
                                    Select Class/Grade
                                  </option>
                                  {categoryValues.map(val => {
                                    return (
                                      <option key={val.id} value={val.value}>
                                        {val.value}
                                      </option>
                                    )
                                  })}
                                </Input>
                                {touched.tsm_classId && errors.tsm_classId ? (
                                  <FormFeedback type="invalid">
                                    {errors.tsm_classId}
                                  </FormFeedback>
                                ) : null}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Subject
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="tsm_subject"
                                  type="select"
                                  className="form-select"
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                  onBlur={handleBlur}
                                  value={values.tsm_subject || 0}
                                  invalid={
                                    touched.tsm_subject && errors.tsm_subject
                                      ? true
                                      : false
                                  }
                                >
                                  <option value="0" disabled>
                                    Select Subject
                                  </option>
                                  {subCategoryValues.map(val => {
                                    return (
                                      <option key={val.id} value={val.value}>
                                        {val.value}
                                      </option>
                                    )
                                  })}
                                </Input>
                                {touched.tsm_subject && errors.tsm_subject ? (
                                  <FormFeedback type="invalid">
                                    {errors.tsm_subject}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                            <Row>
                              <Col md={1} sm={2} xs={2} className="mb-1">
                                <h6 className="form-label">Week Number</h6>
                              </Col>
                              <Col className="mb-1">
                                <h6>Strand</h6>
                              </Col>
                              <Col className="mb-1">
                                <h6>Sub Strand</h6>
                              </Col>
                              <Col className="mb-1">
                                <h6>Content Strandards</h6>
                              </Col>
                              <Col className="mb-1">
                                <h6>Indicators</h6>
                              </Col>
                              <Col className="mb-1">
                                <h6>Resources</h6>
                              </Col>
                            </Row>
                            {loopArray.map((data, i) => {
                              return (
                                <Row key={i}>
                                  <Col md={1} sm={2} xs={2} className="mb-1">
                                    <h6>{`${data.id + 1}`}</h6>
                                  </Col>
                                  <Col className="mb-1">
                                    <Input
                                      name={`tsc_strand${i}`}
                                      type="text"
                                      placeholder="Strand"
                                      onChange={e => {
                                        handleChangeForm(e)
                                      }}
                                      onBlur={handleBlur}
                                      invalid={
                                        touched.tsc_strand && errors.tsc_strand
                                      }
                                      value={
                                        termlySchemeMasterForm[`tsc_strand${i}`]
                                      }
                                    />
                                    {touched.tsc_strand &&
                                      errors.tsc_strand && (
                                        <FormFeedback>
                                          {errors.tsc_strand}
                                        </FormFeedback>
                                      )}
                                  </Col>

                                  <Col className="mb-3">
                                    <Input
                                      name={`tsc_subStrand${i}`}
                                      type="text"
                                      placeholder="Sub Strand"
                                      onChange={e => {
                                        handleChangeForm(e)
                                      }}
                                      onBlur={handleBlur}
                                      invalid={
                                        touched.tsc_subStrand &&
                                        errors.tsc_subStrand
                                      }
                                      value={
                                        termlySchemeMasterForm[
                                          `tsc_subStrand${i}`
                                        ]
                                      }
                                    />
                                    {touched.tsc_subStrand &&
                                      errors.tsc_subStrand && (
                                        <FormFeedback>
                                          {errors.tsc_subStrand}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                  <Col className="mb-3">
                                    <Input
                                      name={`tsc_contentStandards${i}`}
                                      type="text"
                                      placeholder="Content Strandards"
                                      onChange={e => {
                                        handleChangeForm(e)
                                      }}
                                      onBlur={handleBlur}
                                      invalid={
                                        touched.tsc_contentStandards &&
                                        errors.tsc_contentStandards
                                      }
                                      value={
                                        termlySchemeMasterForm[
                                          `tsc_contentStandards${i}`
                                        ]
                                      }
                                    />
                                    {touched.tsc_contentStandards &&
                                      errors.tsc_contentStandards && (
                                        <FormFeedback>
                                          {errors.tsc_contentStandards}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                  <Col className="mb-3">
                                    <Input
                                      name={`tsc_indicators${i}`}
                                      type="text"
                                      placeholder="Indicators"
                                      onChange={e => {
                                        handleChangeForm(e)
                                      }}
                                      onBlur={handleBlur}
                                      invalid={
                                        touched.tsc_indicators &&
                                        errors.tsc_indicators
                                      }
                                      value={
                                        termlySchemeMasterForm[
                                          `tsc_indicators${i}`
                                        ]
                                      }
                                    />
                                    {touched.tsc_indicators &&
                                      errors.tsc_indicators && (
                                        <FormFeedback>
                                          {errors.tsc_indicators}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                  <Col className="mb-3">
                                    <Input
                                      name={`tsc_resources${i}`}
                                      type="text"
                                      placeholder="Resources"
                                      onChange={e => {
                                        handleChangeForm(e)
                                      }}
                                      onBlur={handleBlur}
                                      invalid={
                                        touched.tsc_resources &&
                                        errors.tsc_resources
                                      }
                                      value={
                                        termlySchemeMasterForm[
                                          `tsc_resources${i}`
                                        ]
                                      }
                                    />
                                    {touched.tsc_resources &&
                                      errors.tsc_resources && (
                                        <FormFeedback>
                                          {errors.tsc_resources}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              )
                            })}
                            <Row className="mb-3 text-center">
                              <Col>
                                <Button
                                  size="md"
                                  color="danger"
                                  type="button"
                                  disabled={submitLoading}
                                  className="mx-2"
                                  onClick={() => {
                                    props.history.push("/termlySchemeMaster")
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  size="md"
                                  color="info"
                                  type="submit"
                                  disabled={submitLoading}
                                >
                                  {!isEdit ? "Save" : "Update"}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </Formik>
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

TeamMemberModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default TeamMemberModal
