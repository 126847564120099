import { getApiConfig } from "helpers/authHelper"
import { get, post, put, del } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllSchoolStaff = () => {
  return get(url.SCHOOL_STAFF, getApiConfig())
}

export const getSchoolStaff = id => {
  return get(`${url.SCHOOL_STAFF}/${id}`, getApiConfig())
}

export const createSchoolStaff = data => {
  return post(url.SCHOOL_STAFF, data, getApiConfig())
}

export const updateSchoolStaff = (id, data) => {
  return put(`${url.SCHOOL_STAFF}/${id}`, data, getApiConfig())
}

export const deleteSchoolStaff = id => {
  return del(`${url.SCHOOL_STAFF}/${id}`, getApiConfig())
}
