import React, { useEffect, useState } from "react"
import PropTypes, { number } from "prop-types"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { classRoomType1 } from "../../common/data/dropdownVals"
import {
  bloodGroups as BGdropdownVals,
  countryCodes,
  classRoomType,
  divisions,
} from "common/data/dropdownVals"
import UpdateModal from "../../components/Common/UpdateModal"
import { SaveToast } from "components/Common/SaveToast"
import Select from "react-select"

import {
  getStudent,
  createStudent,
  updateStudent,
} from "helpers/backendHelpers/student"
import { defaultRDCSeparator } from "helpers/common_helper_functions"
import { IMAGE_URL } from "helpers/urlHelper"
import RegionDistrictCircuitDropDownAllSelectable from "components/Common/RegionDistrictCircuitDropDownAllSelectable"
import SubmitLoader from "components/Common/SubmitLoader"
import { getAllClassRoom } from "helpers/backendHelpers/classRoom"
import { getAllSchoolByArea } from "helpers/backendHelpers/filterArea"

const StudentModal = props => {
  const [isEdit, setIsEdit] = useState(false)
  const [isView, setIsView] = useState(false)
  const [studentId, setStudentId] = useState(0)
  const [updateModal, setUpdateModal] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [selectedSchool, setSelectedSchool] = useState({})
  const [schoolDropdownValues, setSchoolDropdownValues] = useState([])
  const [selectedCountryCode, setSelectedCountryCode] = useState({})
  const [selectedParentCountryCode, setSelectedParentCountryCode] = useState({})
  const [selectedClass, setSelectedClass] = useState("")
  const [selectedClassRoom, setSelectedClassRoom] = useState({})
  const [classDropdownValues, setClassRoomDropdownValues] = useState([])

  const [form, setForm] = useState({
    st_studentId: "",
    st_fullName: "",
    st_parentName: "",
    st_phoneNumber: "",
    st_altPhoneNumber: "",
    st_parentPhoneNumber: "",
    st_schoolId: "",
    st_classRoomId: "",
    st_email: "",
    st_profilePic: { fileName: "", file: {} },
    st_bloodGroup: "",
    st_class: "",
    st_division: "",
    st_dateOfBirth: "",
    st_region: "",
    st_district: "",
    st_circuit: "",
    st_address: "",
    st_password: "",
    st_status: 2,
    st_altEmail: "",
    st_parentEmail: "",
    st_areaOfStudy: "",
    st_curricularActivities: "",
    st_countryCode: "",
    areaValue: "",
    st_userType: 1,
    st_parentCountryCode: "",
  })
  const fetchClassDropDownValues = async (id, oldVal) => {
    if (id === "") {
      setClassRoomDropdownValues([])
      setSelectedClassRoom({})
      setForm({ ...form, st_classRoomId: "" })
    } else {
      try {
        const response = await getAllClassRoom(id)
        let { classRoom } = response.data || {}
        let dropdownVals = classRoom.map(classRoom => {
          return { value: classRoom.value, label: classRoom.label }
        })
        dropdownVals = dropdownVals || []
        setClassRoomDropdownValues(dropdownVals)
        if (oldVal) {
          const defVal = dropdownVals.find(item => item.value === oldVal)
          defVal ? setSelectedClassRoom(defVal) : setSelectedClassRoom({})
        }
      } catch (error) {
        let message =
          error?.response?.data?.message ||
          error?.message ||
          "There Was A Problem Fetching classRoom"

        setClassRoomDropdownValues([])

        return SaveToast({ message, type: "error" })
      }
    }
  }

  const fetchAllSchoolByArea = async (values, oldVal) => {
    if (values === "") {
      setSchoolDropdownValues([])
      setSelectedSchool({})
      setForm({ ...form, st_schoolId: "" })
      fetchClassDropDownValues("")
    } else {
      try {
        let [region, district, circuit] =
          (values + "" || "")?.split(defaultRDCSeparator) || []
        region = region || ""
        district = district || ""
        circuit = circuit || ""

        const response = await getAllSchoolByArea(region, district, circuit)
        let { schools } = response.data || {}

        let dropdownVals = schools.map(school => {
          return { value: school.sc_id, label: school.sc_schoolName }
        })

        dropdownVals = dropdownVals || []
        setSchoolDropdownValues(dropdownVals)
        if (oldVal) {
          const defVal = dropdownVals.find(item => item.value === oldVal)
          defVal ? setSelectedSchool(defVal) : setSelectedSchool({})
        }
      } catch (error) {
        let message =
          error?.response?.data?.message ||
          error?.message ||
          "There Was A Problem Fetching Schools"

        setSchoolDropdownValues([])

        return SaveToast({ message, type: "error" })
      }
    }
  }

  useEffect(() => {
    let { type, id } = props.match.params || {}
    document.getElementById("premium-learner").classList.add("mm-active")
    switch (type) {
      case "edit":
        setIsEdit(true)
        setIsView(false)
        setStudentId(parseInt(id))
        break
      case "view":
        setIsView(true)
        setIsEdit(false)
        setStudentId(parseInt(id))
        break
      case "add":
      default:
        setIsView(false)
        setIsEdit(false)
        setStudentId(0)
        break
    }
    if (id) {
      fetchStudentDetailsForEdit(id)
    }
  }, [isEdit])
  const fetchStudentDetailsForEdit = async st_id => {
    try {
      let response = await getStudent(st_id)

      let { student } = response.data || {}
      student = student || {}

      let { st_region, st_district, st_circuit } = student

      let areaValue = `${st_region || ""}${defaultRDCSeparator}${
        st_district || ""
      }${defaultRDCSeparator}${st_circuit || ""}`
      student["areaValue"] = areaValue
      student["st_profilePic_old"] = student["st_profilePic"]
      student["st_profilePic"] = { fileName: "", file: {} }

      fetchAllSchoolByArea(areaValue, student["st_schoolId"])
      setSelectedClass({
        label: student["st_class"],
        value: student["st_class"],
      })
      fetchClassDropDownValues(
        student["st_schoolId"],
        student["st_classRoomId"]
      )
      setSelectedCountryCode(
        countryCodes.find(
          countryCode => countryCode.value === student["st_countryCode"]
        )
      )

      setSelectedParentCountryCode(
        countryCodes.find(
          countryCode => countryCode.value === student["st_parentCountryCode"]
        )
      )

      return setForm(student)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching freelance student details"

      setForm(form)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddStudentSubmit = async data => {
    try {
      setSubmitLoading(true)
      const response = await createStudent(data)
      let message = response?.message || "Student Added Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)
      props.history.push("/premium-learner")
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Adding Student"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleEditStudentSubmit = async (id, data) => {
    if (!id) {
      return SaveToast({
        message: "Please enter Student Id",
        type: "error",
      })
    }
    try {
      setSubmitLoading(true)
      const response = await updateStudent(id, data)
      let message = response?.message || "Student Updated Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)
      props.history.push("/premium-learner")
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Adding Student"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleUpdateStudent = () => {
    setUpdateModal(false)
    SaveToast({ message: "Student Updated Successfully", type: "success" })
    props.history.push("/premium-learner")
  }

  const passwordValidation = !isEdit
    ? Yup.string()
        .min(6, "Password Must Contain Atleast 6 characters")
        .required("Please Enter password")
    : Yup.string()
        .min(6, "Password Must Contain Atleast 6 characters")
        .optional("Please Enter password")

  return (
    <React.Fragment>
      <UpdateModal
        show={updateModal}
        onUpdateClick={handleUpdateStudent}
        onCloseClick={() => setUpdateModal(false)}
      />
      <div className="page-content">
        <div
          className="container-fluid"
          style={submitLoading ? { position: "relative", opacity: "0.8" } : {}}
        >
          {submitLoading ? <SubmitLoader /> : <></>}
          <Card>
            <CardHeader>
              <CardTitle>
                {isView ? (
                  <Label>Student Details</Label>
                ) : (
                  <Label>{!isEdit ? "Enter" : "Update"} Details</Label>
                )}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Formik
                enableReinitialize={true}
                initialValues={form}
                validationSchema={Yup.object({
                  // st_studentId: Yup.string().required(
                  //   "Please enter student id"
                  // ),
                  st_fullName: Yup.string().required("Please enter full name"),
                  st_parentName: Yup.string().notRequired().nullable(),
                  st_phoneNumber: Yup.string()
                    .required("Please enter your phone number")
                    .matches(
                      /^\d{9}$/,
                      "Please enter 9 digit number,without adding 0"
                    )
                    .nullable(),
                  st_parentPhoneNumber: Yup.string()
                    .required("Please enter your parent phone number")
                    .matches(
                      /^\d{9}$/,
                      "Please enter 9 digit number,without adding 0"
                    )
                    .nullable(),
                  // st_altPhoneNumber: Yup.string()
                  //   .nullable()
                  //   .notRequired()
                  //   .matches(
                  //     /^\d{9}$/,
                  //     "Please enter 9 digit number,without adding 0"
                  //   ),
                  st_email: Yup.string()
                    .required("Please enter email")
                    .email("Please enter valid email")
                    .nullable(),
                  st_schoolId: Yup.number()
                    .required("Please select school")
                    .nullable(),
                  // st_classRoomId: Yup.number().notRequired().nullable(),
                  // st_profilePic: Yup.mixed().notRequired().nullable()
                  // .test(
                  //   "fileNotSelected",
                  //   "Please Select Profile Picture",
                  //   value => {
                  //     return isEdit || !!form?.["st_profilePic"]?.file?.type
                  //   }
                  // )
                  // .test("fileFormat", "Unsupported Format", value => {
                  //   if (!form?.["st_profilePic"]?.file?.type)
                  //     return isEdit || false

                  //   return [
                  //     "image/png",
                  //     "image/jpg",
                  //     "image/jpeg",
                  //     "image/gif",
                  //   ].includes(form?.["st_profilePic"].file.type)
                  // }),
                  // st_bloodGroup: Yup.string().notRequired().nullable(),
                  st_class: Yup.string()
                    .required("Please select class")
                    .nullable(),
                  // st_dateOfBirth: Yup.date().notRequired().nullable(),
                  areaValue: Yup.mixed().test(
                    "invalidInput",
                    "Please select region-district",
                    value => {
                      return !!value
                    }
                  ),

                  // st_address: Yup.string()
                  //   .notRequired("Please Enter Address")
                  //   .nullable(),
                  // st_status: Yup.bool().required("Please select status"),
                  st_password: passwordValidation,
                  // st_altEmail: Yup.string().notRequired().nullable(),
                  st_parentEmail: Yup.string()
                    .notRequired()
                    .email("Please enter valid parent email")
                    .nullable(),
                  // st_areaOfStudy: Yup.string().notRequired().nullable(),
                  // st_curricularActivities: Yup.string()
                  //   .notRequired()
                  //   .nullable(),
                  st_countryCode: Yup.string().required(
                    "Please enter country code"
                  ),
                  st_parentCountryCode: Yup.string()
                    .required("Please enter country code")
                    .nullable(),

                  st_division: Yup.string()
                    .required("Please select division")
                    .nullable(),
                })}
                onSubmit={values => {
                  let [st_region, st_district, st_circuit] =
                    (values?.areaValue + "" || "")?.split(
                      defaultRDCSeparator
                    ) || []
                  st_region = st_region || null
                  st_district = st_district || null
                  st_circuit = st_circuit || null

                  let studentData = values
                  studentData["st_countryCode"] =
                    studentData["st_countryCode"] === 0
                      ? null
                      : studentData["st_countryCode"]

                  studentData["st_parentCountryCode"] =
                    studentData["st_parentCountryCode"] === 0
                      ? null
                      : studentData["st_parentCountryCode"]
                  if (isEdit) {
                    studentData["st_region"] = st_region
                    studentData["st_class"] = selectedClass.value

                    studentData["st_district"] = st_district
                    studentData["st_circuit"] = st_circuit
                    studentData["st_profilePic_old"] = form.st_profilePic_old
                    studentData["st_profilePic"] = form.st_profilePic.file
                    studentData["st_userType"] = 2
                    delete studentData["st_id"]
                    return handleEditStudentSubmit(studentId, studentData)
                  } else {
                    studentData["st_profilePic"] = form.st_profilePic.file
                    studentData["st_region"] = st_region
                    studentData["st_district"] = st_district
                    studentData["st_circuit"] = st_circuit
                    studentData["st_userType"] = 2
                    return handleAddStudentSubmit(studentData)
                  }
                }}
              >
                {({
                  touched,
                  errors,
                  values,
                  handleSubmit,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                  setFieldTouched,
                }) => (
                  <>
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        handleSubmit(e)
                        return false
                      }}
                    >
                      <Row>
                        <Col>
                          <Row className="mb-3">
                            <Col md={4} sm={6} xs={12} className="mb-3">
                              <Label className="form-label">
                                Student Id
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="st_studentId"
                                type="text"
                                placeholder="Enter Student Id"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.st_studentId && errors.st_studentId
                                }
                                defaultValue={form.st_studentId}
                              />
                              {touched.st_studentId && errors.st_studentId && (
                                <FormFeedback>
                                  {errors.st_studentId}
                                </FormFeedback>
                              )}
                            </Col>

                            <Col md={4} sm={6} xs={12} className="mb-3">
                              <Label className="form-label">
                                Full Name <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="st_fullName"
                                type="text"
                                placeholder="Enter Full Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.st_fullName && errors.st_fullName
                                }
                                defaultValue={form.st_fullName}
                              />
                              {touched.st_fullName && errors.st_fullName && (
                                <FormFeedback>
                                  {errors.st_fullName}
                                </FormFeedback>
                              )}
                            </Col>

                            <Col
                              md={4}
                              sm={6}
                              xs={12}
                              className="mt-2 mt-md-0 mb-3"
                            >
                              <Label className="form-label">
                                Email <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="st_email"
                                type="email"
                                placeholder="Enter Email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={touched.st_email && errors.st_email}
                                defaultValue={form.st_email}
                              />
                              {touched.st_email && errors.st_email && (
                                <FormFeedback>{errors.st_email}</FormFeedback>
                              )}
                            </Col>

                            <Col
                              md={4}
                              sm={6}
                              xs={12}
                              className="mt-2 mt-md-0 mb-3"
                            >
                              <Label className="form-label">Alt Email</Label>
                              <Input
                                name="st_altEmail"
                                type="email"
                                placeholder="Enter Alt. Email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.st_altEmail && errors.st_altEmail
                                }
                                defaultValue={form.st_altEmail}
                              />
                              {touched.st_altEmail && errors.st_altEmail && (
                                <FormFeedback>
                                  {errors.st_altEmail}
                                </FormFeedback>
                              )}
                            </Col>

                            <Col
                              md={4}
                              sm={6}
                              xs={12}
                              className="mt-2 mt-sm-0 mb-3"
                            >
                              <RegionDistrictCircuitDropDownAllSelectable
                                isRequired={true}
                                fieldName="areaValue"
                                hasTouched={touched.areaValue}
                                hasErrors={errors.areaValue}
                                areaValue={values.areaValue}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                areaChange={fetchAllSchoolByArea}
                              />
                            </Col>

                            <Col
                              md={4}
                              sm={6}
                              xs={12}
                              className="mt-2 mt-sm-0 mb-3"
                            >
                              <Label className="form-label">
                                School Name
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                name="st_schoolId"
                                placeholder="Select School"
                                value={selectedSchool}
                                onChange={value => {
                                  setSelectedSchool(value)
                                  setFieldValue(
                                    "st_schoolId",
                                    value ? value.value : ""
                                  )
                                  fetchClassDropDownValues(
                                    value ? value.value : ""
                                  )
                                }}
                                onBlur={evt => {
                                  setFieldTouched("st_schoolId", true, true)
                                }}
                                options={schoolDropdownValues}
                                isClearable
                                invalid={
                                  touched.st_schoolId && errors.st_schoolId
                                }
                              />
                              {touched.st_schoolId && errors.st_schoolId && (
                                <div className="invalid-react-select-dropdown">
                                  {errors.st_schoolId}
                                </div>
                              )}
                            </Col>

                            {/* <Col
                              md={4}
                              sm={6}
                              xs={12}
                              className="mt-2 mt-sm-0 mb-3"
                            >
                              <Label className="form-label">Class Room </Label>
                              <Select
                                name="st_classRoomId"
                                placeholder="Select School"
                                value={selectedClassRoom}
                                onChange={value => {
                                  setSelectedClassRoom(value)
                                  setFieldValue(
                                    "st_classRoomId",
                                    value ? value.value : ""
                                  )
                                }}
                                onBlur={evt => {
                                  setFieldTouched("st_classRoomId", true, true)
                                }}
                                options={classDropdownValues}
                                isClearable
                                invalid={
                                  touched.st_classRoomId &&
                                  errors.st_classRoomId
                                }
                              />
                              {touched.st_classRoomId &&
                                errors.st_classRoomId && (
                                  <div className="invalid-react-select-dropdown">
                                    {errors.st_classRoomId}
                                  </div>
                                )}
                            </Col> */}

                            <Col
                              md={2}
                              sm={4}
                              xs={12}
                              className="mt-2 mt-sm-0 mb-3"
                            >
                              <Label className="form-label">
                                Country Code{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                name="st_countryCode"
                                placeholder="Select Country Code"
                                value={selectedCountryCode}
                                onChange={value => {
                                  setSelectedCountryCode(value)
                                  setFieldValue(
                                    "st_countryCode",
                                    value ? value.value : ""
                                  )
                                }}
                                onBlur={evt => {
                                  setFieldTouched("st_countryCode", true, true)
                                }}
                                options={countryCodes.map(countryCode => {
                                  return {
                                    label: `${countryCode.label} (+${countryCode.value})`,
                                    value: countryCode.value,
                                  }
                                })}
                                isClearable
                                invalid={
                                  touched.st_countryCode &&
                                  errors.st_countryCode
                                }
                              />
                              {touched.st_countryCode &&
                                errors.st_countryCode && (
                                  <div className="invalid-react-select-dropdown">
                                    {errors.st_countryCode}
                                  </div>
                                )}
                            </Col>

                            <Col
                              md={2}
                              sm={4}
                              xs={6}
                              className="mt-2 mt-sm-0 mb-3"
                            >
                              <Label className="form-label">
                                Phone Number
                                <span className="text-danger">*</span>{" "}
                              </Label>
                              <Input
                                name="st_phoneNumber"
                                type="tel"
                                placeholder="Enter Phone Number"
                                autoComplete="new-phone"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.st_phoneNumber &&
                                  errors.st_phoneNumber
                                }
                                defaultValue={form.st_phoneNumber}
                              />
                              {touched.st_phoneNumber &&
                                errors.st_phoneNumber && (
                                  <FormFeedback>
                                    {errors.st_phoneNumber}
                                  </FormFeedback>
                                )}
                            </Col>

                            {/* <Col
                              md={4}
                              sm={6}
                              xs={12}
                              className="mt-2 mt-sm-0 mb-3"
                            >
                              <Label className="form-label">
                                Alt. Phone Number
                              </Label>
                              <Input
                                name="st_altPhoneNumber"
                                type="tel"
                                placeholder="Enter Alt. Phone Number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.st_altPhoneNumber &&
                                  errors.st_altPhoneNumber
                                }
                                defaultValue={form.st_altPhoneNumber}
                              />
                              {touched.st_altPhoneNumber &&
                                errors.st_altPhoneNumber && (
                                  <FormFeedback>
                                    {errors.st_altPhoneNumber}
                                  </FormFeedback>
                                )}
                            </Col> */}

                            {/* <Col md={4} sm={6} xs={12} className="mb-3">
                              <Label className="form-label">
                                Area Of Study
                              </Label>
                              <Input
                                name="st_areaOfStudy"
                                type="text"
                                placeholder="Enter Area Of Study"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.st_areaOfStudy &&
                                  errors.st_areaOfStudy
                                }
                                defaultValue={form.st_areaOfStudy}
                              />
                              {touched.st_areaOfStudy &&
                                errors.st_areaOfStudy && (
                                  <FormFeedback>
                                    {errors.st_areaOfStudy}
                                  </FormFeedback>
                                )}
                            </Col> */}

                            {/* <Col md={4} sm={6} xs={12} className="mb-3">
                              <Label className="form-label">
                                Curricular Activities
                              </Label>
                              <Input
                                name="st_curricularActivities"
                                type="text"
                                placeholder="Enter Area of Study"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.st_curricularActivities &&
                                  errors.st_curricularActivities
                                }
                                defaultValue={form.st_curricularActivities}
                              />
                              {touched.st_curricularActivities &&
                                errors.st_curricularActivities && (
                                  <FormFeedback>
                                    {errors.st_curricularActivities}
                                  </FormFeedback>
                                )}
                            </Col> */}

                            {/* <Col md={4} sm={6} xs={12} className="mb-3">
                              <Label className="form-label">Blood Group</Label>
                              <Input
                                name="st_bloodGroup"
                                type="select"
                                className="form-select"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.st_bloodGroup || 0}
                                invalid={
                                  touched.st_bloodGroup && errors.st_bloodGroup
                                    ? true
                                    : false
                                }
                              >
                                <option value="0" disabled>
                                  Select Blood Group
                                </option>
                                {BGdropdownVals.map(val => {
                                  return (
                                    <option key={val} value={val}>
                                      {val}
                                    </option>
                                  )
                                })}
                              </Input>
                              {touched.st_bloodGroup && errors.st_bloodGroup ? (
                                <FormFeedback type="invalid">
                                  {errors.st_bloodGroup}
                                </FormFeedback>
                              ) : null}
                            </Col> */}

                            {/* <Col
                              md={4}
                              sm={6}
                              xs={12}
                              className="mt-2 mt-md-0 mb-3"
                            >
                              <Label className="form-label">
                                Date Of Birth
                              </Label>
                              <Input
                                name="st_dateOfBirth"
                                type="date"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.st_dateOfBirth &&
                                  errors.st_dateOfBirth
                                }
                                value={values.st_dateOfBirth}
                              />
                              {touched.st_dateOfBirth &&
                                errors.st_dateOfBirth && (
                                  <FormFeedback>
                                    {errors.st_dateOfBirth}
                                  </FormFeedback>
                                )}
                            </Col> */}

                            {!isEdit && (
                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Password
                                  {!isEdit && (
                                    <span className="text-danger">*</span>
                                  )}
                                </Label>
                                <Input
                                  name="st_password"
                                  autoComplete="new-password"
                                  type="password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.st_password || ""}
                                  invalid={
                                    touched.st_password && errors.st_password
                                      ? true
                                      : false
                                  }
                                />
                                {touched.st_password && errors.st_password ? (
                                  <FormFeedback type="invalid">
                                    {errors.st_password}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            )}

                            <Col md={4} sm={6} xs={12} className="mb-3">
                              <Label className="form-label">
                                Class/Grade
                                <span className="text-danger">*</span>
                              </Label>
                              {/* <Input
                                name="st_class"
                                type="select"
                                className="form-select"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.st_class || 0}
                                invalid={
                                  touched.st_class && errors.st_class
                                    ? true
                                    : false
                                }
                              >
                                <option value="0" disabled>
                                  Select Class
                                </option>
                                {classRoomType.map(val => {
                                  return (
                                    <option key={val} value={val}>
                                      {val}
                                    </option>
                                  )
                                })}
                              </Input>
                              {touched.st_class && errors.st_class ? (
                                <FormFeedback type="invalid">
                                  {errors.st_class}
                                </FormFeedback>
                              ) : null} */}
                              <Select
                                name="st_class"
                                placeholder="Select Class"
                                onChange={value => {
                                  setSelectedClass(value)
                                  setFieldValue(
                                    "st_class",
                                    value ? value.value : ""
                                  )
                                }}
                                // onChange={handleChange}
                                value={selectedClass || 0}
                                options={classRoomType1}
                                isClearable
                                invalid={
                                  touched.st_class && errors.st_class
                                    ? true
                                    : false
                                }
                              />

                              {touched.st_class && errors.st_class && (
                                <div className="invalid-react-select-dropdown">
                                  {errors.st_class}
                                </div>
                              )}
                            </Col>
                            <Col md={4} sm={6} xs={12} className="mb-3">
                              <Label className="form-label">
                                Division
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                name="st_division"
                                type="select"
                                className="form-select"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.st_division || 0}
                                invalid={
                                  touched.st_division && errors.st_division
                                    ? true
                                    : false
                                }
                              >
                                <option value="0" disabled>
                                  Select Division
                                </option>
                                {divisions.map(val => {
                                  return (
                                    <option key={val} value={val}>
                                      {val}
                                    </option>
                                  )
                                })}
                              </Input>
                              {touched.st_division && errors.st_division ? (
                                <FormFeedback type="invalid">
                                  {errors.st_division}
                                </FormFeedback>
                              ) : null}
                            </Col>

                            <Col md={4} sm={6} xs={12} className="mb-3">
                              <Label className="form-label">
                                Profile Pic
                                {isEdit && form?.st_profilePic_old && (
                                  <>
                                    <span className="ms-2">
                                      (
                                      <a
                                        href={`${IMAGE_URL}/${
                                          form?.st_profilePic_old || ""
                                        }`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        Saved Profile Picture
                                      </a>
                                      )
                                    </span>
                                  </>
                                )}
                              </Label>
                              <Input
                                name="st_profilePic"
                                type="file"
                                accept=".png, .jpg, .jpeg, .gif"
                                placeholder="Select Profile Pic"
                                onChange={e => {
                                  let tempForm = form
                                  tempForm["st_profilePic"]["fileName"] =
                                    e.target.value
                                  tempForm["st_profilePic"]["file"] =
                                    e.target.files[0]
                                  setForm(tempForm)
                                }}
                                invalid={
                                  !!touched.st_profilePic &&
                                  !!errors.st_profilePic
                                }
                                defaultValue={form.st_profilePic.fileName}
                              />
                              {touched.st_profilePic &&
                                errors.st_profilePic && (
                                  <FormFeedback>
                                    {errors.st_profilePic}
                                  </FormFeedback>
                                )}
                            </Col>
                          </Row>

                          <Row className="mb-3">
                            <Col md={4} sm={6} xs={12} className="mb-3">
                              <Label className="form-label">Parent Name </Label>
                              <Input
                                name="st_parentName"
                                type="text"
                                placeholder="Enter Parent Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.st_parentName && errors.st_parentName
                                }
                                defaultValue={form.st_parentName}
                              />
                              {touched.st_parentName &&
                                errors.st_parentName && (
                                  <FormFeedback>
                                    {errors.st_parentName}
                                  </FormFeedback>
                                )}
                            </Col>

                            <Col
                              md={4}
                              sm={6}
                              xs={12}
                              className="mt-2 mt-md-0 mb-3"
                            >
                              <Label className="form-label">Parent Email</Label>
                              <Input
                                name="st_parentEmail"
                                type="email"
                                placeholder="Enter ParentEmail"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.st_parentEmail &&
                                  errors.st_parentEmail
                                }
                                defaultValue={form.st_parentEmail}
                              />
                              {touched.st_parentEmail &&
                                errors.st_parentEmail && (
                                  <FormFeedback>
                                    {errors.st_parentEmail}
                                  </FormFeedback>
                                )}
                            </Col>

                            <Col
                              md={2}
                              sm={4}
                              xs={12}
                              className="mt-2 mt-sm-0 mb-3"
                            >
                              <Label className="form-label">Country Code</Label>
                              <Select
                                name="st_parentCountryCode"
                                placeholder="Select Country Code"
                                value={selectedParentCountryCode}
                                onChange={value => {
                                  setSelectedParentCountryCode(value)
                                  setFieldValue(
                                    "st_parentCountryCode",
                                    value ? value.value : ""
                                  )
                                }}
                                onBlur={evt => {
                                  setFieldTouched(
                                    "st_parentCountryCode",
                                    true,
                                    true
                                  )
                                }}
                                options={countryCodes.map(countryCode => {
                                  return {
                                    label: `${countryCode.label} (+${countryCode.value})`,
                                    value: countryCode.value,
                                  }
                                })}
                                isClearable
                                invalid={
                                  touched.st_parentCountryCode &&
                                  errors.st_parentCountryCode
                                }
                              />
                              {touched.st_parentCountryCode &&
                                errors.st_parentCountryCode && (
                                  <div className="invalid-react-select-dropdown">
                                    {errors.st_parentCountryCode}
                                  </div>
                                )}
                            </Col>

                            <Col
                              md={2}
                              sm={4}
                              xs={6}
                              className="mt-2 mt-sm-0 mb-3"
                            >
                              <Label className="form-label">
                                Parent Phone Number
                              </Label>
                              <Input
                                name="st_parentPhoneNumber"
                                type="tel"
                                placeholder="Enter Parent Phone Number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                invalid={
                                  touched.st_parentPhoneNumber &&
                                  errors.st_parentPhoneNumber
                                }
                                defaultValue={form.st_parentPhoneNumber}
                              />
                              {touched.st_parentPhoneNumber &&
                                errors.st_parentPhoneNumber && (
                                  <FormFeedback>
                                    {errors.st_parentPhoneNumber}
                                  </FormFeedback>
                                )}
                            </Col>

                            {/* <Col sm={12} md={6}>
                              <Row>
                                <Col>
                                  <Label className="form-label">Address </Label>
                                  <Input
                                    name="st_address"
                                    type="textarea"
                                    rows={6}
                                    placeholder="Enter Address"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      !!touched.st_address &&
                                      !!errors.st_address
                                    }
                                    defaultValue={form.st_address}
                                  />
                                  {touched.st_address && errors.st_address && (
                                    <FormFeedback>
                                      {errors.st_address}
                                    </FormFeedback>
                                  )}
                                </Col>
                              </Row>
                            </Col> */}

                            <Col sm={12} md={6} className="mt-2 mt-md-0">
                              <Row></Row>
                            </Col>
                          </Row>

                          <Row className="mb-3 text-center">
                            <Col>
                              <Button
                                size="md"
                                color="danger"
                                type="button"
                                disabled={submitLoading}
                                className="mx-2"
                                onClick={() => {
                                  props.history.push("/premium-learner")
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                size="md"
                                color="info"
                                type="submit"
                                disabled={submitLoading}
                              >
                                {!isEdit ? "Save" : "Update"}
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </>
                )}
              </Formik>
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

StudentModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default StudentModal
