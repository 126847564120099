import { getApiConfig } from "helpers/authHelper"
import { get, post, put, del } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllStudent = userType => {
  return get(`${url.STUDENT}?userType=${userType}`, getApiConfig())
}


export const getStudent = id => {
  return get(`${url.STUDENT}/${id}`, getApiConfig())
}

export const createStudent = data => {
  return post(url.STUDENT, data, getApiConfig(true))
}

export const updateStudent = (id, data) => {
  return put(`${url.STUDENT}/${id}`, data, getApiConfig(true))
}

export const deleteStudent = id => {
  return del(`${url.STUDENT}/${id}`, getApiConfig())
}

export const getStudentsCount = () => {
  return get(`${url.STUDENT}/count`, getApiConfig())
}

export const getAllStudentsWithFullSearchAPI = (page, limit, value, userType) => {
  return get(
    `${url.STUDENT}/pagination?page=${page}&limit=${limit}&st_region=${value}&st_fullName=${value}&st_studentId=${value}&st_district=${value}&st_email=${value}&st_phoneNumber=${value}&st_classRoom=${value}&st_userType=${userType}`,
    getApiConfig()
  )
}

export const getAllStudentColumnWiseSearchAPI = (
  page,
  limit,
  query,
  userType
) => {
  return get(
    `${url.STUDENT}/pagination?page=${page}&limit=${limit}${query}&st_userType=${userType}`,
    getApiConfig()
  )
}