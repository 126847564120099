import { getApiConfig } from "helpers/authHelper"
import { get, post, put, del } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllSchool = () => {
  return get(url.SCHOOL, getApiConfig())
}

export const getAllSchoolWithFullSearchAPI = (page, limit, value) => {
  return get(
    `${url.SCHOOL}/pagination?page=${page}&limit=${limit}&sc_schoolName=${value}&sc_schoolType=${value}&sc_schoolId=${value}&sc_circuit=${value}&sc_phoneNumber=${value}`,
    getApiConfig()
  )
}

export const getAllSchoolColumnWiseSearchAPI = (page, limit, query) => {
  return get(
    `${url.SCHOOL}/pagination?page=${page}&limit=${limit}${query}`,
    getApiConfig()
  )
}

export const getSchool = id => {
  return get(`${url.SCHOOL}/${id}`, getApiConfig())
}

export const createSchool = data => {
  return post(url.SCHOOL, data, getApiConfig())
}

export const updateSchool = (id, data) => {
  return put(`${url.SCHOOL}/${id}`, data, getApiConfig())
}

export const deleteSchool = id => {
  return del(`${url.SCHOOL}/${id}`, getApiConfig())
}

export const getRegionWiseSchoolCount = id => {
  return get(`${url.SCHOOL}/region/count`, getApiConfig())
}

export const getLevelWiseSchoolCount = id => {
  return get(`${url.SCHOOL}/level/count`, getApiConfig())
}
