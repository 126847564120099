const tutors = [
  {
    id: 1,
    fullName: "Neal Matthews",
    email: "demo@gmail.com",
    phoneNumber: "9879879898",
    regionId: 3,
    districtId: 13,
    circuitId: 1,
    status: false,
  },
  {
    id: 2,
    fullName: "Jeremy Bray",
    email: "jeremybray@gmail.com",
    phoneNumber: "7979879898",
    regionId: 3,
    districtId: 14,
    circuitId: 2,
    status: true,
  },
  {
    id: 3,
    fullName: "Peter Gillespie",
    email: "petergillespie@gmail.com",
    phoneNumber: "5987989898",
    regionId: 3,
    districtId: 13,
    circuitId: 1,
    status: true,
  },
  {
    id: 4,
    fullName: "Kyle McCallan",
    email: "kylemccallan@gmail.com",
    phoneNumber: "9879988798",
    regionId: 3,
    districtId: 14,
    circuitId: 2,
    status: false,
  },
  {
    id: 5,
    fullName: "John Mooney",
    email: "johnmooney@gmail.com",
    phoneNumber: "8799879898",
    regionId: 5,
    districtId: 26,
    circuitId: 17,
    status: true,
  },
  {
    id: 6,
    fullName: "Paul Mooney",
    email: "paulmooney@gmail.com",
    phoneNumber: "6458879898",
    regionId: 3,
    districtId: 13,
    circuitId: 1,
    status: false,
  },
  {
    id: 7,
    fullName: "Kenny Carroll",
    email: "kennycarroll@gmail.com",
    phoneNumber: "7987989988",
    regionId: 5,
    districtId: 26,
    circuitId: 18,
    status: true,
  },
  {
    id: 8,
    fullName: "Roger Whelan",
    email: "rogerwhelan@gmail.com",
    phoneNumber: "9898798978",
    regionId: 5,
    districtId: 26,
    circuitId: 17,
    status: false,
  },
  {
    id: 9,
    fullName: "Paul Stirling",
    email: "paulstirling@gmail.com",
    phoneNumber: "7579879898",
    regionId: 5,
    districtId: 26,
    circuitId: 18,
    status: true,
  },
  {
    id: 10,
    fullName: "Andrew Britton",
    email: "andrewbritton@gmail.com",
    phoneNumber: "7848545797",
    regionId: 5,
    districtId: 26,
    circuitId: 17,
    status: true,
  },
  {
    id: 11,
    fullName: "James Hall",
    email: "jameshall@gmail.com",
    phoneNumber: "5749715947",
    regionId: 5,
    districtId: 26,
    circuitId: 18,
    status: false,
  },
  {
    id: 12,
    fullName: "Max Sorensen",
    email: "maxsorensen@gmail.com",
    phoneNumber: "8975794975",
    regionId: 3,
    districtId: 13,
    circuitId: 1,
    status: true,
  },
]

export { tutors }
