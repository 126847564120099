import { getApiConfig } from "helpers/authHelper"
import { get, post, put, del } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllBlogs = () => {
  return get(`${url.BLOG}/getAll`, getApiConfig())
}

export const getBlog = id => {
  return get(`${url.BLOG}/${id}`, getApiConfig())
}

export const createBlog = data => {
  return post(url.BLOG, data, getApiConfig())
}

export const updateBlog = (id, data) => {
  return put(`${url.BLOG}/${id}`, data, getApiConfig())
}

export const deleteBlog = id => {
  return del(`${url.BLOG}/${id}`, getApiConfig())
}

// Comments

// Blog Comments By Blog Id
export const getCommentsByBlogId = id => {
  return get(
    `${url.BLOGCOMMENT}/getCommentByBlogId/get?bl_id=${id}`,
    getApiConfig()
  )
}

export const deleteComment = id => {
  return del(`${url.BLOGCOMMENT}/${id}`, getApiConfig())
}
