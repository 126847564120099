import React, { useMemo, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

//import components
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  SimpleStringValue,
  SimpleDateFormate,
} from "helpers/common_helper_functions"
import { SaveToast } from "components/Common/SaveToast"
import { Col, Row, UncontrolledTooltip, Card, CardBody } from "reactstrap"

import {
  deleteTermlySchemeMaster,
  getAllTermlySchemeMaster,
  updateTermlySchemeMaster,
} from "helpers/backendHelpers/TermlySchemeMaster"
import { getAllCategories } from "helpers/backendHelpers/books"

const ContentTeam = props => {
  document.title = "Content Team"

  const [deleteModal, setDeleteModal] = useState(false)
  const [saved, setSaved] = useState(false)
  const [loading, setLoading] = useState(false)
  const [termlySchemeMaster, setTermlySchemeMaster] = useState([])
  const [contentTeamMember, setContentTeamMember] = useState({})
  const [categoryValues, setCategoryValues] = useState([])
  useEffect(() => {
    fetchAllTermlySchemeMaster()
  }, [saved])

  const fetchAllTermlySchemeMaster = async () => {
    try {
      setLoading(true)
      const response = await getAllTermlySchemeMaster()
      let { termlySchemeMaster } = response.data || {}
      termlySchemeMaster = termlySchemeMaster || []
      setTermlySchemeMaster(termlySchemeMaster)
      setLoading(false)
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Termly Schema"
      setTermlySchemeMaster([])
      setLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const onClickDelete = team => {
    setContentTeamMember(team)
    setDeleteModal(true)
  }

  const handleUpdateTermlySchemeMasterStatus = async (tsm_id, status) => {
    if (!tsm_id) {
      return SaveToast({
        message: "Please Enter TermlySchemeMaster Id",
        type: "error",
      })
    }
    try {
      const response = updateTermlySchemeMaster(tsm_id, { tsm_status: status })
      const message = response?.message || "Team Status Updated Successfully"
      SaveToast({ message, type: "success" })

      setSaved(prevSaved => !prevSaved)
      return
    } catch (error) {
      const message =
        error?.message || "There Was A Problem Updating Team Status"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleDeleteTermlySchemeMaster = async () => {
    if (!contentTeamMember.tsm_id) {
      return SaveToast({
        message: "Invalid Termly Scheme Master ID",
        type: "error",
      })
    }
    try {
      const response = await deleteTermlySchemeMaster(contentTeamMember.tsm_id)
      const message =
        response?.message || "Termly Scheme Master Deleted Successfully"
      SaveToast({ message, type: "success" })

      setSaved(prevSaved => !prevSaved)
      setDeleteModal(false)
      return
    } catch (error) {
      const message = error?.message || "There Was A Problem Deleting Team"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddButtonClick = () => {
    props.history.push("/termlySchemeMaster/add")
  }

  const fetchCategoryDropDownValues = async () => {
    try {
      let response = await getAllCategories()
      let { categories } = response.data || {}
      categories = categories || []
      let allCategory = []
      categories.map(category => {
        let item = category.category.map(data => {
          return {
            id: data.CategoryId,
            value: data.CategoryName,
          }
        })
        allCategory.push(...item)
      })
      setCategoryValues(allCategory)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching mainCategories"

      return SaveToast({ message, type: "error" })
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Class",
        accessor: "tsm_classId",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Subject",
        accessor: "tsm_subject",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Date",
        accessor: "tsm_date",
        filterable: true,
        Cell: cellProps => {
          return <SimpleDateFormate {...cellProps} />
        },
      },
      {
        Header: "Term Number",
        accessor: "tsm_termNumber",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "tsm_status",
        disableFilters: true,
        Cell: cellProps => {
          const cellData = cellProps.row.original
          return (
            <div className="form-check form-switch form-switch-md">
              <input
                type="checkbox"
                className="form-check-input bg-dark"
                id={`ct_status_checkbox-${cellData.tsm_id}`}
                name={`ct_status_checkbox-${cellData.tsm_id}`}
                defaultChecked={cellData.tsm_status}
                onChange={e => {
                  let { checked, name } = e.target
                  document.getElementById(name).checked = checked
                  return handleUpdateTermlySchemeMasterStatus(
                    cellData.tsm_id,
                    checked
                  )
                }}
              />
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {/* <Link
                to="#"
                className="text-dark"
                onClick={() => {
                  props.history.push(
                    "/termlySchemeMaster/view/" + cellProps.row.original.tsm_id
                  )
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link> */}
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  props.history.push(
                    "/termlySchemeMaster/edit/" + cellProps.row.original.tsm_id
                  )
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const teamData = cellProps.row.original
                  onClickDelete(teamData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTermlySchemeMaster}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Content" breadcrumbItem="Team" /> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={termlySchemeMaster}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    addButtonLabel="Add Termly Schema"
                    handleAddButtonClick={handleAddButtonClick}
                    customPageSize={10}
                    className="custom-header-css"
                    // canExportCsv={true}
                    dataFetchLoading={loading}
                    canPrint={false}
                    noDataMessage="No system activity found."
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ContentTeam
