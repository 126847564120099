import React, { useEffect, useState } from "react"

//import components
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import {
  Col,
  Row,
  Card,
  CardBody,
  Table,
  Input,
  Label,
  Button,
} from "reactstrap"

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"

import { students } from "common/data/students"

const getTotalDaysInMonth = date => {
  date = date || new Date()
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
}

const StudentAttendance = props => {
  document.title = "Student Attendance V2 | LMS Ghana"

  const [isEdit, setIsEdit] = useState(false)

  const toggleIsEdit = () => {
    setIsEdit(!isEdit)
  }

  const [selectedDate, setSelectedDate] = useState(new Date())
  const [monthDays, setMonthDays] = useState(getTotalDaysInMonth(selectedDate))

  const [selectedMonthDays, setSelectedMonthDays] = useState([])

  useEffect(() => {
    let monthDays = getTotalDaysInMonth(selectedDate)
    setMonthDays(monthDays)

    let temp = []
    for (let i = 0; i < monthDays; ++i) {
      temp.push(
        moment(selectedDate)
          .set("date", i + 1)
          .format()
      )
    }

    setSelectedMonthDays(temp)
  }, [selectedDate])

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Student" breadcrumbItem="Attendance V2" />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col md={4} sm={12} xs={12}>
                      <div className="d-flex gap-2">
                        <Label className="m-auto">Month</Label>
                        <DatePicker
                          className="w-auto form-control"
                          selected={selectedDate}
                          onChange={date => {
                            setSelectedDate(date)
                          }}
                          dateFormat="MMMM, yyyy"
                          showMonthYearPicker
                          showFullMonthYearPicker
                          placeholderText="Select Month"
                        />
                        {isEdit ? (
                          <>
                            <Button
                              onClick={toggleIsEdit}
                              color="success"
                              className="mb-2"
                            >
                              Save
                            </Button>
                            <Button
                              onClick={toggleIsEdit}
                              color="danger"
                              className="mb-2"
                            >
                              Cancel
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              onClick={toggleIsEdit}
                              color="warning"
                              className="mb-2"
                            >
                              Edit
                            </Button>
                          </>
                        )}
                      </div>
                    </Col>

                    <Col className="text-end">
                      <div>
                        <Button
                          color="info"
                          onClick={() => {
                            props.history.push("/students")
                          }}
                        >
                          Back
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    {/* <Col className="table-scroll">
                      <table className="main-table"> */}
                    <Col className="w-auto overflow-auto">
                      <Table className="">
                        <thead>
                          <tr>
                            <td>Name</td>
                            {selectedMonthDays.map((date, index) => (
                              <td key={index}>{moment(date).date()}</td>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {students.map(student => {
                            let { id, fullName } = student

                            return (
                              <tr key={id}>
                                <td>{fullName}</td>
                                {selectedMonthDays.map((date, index) => {
                                  let random = Math.ceil(Math.random() - 0.5)
                                  let isToday =
                                    moment(date).format("DD-MM-YYYY") ===
                                    moment(new Date()).format("DD-MM-YYYY")
                                  let afterToday =
                                    moment(date).diff(new Date()) > 0
                                  return (
                                    <td
                                      key={index}
                                      style={{ verticalAlign: "middle" }}
                                    >
                                      {afterToday ? (
                                        ""
                                      ) : isToday && isEdit ? (
                                        <Input
                                          type="checkbox"
                                          defaultChecked={random}
                                        />
                                      ) : random ? (
                                        <span
                                          style={{
                                            color: "green",
                                            fontSize: "20px",
                                          }}
                                          className="fs-4"
                                        >
                                          ✓
                                        </span>
                                      ) : (
                                        <span className="text-danger fs-4">
                                          ⅹ
                                        </span>
                                      )}
                                    </td>
                                  )
                                })}
                              </tr>
                            )
                          })}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default StudentAttendance
