import React, { useMemo, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

//import components
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import Icon from "../../components/Common/Icon"
import { SimpleStringValue } from "helpers/common_helper_functions"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Button,
} from "reactstrap"
import { SaveToast } from "components/Common/SaveToast"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import {
  getAllBooks,
  deleteBook,
  updateBookStatus,
  getAllBookWithFullSearchAPI,
  getAllBookColumnWiseSearchAPI,
} from "helpers/backendHelpers/books"

import VideoModal from "./WatchModal"
import ListenModal from "./ListenModal"
import { IMAGE_URL } from "helpers/urlHelper"
import { useParams } from "react-router-dom/cjs/react-router-dom"

const Book = props => {
  //meta title
  document.title = "Books | LMS Ghana"

  const bookType = props.bookType
  console.log(bookType)

  const [deleteModal, setDeleteModal] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [modal3, setModal3] = useState(false)
  const [isEffects, setisEffects] = useState(false)

  const [saved, setSaved] = useState(false)
  const [books, setBooks] = useState([])
  const [book, setBook] = useState({})
  const [videoFile, setVideoFile] = useState("")
  const [audioFile, setAudeoFile] = useState("")
  const [bookPreviewImage, setBookPreviewImage] = useState("")
  const [loading, setLoading] = useState(false)
  const [pageIndex, setPageIndex] = useState(1)
  const [pageCount, setPageCount] = useState(30)
  const [limit, setLimit] = useState(100)
  const [totalRecords, setTotalRecords] = useState(0)
  const [temp, setTemp] = useState(false)
  const [searchValue, setSearchValue] = useState("")

  useEffect(() => {
    const fetchAllBooksWithPagination = async () => {
      try {
        setLoading(true)
        const response = await getAllBookWithFullSearchAPI(
          pageIndex,
          limit,
          searchValue,
          bookType
        )

        let { books } = response.data || {}
        books = books || []
        setBooks(books.rows)
        setTotalRecords(books.count)
        let pageCount = Math.ceil(books.count / limit)
        setPageCount(pageCount)
        setTemp(!temp)
        setLoading(false)
      } catch (error) {
        let message =
          error?.response?.data?.message ||
          error?.message ||
          "There Was A Problem Fetching Books"

        setBooks([])
        setLoading(false)

        return SaveToast({ message, type: "error" })
      }
    }

    fetchAllBooksWithPagination()
  }, [pageIndex, limit, searchValue, saved, bookType])

  const fetchColumnWiseSearchAPI = async query => {
    try {
      setLoading(true)
      const response = await getAllBookColumnWiseSearchAPI(
        pageIndex,
        limit,
        query
      )
      let { books } = response.data || {}
      books = books || []
      setBooks(books.rows)
      setTotalRecords(books.count)
      let pageCount = Math.ceil(books.count / limit)
      setPageCount(pageCount)
      setTemp(!temp)
      setLoading(false)
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Teachers"
      setBooks([])
      setLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const onClickDelete = book => {
    setBook(book)
    setDeleteModal(true)
  }

  const handleDeleteBook = async () => {
    if (!book.bk_id) {
      return SaveToast({ message: "Invalid Book ID", type: "error" })
    }
    try {
      const response = await deleteBook(book.bk_id)
      const message = response?.message || "Book Deleted Successfully"
      SaveToast({ message, type: "success" })
      setSaved(prevSaved => !prevSaved)
      setDeleteModal(false)
      return
    } catch (error) {
      const message = error?.message || "There Was A Problem Deleting Book"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleUpdateBookStatus = async (id, status) => {
    if (!id) {
      return SaveToast({
        message: "Please Enter Book Id",
        type: "error",
      })
    }

    try {
      const response = await updateBookStatus(id, status)
      let message = response?.message || "Book Status Updated Successfully"
      SaveToast({ message, type: "success" })

      setSaved(prevSaved => !prevSaved)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Updating Book Status"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddButtonClick = () => {
    props.history.push(`/books/${bookType}/add`)
  }

  const toggleWatchModal = () => {
    setModal2(!modal2)
  }

  const toggleListenModal = () => {
    setModal3(!modal3)
  }

  const columns = useMemo(
    () => [
      {
        Header: "Book Title",
        accessor: "bk_title",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Who Can Read",
        accessor: "bk_whoCanRead",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Author",
        accessor: "bk_author",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Publisher",
        accessor: "bk_publisher",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "isFree?",
        accessor: "bk_isFree",
        filterable: true,
        Cell: cellProps => {
          return cellProps?.row?.original?.bk_isFree ? (
            <i
              className="fas fa-check fa-2x"
              style={{
                color: "#35c38e",
                justifyContent: "center",
                alignItems: "center",
                display: "grid",
              }}
            ></i>
          ) : (
            <>
              <span
                style={{
                  color: "#f46a6a",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "grid",
                }}
              >
                {cellProps?.row?.original?.bk_price}
              </span>
            </>
          )
        },
      },
      {
        Header: "Status",
        accessor: "bk_status",
        disableFilters: true,
        Cell: cellProps => {
          const cellData = cellProps.row.original
          return (
            <div className="form-check form-switch form-switch-md">
              <input
                type="checkbox"
                className="form-check-input bg-dark"
                id={`bk_status_checkbox-${cellData.bk_id}`}
                name={`bk_status_checkbox-${cellData.bk_id}`}
                defaultChecked={cellData.bk_status}
                onChange={e => {
                  let { checked, name } = e.target
                  document.getElementById(name).checked = checked
                  return handleUpdateBookStatus(cellData.bk_id, checked)
                }}
              />
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-dark"
                onClick={() => {
                  props.history.push(
                    `/books/${bookType}/view/${cellProps.row.original.bk_id}`
                  )
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  props.history.push({
                    pathname: `/books/${bookType}/edit/${cellProps.row.original.bk_id}`,
                    search: `?bookType=${bookType}`,
                  })
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const bookData = cellProps.row.original
                  onClickDelete(bookData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const getBookDataForCSV = async () => {
    const response = await getAllBooks()
    return response.data.book
  }

  return (
    <React.Fragment>
      {isEffects ? (
        <Lightbox
          mainSrc={`${IMAGE_URL}/${bookPreviewImage}`}
          enableZoom={false}
          onCloseRequest={() => {
            setisEffects(!isEffects)
          }}
        />
      ) : null}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteBook}
        onCloseClick={() => setDeleteModal(false)}
      />
      <VideoModal isOpen={modal2} toggle={toggleWatchModal} video={videoFile} />
      <ListenModal
        isOpen={modal3}
        toggle={toggleListenModal}
        audio={audioFile}
      ></ListenModal>
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Books" breadcrumbItem="Book" /> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    canExportCsv={true}
                    csvFileName={`book.csv`}
                    csvLink={`/api/v1/admin/book/download?bookType=${bookType}`}
                    columns={columns}
                    data={books}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    addButtonLabel={`Add ${
                      bookType === "video" ? "Video" : "E-Book"
                    }`}
                    handleAddButtonClick={handleAddButtonClick}
                    customPageSize={100}
                    className="custom-header-css"
                    canPrint={false}
                    dataFetchLoading={loading}
                    noDataMessage="No system activity found."
                    pageIndexFromApi={pageIndex}
                    setPageIndexFromApi={setPageIndex}
                    pageCountFromApi={pageCount}
                    setPageCount1={setPageCount}
                    totalRecords={totalRecords}
                    limit={limit}
                    setLimit={setLimit}
                    customPagination={true}
                    setSearchValue={setSearchValue}
                    fetchColumnWiseSearchAPI={fetchColumnWiseSearchAPI}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Book
