import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import KATonTeam from "pages/KATonTeam"
import Areas from "pages/Areas"

//For GES Authorities
import GESAuthorities from "pages/GESAuthorities/index"

//For GES Offices
import GESOffices from "pages/GESOffices/index"
import ADDEditOfficeDetails from "pages/GESOffices/AddEditDetails"

// For Freelance Teacher
import FreelanceTeacher from "pages/FreelanceTeacher"
import AddEditFreelanceTeacherDetails from "pages/FreelanceTeacher/AddEditDetails"

// For Teachers
import Teachers from "pages/Teachers"
import AddEditTeachersDetails from "pages/Teachers/AddEditDetails"

// For Schools
import Schools from "pages/Schools"
import AddEditSchoolDetails from "pages/Schools/AddEditDetails"

//For Content Team
import ContentTeam from "pages/ContentTeam"
import AddEditTeamDetails from "pages/ContentTeam/AddEditDetails"

//For Termly Scheme Master
import TermlySchemeMaster from "pages/TermlySchemeMaster"
import AddEditTermlySchemeMasterDetails from "pages/TermlySchemeMaster/AddEditDetails"

//For Training Program
import TrainingProgram from "pages/TrainingProgram"
import AddEditProgramDetails from "pages/TrainingProgram/AddEditDetails"

//For Content Management
import ContentManagement from "pages/ContentManagement"
import AddEditContentDetails from "pages/ContentManagement/AddEditDetails"

//For Tutor Management
import TutorManagement from "pages/TutorManagement"

//For Tutor Management
import BatchCreation from "pages/BatchCreation"
import AddEditBatchDetails from "pages/BatchCreation/AddEditDetails"
import ViewBatchDetails from "pages/BatchCreation/ViewDetails"

// For Training Participants

// Document Uploaded Participants
import DocumentUploadParticipant from "pages/TrainingParticipants/DocumentUploadParticipant"

// Exam Passed Participants
import ExamPassParticipant from "pages/TrainingParticipants/ExamPassParticipant"

// Certified Participants
import CertifiedParticipant from "pages/TrainingParticipants/CertifiedParticipant"

//For Training Questoins
import TrainingQuestions from "pages/AllTrainingQuestions"
import AddEditTrainingQuestions from "pages/AllTrainingQuestions/AddEditDetails"
import ViewTrainingQuestions from "pages/AllTrainingQuestions/ViewDetails"

//For Training Exam
import TrainingExam from "pages/TrainingExam"
import AddEditTrainingExam from "pages/TrainingExam/AddEditDetails"
import ViewTrainingExam from "pages/TrainingExam/ViewDetails"

//For School Staff
import SchoolStaff from "pages/SchoolStaff/index"
import AddEditStaffDetails from "pages/SchoolStaff/AddEditDetails"

//Students
import Students from "pages/Students"
import AddEditStudent from "pages/Students/AddEditDetails"

//Student Attendance
import StudentAttendance from "pages/Students/Attendance"
import StudentAttendanceV2 from "pages/Students/Attendance/indexV2"

//StudentDetails
import StudentDetails from "pages/StudentDetails"

//For Training Program
import Books from "pages/Books"
import AddEditBooks from "pages/Books/AddEditDetails"

//Categories
import Categories from "pages/Categories"

// Genre
import Genre from "pages/Genre"
import GESOfficeStaff from "pages/GESOfficeStaff"

//For Blogs
import Blog from "pages/Blog/index"
import AddEditBlog from "pages/Blog/AddEditDetails"

//For Past Paper
import PastPaper from "pages/PastPaper/index"
import AddEditPastPaper from "pages/PastPaper/AddEditDetails"

//For Live Session
import LiveSession from "pages/LiveSession/index"
import AddEditLiveSession from "pages/LiveSession/AddEditDetails"

// For Premium Leaner
import PremiumLearner from "pages/PremiumLearner"
import AddEditPremiumLearner from "pages/PremiumLearner/AddEditDetails"
import AttendeesParticipants from "pages/TrainingParticipants/AttendeesParticipant"
import AddEditAssigmentQuestion from "pages/AssigmentQuestion/AddEditDetails"
import AssigmentQuestion from "pages/AssigmentQuestion"

const authProtectedRoutes = [
  // Areas
  { path: "/areas", component: Areas },

  { path: "/dashboard", component: Dashboard },

  // //profile
  { path: "/profile", component: UserProfile },

  // KATon Team
  { path: "/katon-team", component: KATonTeam },

  // Freelance Teacher
  // Main Table Page
  { path: "/freelance-teacher", component: FreelanceTeacher },
  // Add-Edit-View Page
  {
    path: "/freelance-teacher/:type?/:id?",
    component: AddEditFreelanceTeacherDetails,
  },

  //Premium Learner
  { path: "/premium-learner", component: PremiumLearner },

  {
    path: "/premium-learner/:type?/:id?",
    component: AddEditPremiumLearner,
  },

  // Teachers
  // Main Table Page
  { path: "/teachers", component: Teachers },
  // Add-Edit-View Page
  { path: "/teachers/:type?/:id?", component: AddEditTeachersDetails },

  // Schools
  // Main Table Page
  { path: "/schools", component: Schools },
  // Add-Edit-View Page
  { path: "/schools/:type?/:id?", component: AddEditSchoolDetails },

  //GES Authorities
  { path: "/gesauthorities", component: GESAuthorities },

  //GES Offices
  { path: "/gesoffices", component: GESOffices },

  //GES OfficeStaff
  { path: "/gesofficestaff/:id", component: GESOfficeStaff },

  // Add-Edit-View Page
  { path: "/gesoffices/:type?/:id?", component: ADDEditOfficeDetails },

  //Content Team
  { path: "/content-team", component: ContentTeam },
  // Add-Edit-View Page
  { path: "/content-team/:type?/:id?", component: AddEditTeamDetails },

  //Termly Scheme Master
  { path: "/termlySchemeMaster", component: TermlySchemeMaster },
  // Add-Edit-View Page
  {
    path: "/termlySchemeMaster/:type?/:id?",
    component: AddEditTermlySchemeMasterDetails,
  },

  //Training Program
  { path: "/training-program", component: TrainingProgram },
  // Add-Edit-View Page
  { path: "/training-program/:type?/:id?", component: AddEditProgramDetails },

  //Content Management
  { path: "/content-management", component: ContentManagement },
  // Add-Edit-View Page
  { path: "/content-management/:type?/:id?", component: AddEditContentDetails },

  //Tutor Management
  { path: "/tutor-management", component: TutorManagement },

  //Training Participants

  //Document Uploaded Participants
  {
    path: "/training-participants/doc-uploaded",
    component: DocumentUploadParticipant,
  },

  //Attendees List
  {
    path: "/training-participants/attendees",
    component: AttendeesParticipants,
  },

  //Exam Pass Participants
  { path: "/training-participants/exam-pass", component: ExamPassParticipant },

  //Certified Participants
  { path: "/training-participants/certified", component: CertifiedParticipant },

  //Training Questions
  { path: "/training-questions/", component: TrainingQuestions },

  // Add Edit Trainnig Questions
  {
    path: "/training-questions/:type?/:id?",
    component: AddEditTrainingQuestions,
  },

  //Training Exam
  { path: "/training-exam/", component: TrainingExam },

  // Add Edit Trainnig Questions
  { path: "/training-exam/:type?/:id?", component: AddEditTrainingExam },

  //Batch Creation
  { path: "/batch-creation/:tp_id?", component: BatchCreation },

  // Add-Edit-View Page
  { path: "/batch-creation/edit/:id?/:tp_id?", component: AddEditBatchDetails },

  // Add-Edit-View Page
  { path: "/batch-creation/add/:tp_id?", component: AddEditBatchDetails },

  // View Page
  { path: "/batch-creation/view/:id?", component: ViewBatchDetails },

  //School Staff
  { path: "/school-staff", component: SchoolStaff },
  // Add-Edit-View Page
  { path: "/school-staff/:type?/:id?", component: AddEditStaffDetails },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

  //students
  //MainTablePage
  { path: "/students", component: Students },
  //AddEditPage
  { path: "/students/:type?/:id?", component: AddEditStudent },

  //student attendance
  { path: "/student-attendance", component: StudentAttendance },
  { path: "/student-attendance-v2", component: StudentAttendanceV2 },

  //Details Page
  { path: "/student-details/:id?", component: StudentDetails },

  //Books
  {
    path: "/books/e-book",
    component: props => <Books bookType="e-book" {...props} />,
  },
  {
    path: "/books/video",
    component: props => <Books bookType="video" {...props} />,
  },

  // Add-Edit-View Page
  { path: "/books/:bookType/:type", component: AddEditBooks },
  { path: "/books/:bookType/:type/:id", component: AddEditBooks },

  // TODO: Assignment Question
  { path: "/AssignmentQuestion", component: AssigmentQuestion },
  {
    path: "/AssignmentQuestion/:type?/:id?",
    component: AddEditAssigmentQuestion,
  },

  //Categories
  { path: "/content-category", component: Categories },

  //Genre
  { path: "/genre", component: Genre },

  //Blogs
  { path: "/blogs", component: Blog },
  // Add-Edit-View Page
  { path: "/blogs/:type?/:id?", component: AddEditBlog },

  //Past Paper
  { path: "/past-paper/questions/:pp_id", component: AssigmentQuestion },

  { path: "/past-paper", component: PastPaper },
  // Add-Edit-View Page
  { path: "/past-paper/:type?/:id?", component: AddEditPastPaper },

  //Live Session
  { path: "/live-session", component: LiveSession },
  // Add-Edit-View Page
  { path: "/live-session/:type?/:id?", component: AddEditLiveSession },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { publicRoutes, authProtectedRoutes }
