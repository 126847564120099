// src/components/filter.
import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import DeleteModal from "../../components/Common/DeleteModal"
import { Form as RBSForm } from "react-bootstrap"

// //import components
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  getAllMainCategories,
  getAllSubCategories,
  getAllCategories,
  createCategory,
  deleteCategory,
  getAllTopics,
} from "helpers/backendHelpers/category"

import TableContainer from "../../components/Common/TableContainer"
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  Card,
  CardBody,
} from "reactstrap"
import { SimpleStringValue } from "helpers/common_helper_functions"

import Select from "react-select"

import { SaveToast } from "components/Common/SaveToast"
import SubmitLoader from "components/Common/SubmitLoader"
import { subjectsMaster } from "common/data/dropdownVals"

function Categories() {
  //meta title
  document.title = "Categories | LMS Ghana"

  const [activeCategoryTab, setActiveCategoryTab] = useState(1)

  const [mainCategories, setMainCategories] = useState([])
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [topics, setTopics] = useState([])
  // const [programme, setProgramme] = useState([])
  const [saved, setSaved] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [loadingMainCategories, setLoadingMainCategories] = useState(false)
  const [submitLoading, setsubmitLoading] = useState(false)
  const [classGradeDropdown, setClassGradeDropdown] = useState([])

  useEffect(() => {
    fetchAllMainCategories()
    fetchAllCategories()
    fetchAllSubCategories()
    fetchAllTopics()
    // fetchAllProgramme()
  }, [saved])

  const fetchAllMainCategories = async () => {
    try {
      setLoadingMainCategories(true)
      let response = await getAllMainCategories()
      let { mainCategories } = response.data || {}
      mainCategories = mainCategories || []
      setMainCategories(mainCategories)

      setLoadingMainCategories(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching Education Levels"
      setMainCategories([])
      setLoadingMainCategories(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const fetchAllCategories = async () => {
    try {
      let response = await getAllCategories()
      let { categories } = response.data || {}
      let vals = []
      let mainCategoryArray = []
      console.log("categories12", categories)
      categories.map(mainCategory => {
        let val = []
        let { category } = mainCategory

        category.map(cat => {
          val.push({ label: cat.CategoryName, value: cat.CategoryId })
        })
        mainCategoryArray.push({
          label: mainCategory.categoryName,
          options: val,
        })
      })
      setClassGradeDropdown(mainCategoryArray)

      categories = categories || []
      let fillSubCategories = []
      categories.map(mainCategory => {
        const { category } = mainCategory
        category.map(category => {
          const categoryObj = {
            cc_id: category.CategoryId,
            cc_category: category.CategoryName,
            cc_mainCategory: mainCategory.categoryName,
            cc_categoryTypeName: mainCategory.categoryTypeName,
            cc_type: mainCategory.categoryType,
          }
          fillSubCategories.push(categoryObj)
        })
      })
      setCategories(fillSubCategories)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching Class/Grades"
      setCategories([])
      return SaveToast({ message, type: "error" })
    }
  }

  const fetchAllSubCategories = async () => {
    try {
      let response = await getAllSubCategories()
      let { subCategories } = response.data || {}

      subCategories = subCategories || []
      let fillSubCategories = []

      subCategories.map(mainCategoryItem => {
        const { category } = mainCategoryItem
        category.map(categoryItem => {
          const { subCategory } = categoryItem
          subCategory.map(subCategoryItem => {
            const subCategoryObj = {
              cc_id: subCategoryItem.subCateId,
              cc_subCategory: subCategoryItem.subCateName,
              cc_mainCategory: mainCategoryItem.cc_categoryName,
              cc_categoryTypeName: mainCategoryItem.cc_categoryType,
              cc_type: mainCategoryItem.cc_categoryType,
              cc_category: categoryItem.categoryName,
            }
            fillSubCategories.push(subCategoryObj)
          })
        })
      })
      setSubCategories(fillSubCategories)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Subjects"
      setSubCategories([])
      return SaveToast({ message, type: "error" })
    }
  }

  const fetchAllTopics = async () => {
    try {
      let response = await getAllTopics()

      let { topics } = response.data || {}

      topics = topics || []
      let fillTopics = []

      topics.map(mainCategoryItem => {
        const { category } = mainCategoryItem
        category.map(categoryItem => {
          const { subCategory } = categoryItem
          subCategory.map(subCategoryItem => {
            const { topics } = subCategoryItem
            topics.map(topic => {
              const topicObj = {
                cc_id: topic.topicId,
                cc_topic: topic.topicName,
                cc_mainCategory: mainCategoryItem.cc_categoryName,
                cc_categoryTypeName: mainCategoryItem.cc_categoryType,
                cc_category: categoryItem.categoryName,
                cc_subCategory: subCategoryItem.subCateName,
              }
              fillTopics.push(topicObj)
            })
          })
        })
      })
      setTopics(fillTopics)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Topics"
      setTopics([])
      return SaveToast({ message, type: "error" })
    }
  }

  // const fetchAllProgramme = () => {
  //   const data = [
  //     {
  //       cc_id: 101,
  //       cc_programme: "Commerce",
  //       cc_subCategory: "Science",
  //       cc_category: "SHS 1",
  //       cc_mainCategory: "SHS",
  //       cc_categoryTypeName: 1,
  //     },
  //     {
  //       cc_id: 102,
  //       cc_programme: "Arts",
  //       cc_subCategory: "Science",
  //       cc_category: "SHS 1",
  //       cc_mainCategory: "SHS",
  //       cc_categoryTypeName: 1,
  //     },
  //   ]
  //   setProgramme(data)
  // }

  const mainCategoryCategoryDropDownOptions = (() => {
    let options = []
    options = [
      {
        label: "Curriculum",
        options: categories
          .filter(category => {
            return category.cc_type === 1
          })
          .map(category => {
            let { cc_id, cc_category, cc_mainCategory } = category
            cc_category = cc_category + ", " + cc_mainCategory
            return {
              value: cc_id,
              label: cc_category,
            }
          }),
      },
      {
        label: "Training Program",
        options: categories
          .filter(category => category.cc_type === 2)
          .map(category => {
            let { cc_id, cc_category, cc_mainCategory } = category
            cc_category = cc_category + ", " + cc_mainCategory
            return {
              value: cc_id,
              label: cc_category,
            }
          }),
      },
    ]

    return options
  })()
  const mainCategoryAndSubCategoryDropDownOptions = (() => {
    let options = []
    options = [
      {
        label: "Curriculum",
        options: subCategories.map(category => {
          let { cc_id, cc_category, cc_mainCategory, cc_subCategory } = category
          cc_category = cc_category + ", " + cc_subCategory
          return {
            value: cc_id,
            label: cc_category,
          }
        }),
      },
      {
        label: "Training Program",
        options: categories
          .filter(category => category.cc_type === 2)
          .map(category => {
            let { cc_id, cc_category, cc_mainCategory } = category
            cc_category = cc_category + ", " + cc_subCategory
            return {
              value: cc_id,
              label: cc_category,
            }
          }),
      },
    ]

    return options
  })()

  const [isEdit, setIsEdit] = useState(false)
  const [mainCategoryModal, setCategoryModal] = useState(false)
  const [categoryModal, setDistrictModal] = useState(false)
  const [subCategoryModal, setSubCategoryModal] = useState(false)
  const [topicModal, setTopicModal] = useState(false)
  // const [programmeModal, setProgrammeModal] = useState(false)
  const [category, setCategory] = useState({})
  const [form, setForm] = useState({})
  const [subject, setSubject] = useState("")

  const toggleMainCategoryModal = () => {
    if (mainCategoryModal) {
      setForm({})
      setIsEdit(false)
    }
    setCategoryModal(!mainCategoryModal)
  }
  const toggleCategoryModal = () => {
    if (categoryModal) {
      setForm({})
      setIsEdit(false)
    }
    setDistrictModal(!categoryModal)
  }
  const toggleSubCategoryModal = () => {
    if (subCategoryModal) {
      setForm({})
      setIsEdit(false)
    }
    setSubCategoryModal(!subCategoryModal)
  }

  const toggleTopicModal = () => {
    if (topicModal) {
      setForm({})
      setIsEdit(false)
    }
    setTopicModal(!topicModal)
  }

  // const toggleProgrammeModal = () => {
  //   if (programmeModal) {
  //     setForm({})
  //     setIsEdit(false)
  //   }
  //   setProgrammeModal(!programmeModal)
  // }

  const handleMainCategorySubmit = async e => {
    e.preventDefault()
    try {
      let { cc_mainCategory: mainCategoryValue, cc_type: typeValue } =
        form || {}
      let mainCategoryValueType = {
        cc_categoryName: mainCategoryValue,
        cc_categoryType: typeValue,
        cc_categoryTag: "mainCategory",
      }
      setsubmitLoading(true)
      let response = await createCategory(mainCategoryValueType)
      let message = response?.message || "Education Level Created Successfully"
      SaveToast({ message, type: "success" })
      toggleMainCategoryModal()
      setSaved(prevSaved => !prevSaved)
      setsubmitLoading(false)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Creating Education Level"
      setsubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleCategorySubmit = async e => {
    e.preventDefault()

    try {
      let {
        cc_category: categoryValue,
        cc_mainCategoryId,
        cc_type: typeValue,
      } = form || {}

      //type
      const cc_type = mainCategories.find(
        c => c.cc_id === cc_mainCategoryId
      ).cc_categoryType

      const categoryValues = {
        cc_categoryName: categoryValue,
        cc_categoryType: typeValue,
        cc_parentId: cc_mainCategoryId,
        cc_categoryTag: "category",
        cc_categoryType: cc_type,
      }
      setsubmitLoading(true)
      let response = await createCategory(categoryValues)
      let message = response?.message || "Class/Grade Created Successfully"
      SaveToast({ message, type: "success" })
      toggleCategoryModal()
      setSaved(prevSaved => !prevSaved)
      setsubmitLoading(false)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Creating Class/Grade"
      setsubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleSubCategorySubmit = async e => {
    e.preventDefault()
    console.log("form1212", form)
    try {
      let {
        cc_subCategory: subCategoryValue,
        cc_categoryId,
        cc_type: typeValue,
      } = form || {}
      const cc_type = categories.find(c => c.cc_id === cc_categoryId).cc_type
      const subCategoryValues = {
        cc_categoryName: subCategoryValue,
        cc_categoryType: cc_type,
        cc_parentId: cc_categoryId,
        cc_categoryTag: "subCategory",
      }
      setsubmitLoading(true)
      let response = await createCategory(subCategoryValues)
      let message = response?.message || "Subject Created Successfully"
      SaveToast({ message, type: "success" })
      toggleSubCategoryModal()
      setSaved(prevSaved => !prevSaved)
      setsubmitLoading(false)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Creating Subject"
      setsubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleTopicSubmit = async e => {
    e.preventDefault()

    try {
      let {
        cc_topic: topicValue,
        cc_categoryId,
        cc_type: typeValue,
      } = form || {}

      const cc_type = subCategories.find(c => c.cc_id === cc_categoryId).cc_type

      const topicValues = {
        cc_categoryName: topicValue,
        cc_categoryType: cc_type,
        cc_parentId: cc_categoryId,
        cc_categoryTag: "topic",
      }
      setsubmitLoading(true)
      let response = await createCategory(topicValues)
      let message = response?.message || "Topic is Created Successfully"
      SaveToast({ message, type: "success" })
      toggleTopicModal()
      setSaved(prevSaved => !prevSaved)
      setsubmitLoading(false)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Creating Topic"
      setsubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  // // TODO
  // const handleProgrammeSubmit = () => {}

  const onClickDelete = categoryId => {
    setCategory(categoryId)
    setDeleteModal(true)
  }
  const handleDeleteCategory = async () => {
    try {
      let response = await deleteCategory(category)
      let message = response?.message || "Class/Grade Delete Successfully"
      SaveToast({ message, type: "success" })
      setSaved(prevSaved => !prevSaved)
      setDeleteModal(false)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Deleting Class/Grade"
      return SaveToast({ message, type: "error" })
    }
  }

  const mainCategoryColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "cc_id",
        disableFilters: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Education Level",
        accessor: "cc_categoryName",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Category Type",
        accessor: "cc_categoryTypeName",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const mainCategory = cellProps.row.original.cc_id
                  onClickDelete(mainCategory)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const categoryColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "cc_id",
        disableFilters: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Class/Grade",
        accessor: "cc_category",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Education Level",
        accessor: "cc_mainCategory",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Category Type",
        accessor: "cc_categoryTypeName",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const category = cellProps.row.original.cc_id
                  onClickDelete(category)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  // const programmeColumns = useMemo(
  //   () => [
  //     {
  //       Header: "ID",
  //       accessor: "cc_id",
  //       disableFilters: true,
  //       Cell: cellProps => <SimpleStringValue {...cellProps} />,
  //     },
  //     {
  //       Header: "Programme",
  //       accessor: "cc_programme",
  //       filterable: true,
  //       Cell: cellProps => <SimpleStringValue {...cellProps} />,
  //     },
  //     {
  //       Header: "Class/Grade",
  //       accessor: "cc_category",
  //       filterable: true,
  //       Cell: cellProps => <SimpleStringValue {...cellProps} />,
  //     },
  //     {
  //       Header: "Education Level",
  //       accessor: "cc_mainCategory",
  //       filterable: true,
  //       Cell: cellProps => <SimpleStringValue {...cellProps} />,
  //     },
  //     {
  //       Header: "Category Type",
  //       accessor: "cc_categoryTypeName",
  //       filterable: true,
  //       Cell: cellProps => <SimpleStringValue {...cellProps} />,
  //     },
  //     // TODO
  //     {
  //       Header: "Action",
  //       accessor: "action",
  //       disableFilters: true,
  //       Cell: cellProps => {
  //         return (
  //           <div className="d-flex gap-3">
  //             <Link
  //               to="#"
  //               className="text-danger"
  //               onClick={() => {
  //                 const subCategory = cellProps.row.original.cc_id
  //                 onClickDelete(subCategory)
  //               }}
  //             >
  //               <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
  //               <UncontrolledTooltip placement="top" target="deletetooltip">
  //                 Delete
  //               </UncontrolledTooltip>
  //             </Link>
  //           </div>
  //         )
  //       },
  //     },
  //   ],
  //   []
  // )

  const subCategoryColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "cc_id",
        disableFilters: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Subject",
        accessor: "cc_subCategory",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Class/Grade",
        accessor: "cc_category",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Education Level",
        accessor: "cc_mainCategory",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Category Type",
        accessor: "cc_categoryTypeName",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const subCategory = cellProps.row.original.cc_id
                  onClickDelete(subCategory)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const topicColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "cc_id",
        disableFilters: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Topic",
        accessor: "cc_topic",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Subject",
        accessor: "cc_subCategory",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Class/Grade",
        accessor: "cc_category",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Education Level",
        accessor: "cc_mainCategory",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Category Type",
        accessor: "cc_categoryTypeName",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const subCategory = cellProps.row.original.cc_id
                  onClickDelete(subCategory)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const printAreaColumns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "cc_id",
        disableFilters: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Subject",
        accessor: "cc_subCategory",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Class/Grade",
        accessor: "cc_category",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
      {
        Header: "Education Level",
        accessor: "cc_mainCategory",
        filterable: true,
        Cell: cellProps => <SimpleStringValue {...cellProps} />,
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteCategory}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Categories" breadcrumbItem="Categories" /> */}

          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeCategoryTab === 1 ? "active" : ""}
                onClick={() => {
                  setActiveCategoryTab(1)
                }}
              >
                Education Level
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeCategoryTab === 2 ? "active" : ""}
                onClick={() => {
                  setActiveCategoryTab(2)
                }}
              >
                Class/Grade
              </NavLink>
            </NavItem>

            {/* <NavItem>
              <NavLink
                className={activeCategoryTab === 5 ? "active" : ""}
                onClick={() => {
                  setActiveCategoryTab(5)
                }}
              >
                Programme
              </NavLink>
            </NavItem> */}

            <NavItem>
              <NavLink
                className={activeCategoryTab === 3 ? "active" : ""}
                onClick={() => {
                  setActiveCategoryTab(3)
                }}
              >
                Subject
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={activeCategoryTab === 4 ? "active" : ""}
                onClick={() => {
                  setActiveCategoryTab(4)
                }}
              >
                Topics
              </NavLink>
            </NavItem>
          </Nav>

          <Card>
            <CardBody>
              <TabContent activeTab={activeCategoryTab}>
                <TabPane tabId={1}>
                  <Row className="mt-3">
                    <Col>
                      <TableContainer
                        columns={mainCategoryColumns}
                        data={mainCategories}
                        isGlobalFilter={true}
                        isAddOptions={true}
                        addButtonLabel="Add New Education Level"
                        handleAddButtonClick={() => {
                          toggleMainCategoryModal()
                        }}
                        customPageSize={10}
                        className="custom-header-css"
                        dataFetchLoading={loadingMainCategories}
                        noDataMessage="No Education Levels founded"
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={2}>
                  <Row className="mt-3">
                    <Col>
                      <TableContainer
                        columns={categoryColumns}
                        data={categories}
                        isGlobalFilter={true}
                        isAddOptions={true}
                        addButtonLabel="Add New Class/Grade"
                        handleAddButtonClick={() => {
                          toggleCategoryModal()
                        }}
                        customPageSize={10}
                        className="custom-header-css"
                        noDataMessage="No Class/Grades founded"
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={3}>
                  <Row className="mt-3">
                    <Col>
                      <TableContainer
                        columns={subCategoryColumns}
                        data={subCategories}
                        isGlobalFilter={true}
                        isAddOptions={true}
                        addButtonLabel="Add New Subject"
                        handleAddButtonClick={() => {
                          toggleSubCategoryModal()
                        }}
                        customPageSize={10}
                        className="custom-header-css"
                        canPrint={true}
                        printColumns={printAreaColumns}
                        noDataMessage="No Subjects founded"
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={4}>
                  <Row className="mt-3">
                    <Col>
                      <TableContainer
                        columns={topicColumns}
                        data={topics}
                        isGlobalFilter={true}
                        isAddOptions={true}
                        addButtonLabel="Add Topics"
                        handleAddButtonClick={() => {
                          toggleTopicModal()
                        }}
                        customPageSize={10}
                        className="custom-header-css"
                        canPrint={true}
                        printColumns={printAreaColumns}
                        noDataMessage="No Topics founded"
                      />
                    </Col>
                  </Row>
                </TabPane>

                {/* <TabPane tabId={5}>
                  <Row className="mt-3">
                    <Col>
                      <TableContainer
                        columns={programmeColumns}
                        data={programme}
                        isGlobalFilter={true}
                        isAddOptions={true}
                        addButtonLabel="Add Programme"
                        handleAddButtonClick={() => {
                          toggleProgrammeModal()
                        }}
                        customPageSize={10}
                        className="custom-header-css"
                        canPrint={true}
                        printColumns={printAreaColumns}
                        noDataMessage="No Programme founded"
                      />
                    </Col>
                  </Row>
                </TabPane> */}
              </TabContent>
            </CardBody>
          </Card>

          {/* Education Level Modal */}
          <Modal
            size="md"
            isOpen={mainCategoryModal}
            toggle={toggleMainCategoryModal}
            role="dialog"
            style={submitLoading ? { position: "relative" } : {}}
            autoFocus
            centered
          >
            {submitLoading ? <SubmitLoader /> : <></>}
            <ModalHeader toggle={toggleMainCategoryModal}>
              {`${isEdit ? "Edit" : "Add"} Education Level`}
            </ModalHeader>
            <ModalBody>
              <Row className="mb-3">
                <Col>
                  <Label className="form-label">
                    Type <span className="text-danger">*</span>
                  </Label>
                  <Row>
                    <Col>
                      <RBSForm.Check
                        id="type-curriculum"
                        name="cc_type"
                        type="radio"
                        inline
                        label="Curriculum"
                        onChange={event => {
                          if (event.target.checked) {
                            let temp = form
                            temp["cc_type"] = 1
                            setForm(temp)
                          }
                        }}
                      />
                      <RBSForm.Check
                        id="type-trainingProgram"
                        name="cc_type"
                        type="radio"
                        inline
                        label="Training Program"
                        onChange={event => {
                          if (event.target.checked) {
                            let temp = form
                            temp["cc_type"] = 2
                            setForm(temp)
                          }
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label className="form-label">
                    Education Level <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="mainCategory"
                    type="text"
                    placeholder="Enter Education Level"
                    onChange={e => {
                      setForm({ ...form, cc_mainCategory: e.target.value })
                    }}
                    value={form?.cc_mainCategory}
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="text-end">
                  <Button
                    type="submit"
                    color={isEdit ? "primary" : "success"}
                    size="sm"
                    onClick={handleMainCategorySubmit}
                    disabled={submitLoading}
                  >
                    {isEdit ? "Update" : "Save"}
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          {/* Category Modal */}
          <Modal
            size="md"
            isOpen={categoryModal}
            toggle={toggleCategoryModal}
            role="dialog"
            style={submitLoading ? { position: "relative" } : {}}
            autoFocus
            centered
          >
            {submitLoading ? <SubmitLoader /> : <></>}
            <ModalHeader toggle={toggleCategoryModal}>
              {`${isEdit ? "Edit" : "Add"} Class/Grade`}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col>
                  <Label className="form-label">
                    Education Level <span className="text-danger">*</span>
                  </Label>
                  <Select
                    name="cc_mainCategoryId"
                    placeholder="Select Education Level"
                    options={[
                      {
                        label: "Curriculum",
                        options: mainCategories
                          .filter(category => {
                            return category.cc_categoryType === 1
                          })
                          .map(category => {
                            return {
                              value: category.cc_id,
                              label: category.cc_categoryName,
                            }
                          }),
                      },
                      {
                        label: "Training Program",
                        options: mainCategories
                          .filter(category => category.cc_categoryType === 2)
                          .map(category => {
                            return {
                              value: category.cc_id,
                              label: category.cc_categoryName,
                            }
                          }),
                      },
                    ]}
                    onChange={e => {
                      setForm({ ...form, cc_mainCategoryId: e?.value })
                    }}
                    value={
                      mainCategories
                        .map(mainCategory =>
                          mainCategory.cc_id === form?.mainCategoryId
                            ? {
                                value: mainCategory.cc_id,
                                label: mainCategory.cc_categoryName,
                              }
                            : null
                        )
                        .filter(data => data)[0]
                    }
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col>
                  <Label className="form-label">
                    Class/Grade <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="category"
                    type="text"
                    placeholder="Enter Class/Grade"
                    onChange={e => {
                      setForm({ ...form, cc_category: e.target.value })
                    }}
                    value={form?.cc_category}
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="text-end">
                  <Button
                    type="submit"
                    color={isEdit ? "primary" : "success"}
                    size="sm"
                    onClick={handleCategorySubmit}
                    disabled={submitLoading}
                  >
                    {isEdit ? "Update" : "Save"}
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          {/* Subject Modal */}
          <Modal
            size="md"
            isOpen={subCategoryModal}
            toggle={toggleSubCategoryModal}
            role="dialog"
            style={submitLoading ? { position: "relative" } : {}}
            autoFocus
            centered
          >
            {submitLoading ? <SubmitLoader /> : <></>}
            <ModalHeader toggle={toggleSubCategoryModal}>
              {`${isEdit ? "Edit" : "Add"} Subject`}
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col>
                  <Label className="form-label">
                    Class/Grade <span className="text-danger">*</span>
                  </Label>
                  <Select
                    name="category"
                    placeholder="Select Class/Grade"
                    options={classGradeDropdown}
                    onChange={e => {
                      setForm({ ...form, cc_categoryId: e?.value })
                    }}
                    value={
                      mainCategories
                        .map(mainCategory =>
                          mainCategory.cc_id === form?.mainCategoryId
                            ? {
                                value: mainCategory.cc_id,
                                label: mainCategory.cc_categoryName,
                              }
                            : null
                        )
                        .filter(data => data)[0]
                    }
                    // value={mainCategoryCategoryDropDownOptions.find(
                    //   value => value?.value === form?.mainCategoryId
                    // )}
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col>
                  <Label className="form-label">
                    Subject <span className="text-danger">*</span>
                  </Label>
                  <Select
                    name="subCategory"
                    placeholder="Select Subject1"
                    options={subjectsMaster}
                    onChange={e => {
                      setForm({ ...form, cc_subCategory: e?.value })
                      setSubject(e)
                    }}
                    value={subject}
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="text-end">
                  <Button
                    type="submit"
                    color={isEdit ? "primary" : "success"}
                    size="sm"
                    onClick={handleSubCategorySubmit}
                    disabled={submitLoading}
                  >
                    {isEdit ? "Update" : "Save"}
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          {/* Topics Modal */}
          <Modal
            size="md"
            isOpen={topicModal}
            toggle={toggleTopicModal}
            role="dialog"
            style={submitLoading ? { position: "relative" } : {}}
            autoFocus
            centered
          >
            {submitLoading ? <SubmitLoader /> : <></>}
            <ModalHeader toggle={toggleTopicModal}>
              {`${isEdit ? "Edit" : "Add"} Topic`}
            </ModalHeader>
            <ModalBody>
              <Row className="mt-3">
                <Col>
                  <Label className="form-label">
                    Subject <span className="text-danger">*</span>
                  </Label>

                  <Select
                    name="category"
                    placeholder="Select Subject"
                    options={mainCategoryAndSubCategoryDropDownOptions}
                    onChange={e => {
                      setForm({ ...form, cc_categoryId: e?.value })
                    }}
                    value={
                      mainCategories
                        .map(mainCategory =>
                          mainCategory.cc_id === form?.mainCategoryId
                            ? {
                                value: mainCategory.cc_id,
                                label: mainCategory.cc_categoryName,
                              }
                            : null
                        )
                        .filter(data => data)[0]
                    }
                    // value={mainCategoryCategoryDropDownOptions.find(
                    //   value => value?.value === form?.mainCategoryId
                    // )}
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col>
                  <Label className="form-label">
                    Topic <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="topic"
                    type="text"
                    placeholder="Enter Topic"
                    onChange={e => {
                      setForm({ ...form, cc_topic: e.target.value })
                    }}
                    value={form?.cc_topic}
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="text-end">
                  <Button
                    type="submit"
                    color={isEdit ? "primary" : "success"}
                    size="sm"
                    onClick={handleTopicSubmit}
                    disabled={submitLoading}
                  >
                    {isEdit ? "Update" : "Save"}
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          {/* Programme Modal */}
          {/* <Modal
            size="md"
            isOpen={programmeModal}
            toggle={toggleProgrammeModal}
            role="dialog"
            style={submitLoading ? { position: "relative" } : {}}
            autoFocus
            centered
          >
            {submitLoading ? <SubmitLoader /> : <></>}
            <ModalHeader toggle={toggleProgrammeModal}>
              {`${isEdit ? "Edit" : "Add"} Programme`}
            </ModalHeader>
            <ModalBody>
              <Row className="mt-3">
                <Col>
                  <Label className="form-label">
                    Class/Grade <span className="text-danger">*</span>
                  </Label>
                  <Select
                    name="category"
                    placeholder="Select Class/Grade"
                    options={mainCategoryAndSubCategoryDropDownOptions}
                    onChange={e => {
                      setForm({ ...form, cc_categoryId: e?.value })
                    }}
                    // // TODO
                    value={
                      mainCategories
                        .map(mainCategory =>
                          mainCategory.cc_id === form?.mainCategoryId
                            ? {
                                value: mainCategory.cc_id,
                                label: mainCategory.cc_categoryName,
                              }
                            : null
                        )
                        .filter(data => data)[0]
                    }
                    // value={mainCategoryCategoryDropDownOptions.find(
                    //   value => value?.value === form?.mainCategoryId
                    // )}
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col>
                  <Label className="form-label">
                    Programme <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="topic"
                    type="text"
                    placeholder="Enter Programme"
                    onChange={e => {
                      setForm({ ...form, cc_topic: e.target.value })
                    }}
                    value={form?.cc_topic}
                    required
                  />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col className="text-end">
                  <Button
                    type="submit"
                    color={isEdit ? "primary" : "success"}
                    size="sm"
                    onClick={handleProgrammeSubmit}
                    disabled={submitLoading}
                  >
                    {isEdit ? "Update" : "Save"}
                  </Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal> */}
        </div>
      </div>
    </React.Fragment>
  )
}

Categories.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Categories
