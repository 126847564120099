import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"

// import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import ViewDetails from "./ViewDetails"
import Select from "react-select"
import UpdateModal from "../../components/Common/UpdateModal"
import { SaveToast } from "components/Common/SaveToast"
import { getAllSchool } from "helpers/backendHelpers/school"
import {
  certificateList,
  countryCodes,
  levels,
  yearsOfExperience,
  languages,
} from "common/data/dropdownVals"
import { bloodGroups } from "../../common/data/dropdownVals"
import {
  createTeacher,
  getTeacher,
  updateTeacher,
} from "helpers/backendHelpers/teacher"
import { IMAGE_URL } from "helpers/urlHelper"
import SubmitLoader from "components/Common/SubmitLoader"
import { getAllSchoolByArea } from "helpers/backendHelpers/filterArea"
import RegionDistrictCircuitDropDownAllSelectable from "components/Common/RegionDistrictCircuitDropDownAllSelectable"
import { defaultRDCSeparator } from "helpers/common_helper_functions"
import { Form as RBSForm } from "react-bootstrap"

const TeacherModal = props => {
  const [isEdit, setIsEdit] = useState(false)
  const [isView, setIsView] = useState(false)
  const [teacherId, setTeacherId] = useState(0)
  const [selectedSchool, setSelectedSchool] = useState({})
  const [selectedCountryCode, setSelectedCountryCode] = useState({})
  const [selectedCertificate, setSelectedCertificate] = useState(null)
  const [selectedLevel, setSelectedLevel] = useState(null)
  const [selectedExperience, setSelectedExperience] = useState(null)
  const [selectedLanguage, setSelectedLanguage] = useState(null)
  const [schoolDropdownValues, setSchoolDropdownValues] = useState([])

  const [updateModal, setUpdateModal] = useState(false)
  const [dropdownValues, setDropdownValues] = useState([])
  const [submitLoading, setSubmitLoading] = useState(false)

  const [form, setForm] = useState({
    tc_fullName: "",
    tc_email: "",
    tc_altEmail: "",
    tc_staffId: "",
    tc_schoolId: "",
    tc_education: "",
    tc_phoneNumber: "",
    tc_altPhoneNumber: "",
    tc_address: "",
    tc_profilePic: { fileName: "", file: {} },
    tc_degreeCertificate: { fileName: "", file: {} },
    tc_countryCode: "",
    tc_level: "",
    // tc_dateOfBirth: "",
    // tc_bloodGroup: "",
    areaValue: "",
    tc_certificate: "",
    canCreateLiveClass: false,
    tc_userType: 1,
    tc_alsoKnownAs: "",
    tc_experience: "",
    tc_languageSpoken: "",
    tc_briefProfile: "",
  })

  const setSchoolDropDownValues = async id => {
    try {
      const response = await getAllSchool()
      let { schools } = response.data || {}
      let dropdownVals = schools.map(school => {
        return { value: school.sc_id, label: school.sc_schoolName }
      })
      dropdownVals = dropdownVals || []
      setDropdownValues(dropdownVals)
      if (id) {
        const defVal = dropdownVals.find(item => item.value === id)
        defVal ? setSelectedSchool(defVal) : setSelectedSchool({})
      }
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Schools"

      setDropdownValues([])

      return SaveToast({ message, type: "error" })
    }
  }

  const fetchAllSchoolByArea = async (values, oldVal) => {
    if (values === "") {
      setSchoolDropdownValues([])
      setSelectedSchool({})
      setForm({ ...form, tc_schoolId: "" })
      // fetchClassDropDownValues("")
    } else {
      try {
        let [region, district, circuit] =
          (values + "" || "")?.split(defaultRDCSeparator) || []
        region = region || ""
        district = district || ""
        circuit = circuit || ""

        const response = await getAllSchoolByArea(region, district, circuit)
        let { schools } = response.data || {}

        let dropdownVals = schools.map(school => {
          return { value: school.sc_id, label: school.sc_schoolName }
        })

        dropdownVals = dropdownVals || []
        setSchoolDropdownValues(dropdownVals)
        if (oldVal) {
          const defVal = dropdownVals.find(item => item.value === oldVal)
          defVal ? setSelectedSchool(defVal) : setSelectedSchool({})
        }
      } catch (error) {
        let message =
          error?.response?.data?.message ||
          error?.message ||
          "There Was A Problem Fetching Schools"

        setSchoolDropdownValues([])

        return SaveToast({ message, type: "error" })
      }
    }
  }

  useEffect(() => {
    //setting dropdown values for school
    setSchoolDropDownValues()

    document.getElementById("teachers").classList.add("mm-active")
    let { type, id } = props.match.params || {}

    switch (type) {
      case "edit":
        setIsEdit(true)
        setIsView(false)
        setTeacherId(parseInt(id))
        break
      case "view":
        setIsView(true)
        setIsEdit(false)
        setTeacherId(parseInt(id))
        break
      case "add":
        break
      default:
        setIsView(false)
        setIsEdit(false)
        setTeacherId(parseInt(id))
        break
    }

    if (id) {
      fetchTeacherDetailsForEdit(id)
    }
  }, [isEdit])

  const fetchTeacherDetailsForEdit = async tc_id => {
    try {
      let response = await getTeacher(tc_id)

      let { teacher } = response.data || {}
      teacher = teacher || {}

      let { tc_region, tc_district, tc_circuit } = teacher

      let areaValue = `${tc_region || ""}${defaultRDCSeparator}${
        tc_district || ""
      }${defaultRDCSeparator}${tc_circuit || ""}`
      teacher["areaValue"] = areaValue

      teacher["tc_profilePic_old"] = teacher["tc_profilePic"]
      teacher["tc_degreeCertificate_old"] = teacher["tc_degreeCertificate"]
      teacher["tc_profilePic"] = { fileName: "", file: {} }
      teacher["tc_degreeCertificate"] = { fileName: "", file: {} }

      fetchAllSchoolByArea(areaValue, teacher["tc_schoolId"])

      if (teacher.tc_certificate) {
        setSelectedCertificate({
          label: teacher.tc_certificate,
          value: teacher.tc_certificate,
        })
      }

      if (teacher.tc_level) {
        setSelectedLevel({
          label: teacher.tc_level,
          value: teacher.tc_level,
        })
      }

      if (teacher.tc_experience) {
        setSelectedExperience({
          label: teacher.tc_experience,
          value: teacher.tc_experience,
        })
      }

      let langFromRes = teacher["tc_languageSpoken"]
      let tempLang = []
      if (langFromRes && langFromRes.length > 0) {
        tempLang = languages?.filter(master => {
          return langFromRes?.find(subRes => {
            return subRes == master.value
          })
        })
        setSelectedLanguage(tempLang)
      }

      if (isEdit) {
        if (teacher.canCreateLiveClass === true) {
          document.getElementById("canCreateLiveClass").checked = true
        } else {
          document.getElementById("canNotCreateLiveClass").checked = true
        }
      }

      setSchoolDropDownValues(teacher["tc_schoolId"])
      setSelectedCountryCode(
        countryCodes.find(
          countryCode => countryCode.value === teacher["tc_countryCode"]
        )
      )

      return setForm(teacher)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching teacher details"

      setForm(form)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddTeacherSubmit = async data => {
    try {
      setSubmitLoading(true)
      const response = await createTeacher(data)
      let message = response?.message || "Teacher Added Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)
      props.history.push("/teachers")
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Adding Teacher"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleEditTeacherSubmit = async (id, data) => {
    if (!id) {
      return SaveToast({
        message: "Please enter Teacher Id",
        type: "error",
      })
    }
    try {
      setSubmitLoading(true)
      const response = await updateTeacher(id, data)
      let message = response?.message || "Teacher Updated Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)
      props.history.push("/teachers")
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Adding Teacher"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleUpdateTeacher = () => {
    setUpdateModal(false)
    SaveToast({ message: "Teacher Updated Successfully", type: "success" })
    props.history.push("/teachers")
  }

  return (
    <React.Fragment>
      <UpdateModal
        show={updateModal}
        onUpdateClick={handleUpdateTeacher}
        onCloseClick={() => setUpdateModal(false)}
      />
      <div className="page-content">
        <div
          className="container-fluid"
          style={submitLoading ? { position: "relative", opacity: "0.8" } : {}}
        >
          {/* <Breadcrumbs title="Teachers" breadcrumbItem="Teacher" /> */}
          {submitLoading ? <SubmitLoader /> : <></>}
          {isView ? (
            <>
              <ViewDetails {...props} />
            </>
          ) : (
            <Card>
              {/* <CardHeader> 
                 <CardTitle> 
                  <Label>{!isEdit ? "Enter" : "Update"} Details</Label> 
                 </CardTitle> 
               </CardHeader>  */}
              <CardBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={form}
                  validationSchema={Yup.object({
                    tc_fullName: Yup.string().required(
                      "Please Enter Your Full Name"
                    ),
                    tc_email: Yup.string()
                      .required("Please Enter Your Email")
                      .email("Please Enter Valid Email"),
                    tc_altEmail: Yup.string()
                      .required("Please Enter Your Email")
                      .email("Please Enter Valid Email"),
                    tc_staffId: Yup.string().required(
                      "Please Enter Your Staff Id"
                    ),
                    tc_schoolId: Yup.number().required("Please select school"),
                    tc_level: Yup.string().required("Please select level"),
                    tc_languageSpoken: Yup.mixed().test(
                      "invalidInput",
                      "Please Select Languages",
                      value => {
                        if (value) {
                          return value.length
                        } else {
                          return false
                        }
                      }
                    ),
                    tc_experience: Yup.string().required(
                      "Please enter your experience"
                    ),
                    tc_education: Yup.string()
                      .notRequired("Please Enter Your Education")
                      .nullable(),
                    tc_address: Yup.string()
                      .notRequired("Please Enter Your Address")
                      .nullable(),
                    tc_phoneNumber: Yup.string()
                      .required("Please enter your phone number")
                      .matches(
                        /^\d{9}$/,
                        "Please enter 9 digit number,without adding 0"
                      ),
                    tc_altPhoneNumber: Yup.string()
                      .nullable()
                      .notRequired()
                      .matches(
                        /^\d{9}$/,
                        "Please enter 9 digit number,without adding 0"
                      ),
                    tc_briefProfile: Yup.string().required(
                      "Please enter your brief profile"
                    ),
                    tc_profilePic: Yup.mixed().nullable().notRequired(),
                    // .test(
                    //   "fileNotSelected",
                    //   "Please Select Profile Picture",
                    //   value => {
                    //     return isEdit || !!form?.["tc_profilePic"]?.file?.type
                    //   }
                    // )
                    // .test("fileFormat", "Unsupported Format", value => {
                    //   if (!form?.["tc_profilePic"]?.file?.type)
                    //     return isEdit || false

                    //   return [
                    //     "image/png",
                    //     "image/jpg",
                    //     "image/jpeg",
                    //     "image/gif",
                    //   ].includes(form?.["tc_profilePic"].file.type)
                    // }),
                    tc_degreeCertificate: Yup.mixed().nullable().notRequired(),
                    // .test(
                    //   "fileNotSelected",
                    //   "Please Select Degree Certificate",
                    //   value => {
                    //     return (
                    //       isEdit ||
                    //       !!form?.["tc_degreeCertificate"]?.file?.type
                    //     )
                    //   }
                    // )
                    // .test("fileFormat", "Unsupported Format", value => {
                    //   if (!form?.["tc_degreeCertificate"]?.file?.type)
                    //     return isEdit || false

                    //   return [
                    //     "image/png",
                    //     "image/jpg",
                    //     "image/jpeg",
                    //     "image/gif",
                    //   ].includes(form?.["tc_degreeCertificate"].file.type)
                    // }),
                    tc_countryCode: Yup.string().required(
                      "Please Select Country Code"
                    ),
                    tc_certificate: Yup.string()
                      .required("Please Select Country Code")
                      .nullable(),
                    // tc_dateOfBirth: Yup.string().notRequired().nullable(),
                    // tc_bloodGroup: Yup.string()
                    //   .nullable()
                    //   .notRequired("Please Select bloodGroups"),
                    areaValue: Yup.mixed().test(
                      "invalidInput",
                      "Please Select Region-District-Circuit",
                      value => {
                        return !!value
                      }
                    ),
                  })}
                  onSubmit={values => {
                    let [tc_region, tc_district, tc_circuit] =
                      (values?.areaValue + "" || "")?.split(
                        defaultRDCSeparator
                      ) || []
                    tc_region = tc_region || null
                    tc_district = tc_district || null
                    tc_circuit = tc_circuit || null

                    let teacherData = values

                    let tc_languageSpoken = selectedLanguage.map(
                      data => data.value
                    )
                    teacherData["tc_languageSpoken"] = tc_languageSpoken
                      ? tc_languageSpoken?.toString()
                      : null

                    if (isEdit) {
                      teacherData["tc_profilePic_old"] = form.tc_profilePic_old
                      teacherData["canCreateLiveClass"] =
                        form.canCreateLiveClass
                      teacherData["tc_degreeCertificate_old"] =
                        form.tc_degreeCertificate_old
                      teacherData["tc_profilePic"] = form.tc_profilePic.file
                      teacherData["tc_degreeCertificate"] =
                        form.tc_degreeCertificate.file
                      teacherData["tc_region"] = tc_region
                      teacherData["tc_district"] = tc_district
                      teacherData["tc_circuit"] = tc_circuit
                      teacherData["tc_userType"] = 1
                      delete teacherData["tc_id"]
                      return handleEditTeacherSubmit(teacherId, teacherData)
                    } else {
                      teacherData["tc_profilePic"] = form.tc_profilePic.file
                      teacherData["canCreateLiveClass"] =
                        form.canCreateLiveClass
                      teacherData["tc_degreeCertificate"] =
                        form.tc_degreeCertificate.file
                      teacherData["tc_region"] = tc_region
                      teacherData["tc_district"] = tc_district
                      teacherData["tc_circuit"] = tc_circuit
                      teacherData["tc_userType"] = 1
                      return handleAddTeacherSubmit(teacherData)
                    }
                  }}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    setFieldTouched,
                  }) => (
                    <>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          handleSubmit(e)
                          return false
                        }}
                      >
                        <Row>
                          <Col>
                            <Row className="mb-3">
                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">
                                  Full Name{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="tc_fullName"
                                  type="text"
                                  placeholder="Enter Full Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.tc_fullName && errors.tc_fullName
                                  }
                                  defaultValue={form.tc_fullName}
                                />
                                {touched.tc_fullName && errors.tc_fullName && (
                                  <FormFeedback>
                                    {errors.tc_fullName}
                                  </FormFeedback>
                                )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Email <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="tc_email"
                                  type="email"
                                  placeholder="Enter Email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={touched.tc_email && errors.tc_email}
                                  defaultValue={form.tc_email}
                                />
                                {touched.tc_email && errors.tc_email && (
                                  <FormFeedback>{errors.tc_email}</FormFeedback>
                                )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Alt. Email{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="tc_altEmail"
                                  type="email"
                                  placeholder="Enter Alternate Email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.tc_altEmail && errors.tc_altEmail
                                  }
                                  defaultValue={form.tc_altEmail}
                                />
                                {touched.tc_altEmail && errors.tc_altEmail && (
                                  <FormFeedback>
                                    {errors.tc_altEmail}
                                  </FormFeedback>
                                )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Staff Id
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="tc_staffId"
                                  type="string"
                                  placeholder="Enter Staff Id"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.tc_staffId && errors.tc_staffId
                                  }
                                  defaultValue={form.tc_staffId}
                                />
                                {touched.tc_staffId && errors.tc_staffId && (
                                  <FormFeedback>
                                    {errors.tc_staffId}
                                  </FormFeedback>
                                )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <RegionDistrictCircuitDropDownAllSelectable
                                  isRequired={true}
                                  fieldName="areaValue"
                                  hasTouched={touched.areaValue}
                                  hasErrors={errors.areaValue}
                                  areaValue={values.areaValue}
                                  setFieldValue={setFieldValue}
                                  setFieldTouched={setFieldTouched}
                                  areaChange={fetchAllSchoolByArea}
                                />
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  School Name{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Select
                                  name="tc_schoolId"
                                  placeholder="Select School"
                                  value={selectedSchool}
                                  onChange={value => {
                                    setSelectedSchool(value)
                                    setFieldValue(
                                      "tc_schoolId",
                                      value ? value.value : ""
                                    )
                                    // fetchClassDropDownValues(
                                    //   value ? value.value : ""
                                    // )
                                  }}
                                  onBlur={evt => {
                                    setFieldTouched("tc_schoolId", true, true)
                                  }}
                                  options={schoolDropdownValues}
                                  isClearable
                                  invalid={
                                    touched.tc_schoolId && errors.tc_schoolId
                                  }
                                />
                                {touched.tc_schoolId && errors.tc_schoolId && (
                                  <div className="invalid-react-select-dropdown">
                                    {errors.tc_schoolId}
                                  </div>
                                )}
                              </Col>
                              {/* <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">Education</Label>
                                <Input
                                  name="tc_education"
                                  type="text"
                                  placeholder="Enter Education"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.tc_education && errors.tc_education
                                  }
                                  defaultValue={form.tc_education}
                                />
                                {touched.tc_education &&
                                  errors.tc_education && (
                                    <FormFeedback>
                                      {errors.tc_education}
                                    </FormFeedback>
                                  )}
                              </Col> */}

                              <Col
                                md={2}
                                sm={4}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Country Code{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Select
                                  name="tc_countryCode"
                                  placeholder="Select Country Code"
                                  value={selectedCountryCode}
                                  onChange={value => {
                                    setSelectedCountryCode(value)
                                    setFieldValue(
                                      "tc_countryCode",
                                      value ? value.value : ""
                                    )
                                  }}
                                  onBlur={evt => {
                                    setFieldTouched(
                                      "tc_countryCode",
                                      true,
                                      true
                                    )
                                  }}
                                  options={countryCodes.map(countryCode => {
                                    return {
                                      label: `${countryCode.label} (+${countryCode.value})`,
                                      value: countryCode.value,
                                    }
                                  })}
                                  isClearable
                                  invalid={
                                    touched.tc_countryCode &&
                                    errors.tc_countryCode
                                  }
                                />
                                {touched.tc_countryCode &&
                                  errors.tc_countryCode && (
                                    <div className="invalid-react-select-dropdown">
                                      {errors.tc_countryCode}
                                    </div>
                                  )}
                              </Col>

                              <Col
                                md={2}
                                sm={3}
                                xs={6}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Phone Number{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="tc_phoneNumber"
                                  type="tel"
                                  placeholder="Enter Phone Number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.tc_phoneNumber &&
                                    errors.tc_phoneNumber
                                  }
                                  defaultValue={form.tc_phoneNumber}
                                />
                                {touched.tc_phoneNumber &&
                                  errors.tc_phoneNumber && (
                                    <FormFeedback>
                                      {errors.tc_phoneNumber}
                                    </FormFeedback>
                                  )}
                              </Col>

                              {/* <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Alt. Phone Number
                                </Label>
                                <Input
                                  name="tc_altPhoneNumber"
                                  type="tel"
                                  placeholder="Enter Alt. Phone Number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.tc_altPhoneNumber &&
                                    errors.tc_altPhoneNumber
                                  }
                                  defaultValue={form.tc_altPhoneNumber}
                                />
                                {touched.tc_altPhoneNumber &&
                                  errors.tc_altPhoneNumber && (
                                    <FormFeedback>
                                      {errors.tc_altPhoneNumber}
                                    </FormFeedback>
                                  )}
                              </Col> */}

                              {/* <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Select BloodGroup{" "}
                                </Label>
                                <Input
                                  name="tc_bloodGroup"
                                  type="select"
                                  id="bloodGropus"
                                  className="form-select"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.tc_bloodGroup || 0}
                                  invalid={
                                    touched.tc_bloodGroup &&
                                    errors.tc_bloodGroup
                                      ? true
                                      : false
                                  }
                                >
                                  <option disabled value={0}>
                                    Select BloodGroup
                                  </option>
                                  {bloodGroups.map(val => {
                                    return (
                                      <option key={val} value={val}>
                                        {val}
                                      </option>
                                    )
                                  })}
                                </Input>
                                {touched.tc_bloodGroup &&
                                  errors.tc_bloodGroup && (
                                    <FormFeedback>
                                      {errors.tc_bloodGroup}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Date Of Birth{" "}
                                </Label>
                                <Input
                                  name="tc_dateOfBirth"
                                  type="date"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.tc_dateOfBirth &&
                                    errors.tc_dateOfBirth
                                  }
                                  value={values.tc_dateOfBirth}
                                />
                                {touched.tc_dateOfBirth &&
                                  errors.tc_dateOfBirth && (
                                    <FormFeedback>
                                      {errors.tc_dateOfBirth}
                                    </FormFeedback>
                                  )}
                              </Col> */}
                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Select Certificate{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Select
                                  name="tc_certificate"
                                  placeholder="Select certificate"
                                  value={selectedCertificate}
                                  onChange={value => {
                                    setSelectedCertificate(value)
                                    setFieldValue(
                                      "tc_certificate",
                                      value ? value.value : ""
                                    )
                                  }}
                                  onBlur={evt => {
                                    setFieldTouched(
                                      "tc_certificate",
                                      true,
                                      true
                                    )
                                  }}
                                  options={certificateList}
                                  isClearable
                                  invalid={
                                    touched.tc_certificate &&
                                    errors.tc_certificate
                                  }
                                />
                                {touched.tc_certificate &&
                                  errors.tc_certificate && (
                                    <div className="invalid-react-select-dropdown">
                                      {errors.tc_certificate}
                                    </div>
                                  )}
                              </Col>
                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Select Level
                                  <span className="text-danger">*</span>
                                </Label>
                                <Select
                                  name="tc_level"
                                  placeholder="Select level"
                                  value={selectedLevel}
                                  onChange={value => {
                                    setSelectedLevel(value)
                                    setFieldValue(
                                      "tc_level",
                                      value ? value.value : ""
                                    )
                                  }}
                                  onBlur={evt => {
                                    setFieldTouched("tc_level", true, true)
                                  }}
                                  options={levels}
                                  isClearable
                                  invalid={touched.tc_level && errors.tc_level}
                                />
                                {touched.tc_level && errors.tc_level && (
                                  <div className="invalid-react-select-dropdown">
                                    {errors.tc_level}
                                  </div>
                                )}
                              </Col>
                              <Col
                                md={4}
                                tc_experience
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Years Of Experience
                                  <span className="text-danger">*</span>
                                </Label>
                                <Select
                                  name="tc_experience"
                                  placeholder="Years Of Experience"
                                  value={selectedExperience}
                                  onChange={value => {
                                    setSelectedExperience(value)
                                    setFieldValue(
                                      "tc_experience",
                                      value ? value.value : ""
                                    )
                                  }}
                                  onBlur={evt => {
                                    setFieldTouched("tc_experience", true, true)
                                  }}
                                  options={yearsOfExperience}
                                  isClearable
                                  invalid={
                                    touched.tc_experience &&
                                    errors.tc_experience
                                  }
                                />
                                {touched.tc_experience &&
                                  errors.tc_experience && (
                                    <div className="invalid-react-select-dropdown">
                                      {errors.tc_experience}
                                    </div>
                                  )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Languages Spoken
                                  <span className="text-danger">*</span>
                                </Label>
                                <Select
                                  isMulti
                                  name="tc_languageSpoken"
                                  placeholder="Select languages"
                                  value={selectedLanguage}
                                  onChange={value => {
                                    setSelectedLanguage(value)
                                    setFieldValue(
                                      "tc_languageSpoken",
                                      value ? value : ""
                                    )
                                  }}
                                  onBlur={evt => {
                                    setFieldTouched(
                                      "tc_languageSpoken",
                                      true,
                                      true
                                    )
                                  }}
                                  options={languages}
                                  isClearable
                                  invalid={
                                    touched.tc_languageSpoken &&
                                    errors.tc_languageSpoken
                                  }
                                />
                                {touched.tc_languageSpoken &&
                                  errors.tc_languageSpoken && (
                                    <div className="invalid-react-select-dropdown">
                                      {errors.tc_languageSpoken}
                                    </div>
                                  )}
                              </Col>

                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">
                                  Also Known As
                                </Label>
                                <Input
                                  name="tc_alsoKnownAs"
                                  type="text"
                                  placeholder="Enter also known as"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.tc_alsoKnownAs &&
                                    errors.tc_alsoKnownAs
                                  }
                                  defaultValue={form.tc_alsoKnownAs}
                                />
                                {touched.tc_alsoKnownAs &&
                                  errors.tc_alsoKnownAs && (
                                    <FormFeedback>
                                      {errors.tc_alsoKnownAs}
                                    </FormFeedback>
                                  )}
                              </Col>
                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Can Create Live Class{" "}
                                </Label>
                                <br />
                                <RBSForm.Check
                                  id="canCreateLiveClass"
                                  name="canCreateLiveClass"
                                  type="radio"
                                  inline
                                  label="Yes"
                                  onChange={event => {
                                    if (event.target.checked) {
                                      let temp = form
                                      temp["canCreateLiveClass"] = true
                                      validation.values[
                                        "canCreateLiveClass"
                                      ] = true
                                      setForm(temp)
                                    }
                                  }}
                                />
                                <RBSForm.Check
                                  id="canNotCreateLiveClass"
                                  name="canCreateLiveClass"
                                  type="radio"
                                  inline
                                  label="No"
                                  onChange={event => {
                                    if (event.target.checked) {
                                      let temp = form
                                      temp["canCreateLiveClass"] = false
                                      validation.values[
                                        "canCreateLiveClass"
                                      ] = false
                                      setForm(temp)
                                    }
                                  }}
                                />
                                {touched.tc_dateOfBirth &&
                                  errors.tc_dateOfBirth && (
                                    <FormFeedback>
                                      {errors.tc_dateOfBirth}
                                    </FormFeedback>
                                  )}
                              </Col>
                            </Row>

                            <Row className="mb-3">
                              {/* <Col sm={12} md={6}>
                                <Row>
                                  <Col>
                                    <Label className="form-label">
                                      Address{" "}
                                    </Label>
                                    <Input
                                      name="tc_address"
                                      type="textarea"
                                      rows={6}
                                      placeholder="Enter Address"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      invalid={
                                        touched.tc_address && errors.tc_address
                                      }
                                      defaultValue={form.tc_address}
                                    />
                                    {touched.tc_address &&
                                      errors.tc_address && (
                                        <FormFeedback>
                                          {errors.tc_address}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col> */}

                              <Col sm={12} md={6}>
                                <Row>
                                  <Col>
                                    <Label className="form-label">
                                      Brief Profile
                                    </Label>
                                    <Input
                                      name="tc_briefProfile"
                                      type="textarea"
                                      rows={6}
                                      placeholder="Enter Address"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      invalid={
                                        touched.tc_briefProfile &&
                                        errors.tc_briefProfile
                                      }
                                      defaultValue={form.tc_briefProfile}
                                    />
                                    {touched.tc_briefProfile &&
                                      errors.tc_briefProfile && (
                                        <FormFeedback>
                                          {errors.tc_briefProfile}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>

                              {/* <Col sm={12} md={6}>
                                <Row>
                                  <Col>
                                    <Label className="form-label">
                                      Address{" "}
                                    </Label>
                                    <Input
                                      name="tc_address"
                                      type="textarea"
                                      rows={6}
                                      placeholder="Enter Address"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      invalid={
                                        touched.tc_address && errors.tc_address
                                      }
                                      defaultValue={form.tc_address}
                                    />
                                    {touched.tc_address &&
                                      errors.tc_address && (
                                        <FormFeedback>
                                          {errors.tc_address}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col> */}

                              <Col sm={12} md={6} className="mt-2 mt-md-0">
                                <Row>
                                  <Col>
                                    <Label className="form-label">
                                      Profile Pic
                                      {isEdit && form?.tc_profilePic_old && (
                                        <>
                                          <span className="ms-2">
                                            (
                                            <a
                                              href={`${IMAGE_URL}/${
                                                form?.tc_profilePic_old || ""
                                              }`}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              Saved Profile Picture
                                            </a>
                                            )
                                          </span>
                                        </>
                                      )}
                                    </Label>
                                    <Input
                                      name="tc_profilePic"
                                      type="file"
                                      accept=".png, .jpg, .jpeg, .gif"
                                      placeholder="Select Profile Pic"
                                      onChange={e => {
                                        let tempForm = form
                                        tempForm["tc_profilePic"]["fileName"] =
                                          e.target.value
                                        tempForm["tc_profilePic"]["file"] =
                                          e.target.files[0]
                                        setForm(tempForm)
                                      }}
                                      // onBlur={handleBlur}
                                      invalid={
                                        touched.tc_profilePic &&
                                        errors.tc_profilePic
                                      }
                                      defaultValue={form.tc_profilePic.fileName}
                                    />
                                    {touched.tc_profilePic &&
                                      errors.tc_profilePic && (
                                        <FormFeedback>
                                          {errors.tc_profilePic}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>

                                <Row className="mt-2">
                                  <Col>
                                    <Label className="form-label">
                                      Degree Certificate
                                      {isEdit &&
                                        form?.tc_degreeCertificate_old && (
                                          <>
                                            <span className="ms-2">
                                              (
                                              <a
                                                href={`${IMAGE_URL}/${
                                                  form?.tc_degreeCertificate_old ||
                                                  ""
                                                }`}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                Saved Degree Certificate
                                              </a>
                                              )
                                            </span>
                                          </>
                                        )}
                                    </Label>
                                    <Input
                                      name="tc_degreeCertificate"
                                      type="file"
                                      accept=".png, .jpg, .jpeg, .gif, .pdf"
                                      placeholder="Select Degree Certificate"
                                      onChange={e => {
                                        let tempForm = form
                                        tempForm["tc_degreeCertificate"][
                                          "fileName"
                                        ] = e.target.value
                                        tempForm["tc_degreeCertificate"][
                                          "file"
                                        ] = e.target.files[0]
                                        setForm(tempForm)
                                      }}
                                      invalid={
                                        touched.tc_degreeCertificate &&
                                        errors.tc_degreeCertificate
                                      }
                                      defaultValue={
                                        form.tc_degreeCertificate.fileName
                                      }
                                    />
                                    {touched.tc_degreeCertificate &&
                                      errors.tc_degreeCertificate && (
                                        <FormFeedback>
                                          {errors.tc_degreeCertificate}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="mb-3 text-center">
                              <Col>
                                <Button
                                  size="md"
                                  color="danger"
                                  type="button"
                                  disabled={submitLoading}
                                  className="mx-2"
                                  onClick={() => {
                                    props.history.push("/teachers")
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  size="md"
                                  color="info"
                                  type="submit"
                                  disabled={submitLoading}
                                >
                                  {!isEdit ? "Save" : "Update"}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </Formik>
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

TeacherModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default TeacherModal
