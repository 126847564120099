import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Button, Col, Row, Table } from "reactstrap"
import { getContent } from "helpers/backendHelpers/contentManagement"
import { IMAGE_URL } from "helpers/urlHelper"

const ViewDetails = props => {
  const [contentData, setcontentData] = useState(props.contentData || {})
  const [contentId, setContentId] = useState(0)

  useEffect(() => {
    let { id } = props.match.params || {}
    setContentId(parseInt(id))

    if (id) {
      let contentData = {}
      getContent(id)
        .then(resp => {
          contentData = resp.data.content

          Object.keys(contentData).forEach(key => {
            if (contentData[key] === null) {
              delete contentData[key]
            }
          })
          setcontentData(contentData)
        })
        .catch(err => {})
    }
  }, [])

  return (
    <React.Fragment>
      <Row className="mt-3">
        <Col className="text-end">
          <Button
            color="info"
            onClick={() => {
              props.history.goBack()
            }}
          >
            Back
          </Button>
        </Col>
      </Row>
      <Table>
        <tbody>
          <tr>
            <th>Content Title</th>
            <td>{contentData.cm_contentTitle}</td>
          </tr>
          <tr>
            <th>Description</th>
            <td>{contentData.cm_description}</td>
          </tr>
          <tr>
            <th>Reading Material</th>
            <td>
              <a
                href={`${IMAGE_URL}/${contentData.cm_readingMaterial}`}
                target="#"
              >
                {" "}
                View Material
              </a>
            </td>
          </tr>
          <tr>
            <th>Video</th>
            <td>
              <a href={IMAGE_URL + "/" + contentData.cm_video} target="#">
                Watch Video
              </a>
            </td>
          </tr>
          <tr>
            <th>Status</th>
            <td>{contentData.cm_status ? "Active" : "Inactive"}</td>
          </tr>
        </tbody>
      </Table>
    </React.Fragment>
  )
}

ViewDetails.propTypes = {
  contentData: PropTypes.object.isRequired,
}

export default ViewDetails
