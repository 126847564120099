import React, { useMemo, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link, useHistory } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

//import components
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import { SaveToast } from "components/Common/SaveToast"

import { SimpleStringValue } from "helpers/common_helper_functions"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Button,
} from "reactstrap"

import PDFModal from "./ViewModal"
import VideoModal from "./WatchModal"

import {
  getAllContent,
  deleteContent,
  updateContent,
} from "helpers/backendHelpers/contentManagement"

const ContentManagement = props => {
  const history = useHistory()
  //meta title
  document.title = "Content Management | LMS Ghana"

  //delete order
  const [deleteModal, setDeleteModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [modal2, setModal2] = useState(false)
  const [pdfFile, setPdfFile] = useState("")
  const [videoFile, setVideoFile] = useState("")

  const [contents, setContents] = useState([])
  const [content, setContent] = useState({})
  const [saved, setSaved] = useState(false)
  const [loading, setLoading] = useState(false)
  const tp_id = props.location?.state?.tp_id

  useEffect(() => {
    fetchAllContent(tp_id)
  }, [saved])

  const fetchAllContent = async () => {
    try {
      setLoading(true)
      const response = await getAllContent(tp_id)
      let { content } = response.data || {}
      content = content || []
      setContents(content)
      setLoading(false)
    } catch (error) {
      const message = error?.message || "There Was A Problem Fetching Content"
      setContents([])
      setLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const onClickDelete = content => {
    setContent(content)
    setDeleteModal(true)
  }

  const handleUpdateContentStatus = async (id, status) => {
    if (!id) {
      return SaveToast({
        message: "Please Enter Content Id",
        type: "error",
      })
    }
    try {
      const response = updateContent(id, { cm_status: status })
      const message = response?.message || "Content Status Updated Successfully"
      SaveToast({ message, type: "success" })

      setSaved(prevSaved => !prevSaved)
      return
    } catch (error) {
      const message =
        error?.message || "There Was A Problem Updating Content Status"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleDeleteContent = async () => {
    if (!content.cm_id) {
      return SaveToast({ message: "Invalid Content ID", type: "error" })
    }
    try {
      const response = await deleteContent(content.cm_id)
      const message = response?.message || "Content Deleted Successfully"
      SaveToast({ message, type: "success" })

      setSaved(prevSaved => !prevSaved)
      setDeleteModal(false)
      return
    } catch (error) {
      const message = error?.message || "There was a problem deleting Content"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddButtonClick = () => {
    history.push({
      pathname: "/content-management/add",
      state: { tp_id: props.location?.state?.tp_id },
    })
  }

  const toggleViewModal = () => {
    setModal1(!modal1)
    if (pdfFile) {
      setPdfFile(null)
    }
    if (videoFile) {
      setVideoFile(null)
    }
  }

  const toggleWatchModal = () => {
    setModal2(!modal2)
  }

  const handleBackButtonClick = () => {
    // props.history.push("/batch-creation/add")
    history.push({
      pathname: `/training-program`,
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: "Content Title",
        accessor: "cm_contentTitle",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Reading Material",
        accessor: "cm_readingMaterial",
        filterable: true,
        Cell: cellProps => {
          return (
            <Button
              color="info"
              onClick={() => {
                setPdfFile(cellProps.row.original.cm_readingMaterial)
                toggleViewModal()
              }}
            >
              Read
            </Button>
          )
        },
      },
      {
        Header: "Video",
        accessor: "cm_video",
        filterable: true,
        Cell: cellProps => {
          return (
            <Button
              color="info"
              onClick={() => {
                setVideoFile(cellProps.row.original.cm_video)
                toggleWatchModal()
              }}
            >
              Watch
            </Button>
          )
        },
      },
      {
        Header: "Status",
        accessor: "cm_status",
        disableFilters: true,
        Cell: cellProps => {
          const cellData = cellProps.row.original
          return (
            <div className="form-check form-switch form-switch-md">
              <input
                type="checkbox"
                className="form-check-input"
                id={`cm_status_checkbox-${cellData.cm_id}`}
                name={`cm_status_checkbox-${cellData.cm_id}`}
                defaultChecked={cellData.cm_status}
                onChange={e => {
                  let { checked, name } = e.target
                  document.getElementById(name).checked = checked
                  return handleUpdateContentStatus(cellData.cm_id, checked)
                }}
              />
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={`/content-management/view/` + cellProps.row.original.cm_id}
                className="text-dark"
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to={"/content-management/edit/" + cellProps.row.original.cm_id}
                className="text-success"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const contentData = cellProps.row.original
                  onClickDelete(contentData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteContent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <PDFModal isOpen={modal1} toggle={toggleViewModal} pdf={pdfFile} />
      <VideoModal isOpen={modal2} toggle={toggleWatchModal} video={videoFile} />
      {/* <Breadcrumbs title="Contnt Management" breadcrumbItem="Content" /> */}
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={contents}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    isBackOption={true}
                    addButtonLabel="Add Content"
                    handleAddButtonClick={handleAddButtonClick}
                    handleBackButtonClick={handleBackButtonClick}
                    customPageSize={10}
                    className="custom-header-css"
                    canExportCsv={true}
                    canPrint={false}
                    dataFetchLoading={loading}
                    noDataMessage={"No system activity found."}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ContentManagement
