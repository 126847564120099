import { getApiConfig } from "helpers/authHelper"
import { get, post, put, del } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllMainCategories = () => {
  return get(url.MAIN_CATEGORY, getApiConfig())
}

export const getAllCategories = () => {
  return get(url.CATEGORY, getApiConfig())
}

export const getAllSubCategories = () => {
  return get(url.SUB_CATEGORY, getApiConfig())
}
export const getAllTopics = () => {
  return get(url.TOPIC, getApiConfig())
}

export const createCategory = data => {
  return post(url.MANAGE_CATEGORY, data, getApiConfig())
}

export const deleteCategory = id => {
  return del(`${url.MANAGE_CATEGORY}/${id}`, getApiConfig())
}
export const getSubCategories = () => {
  return get(`${url.MANAGE_CATEGORY}/subCategories`, getApiConfig())
}

export const getAllContentCategories = () => {
  return get(`${url.MANAGE_CATEGORY}/all`, getApiConfig())
}
