import { Form, Formik } from "formik"
import { updateTrainingParticipant } from "helpers/backendHelpers/trainingParticipants"
import moment from "moment"
import React, { useState } from "react"
import DatePicker from "react-datepicker"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

import * as Yup from "yup"

const formInitialState = {
  tps_score: "",
  tps_examDate: new Date(),
}

function ExamScoreUpdateFormModal({ tpsId, refreshParent, closeModal }) {
  const isOpen = tpsId != null
  const [isLoading, setIsLoading] = useState(false)

  const handleFormSubmit = async values => {
    const data = {}

    data["tps_trainingStatus"] = 4
    data["tps_examDate"] = moment(values.tps_examDate).format("DD-MM-YYYY")
    data["tps_score"] = values.tps_score

    try {
      setIsLoading(true)
      await updateTrainingParticipant(tpsId, data)

      setIsLoading(false)
      closeModal()
      refreshParent()
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} centered={true}>
      <ModalHeader color="dark" toggle={closeModal}>
        Update Exam Detail
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={formInitialState}
          validationSchema={Yup.object({
            tps_score: Yup.string()
              .required("Please Enter Score")
              .matches(/^[0-9]+$/, "Please Enter a valid Score"),
            tps_examDate: Yup.string().required("Please Enter Exam Date"),
          })}
          onSubmit={handleFormSubmit}
        >
          {({
            touched,
            errors,
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
            setFieldTouched,
          }) => (
            <>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  handleSubmit(e)
                  return false
                }}
              >
                <Row>
                  <Col xs="12" md="12" className="mb-3">
                    <Label className="form-label">Score</Label>
                    <Input
                      name="tps_score"
                      type="text"
                      placeholder="Enter Score"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.tps_score && errors.tps_score}
                      defaultValue={values.tps_score}
                    />
                    {touched.tps_score && errors.tps_score && (
                      <FormFeedback>{errors.tps_score}</FormFeedback>
                    )}
                  </Col>

                  <Col xs="12" md="12" className="mb-3">
                    <Label className="form-label">Enter Exam Date</Label>
                    <DatePicker
                      name="tps_examDate"
                      onChange={e => {
                        setFieldValue("tps_examDate", e)
                      }}
                      placeholderText="Select Exam Date"
                      selected={
                        values.tps_examDate != undefined && values.tps_examDate
                      }
                      invalid={touched.tps_examDate && errors.tps_examDate}
                      dateFormat="dd/MM/yy"
                    />

                    {touched.tps_examDate && errors.tps_examDate && (
                      <FormFeedback>{errors.tps_examDate}</FormFeedback>
                    )}
                  </Col>

                  <Col xs="12" md="12" className="text-center">
                    <Col>
                      <Button
                        size="md"
                        color="danger"
                        type="button"
                        disabled={isLoading}
                        className="mx-2"
                        onClick={closeModal}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="md"
                        color="info"
                        type="submit"
                        disabled={isLoading}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default ExamScoreUpdateFormModal
