import moment from "moment"

export const SimpleStringValue = cell => {
  return cell.value + "" || ""
}

export const showImage = cell => {
  return cell.value || ""
}

export const SimpleDateFormate = cell => {
  return cell.value ? moment(cell.value).format("YYYY/MM/DD") : ""
}

export const FreePaidValue = cell => {
  return cell.value ? "Free" : "Paid" || ""
}

export const ArrayToStringValue = cell => {
  return (cell.value && cell.value?.toString()) || ""
}

export const formatDate = (date, format = "DD MMM, YYYY") => {
  return date ? moment(date).format(format) : ""
}

export const stringToLowerCase = (str = "") => {
  return str.toLowerCase()
}

export const defaultRDCSeparator = ">>-<<"

export const defaultCCGSeparator = ">>-<<"

export const fetchImageAndConvertToBase64 = async imageUrl => {
  const response = await fetch(imageUrl)
  const blob = await response.blob()
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onloadend = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })
}
