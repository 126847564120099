import { SaveToast } from "components/Common/SaveToast"
import SubmitLoader from "components/Common/SubmitLoader"
import { Form, Formik } from "formik"
import { getAllMainCategories } from "helpers/backendHelpers/category"
import {
  createPastPaper,
  getPastPaper,
  updatePastPaper,
} from "helpers/backendHelpers/pastPaper"
import { IMAGE_URL } from "helpers/urlHelper"
import useAllContentCategories from "hooks/useAllContentCategories"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import DatePicker from "react-datepicker"
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import ViewDetails from "./ViewDetails"

const AddEditPastPaper = props => {
  const [isEdit, setIsEdit] = useState(false)
  const [isView, setIsView] = useState(false)

  const [mainCategoryValues, setMainCategoryValues] = useState([])
  const [categories, setCategories] = useState([])
  const [subjects, setSubjects] = useState([])

  const [PastPaperId, setPastPaperId] = useState(0)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [selectedYear, setSelectedYear] = useState("")

  const { getClasses, getSubjects } = useAllContentCategories()

  const [form, setForm] = useState({
    pp_year: "",
    pp_body: "",
    pp_title: "",
    pp_mainCategory: "",
    pp_category: "",
    pp_subcategory: "",
    pp_totalMarks: "",
    pp_pdf: { fileName: "", file: {} },
    pp_writtenPDF: { fileName: "", file: {} },
  })

  useEffect(() => {}, [])

  useEffect(() => {
    document.getElementById("past-paper").classList.add("mm-active")

    let { type, id } = props.match.params || {}
    fetchMainCategoryDropDownValues()

    switch (type) {
      case "edit":
        setIsEdit(true)
        setIsView(false)
        setPastPaperId(parseInt(id))
        break
      case "view":
        setIsView(true)
        setIsEdit(false)
        setPastPaperId(parseInt(id))
        break
      case "add":
        break
      default:
        setIsView(false)
        setIsEdit(false)
        setPastPaperId(parseInt(id))
        break
    }

    if (id) {
      fetchPastPaperDetailsForEdit(id)
    }
  }, [isEdit, getClasses, getSubjects])

  const fetchMainCategoryDropDownValues = async () => {
    try {
      let response = await getAllMainCategories()
      let { mainCategories } = response.data || {}
      mainCategories = mainCategories || []
      let mainCategoryVals = mainCategories.map(mainCategory => {
        return {
          id: mainCategory.cc_id,
          value: mainCategory.cc_categoryName,
        }
      })
      mainCategoryVals.sort((a, b) => a.value > b.value)
      setMainCategoryValues(mainCategoryVals)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching mainCategories"

      return SaveToast({ message, type: "error" })
    }
  }

  const fetchPastPaperDetailsForEdit = async pp_id => {
    try {
      let response = await getPastPaper(pp_id)
      let { pastPaper } = response.data || {}
      pastPaper = pastPaper || {}
      pastPaper["pp_pdf_old"] = pastPaper["pp_pdf"]
      pastPaper["pp_writtenPDF_old"] = pastPaper["pp_writtenPDF"]
      pastPaper["pp_pdf"] = { fileName: "", file: {} }
      pastPaper["pp_writtenPDF"] = { fileName: "", file: {} }

      setSelectedYear(new Date().setFullYear(pastPaper.pp_year, 1, 1))

      // Assigning the subject list
      if (
        pastPaper["pp_mainCategory"] &&
        pastPaper["pp_category"] &&
        pastPaper["pp_subcategory"]
      ) {
        // Set the class list
        const classes = getClasses(pastPaper["pp_mainCategory"])
        setCategories(classes)

        // Set the subject list
        const subjects = getSubjects(
          pastPaper["pp_mainCategory"],
          pastPaper["pp_category"]
        )
        setSubjects(subjects)
      }

      return setForm(pastPaper)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching freelance teacher details"
      setForm(form)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddPastPaperSubmit = async data => {
    console.log(data)
    try {
      setSubmitLoading(true)
      const response = await createPastPaper(data)
      let message = response?.message || "PastPaper Added Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)

      props.history.push("/past-paper")
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Adding Past Paper"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleEditPastPaperSubmit = async (id, data) => {
    if (!id) {
      return SaveToast({
        message: "Please enter Past Paper Id",
        type: "error",
      })
    }
    try {
      setSubmitLoading(true)
      const response = await updatePastPaper(id, data)
      let message = response?.message || "Past Paper Updated Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)

      props.history.push("/past-paper")
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Updating Past Paper"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div
          className="container-fluid"
          style={submitLoading ? { position: "relative", opacity: "0.8" } : {}}
        >
          {submitLoading ? <SubmitLoader /> : <></>}
          {isView ? (
            <>
              <ViewDetails {...props} />
            </>
          ) : (
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={form}
                  validationSchema={Yup.object({
                    pp_title: Yup.string().required("Please Enter Paper Title"),

                    // pp_year: Yup.string().required("Please Enter Year"),

                    pp_category: Yup.string().required(
                      "Please Select Class/Grade"
                    ),
                    pp_subcategory: Yup.string().required(
                      "Please Select Subjects"
                    ),
                    pp_pdf: Yup.mixed()
                      .required("Please Select Pdf")
                      .test("fileNotSelected", "Please Select Pdf", value => {
                        return isEdit || !!form?.["pp_pdf"]?.file?.type
                      })
                      .test("fileFormat", "Unsupported Format", value => {
                        if (!form?.["pp_pdf"]?.file?.type)
                          return isEdit || false

                        return ["application/pdf"].includes(
                          form?.["pp_pdf"].file.type
                        )
                      }),
                    pp_writtenPDF: Yup.mixed()
                      .required("Please Select Pdf")
                      .test("fileNotSelected", "Please Select Pdf", value => {
                        return isEdit || !!form?.["pp_writtenPDF"]?.file?.type
                      })
                      .test("fileFormat", "Unsupported Format", value => {
                        if (!form?.["pp_writtenPDF"]?.file?.type)
                          return isEdit || false

                        return ["application/pdf"].includes(
                          form?.["pp_writtenPDF"].file.type
                        )
                      }),
                    // .nullable(),
                    pp_body: Yup.string().required("Please Select Body"),
                    pp_totalMarks: Yup.string().required(
                      "Please Enter Total Marks"
                    ),
                  })}
                  onSubmit={values => {
                    let yearDate = new Date(selectedYear).getFullYear()

                    let PastPaperValues = values

                    PastPaperValues["pp_year"] = yearDate

                    if (isEdit) {
                      PastPaperValues["pp_pdf_old"] = form.pp_pdf_old
                      PastPaperValues["pp_pdf"] = form.pp_pdf.file

                      PastPaperValues["pp_writtenPDF_old"] =
                        form.pp_writtenPDF_old
                      PastPaperValues["pp_writtenPDF"] = form.pp_writtenPDF.file

                      return handleEditPastPaperSubmit(
                        PastPaperId,
                        PastPaperValues
                      )
                    } else {
                      PastPaperValues["pp_pdf"] = form.pp_pdf.file
                      PastPaperValues["pp_writtenPDF"] = form.pp_writtenPDF.file
                      return handleAddPastPaperSubmit(PastPaperValues)
                    }
                  }}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    setFieldTouched,
                  }) => (
                    <>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          handleSubmit(e)
                          return false
                        }}
                      >
                        <Row>
                          <Col>
                            <Row className="mb-3">
                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Title
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="pp_title"
                                  type="text"
                                  placeholder="Enter Title"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={touched.pp_title && errors.pp_title}
                                  defaultValue={form.pp_title}
                                />
                                {touched.pp_title && errors.pp_title && (
                                  <FormFeedback>{errors.pp_title}</FormFeedback>
                                )}
                              </Col>
                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Total Marks
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="pp_totalMarks"
                                  type="number"
                                  placeholder="Enter Toal Marks"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.pp_totalMarks &&
                                    errors.pp_totalMarks
                                  }
                                  defaultValue={form.pp_totalMarks}
                                />
                                {touched.pp_totalMarks &&
                                  errors.pp_totalMarks && (
                                    <FormFeedback>
                                      {errors.pp_totalMarks}
                                    </FormFeedback>
                                  )}
                              </Col>
                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">
                                  Enter Year
                                  <span className="text-danger">*</span>
                                </Label>

                                <DatePicker
                                  name="pp_year"
                                  onChange={e => {
                                    setSelectedYear(e)
                                  }}
                                  placeholderText="Select Year"
                                  selected={
                                    selectedYear !== undefined && selectedYear
                                  }
                                  invalid={touched.pp_year && errors.pp_year}
                                  dateFormat="yyyy"
                                  showYearPicker
                                  // value={selectedYear}
                                />
                                {touched.pp_year && errors.pp_year && (
                                  <FormFeedback>{errors.pp_year}</FormFeedback>
                                )}
                              </Col>
                            </Row>

                            <Row className="mb-3">
                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Level
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="pp_mainCategory"
                                  type="select"
                                  className="form-select"
                                  onChange={e => {
                                    handleChange(e)

                                    // Set the class list
                                    const classes = getClasses(e.target.value)
                                    setCategories(classes)

                                    setFieldValue("pp_category", "")
                                    setFieldValue("pp_subcategory", "")
                                  }}
                                  onBlur={handleBlur}
                                  value={values.pp_mainCategory || 0}
                                  invalid={
                                    touched.pp_mainCategory &&
                                    errors.pp_mainCategory
                                      ? true
                                      : false
                                  }
                                >
                                  <option value="0" disabled>
                                    Select Level
                                  </option>
                                  {mainCategoryValues.map(item => {
                                    return (
                                      <option key={item.key} value={item.value}>
                                        {item.value}
                                      </option>
                                    )
                                  })}
                                </Input>
                                {touched.pp_mainCategory &&
                                errors.pp_mainCategory ? (
                                  <FormFeedback type="invalid">
                                    {errors.pp_mainCategory}
                                  </FormFeedback>
                                ) : null}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Class/Grade
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="pp_category"
                                  disabled={!values.pp_mainCategory}
                                  type="select"
                                  className="form-select"
                                  onChange={e => {
                                    handleChange(e)

                                    // Set the class list
                                    const newSubjects = getSubjects(
                                      "",
                                      e.target.value
                                    )
                                    setSubjects(newSubjects)

                                    setFieldValue("pp_subcategory", "")
                                  }}
                                  onBlur={handleBlur}
                                  value={values.pp_category || 0}
                                  invalid={
                                    touched.pp_category && errors.pp_category
                                      ? true
                                      : false
                                  }
                                >
                                  <option value="0" disabled>
                                    Select Class/Grade
                                  </option>
                                  {categories.map(category => {
                                    return (
                                      <option key={category} value={category}>
                                        {category}
                                      </option>
                                    )
                                  })}
                                </Input>
                                {touched.pp_category && errors.pp_category ? (
                                  <FormFeedback type="invalid">
                                    {errors.pp_category}
                                  </FormFeedback>
                                ) : null}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Subject
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="pp_subcategory"
                                  disabled={!values.pp_category}
                                  type="select"
                                  className="form-select"
                                  onChange={e => {
                                    handleChange(e)
                                  }}
                                  onBlur={handleBlur}
                                  value={values.pp_subcategory || 0}
                                  invalid={
                                    touched.pp_subcategory &&
                                    errors.pp_subcategory
                                      ? true
                                      : false
                                  }
                                >
                                  <option value="0" disabled>
                                    Select Subject
                                  </option>
                                  {subjects.map(subject => {
                                    return (
                                      <option key={subject} value={subject}>
                                        {subject}
                                      </option>
                                    )
                                  })}
                                </Input>
                                {touched.pp_subcategory &&
                                errors.pp_subcategory ? (
                                  <FormFeedback type="invalid">
                                    {errors.pp_subcategory}
                                  </FormFeedback>
                                ) : null}
                              </Col>

                              <Col md={4} sm={6} xs={12}>
                                <Label className="form-label">
                                  Body
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="pp_body"
                                  type="select"
                                  className="form-select"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.pp_body || 0}
                                  invalid={
                                    touched.pp_body && errors.pp_body
                                      ? true
                                      : false
                                  }
                                >
                                  <option value="0" disabled>
                                    Select Body
                                  </option>
                                  <option value="JAMB">JAMB</option>
                                  <option value="WAEC">WAEC</option>
                                </Input>
                                {touched.pp_body && errors.pp_body && (
                                  <FormFeedback>{errors.pp_body}</FormFeedback>
                                )}
                              </Col>
                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">
                                  Objective Paper
                                  <span className="text-danger">*</span>
                                  {isEdit && form?.pp_pdf_old && (
                                    <>
                                      <span className="ms-2">
                                        (
                                        <a
                                          href={`${IMAGE_URL}/${
                                            form?.pp_pdf_old || ""
                                          }`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          Saved Pdf
                                        </a>
                                        )
                                      </span>
                                    </>
                                  )}
                                </Label>
                                <Input
                                  name="pp_pdf"
                                  type="file"
                                  accept=".pdf"
                                  placeholder="Select Pdf file"
                                  onChange={e => {
                                    let tempForm = form
                                    tempForm["pp_pdf"]["fileName"] =
                                      e.target.value
                                    tempForm["pp_pdf"]["file"] =
                                      e.target.files[0]
                                    setForm(tempForm)
                                  }}
                                  // onBlur={handleBlur}
                                  invalid={!!touched.pp_pdf && !!errors.pp_pdf}
                                  defaultValue={form.pp_pdf.fileName}
                                />
                                {!!touched.pp_pdf && !!errors.pp_pdf && (
                                  <FormFeedback>{errors.pp_pdf}</FormFeedback>
                                )}
                              </Col>

                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">
                                  Written Paper
                                  <span className="text-danger">*</span>
                                  {isEdit && form?.pp_writtenPDF_old && (
                                    <>
                                      <span className="ms-2">
                                        (
                                        <a
                                          href={`${IMAGE_URL}/${
                                            form?.pp_writtenPDF_old || ""
                                          }`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          Saved Pdf
                                        </a>
                                        )
                                      </span>
                                    </>
                                  )}
                                </Label>
                                <Input
                                  name="pp_writtenPDF"
                                  type="file"
                                  accept=".pdf"
                                  placeholder="Select Pdf file"
                                  onChange={e => {
                                    let tempForm = form
                                    tempForm["pp_writtenPDF"]["fileName"] =
                                      e.target.value
                                    tempForm["pp_writtenPDF"]["file"] =
                                      e.target.files[0]
                                    setForm(tempForm)
                                  }}
                                  // onBlur={handleBlur}
                                  invalid={
                                    !!touched.pp_writtenPDF &&
                                    !!errors.pp_writtenPDF
                                  }
                                  defaultValue={form.pp_writtenPDF.fileName}
                                />
                                {!!touched.pp_writtenPDF &&
                                  !!errors.pp_writtenPDF && (
                                    <FormFeedback>
                                      {errors.pp_writtenPDF}
                                    </FormFeedback>
                                  )}
                              </Col>
                            </Row>

                            <Row className="mb-3 text-center">
                              <Col>
                                <Button
                                  size="md"
                                  color="danger"
                                  type="button"
                                  disabled={submitLoading}
                                  className="mx-2"
                                  onClick={() => {
                                    props.history.push("/past-paper")
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  size="md"
                                  color="info"
                                  type="submit"
                                  disabled={submitLoading}
                                >
                                  {!isEdit ? "Save" : "Update"}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </Formik>
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

AddEditPastPaper.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default AddEditPastPaper
