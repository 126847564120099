import React, { useMemo, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

//import components
import DeleteModal from "../../components/Common/DeleteModal"

import { SimpleStringValue } from "helpers/common_helper_functions"

import { Col, Row, UncontrolledTooltip, Card, CardBody } from "reactstrap"

import { SaveToast } from "components/Common/SaveToast"

import {
  updateStudent,
  deleteStudent,
  getAllStudent,
} from "helpers/backendHelpers/student"

const PremiumLearner = props => {
  document.title = "Premium Learner | LMS Ghana"

  const [deleteModal, setDeleteModal] = useState(false)
  const [students, setStudents] = useState([])
  const [student, setStudent] = useState({})
  const [saved, setSaved] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchAllStudents()
  }, [saved])

  const fetchAllStudents = async () => {
    try {
      setLoading(true)
      const userType = 2 // 1 = public && 2 = premium
      const response = await getAllStudent(userType)
      let { students } = response.data || {}
      students = students || []
      setStudents(students)
      setLoading(false)
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Students"
      setStudents([])
      setLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const onClickDelete = student => {
    setStudent(student)
    setDeleteModal(true)
  }

  const handleUpdateStudentStatus = async (id, status) => {
    if (!id) {
      return SaveToast({
        message: "Please Enter Student Id",
        type: "error",
      })
    }
    try {
      const response = updateStudent(id, { st_status: status })
      const message = response?.message || "Student Status Updated Successfully"
      SaveToast({ message, type: "success" })

      setSaved(prevSaved => !prevSaved)
      return
    } catch (error) {
      const message =
        error?.message || "There Was A Problem Updating Student Status"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleDeleteStudent = async () => {
    if (!student.st_id) {
      return SaveToast({ message: "Invalid Student ID", type: "error" })
    }
    try {
      const response = await deleteStudent(student.st_id)
      const message = response?.message || "Student Deleted Successfully"
      SaveToast({ message, type: "success" })

      setSaved(prevSaved => !prevSaved)
      setDeleteModal(false)
      return
    } catch (error) {
      const message = error?.message || "There was a problem deleting Student"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddButtonClick = () => {
    props.history.push("/premium-learner/add")
  }

  const columns = useMemo(
    () => [
      // {
      //   Header: "School Name",
      //   accessor: "st_school.sc_schoolName",
      //   filterable: true,
      //   Cell: cellProps => {
      //     return <SimpleStringValue {...cellProps} />
      //   },
      // },
      {
        Header: "Full Name",
        accessor: "st_fullName",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "StudentID",
        accessor: "st_studentId",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "st_email",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Phone",
        accessor: "st_phoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Class",
        accessor: "st_classRoom",
        filterable: true,
        Cell: cellProps => {
          return cellProps.value
            ? `${cellProps.value.cr_class}-${cellProps.value.cr_division}`
            : ""
        },
      },
      {
        Header: "Region",
        accessor: "st_region",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },

      {
        Header: "District",
        accessor: "st_district",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      // {
      //   Header: "Status",
      //   accessor: "st_status",
      //   disableFilters: true,
      //   Cell: cellProps => {
      //     const cellData = cellProps.row.original
      //     return (
      //       <div className="form-check form-switch form-switch-md">
      //         <input
      //           type="checkbox"
      //           className="form-check-input"
      //           id={`st_status_checkbox-${cellData.st_id}`}
      //           name={`st_status_checkbox-${cellData.st_id}`}
      //           defaultChecked={cellData.st_status}
      //           onChange={e => {
      //             let { checked, name } = e.target
      //             document.getElementById(name).checked = checked
      //             return handleUpdateStudentStatus(cellData.st_id, checked)
      //           }}
      //         />
      //       </div>
      //     )
      //   },
      // },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {/* <Link
                to="#"
                className="text-dark"
                onClick={() => {
                  props.history.push(
                    "/student-details/" + cellProps.row.original.st_id
                  )
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link> */}
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  props.history.push(
                    "/premium-learner/edit/" + cellProps.row.original.st_id
                  )
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const teacherData = cellProps.row.original
                  onClickDelete(teacherData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const printColumns = useMemo(
    () => [
      {
        Header: "Classroom",
        accessor: "classroom",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Full Name",
        accessor: "st_fullName",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Region",
        accessor: "st_region",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Phone Number",
        accessor: "st_phoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "st_status",
        disableFilters: true,
        Cell: cellProps => {
          return cellProps?.row?.original?.st_status ? "Active" : "Inactive"
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteStudent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={students}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    addButtonLabel="Add Student"
                    handleAddButtonClick={handleAddButtonClick}
                    customPageSize={10}
                    className="custom-header-css"
                    dataFetchLoading={loading}
                    canExportCsv={true}
                    canPrint={true}
                    printColumns={printColumns}
                    noDataMessage="No system activity found."
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PremiumLearner
