import React from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap"

const AuthorityMemberModal = props => {
  const { isOpen, toggle, member } = props
  {
    if (member !== null)
      return (
        <>
          <Modal
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            className="exampleModal"
            tabIndex="-1"
            toggle={toggle}
          >
            <ModalHeader toggle={toggle}>Member Details</ModalHeader>
            <ModalBody>
              <Table>
                <tbody>
                  <tr>
                    <th>Full Name</th>
                    <td>{member.gm_fullName}</td>
                  </tr>
                  <tr>
                    <th> GESOffice</th>
                    <td>{member.gm_gesOffice?.go_officeTitle}</td>
                  </tr>
                  <tr>
                    <th>Designation</th>
                    <td>{member.gm_designation}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{member.gm_email}</td>
                  </tr>
                  <tr>
                    <th>Phone Number</th>
                    <td>{member.gm_phoneNumber}</td>
                  </tr>
                  <tr>
                    <th>Alt. Phone Number</th>
                    <td>{member.gm_altPhoneNumber}</td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td>{member.gm_status ? "Active" : "Inactive"}</td>
                  </tr>
                </tbody>
              </Table>
            </ModalBody>
          </Modal>
        </>
      )
  }
}

AuthorityMemberModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default AuthorityMemberModal
