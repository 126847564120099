import { getApiConfig } from "helpers/authHelper"
import { get, post, put, del } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllGESOfficeStaff = officeId => {
  return get(
    `${url.GES_OFFICE_STAFF}?gs_gesOfficeId=${officeId}`,
    getApiConfig()
  )
}

export const getGESOfficeStaff = id => {
  return get(`${url.GES_OFFICE_STAFF}/${id}`, getApiConfig())
}

export const createGESOfficeStaff = data => {
  return post(url.GES_OFFICE_STAFF, data, getApiConfig())
}

export const updateGESOfficeStaff = (id, data) => {
  return put(`${url.GES_OFFICE_STAFF}/${id}`, data, getApiConfig())
}

export const deleteGESOfficeStaff = id => {
  return del(`${url.GES_OFFICE_STAFF}/${id}`, getApiConfig())
}
