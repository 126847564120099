import React, { useMemo, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

// import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  SimpleDateFormate,
  SimpleStringValue,
} from "helpers/common_helper_functions"
import { SaveToast } from "components/Common/SaveToast"
import {
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  Label,
  Button,
  Table,
} from "reactstrap"

import {
  getAllBlogs,
  updateBlog,
  deleteBlog,
  getCommentsByBlogId,
  deleteComment,
} from "helpers/backendHelpers/blog"
import { IMAGE_URL } from "helpers/urlHelper"
import SubmitLoader from "common/SubmitLoader"
import moment from "moment"

const Blogs = props => {
  document.title = "Blogs | LMS Ghana"

  const [deleteModal, setDeleteModal] = useState(false)
  const [blogCommentDeleteModal, setBlogCommentDeleteModal] = useState(false)
  const [blogs, setBlogs] = useState([])
  const [blog, setBlog] = useState({})
  const [Comments, setComments] = useState([])
  const [comment, setComment] = useState("")
  const [blogId, setBlogId] = useState("")
  const [saved, setSaved] = useState(false)
  const [loading, setLoading] = useState(false)
  const [blogModal, setBlogModal] = useState(false)
  const [noRecords, setNoRecords] = useState(false)

  useEffect(() => {
    fetchAllBlogs()
  }, [saved])

  useEffect(() => {
    if (blogId) {
      fetchAllComments(blogId)
    }
  }, [blogId])

  const toggleBlogModal = () => {
    setBlogModal(!blogModal)
  }

  const fetchAllBlogs = async () => {
    try {
      setLoading(true)
      const response = await getAllBlogs()
      let { blog } = response.data || {}
      blog = blog || []
      setBlogs(blog)
      setLoading(false)
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching SchoolStaff"

      setBlogs([])
      setLoading(false)

      return SaveToast({ message, type: "error" })
    }
  }

  const fetchAllComments = async id => {
    try {
      setLoading(true)
      const response = await getCommentsByBlogId(id)
      let { comment } = response.data || {}
      comment = comment || []
      setComments(comment)
      if (comment.length == 0) {
        setNoRecords(true)
      } else {
        setNoRecords(false)
      }
      setLoading(false)
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching SchoolStaff"
      setComments([])
      setLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const onClickDelete = blog => {
    setBlog(blog)
    setDeleteModal(true)
  }

  const onClickDeleteBlogComment = id => {
    setComment(id)
    setBlogCommentDeleteModal(true)
  }

  const handleDeleteBlog = async () => {
    if (!blog.bl_id) {
      return SaveToast({ message: "Invalid Blog ID", type: "error" })
    }
    try {
      const response = await deleteBlog(blog.bl_id)
      const message = response?.message || "Blog Deleted Successfully"
      SaveToast({ message, type: "success" })
      setSaved(prevSaved => !prevSaved)
      setDeleteModal(false)
      return
    } catch (error) {
      const message = error?.message || "There was a problem deleting Blog"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleDeleteBlogComment = async () => {
    if (!comment) {
      return SaveToast({ message: "Invalid Comment ID", type: "error" })
    }
    try {
      const response = await deleteComment(comment)
      const message = response?.message || "Comment Deleted Successfully"
      SaveToast({ message, type: "success" })
      // setSaved(prevSaved => !prevSaved)
      fetchAllComments(blogId)
      setBlogCommentDeleteModal(false)
      return
    } catch (error) {
      const message = error?.message || "There was a problem deleting Blog"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddButtonClick = () => {
    props.history.push("/blogs/add")
  }

  const columns = useMemo(
    () => [
      {
        Header: "Blog Title",
        accessor: "bl_title",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Blog Image",
        accessor: "bl_image",
        filterable: true,
        Cell: cellProps => {
          const cellData = cellProps.row.original
          return (
            <div className="text-center">
              {cellData?.bl_image && (
                <img
                  src={`${IMAGE_URL}/${cellData?.bl_image}`}
                  style={{ width: "50px" }}
                />
              )}
            </div>
          )
        },
      },
      {
        Header: "Total Likes",
        accessor: "bl_likeCount",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Date",
        accessor: "bl_createdAt",
        filterable: true,
        Cell: cellProps => {
          return <SimpleDateFormate {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const blogData = cellProps.row.original
                  onClickDelete(blogData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-dark"
                onClick={() => {
                  toggleBlogModal()
                  const blogData = cellProps.row.original
                  setBlogId(blogData?.bl_id)
                  fetchAllComments(blogData?.bl_id)
                }}
              >
                <i
                  className="mdi mdi-comment font-size-18"
                  id="commentTooltip"
                />
                <UncontrolledTooltip placement="top" target="commentTooltip">
                  Show Comments
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  const printColumns = useMemo(
    () => [
      {
        Header: "Role",
        accessor: "ss_staffRole",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "ss_email",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Full Name",
        accessor: "ss_fullName",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Phone Number",
        accessor: "ss_phoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "ss_status",
        disableFilters: true,
        Cell: cellProps => {
          return cellProps?.row?.original?.ss_status ? "Active" : "Inactive"
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteBlog}
        onCloseClick={() => setDeleteModal(false)}
      />
      <DeleteModal
        show={blogCommentDeleteModal}
        onDeleteClick={handleDeleteBlogComment}
        onCloseClick={() => setBlogCommentDeleteModal(false)}
      />

      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="School" breadcrumbItem="Staff" /> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={blogs}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    addButtonLabel="Add Blog"
                    handleAddButtonClick={handleAddButtonClick}
                    customPageSize={10}
                    className="custom-header-css"
                    dataFetchLoading={loading}
                    canExportCsv={true}
                    csvFileName={`blogs.csv`}
                    csvLink="/api/v1/admin/blog/download/data"
                    canPrint={true}
                    printColumns={printColumns}
                    noDataMessage="No system activity found."
                  />
                </CardBody>
              </Card>
              <Modal
                size="lg"
                isOpen={blogModal}
                toggle={toggleBlogModal}
                role="dialog"
                autoFocus
                centered
              >
                {/* {submitLoading ? <SubmitLoader /> : <></>} */}
                <ModalHeader toggle={toggleBlogModal}>{`Comments`}</ModalHeader>
                <ModalBody style={{ maxHeight: "380px", overflow: "auto" }}>
                  <div style={{ minHeight: "100px" }}>
                    {loading ? (
                      <div
                        style={
                          loading
                            ? {
                                position: "relative",
                                opacity: "0.8",
                                minHeight: "100px",
                              }
                            : {}
                        }
                      >
                        <SubmitLoader />
                      </div>
                    ) : (
                      <>
                        {noRecords && (
                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{ minHeight: "100px" }}
                          >
                            <h1>No system activity found.</h1>
                          </div>
                        )}
                        <Row className="mb-3">
                          <Col md={12} lg={12} sm={12}>
                            <Table>
                              {!noRecords && (
                                <thead>
                                  <tr>
                                    <th>Full Name</th>
                                    <th>Date</th>
                                    <th>Comment</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                              )}
                              <tbody>
                                {Comments &&
                                  Comments.length > 0 &&
                                  Comments.map((data, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>{data?.cmn_fullName}</td>

                                        <td>
                                          {moment(data?.cmn_date).format(
                                            "YYYY/MM/DD"
                                          )}
                                        </td>
                                        <td
                                          style={{
                                            maxWidth: "360px",
                                            width: "300px",
                                          }}
                                        >
                                          {data?.cmn_comment}
                                        </td>
                                        <td>
                                          <Link
                                            to="#"
                                            className="text-danger"
                                            onClick={() => {
                                              onClickDeleteBlogComment(
                                                data?.cmn_id
                                              )
                                            }}
                                          >
                                            <i
                                              className="mdi mdi-delete font-size-18"
                                              id="deletetooltip"
                                            />
                                            <UncontrolledTooltip
                                              placement="top"
                                              target="deletetooltip"
                                            >
                                              Delete
                                            </UncontrolledTooltip>
                                          </Link>
                                        </td>
                                      </tr>
                                    )
                                  })}
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Blogs
