import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { IMAGE_URL } from "helpers/urlHelper"

const PDFModal = props => {
  const { isOpen, toggle, pdf } = props

  {
    if (pdf !== null)
      return (
        <>
          <Modal
            isOpen={isOpen}
            size="xl"
            role="dialog"
            autoFocus={true}
            centered={true}
            className="modal-fullscreen"
            tabIndex="-1"
            toggle={toggle}
          >
            <ModalHeader toggle={toggle}>Read PDF</ModalHeader>
            <ModalBody>
              <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                <iframe
                  title="test"
                  className="embed-responsive-item"
                  src={`${IMAGE_URL}/${pdf}`}
                />
              </div>
            </ModalBody>
          </Modal>
        </>
      )
  }
}

PDFModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default PDFModal
