import { getApiConfig } from "helpers/authHelper"
import { get, post, put, del } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllLiveSession = () => {
  return get(`${url.LIVESESSION}/getAll`, getApiConfig())
}

export const getBlog = id => {
  return get(`${url.LIVESESSION}/${id}`, getApiConfig())
}

export const createBlog = data => {
  return post(url.LIVESESSION, data, getApiConfig())
}

export const updateBlog = (id, data) => {
  return put(`${url.LIVESESSION}/${id}`, data, getApiConfig())
}

export const deleteBlog = id => {
  return del(`${url.LIVESESSION}/${id}`, getApiConfig())
}
