import { getApiConfig } from "helpers/authHelper"
import { get, post, put, del } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllTermlySchemeMaster = () => {
  return get(`${url.TERMLYSCHEMEMASTER}/get`, getApiConfig())
}

export const getTermlySchemeMaster = id => {
  return get(`${url.TERMLYSCHEMEMASTER}/${id}`, getApiConfig())
}

export const createTermlySchemeMaster = data => {
  return post(url.TERMLYSCHEMEMASTER, data, getApiConfig())
}

export const updateTermlySchemeMaster = (id, data) => {
  return put(`${url.TERMLYSCHEMEMASTER}/${id}`, data, getApiConfig())
}

export const deleteTermlySchemeMaster = id => {
  return del(`${url.TERMLYSCHEMEMASTER}/${id}`, getApiConfig())
}
