import { getApiConfig } from "helpers/authHelper"
import { get } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllSchoolByArea = (region, district, circuit) => {
  return get(
    `${url.AREA}?sc_region=${region}&sc_district=${district}&sc_circuit=${circuit}`,
    getApiConfig()
  )
}
