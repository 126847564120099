export default [
  { id: 1, circuit: "Adai", districtId: 13 },
  { id: 2, circuit: "Adarchak", districtId: 14 },
  { id: 3, circuit: "Madhopur", districtId: 27 },
  { id: 4, circuit: "Maganpur", districtId: 30 },
  { id: 5, circuit: "Maheshra", districtId: 35 },
  { id: 6, circuit: "Mahuatand", districtId: 30 },
  { id: 7, circuit: "Mahugain", districtId: 27 },
  { id: 8, circuit: "A Narayanapuram", districtId: 1 },
  { id: 9, circuit: "Akuledu", districtId: 1 },
  { id: 10, circuit: "Alamuru", districtId: 1 },
  { id: 11, circuit: "Allapuram", districtId: 1 },
  { id: 12, circuit: "Adarkona", districtId: 7 },
  { id: 13, circuit: "Alamkhani", districtId: 7 },
  { id: 14, circuit: "Alexanderpur", districtId: 8 },
  { id: 15, circuit: "Algapur", districtId: 9 },
  { id: 16, circuit: "Varachha", districtId: 26 },
  { id: 17, circuit: "Adajan", districtId: 26 },
  { id: 18, circuit: "Pal", districtId: 26 },
]
