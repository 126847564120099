import React, { useState, useEffect } from "react"
import {
  Button,
  Col,
  Row,
  Card,
  CardBody,
  CardText,
  CardTitle,
} from "reactstrap"

import { getBook } from "helpers/backendHelpers/books"
import { SaveToast } from "components/Common/SaveToast"
import { IMAGE_URL } from "helpers/urlHelper"
import VideoModal from "./WatchModal"
import ListenModal from "./ListenModal"
import PDFModal from "./ViewModal"

const ViewDetails = props => {
  const [bookData, setBookData] = useState(props.bookData || {})
  const [genreData, setGenreData] = useState({})
  const [bookId, setBookId] = useState(0)
  const [videoModal, setVideoModal] = useState(false)

  const [pdfModal, setPdfModal] = useState(false)
  const [ePUBModal, setEPUBModal] = useState(false)
  const [audioModal, setAudioModal] = useState(false)
  const [previewVideoModel, setPreviewVideoModel] = useState(false)

  const [videoFile, setVideoFile] = useState("")
  const [ePUBFile, setEPUBFile] = useState("")
  const [pdfFile, setPdfFile] = useState("")
  const [audioFile, setAudioFile] = useState("")
  const [previewVideoFile, setPreviewVideoFile] = useState("")

  useEffect(() => {
    let { id } = props.match.params || {}
    setBookId(parseInt(id))

    if (id) {
      fetchBook(id)
    }
  }, [])

  const fetchBook = async bk_id => {
    try {
      let response = await getBook(bk_id)
      let bookData = response.data.book
      let { bk_genre } = bookData
      if (bookData.bk_previewVideo) {
        setPreviewVideoFile(bookData.bk_previewVideo)
      }

      if (bookData.bk_epub) {
        setEPUBFile(bookData.bk_epub)
      }
      if (bookData.bk_pdf) {
        setPdfFile(bookData.bk_pdf)
      }
      if (bookData.bk_audio) {
        setAudioFile(bookData.bk_audio)
      }
      if (bookData.bk_video) {
        setVideoFile(bookData.bk_video)
      }

      setGenreData(bk_genre)
      setBookData(bookData)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Adding Book"
      return SaveToast({ message: message, type: "error" })
    }
  }

  const toggleWatchModal = () => {
    setVideoModal(!videoModal)
  }

  const togglePreviewWatchModal = () => {
    setPreviewVideoModel(!previewVideoModel)
  }

  const toggleEpubModal = () => {
    setEPUBModal(!ePUBModal)
  }

  const togglePDFModal = () => {
    setPdfModal(!pdfModal)
  }

  const toggleAudioModal = () => {
    setAudioModal(!audioModal)
  }
  return (
    <React.Fragment>
      <PDFModal isOpen={pdfModal} toggle={togglePDFModal} pdf={pdfFile} />
      <PDFModal isOpen={ePUBModal} toggle={toggleEpubModal} pdf={ePUBFile} />

      <VideoModal
        isOpen={videoModal}
        toggle={toggleWatchModal}
        video={videoFile}
      />

      <VideoModal
        isOpen={previewVideoModel}
        toggle={togglePreviewWatchModal}
        video={previewVideoFile}
      />

      <ListenModal
        isOpen={audioModal}
        toggle={toggleAudioModal}
        audio={audioFile}
      />

      <Row className="mt-3">
        <Col className="text-end">
          <Button
            color="info"
            onClick={() => {
              props.history.push(`/books/${props.bookType}`)
            }}
          >
            Back
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} className="mb-3">
          <Card>
            <Row className="no-gutters align-items-center">
              <Col md={12}>
                <CardBody>
                  <Row>
                    <Col md={4}>
                      <img
                        className="img-thumbnail"
                        alt="Skote"
                        width="350"
                        src={`${IMAGE_URL}/${bookData.bk_preview}`}
                      />
                    </Col>
                    <Col md={8}>
                      <Col className="mt-3 mb-2">
                        <CardTitle className="fs-2 text-info">
                          {bookData.bk_title}
                        </CardTitle>
                      </Col>

                      <Col>
                        {!bookData.bk_isFree ? (
                          <CardTitle className="fs-4 ">
                            <span className="fw-bold">Price: </span>$
                            {bookData.bk_price}
                          </CardTitle>
                        ) : (
                          <></>
                        )}
                      </Col>
                      <Col>
                        <CardTitle className="fs-6 ">
                          Status:{" "}
                          <i
                            className={`mdi mdi-circle text-${
                              bookData.bk_status ? "success" : "danger"
                            } align-middle me-1`}
                          />
                          {bookData.bk_status ? "Active" : "Inactive"}
                        </CardTitle>
                      </Col>

                      <Col className="mb-3" md={6}>
                        <CardText>{bookData.bk_description}</CardText>
                      </Col>

                      <Col>
                        <Row>
                          <Col>
                            <Col className="mb-2">
                              <CardText>
                                <span className="fw-bold">Category: </span>

                                {`${bookData.bk_mainCategory}${
                                  bookData.bk_category
                                    ? `, ${bookData.bk_category}`
                                    : ""
                                }${
                                  bookData.bk_subCategory
                                    ? `, ${bookData.bk_subCategory}`
                                    : ""
                                }${
                                  bookData.bk_topic
                                    ? `, ${bookData.bk_topic}`
                                    : ""
                                }`}
                              </CardText>
                            </Col>
                            <Col className="mb-2">
                              <CardText>
                                <span className="fw-bold">Genre: </span>
                                {bookData.bk_genre?.toString()}
                              </CardText>
                            </Col>
                            <Col className="mb-2">
                              <CardText>
                                <span className="fw-bold">No. Of Pages: </span>
                                {bookData.bk_noOfPages}
                              </CardText>
                            </Col>
                            <Col className="mb-2">
                              <CardText>
                                <span className="fw-bold">
                                  Physical Available? :{" "}
                                </span>
                                {bookData.bk_isPhysicalAvailable ? "Yes" : "No"}
                                 
                              </CardText>
                            </Col>
                            <Col className="mb-2">
                              <CardText>
                                <span className="fw-bold">Is Free? : </span>
                                {bookData.bk_isFree ? "Yes" : "No"}
                              </CardText>
                            </Col>
                          </Col>
                          <Col>
                            <Col className="mb-2">
                              <CardText>
                                <span className="fw-bold">Author: </span>
                                {bookData.bk_author}
                              </CardText>
                            </Col>
                            <Col className="mb-2">
                              <CardText>
                                <span className="fw-bold">Publisher: </span>
                                {bookData.bk_publisher}
                              </CardText>
                            </Col>
                            <Col className="mb-2">
                              <CardText>
                                <span className="fw-bold">Edition: </span>
                                {bookData.bk_edition}
                              </CardText>
                            </Col>
                            <Col className="mb-2">
                              <CardText>
                                <span className="fw-bold">Language: </span>
                                {bookData.bk_language}
                              </CardText>
                            </Col>
                            <Col className="mb-2">
                              <CardText>
                                <span className="fw-bold">
                                  Published Date:{" "}
                                </span>
                                {bookData.bk_publishedDate}
                              </CardText>
                            </Col>
                          </Col>
                        </Row>
                      </Col>
                    </Col>
                  </Row>
                </CardBody>
              </Col>
            </Row>
          </Card>
          <Row className="mb-3 text-center">
            <Col className="mb-3">
              <Button
                className="me-3"
                color="info"
                onClick={() => {
                  togglePDFModal()
                }}
                disabled={!pdfFile}
              >
                Read PDF
              </Button>
              <Button
                className="me-3"
                color="success"
                onClick={() => {
                  toggleWatchModal()
                }}
                disabled={!videoFile}
              >
                Watch Video
              </Button>
              <Button
                className="me-3"
                color="info"
                onClick={() => {
                  toggleEpubModal()
                }}
                disabled={!ePUBFile}
              >
                Read ePUB
              </Button>
              <Button className="me-3" color="info" onClick={() => {}}>
                Read Book Review
              </Button>
              <Button
                className="me-3"
                color="success"
                onClick={() => {
                  togglePreviewWatchModal()
                }}
                disabled={!previewVideoFile}
              >
                Watch Preview Video
              </Button>
              <Button
                className="me-3"
                color="info"
                onClick={() => {
                  toggleAudioModal()
                }}
                disabled={!audioFile}
              >
                Listen Audio
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ViewDetails
