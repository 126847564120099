import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import ViewDetails from "./ViewDetails"
import UpdateModal from "../../components/Common/UpdateModal"
import { SaveToast } from "components/Common/SaveToast"
import SubmitLoader from "components/Common/SubmitLoader"

import {
  createContent,
  updateContent,
  getContent,
} from "helpers/backendHelpers/contentManagement"
import { IMAGE_URL } from "helpers/urlHelper"
import ChipInput from "material-ui-chip-input"

const ContentModal = props => {
  const [isEdit, setIsEdit] = useState(false)
  const [isView, setIsView] = useState(false)
  const [contentId, setContentId] = useState(0)
  const [updateModal, setUpdateModal] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [temp, setTemp] = useState(false)

  const [tp_id, settp_id] = useState(props.location?.state?.tp_id)

  const [form, setForm] = useState({
    cm_contentTitle: "",
    cm_description: "",
    cm_readingMaterial: { fileName: "", file: {} },
    cm_video: "",
    cm_status: true,
  })
  const [description, setDescription] = useState([])
  useEffect(() => {
    let { type, id } = props.match.params || {}

    switch (type) {
      case "edit":
        setIsEdit(true)
        document.getElementById("training").classList.add("mm-active")
        setIsView(false)
        setContentId(parseInt(id))
        break
      case "view":
        setIsView(true)
        setIsEdit(false)
        setContentId(parseInt(id))
        break
      case "add":
        break
      default:
        setIsView(false)
        setIsEdit(false)
        setContentId(0)
        break
    }

    if (id) {
      fetchContentDetailsForEdit(id)
    }
  }, [isEdit])

  const fetchContentDetailsForEdit = async cm_id => {
    try {
      let response = await getContent(cm_id)

      let { content } = response.data || {}
      content = content || {}

      content["cm_readingMaterial_old"] = content["cm_readingMaterial"]
      content["cm_readingMaterial"] = { fileName: "", file: {} }

      content["cm_video"] =
        content["cm_video"] === null ? "" : content["cm_video"]?.slice(30)

      setDescription(content?.cm_description ? content?.cm_description : [])

      return setForm(content)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching freelance content details"

      setForm(form)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddContentSubmit = async data => {
    try {
      setSubmitLoading(true)
      const response = await createContent(data)
      let message = response?.message || "Content Added Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)
      props.history.goBack()
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Adding Content"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleEditContentSubmit = async (id, data) => {
    if (!id) {
      return SaveToast({
        message: "Please enter Content Id",
        type: "error",
      })
    }
    try {
      setSubmitLoading(true)
      const response = await updateContent(id, data)
      let message = response?.message || "Content Updated Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)
      props.history.goBack()
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Adding Content"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleUpdateContent = () => {
    setUpdateModal(false)
    SaveToast({ message: "Content Updated Successfully", type: "success" })
    props.history.push("/content-management")
  }

  return (
    <React.Fragment>
      <UpdateModal
        show={updateModal}
        onUpdateClick={handleUpdateContent}
        onCloseClick={() => setUpdateModal(false)}
      />
      <div className="page-content">
        <div
          className="container-fluid"
          style={submitLoading ? { position: "relative", opacity: "0.8" } : {}}
        >
          {/* <Breadcrumbs title="Content Management" breadcrumbItem="Content" /> */}
          {submitLoading ? <SubmitLoader /> : <></>}

          <Card>
            {/* <CardHeader>
              <CardTitle>
                {isView ? (
                  <Label>Content Details</Label>
                ) : (
                  <Label>{!isEdit ? "Enter" : "Update"} Details</Label>
                )}
              </CardTitle>
            </CardHeader> */}
            <CardBody>
              {isView ? (
                <>
                  <ViewDetails contentData={form} {...props} />
                </>
              ) : (
                <Formik
                  enableReinitialize={true}
                  initialValues={form}
                  validationSchema={Yup.object({
                    cm_contentTitle: Yup.string().required(
                      "Please Enter Content Title"
                    ),
                    // cm_description: Yup.mixed().test(
                    //   "invalidInput",
                    //   "Please Add Description",
                    //   value =>  {
                    //     if (value) {
                    //       return value.length
                    //     } else {
                    //       return false
                    //     }
                    //   }
                    // ),
                    // cm_description: Yup.string().required(
                    //   "Please Enter Content Description"
                    // ),

                    // cm_video: Yup.mixed()
                    //   .test("fileNotSelected", "Please Select Video", value => {
                    //     return isEdit || !!form?.["cm_video"]?.file?.type
                    //   })
                    //   .test("fileFormat", "Unsupported Format", value => {
                    //     if (!form?.["cm_video"]?.file?.type)
                    //       return isEdit || false

                    //     return [
                    //       "video/mp4",
                    //       "video/x-msvideo",
                    //       "video/mpeg",
                    //     ].includes(form?.["cm_video"].file.type)
                    //   }),
                    // cm_video: Yup.string()
                    //   .optional()
                    //   .required("Please Enter Video Path")
                    //   .nullable(),

                    cm_readingMaterial: Yup.mixed()
                      .optional()

                      .test("fileFormat", "Unsupported Format", value => {
                        if (!form?.["cm_readingMaterial"]?.file?.type)
                          return (
                            isEdit ||
                            form["cm_readingMaterial"].fileName === "" ||
                            false
                          )

                        return ["application/pdf"].includes(
                          form?.["cm_readingMaterial"].file.type
                        )
                      })
                      .nullable(),
                    cm_status: Yup.boolean().required("Please Select Status"),
                  })}
                  onSubmit={values => {
                    let contentData = values

                    let videoUrl = values?.cm_video
                    let isUrlContains = videoUrl.includes("watch?v=")

                    if (isUrlContains) {
                      values.cm_video = videoUrl.replace("watch?v=", "embed/")
                    }

                    if (isEdit) {
                      const contentId = contentData["cm_id"]
                      contentData["cm_readingMaterial_old"] =
                        form.cm_readingMaterial_old
                      contentData["tp_id"] = tp_id
                      contentData["cm_description"] = description.toString()

                      contentData["cm_readingMaterial"] =
                        form.cm_readingMaterial.file
                      return handleEditContentSubmit(contentId, contentData)
                    } else {
                      contentData["tp_id"] = tp_id
                      contentData["cm_readingMaterial"] =
                        form.cm_readingMaterial.file
                      contentData["cm_description"] = description.toString()
                      return handleAddContentSubmit(contentData)
                    }
                  }}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                  }) => (
                    <>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          handleSubmit(e)
                          return false
                        }}
                      >
                        <Row>
                          <Col>
                            <Row className="mb-2">
                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Content Title{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="cm_contentTitle"
                                  type="text"
                                  placeholder="Enter Content Title"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.cm_contentTitle &&
                                    errors.cm_contentTitle
                                  }
                                  value={values.cm_contentTitle}
                                />
                                {touched.cm_contentTitle &&
                                  errors.cm_contentTitle && (
                                    <FormFeedback>
                                      {errors.cm_contentTitle}
                                    </FormFeedback>
                                  )}
                              </Col>
                            </Row>

                            <Row className="mb-3">
                              <Col sm={12} md={6}>
                                <Row>
                                  <Col>
                                    <Label className="form-label">
                                      Description{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <br />
                                    {/* <Input
                                      name="cm_description"
                                      type="textarea"
                                      rows={5}
                                      placeholder="Enter Content Description"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      invalid={
                                        touched.cm_description &&
                                        errors.cm_description
                                      }
                                      value={values.cm_description}
                                    />
                                    {touched.cm_description &&
                                      errors.cm_description && (
                                        <FormFeedback>
                                          {errors.cm_description}
                                        </FormFeedback>
                                      )} */}
                                    <ChipInput
                                      className="w-100"
                                      name="cm_description"
                                      value={description}
                                      onAdd={chip => {
                                        setDescription(pre => [...pre, chip])
                                      }}
                                      onDelete={(chip, index) => {
                                        let tempDesc = description
                                        tempDesc.splice(index, 1)
                                        setDescription(tempDesc)
                                        setTemp(!temp)
                                      }}
                                    />
                                    {!description && description.length == 0 && (
                                      <div className="invalid-react-select-dropdown">
                                        {/* {errors.cm_description} */}
                                        Please Add Description
                                      </div>
                                    )}
                                    {/* {!!touched.cm_description &&
                                    !!errors.cm_description ? (
                                      <div className="invalid-react-select-dropdown">
                                        {errors.cm_description}
                                      </div>
                                    ) : null} */}
                                  </Col>
                                </Row>
                              </Col>

                              <Col sm={12} md={6} className="mt-2 mt-md-0">
                                <Row>
                                  <Col>
                                    <Row>
                                      <Col className="mt-2 mt-md-0 mb-2">
                                        <Label className="form-label">
                                          Reading Material
                                          {isEdit &&
                                            form?.cm_readingMaterial_old && (
                                              <>
                                                <span className="ms-2">
                                                  (
                                                  <a
                                                    href={`${IMAGE_URL}/${
                                                      form?.cm_readingMaterial_old ||
                                                      ""
                                                    }`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                  >
                                                    Saved readingMaterial
                                                  </a>
                                                  )
                                                </span>
                                              </>
                                            )}
                                        </Label>
                                        <Input
                                          name="cm_readingMaterial"
                                          type="file"
                                          accept=".pdf"
                                          placeholder="Select Reading Material"
                                          onChange={e => {
                                            let tempForm = form
                                            tempForm["cm_readingMaterial"][
                                              "fileName"
                                            ] = e.target.value

                                            tempForm["cm_readingMaterial"][
                                              "file"
                                            ] = e.target.files[0]

                                            setForm(tempForm)
                                          }}
                                          invalid={
                                            touched.cm_readingMaterial &&
                                            errors.cm_readingMaterial
                                          }
                                          defaultValue={
                                            form.cm_readingMaterial.fileName
                                          }
                                        />
                                        {touched.cm_readingMaterial &&
                                          errors.cm_readingMaterial && (
                                            <FormFeedback>
                                              {errors.cm_readingMaterial}
                                            </FormFeedback>
                                          )}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>

                                <Row className="mt-2">
                                  <Col>
                                    <Row>
                                      <Col className="mt-2 mt-md-0 mb-2">
                                        <Label className="form-label">
                                          Video{" "}
                                        </Label>
                                        <Input
                                          name="cm_video"
                                          type="text"
                                          placeholder="Enter Video Path..."
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          invalid={
                                            touched.cm_video && errors.cm_video
                                          }
                                          value={values.cm_video}
                                        />
                                        {touched.cm_video &&
                                          errors.cm_video && (
                                            <FormFeedback>
                                              {errors.cm_video}
                                            </FormFeedback>
                                          )}
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="mb-3 text-center">
                              <Col>
                                <Button
                                  size="md"
                                  color="danger"
                                  type="button"
                                  disabled={submitLoading}
                                  className="mx-2"
                                  onClick={() => {
                                    props.history.goBack()
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  size="md"
                                  color="info"
                                  type="submit"
                                  disabled={submitLoading}
                                >
                                  {!isEdit ? "Save" : "Update"}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </Formik>
              )}
            </CardBody>
          </Card>
        </div>
      </div>
    </React.Fragment>
  )
}

ContentModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default ContentModal
