import { getApiConfig } from "helpers/authHelper"
import { get, post, put, del } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllBooks = () => {
  return get(url.BOOK, getApiConfig())
}

export const getAllBookWithFullSearchAPI = (page, limit, value, bookType) => {
  if(!bookType) bookType = null;
  return get(
    `${url.BOOK}/pagination?page=${page}&limit=${limit}&bk_title=${value}&bk_whoCanRead=${value}&bk_author=${value}&bk_publisher=${value}&bk_isFree=${value}&bookType=${bookType}`,
    getApiConfig()
  )
}

export const getAllBookColumnWiseSearchAPI = (page, limit, query) => {
  return get(
    `${url.BOOK}/pagination?page=${page}&limit=${limit}${query}`,
    getApiConfig()
  )
}

export const getBook = id => {
  return get(`${url.BOOK}/${id}`, getApiConfig())
}

export const createBook = data => {
  return post(url.BOOK, data, getApiConfig(true))
}

export const updateBook = (id, data) => {
  return put(`${url.BOOK}/${id}`, data, getApiConfig(true))
}

export const deleteBook = id => {
  return del(`${url.BOOK}/${id}`, getApiConfig())
}

export const updateBookStatus = (bk_id, bk_status) => {
  return put(`${url.BOOK}/${bk_id}/${bk_status}`, {}, getApiConfig())
}

// Book-Category
//Type=1 for Curriculum Categories
export const getAllCategories = () => {
  return get(`${url.TOPIC}?cc_categoryType=1`, getApiConfig())
}

export const getAllContentCategory = () => {
  return get(`${url.MANAGE_CATEGORY}/categories`, getApiConfig())
}

export const getSubCategories = () => {
  return get(`${url.MANAGE_CATEGORY}/subCategories`, getApiConfig())
}

// Book-Genre
export const getAllGenres = () => {
  return get(url.BOOK_GENRE, getApiConfig())
}

export const getBooksCount = () => {
  return get(`${url.BOOK}/count`, getApiConfig())
}

export const getSubjectWiseEBooks = () => {
  return get(`${url.BOOK}/count/subject-wise/e-book`, getApiConfig())
}

export const getSubjectWiseVideos = () => {
  return get(`${url.BOOK}/count/subject-wise/video`, getApiConfig())
}

