import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
// 18
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import AuthorityMemeberModel from "./ViewModal"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"

import { authorityType, areaType } from "../../common/data/dropdownVals"
import {
  defaultRDCSeparator,
  SimpleStringValue,
} from "helpers/common_helper_functions"
import { SaveToast } from "components/Common/SaveToast"

import Select from "react-select"

import RegionDistrictCircuitAllSelectable from "common/data/Region-District-Circuit-All-Selectable"

import {
  createGESMember,
  getAllGESMember,
  getGESMember,
  updateGESMember,
  deleteGESMember,
} from "helpers/backendHelpers/GESAuthority"
import SubmitLoader from "components/Common/SubmitLoader"
import RegionDistrictCircuitDropDownAllSelectable from "components/Common/RegionDistrictCircuitDropDownAllSelectable"
import { getAllGESOffice } from "helpers/backendHelpers/GESOffice"

function GESAuthorities() {
  //meta title
  document.title = "GES Authority | GES"

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [save, setSave] = useState(false)

  const [authorityMember, setAuthorityMember] = useState({})
  const [authorityMembers, setAuthorityMembers] = useState([])

  const [gesOfficeDropdownValues, setGESOfficeDropdownValues] = useState([])

  const [deleteModal, setDeleteModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitLoading, setsubmitLoading] = useState(false)

  useEffect(() => {
    getAllGESMembers()
    fetchGESOfficeDropDownValues()
  }, [save])

  const fetchGESOfficeDropDownValues = async () => {
    try {
      let response = await getAllGESOffice()
      let { gesOffices } = response.data || {}
      gesOffices = gesOffices || []
      let gesOfficesVals = gesOffices
        .filter(gesOffices => {
          return gesOffices.go_status
        })
        .map(gesOffices => {
          return {
            id: gesOffices.go_id,
            value:
              gesOffices.go_officeTitle == null
                ? "OfficeTitle"
                : gesOffices.go_officeTitle,
            optVale: gesOffices.go_officeLevel,
            name: `${
              gesOffices.go_directorName == null
                ? "directorName"
                : gesOffices.go_directorName
            }`,
          }
        })

      setGESOfficeDropdownValues(gesOfficesVals)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching gesOffices"

      return SaveToast({ message, type: "error" })
    }
  }

  const getAllGESMembers = async () => {
    try {
      setLoading(true)
      let response = await getAllGESMember()
      let { authorityMembers } = response.data || {}
      authorityMembers = authorityMembers || []
      setAuthorityMembers(authorityMembers)
      setLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching ges members"
      setAuthorityMembers([])
      setLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddGESMemberSubmit = async data => {
    try {
      setsubmitLoading(true)
      let response = await createGESMember(data)
      let message = response?.message || "GES Member Created Successfully"
      SaveToast({ message, type: "success" })
      validation.resetForm()
      toggle()
      setSave(prevSave => !prevSave)
      setsubmitLoading(false)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem creating ges member"
      setsubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleEditGESMemberSubmit = async (id, data) => {
    if (!id) {
      return SaveToast({
        message: "Please enter ges member id",
        type: "error",
      })
    }

    try {
      setsubmitLoading(true)
      let response = await updateGESMember(id, data)
      let message = response?.message || "GES Member Updated Successfully"
      SaveToast({ message, type: "success" })

      validation.resetForm()
      toggle()
      setSave(prevSave => !prevSave)
      setsubmitLoading(false)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem creating ges member"
      setsubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleUpdateGESMemberStatus = async (gm_id, gm_status) => {
    if (!gm_id) {
      return SaveToast({
        message: "Please enter ges member id",
        type: "error",
      })
    }

    try {
      let response = await updateGESMember(gm_id, { gm_status })
      let message =
        response?.message || "GES Member Status Updated Successfully"
      SaveToast({ message, type: "success" })

      setSave(prevSave => !prevSave)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem updating ges member status"
      return SaveToast({ message, type: "error" })
    }
  }

  const passwordValidation = !isEdit
    ? Yup.string()
        .min(6, "Password Must Contain Atleast 6 characters")
        .required("Please Enter password")
    : Yup.string()
        .min(6, "Password Must Contain Atleast 6 characters")
        .optional("Please Enter password")

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      gm_authorityType:
        (authorityMember && authorityMember.gm_authorityType) || 0,
      gm_fullName: (authorityMember && authorityMember.gm_fullName) || "",
      gm_email: (authorityMember && authorityMember.gm_email) || "",
      gm_phoneNumber: (authorityMember && authorityMember.gm_phoneNumber) || "",
      gm_altPhoneNumber:
        (authorityMember && authorityMember.gm_altPhoneNumber) || "",
      gm_status: (authorityMember && authorityMember.gm_status) || true,
      gm_designation: (authorityMember && authorityMember.gm_designation) || "",
      gm_areaValue: (authorityMember && authorityMember.gm_areaValue) || "",
      gm_password: "",
      gm_gesOfficeId: (authorityMember && authorityMember.gm_gesOfficeId) || "",
    },
    validationSchema: Yup.object({
      gm_fullName: Yup.string().required("Please Enter Full Name"),
      gm_email: Yup.string().email().required("Please Enter Email"),
      gm_phoneNumber: Yup.string()
        .required("Please Enter Phone Number")
        .matches(/^[0-9]{10}$/, "Please Enter Valid Phone Number"),
      gm_designation: Yup.string().required("Please Enter Designation"),
      gm_altPhoneNumber: Yup.string()
        .notRequired()
        .matches(/^[0-9]{10}$/, "Please Enter Valid Phone Number"),
      gm_status: Yup.bool().required("Please Select Status"),
      gm_password: passwordValidation,
      gm_gesOfficeId: Yup.string().required("Please Select Office Title"),
    }),
    onSubmit: values => {
      let GESMember = {
        gm_fullName: values.gm_fullName,
        gm_designation: values.gm_designation,
        gm_email: values.gm_email,
        gm_phoneNumber: values.gm_phoneNumber,
        gm_altPhoneNumber: values.gm_altPhoneNumber,
        gm_status: values.gm_status,
        gm_password: values.gm_password,
        gm_gesOfficeId: values.gm_gesOfficeId,
      }

      GESMember.gm_altPhoneNumber =
        GESMember.gm_altPhoneNumber === "" ? null : GESMember.gm_altPhoneNumber
      GESMember.gm_password =
        GESMember.gm_password === "" ? null : GESMember.gm_password
      if (isEdit) {
        if (!GESMember.gm_password) {
          delete GESMember.gm_password
        }

        delete GESMember["gm_id"]
        return handleEditGESMemberSubmit(authorityMember.gm_id, GESMember)
      } else {
        return handleAddGESMemberSubmit(GESMember)
      }
    },
  })

  const toggleViewModal = () => {
    if (modal1) {
      setAuthorityMember({})
    }
    setModal1(!modal1)
  }

  const toggle = () => {
    if (modal) {
      setAuthorityMember(null)
    }
    setModal(!modal)
  }
  const handleAuthorityMemberClick = async arg => {
    const member = arg

    setAuthorityMember({
      gm_id: member.gm_id,
      gm_fullName: member.gm_fullName,
      gm_designation: member.gm_designation,
      gm_phoneNumber: member.gm_phoneNumber,
      gm_altPhoneNumber: member.gm_altPhoneNumber,
      gm_email: member.gm_email,
      gm_status: member.gm_status,
      gm_gesOfficeId: member.gm_gesOfficeId,
    })
    setIsEdit(true)
    toggle()
  }

  const onClickDelete = member => {
    setAuthorityMember(member)
    setDeleteModal(true)
  }

  const handleDeleteGES = async () => {
    if (!authorityMember.gm_id) {
      return SaveToast({ message: "Invalid GES Member ID", type: "error" })
    }

    try {
      const response = await deleteGESMember(authorityMember.gm_id)
      let message = response?.message || "GES Member Deleted Successfully"
      SaveToast({ message, type: "success" })

      setSave(prevSave => !prevSave)
      setDeleteModal(false)
      setAuthorityMember({})
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem deleting ges member"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAuthorityClicks = () => {
    setIsEdit(false)
    toggle()
  }

  const handleDeleteModalCloseClick = () => {
    setDeleteModal(false)
    setAuthorityMember({})
  }

  const columns = useMemo(
    () => [
      {
        Header: "Full Name",
        accessor: "gm_fullName",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "GESOffice",
        accessor: "gm_gesOffice.go_officeTitle",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Designation",
        accessor: "gm_designation",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "gm_email",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Phone Number",
        accessor: "gm_phoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Alt Phone Number",
        accessor: "gm_altPhoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "gm_status",
        disableFilters: true,
        Cell: cellProps => {
          const cellData = cellProps.row.original
          return (
            <div className="form-check form-switch form-switch-md">
              <input
                type="checkbox"
                className="form-check-input bg-dark"
                id={`gm_status_checkbox-${cellData.gm_id}`}
                name={`gm_status_checkbox-${cellData.gm_id}`}
                defaultChecked={cellData.gm_status}
                onChange={e => {
                  let { checked, name } = e.target
                  document.getElementById(name).checked = checked
                  return handleUpdateGESMemberStatus(cellData.gm_id, checked)
                }}
              />
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-dark"
                onClick={() => {
                  setAuthorityMember(cellProps.row.original)
                  toggleViewModal()
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const authorityData = cellProps.row.original
                  handleAuthorityMemberClick(authorityData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const authorityData = cellProps.row.original
                  onClickDelete(authorityData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const printColumns = useMemo(
    () => [
      {
        Header: "Full Name",
        accessor: "gm_fullName",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "GESOffice",
        accessor: "gm_gesOffice.go_officeTitle",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Designation",
        accessor: "gm_designation",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "gm_email",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Phone Number",
        accessor: "gm_phoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Alt Phone Number",
        accessor: "gm_altPhoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "gm_status",
        disableFilters: true,
        Cell: cellProps => {
          return cellProps?.row?.original?.status ? "Active" : "Inactive"
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <AuthorityMemeberModel
        isOpen={modal1}
        toggle={toggleViewModal}
        member={authorityMember}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteGES}
        onCloseClick={handleDeleteModalCloseClick}
      />
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="GES" breadcrumbItem="Authorities" /> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={authorityMembers}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddButtonClick={handleAuthorityClicks}
                    addButtonLabel={"Add Authority Member"}
                    customPageSize={10}
                    className="custom-header-css"
                    // canExportCsv={true}
                    dataFetchLoading={loading}
                    noDataMessage="No system activity found."
                    canPrint={true}
                    printColumns={printColumns}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            toggle={toggle}
            style={submitLoading ? { position: "relative" } : {}}
          >
            {submitLoading ? <SubmitLoader /> : <></>}
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Member" : "Add Member"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">
                        Full Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="gm_fullName"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.gm_fullName || ""}
                        invalid={
                          validation.touched.gm_fullName &&
                          validation.errors.gm_fullName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.gm_fullName &&
                      validation.errors.gm_fullName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.gm_fullName}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">
                        Select Office <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="gm_gesOfficeId"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.gm_gesOfficeId || 0}
                        invalid={
                          validation.touched.gm_gesOfficeId &&
                          validation.errors.gm_gesOfficeId
                            ? true
                            : false
                        }
                      >
                        <option value="0" disabled>
                          Select Office
                        </option>
                        {gesOfficeDropdownValues.map(val => {
                          return (
                            <option key={val.id} value={val.id}>
                              {val.id} {val.value}({val.name})
                            </option>
                          )
                        })}
                      </Input>
                      {validation.touched.gm_gesOfficeId &&
                      validation.errors.gm_gesOfficeId ? (
                        <FormFeedback type="invalid">
                          {validation.errors.gm_gesOfficeId}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">
                        Designation <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="gm_designation"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.gm_designation || ""}
                        invalid={
                          validation.touched.gm_designation &&
                          validation.errors.gm_designation
                            ? true
                            : false
                        }
                      />
                      {validation.touched.gm_designation &&
                      validation.errors.gm_designation ? (
                        <FormFeedback type="invalid">
                          {validation.errors.gm_designation}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        Email <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="gm_email"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.gm_email || ""}
                        invalid={
                          validation.touched.gm_email &&
                          validation.errors.gm_email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.gm_email &&
                      validation.errors.gm_email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.gm_email}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        Phone Number <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="gm_phoneNumber"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.gm_phoneNumber || ""}
                        invalid={
                          validation.touched.gm_phoneNumber &&
                          validation.errors.gm_phoneNumber
                            ? true
                            : false
                        }
                      />
                      {validation.touched.gm_phoneNumber &&
                      validation.errors.gm_phoneNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.gm_phoneNumber}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">
                        Password
                        {!isEdit && <span className="text-danger">*</span>}
                      </Label>
                      <Input
                        name="gm_password"
                        type="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.gm_password || ""}
                        invalid={
                          validation.touched.gm_password &&
                          validation.errors.gm_password
                            ? true
                            : false
                        }
                      />
                      {validation.touched.gm_password &&
                      validation.errors.gm_password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.gm_password}
                        </FormFeedback>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Alt. Phone Number</Label>
                      <Input
                        name="gm_altPhoneNumber"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.gm_altPhoneNumber || ""}
                        invalid={
                          validation.touched.gm_altPhoneNumber &&
                          validation.errors.gm_altPhoneNumber
                            ? true
                            : false
                        }
                      />
                      {validation.touched.gm_altPhoneNumber &&
                      validation.errors.gm_altPhoneNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.gm_altPhoneNumber}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                        disabled={submitLoading}
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}
GESAuthorities.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default GESAuthorities
