import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import ViewDetails from "./ViewDetails"
import { Form as RBSForm } from "react-bootstrap"
import { schoolType } from "common/data/dropdownVals"

import UpdateModal from "../../components/Common/UpdateModal"
import { SaveToast } from "components/Common/SaveToast"
import RegionDistrictCircuitDropDownAllSelectable from "components/Common/RegionDistrictCircuitDropDownAllSelectable"
import { defaultRDCSeparator } from "helpers/common_helper_functions"

import {
  createSchool,
  getSchool,
  updateSchool,
} from "helpers/backendHelpers/school"

import SubmitLoader from "components/Common/SubmitLoader"

const SchoolModal = props => {
  const [isEdit, setIsEdit] = useState(false)
  const [isView, setIsView] = useState(false)
  const [schoolId, setSchoolId] = useState(0)

  const [updateModal, setUpdateModal] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const [form, setForm] = useState({
    sc_schoolName: "",
    sc_schoolType: "",
    sc_schoolId: "",
    sc_schoolHeadName: "",
    sc_region: "",
    sc_district: "",
    sc_circuit: "",
    sc_address: "",
    sc_email: "",
    sc_phoneNumber: "",
    sc_altPhoneNumber: "",
    sc_password: "",
    sc_town: "",
    sc_latitude: "",
    sc_longitude: "",
    sc_noOfClassroom: 0,
    sc_boardingFacilities: null,
    sc_sanitaryFacilities: null,
    sc_scienceLab: null,
    sc_assemblyHall: null,
    sc_libraryFacilities: null,
    sc_diningFacilities: null,
    sc_schoolBus: null,
    sc_sportingFacilities: null,
    sc_staffAccommodation: null,
    sc_description: "",
    areaValue: "",
  })

  useEffect(() => {
    let { type, id } = props.match.params || {}

    document.getElementById("schools").classList.add("mm-active")
    switch (type) {
      case "edit":
        setIsEdit(true)
        setIsView(false)
        setSchoolId(parseInt(id))
        break
      case "view":
        setIsView(true)
        setIsEdit(false)
        setSchoolId(parseInt(id))
        break
      case "add":
        break
      default:
        setIsView(false)
        setIsEdit(false)
        setSchoolId(0)
        break
    }
    if (id) {
      fetchEditSchoolDetails(id)
    }
  }, [isEdit])

  const fetchEditSchoolDetails = async sc_id => {
    try {
      const response = await getSchool(sc_id)

      let { school } = response.data || {}
      school = school || {}
      let { sc_region, sc_district, sc_circuit } = school

      let areaValue = `${sc_region || ""}${defaultRDCSeparator}${
        sc_district || ""
      }${defaultRDCSeparator}${sc_circuit || ""}`
      school["areaValue"] = areaValue

      if (isEdit) {
        if (school.sc_boardingFacilities === true) {
          document.getElementById("boardingFacilities-available").checked = true
        } else if (school.sc_boardingFacilities === false) {
          document.getElementById(
            "boardingFacilities-unavailable"
          ).checked = true
        }
        if (school.sc_sanitaryFacilities === true) {
          document.getElementById("sanitaryFacilities-available").checked = true
        } else if (school.sc_sanitaryFacilities === false) {
          document.getElementById(
            "sanitaryFacilities-unavailable"
          ).checked = true
        }

        if (school.sc_scienceLab === true) {
          document.getElementById("scienceLab-available").checked = true
        } else if (school.sc_scienceLab === false) {
          document.getElementById("scienceLab-unavailable").checked = true
        }

        if (school.sc_assemblyHall === true) {
          document.getElementById("assemblyHall-available").checked = true
        } else if (school.sc_assemblyHall === false) {
          document.getElementById("assemblyHall-unavailable").checked = true
        }

        if (school.sc_libraryFacilities === true) {
          document.getElementById("libraryFacilities-available").checked = true
        } else if (school.sc_libraryFacilities === false) {
          document.getElementById(
            "libraryFacilities-unavailable"
          ).checked = true
        }

        if (school.sc_diningFacilities === true) {
          document.getElementById("diningFacilities-available").checked = true
        } else if (school.sc_diningFacilities === false) {
          document.getElementById("diningFacilities-unavailable").checked = true
        }

        if (school.sc_schoolBus === true) {
          document.getElementById("schoolBus-available").checked = true
        } else if (school.sc_schoolBus === false) {
          document.getElementById("schoolBus-unavailable").checked = true
        }

        if (school.sc_sportingFacilities === true) {
          document.getElementById("sportingFacilities-available").checked = true
        } else if (school.sc_sportingFacilities === false) {
          document.getElementById(
            "sportingFacilities-unavailable"
          ).checked = true
        }

        if (school.sc_staffAccommodation === true) {
          document.getElementById("staffAccommodation-available").checked = true
        } else if (school.sc_staffAccommodation === false) {
          document.getElementById(
            "staffAccommodation-unavailable"
          ).checked = true
        }
      }

      return setForm(school)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching freelance school details"

      setForm(form)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddSchoolSubmit = async data => {
    try {
      setSubmitLoading(true)
      const response = await createSchool(data)
      let message = response?.message || "School Added Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)
      props.history.push("/schools")
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Adding School"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleEditSchoolSubmit = async (id, data) => {
    if (!id) {
      return SaveToast({
        message: "Please enter School Id",
        type: "error",
      })
    }
    try {
      setSubmitLoading(true)
      const response = await updateSchool(id, data)
      let message = response?.message || "School Updated Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)
      props.history.push("/schools")
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Updating School"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleUpdateSchool = () => {
    setUpdateModal(false)
    SaveToast({ message: "School Updated Successfully", type: "success" })
    props.history.push("/schools")
  }

  const passwordValidation = !isEdit
    ? Yup.string()
        .min(6, "Password Must Contain Atleast 6 characters")
        .required("Please Enter password")
    : Yup.string()
        .min(6, "Password Must Contain Atleast 6 characters")
        .optional("Please Enter password")

  return (
    <React.Fragment>
      <UpdateModal
        show={updateModal}
        onUpdateClick={handleUpdateSchool}
        onCloseClick={() => setUpdateModal(false)}
      />
      <div className="page-content">
        <div
          className="container-fluid"
          style={submitLoading ? { position: "relative", opacity: "0.8" } : {}}
        >
          {/* <Breadcrumbs title="Schools" breadcrumbItem="School" /> */}
          {submitLoading ? <SubmitLoader /> : <></>}
          {isView ? (
            <>
              <ViewDetails schoolData={form} {...props} />
            </>
          ) : (
            <Card>
              {/* <CardHeader>
              <CardTitle>
                {isView ? (
                  <Label>School Details</Label>
                ) : (
                  <Label>{!isEdit ? "Enter" : "Update"} Details</Label>
                )}
              </CardTitle>
            </CardHeader> */}
              <CardBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={form}
                  validationSchema={Yup.object({
                    sc_schoolName: Yup.string().required(
                      "Please Enter School Name"
                    ),
                    sc_schoolType: Yup.string()
                      .oneOf(
                        schoolType.map(val => val.value),
                        "Please Select Valid School Type"
                      )
                      .required("Please Select School Type"),
                    sc_schoolId: Yup.string()
                      .required("Please Enter School Id")
                      .nullable(),
                    sc_schoolHeadName: Yup.string().required(
                      "Please Enter School Head Name"
                    ),
                    areaValue: Yup.mixed().test(
                      "invalidInput",
                      "Please Select Region-District-Circuit",
                      value => {
                        return !!value
                      }
                    ),
                    sc_address: Yup.string().notRequired().nullable(),
                    sc_phoneNumber: Yup.string()
                      .required("Please Enter Phone Number")
                      .matches(
                        new RegExp(/^[0-9]{10}$/),
                        "Please Enter Valid Phone Number"
                      ),
                    sc_altPhoneNumber: Yup.string()
                      .nullable()
                      .matches(
                        new RegExp(/^[0-9]{10}$/),
                        "Please Enter Valid Phone Number"
                      ),
                    sc_email: Yup.string()
                      .required("Please Enter School Email")
                      .email("Please Enter Valid Email"),
                    sc_password: passwordValidation,
                    sc_town: Yup.string().notRequired().nullable(),
                    sc_latitude: Yup.string().notRequired().nullable(),
                    sc_longitude: Yup.string().notRequired().nullable(),
                    sc_description: Yup.string().notRequired().nullable(),
                    sc_noOfClassroom: Yup.number().notRequired().nullable(),
                  })}
                  onSubmit={values => {
                    let [sc_region, sc_district, sc_circuit] =
                      (values?.areaValue + "" || "")?.split(
                        defaultRDCSeparator
                      ) || []
                    sc_region = sc_region || null
                    sc_district = sc_district || null
                    sc_circuit = sc_circuit || null

                    let schoolData = values
                    if (isEdit) {
                      schoolData["sc_region"] = sc_region
                      schoolData["sc_district"] = sc_district
                      schoolData["sc_circuit"] = sc_circuit
                      delete schoolData["sc_id"]
                      return handleEditSchoolSubmit(schoolId, schoolData)
                    } else {
                      schoolData["sc_region"] = sc_region
                      schoolData["sc_district"] = sc_district
                      schoolData["sc_circuit"] = sc_circuit
                      return handleAddSchoolSubmit(schoolData)
                    }
                  }}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    setFieldTouched,
                  }) => (
                    <>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          handleSubmit(e)
                          return false
                        }}
                      >
                        <Row>
                          <Col>
                            <Row className="mb-3">
                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">
                                  School Name{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="sc_schoolName"
                                  type="text"
                                  placeholder="Enter School Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.sc_schoolName &&
                                    errors.sc_schoolName
                                  }
                                  defaultValue={form.sc_schoolName}
                                />
                                {touched.sc_schoolName &&
                                  errors.sc_schoolName && (
                                    <FormFeedback>
                                      {errors.sc_schoolName}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  School Type{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="sc_schoolType"
                                  type="select"
                                  className="form-select"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.sc_schoolType || 0}
                                  invalid={
                                    touched.sc_schoolType &&
                                    errors.sc_schoolType
                                      ? true
                                      : false
                                  }
                                >
                                  <option disabled value={0}>
                                    Select School Type
                                  </option>
                                  {schoolType.map(type => {
                                    return (
                                      <option
                                        key={type.value}
                                        value={type.value}
                                      >
                                        {type.label}
                                      </option>
                                    )
                                  })}
                                </Input>
                                {touched.sc_schoolType &&
                                  errors.sc_schoolType && (
                                    <FormFeedback>
                                      {errors.sc_schoolType}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  School Id{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="sc_schoolId"
                                  type="text"
                                  placeholder="Enter School Id"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.sc_schoolId && errors.sc_schoolId
                                  }
                                  defaultValue={form.sc_schoolId}
                                />
                                {touched.sc_schoolId && errors.sc_schoolId && (
                                  <FormFeedback>
                                    {errors.sc_schoolId}
                                  </FormFeedback>
                                )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  School Head Name{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="sc_schoolHeadName"
                                  type="text"
                                  placeholder="Enter School Head Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.sc_schoolHeadName &&
                                    errors.sc_schoolHeadName
                                  }
                                  defaultValue={form.sc_schoolHeadName}
                                />
                                {touched.sc_schoolHeadName &&
                                  errors.sc_schoolHeadName && (
                                    <FormFeedback>
                                      {errors.sc_schoolHeadName}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <RegionDistrictCircuitDropDownAllSelectable
                                  isRequired={true}
                                  fieldName="areaValue"
                                  hasTouched={touched.areaValue}
                                  hasErrors={errors.areaValue}
                                  areaValue={values.areaValue}
                                  setFieldValue={setFieldValue}
                                  setFieldTouched={setFieldTouched}
                                />
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Email <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="sc_email"
                                  type="email"
                                  placeholder="Enter Email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={touched.sc_email && errors.sc_email}
                                  defaultValue={form.sc_email}
                                />
                                {touched.sc_email && errors.sc_email && (
                                  <FormFeedback>{errors.sc_email}</FormFeedback>
                                )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Phone Number{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="sc_phoneNumber"
                                  type="tel"
                                  placeholder="Enter Phone Number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.sc_phoneNumber &&
                                    errors.sc_phoneNumber
                                  }
                                  defaultValue={form.sc_phoneNumber}
                                />
                                {touched.sc_phoneNumber &&
                                  errors.sc_phoneNumber && (
                                    <FormFeedback>
                                      {errors.sc_phoneNumber}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Alt. Phone Number
                                </Label>
                                <Input
                                  name="sc_altPhoneNumber"
                                  type="tel"
                                  placeholder="Enter Alt. Phone Number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.sc_altPhoneNumber &&
                                    errors.sc_altPhoneNumber
                                  }
                                  defaultValue={form.sc_altPhoneNumber}
                                />
                                {touched.sc_altPhoneNumber &&
                                  errors.sc_altPhoneNumber && (
                                    <FormFeedback>
                                      {errors.sc_altPhoneNumber}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Password
                                  {!isEdit && (
                                    <span className="text-danger">*</span>
                                  )}
                                </Label>
                                <Input
                                  name="sc_password"
                                  type="password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.sc_password || ""}
                                  invalid={
                                    touched.sc_password && errors.sc_password
                                      ? true
                                      : false
                                  }
                                />
                                {touched.sc_password && errors.sc_password ? (
                                  <FormFeedback type="invalid">
                                    {errors.sc_password}
                                  </FormFeedback>
                                ) : null}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">Town</Label>
                                <Input
                                  name="sc_town"
                                  type="text"
                                  placeholder="Enter Town Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={touched.sc_town && errors.sc_town}
                                  defaultValue={form.sc_town}
                                />
                                {touched.sc_town && errors.sc_town && (
                                  <FormFeedback>{errors.sc_town}</FormFeedback>
                                )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">Latitude</Label>
                                <Input
                                  name="sc_latitude"
                                  type="text"
                                  placeholder="Enter Area Latitude"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.sc_latitude && errors.sc_latitude
                                  }
                                  defaultValue={form.sc_latitude}
                                />
                                {touched.sc_latitude && errors.sc_latitude && (
                                  <FormFeedback>
                                    {errors.sc_latitude}
                                  </FormFeedback>
                                )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">Longitude</Label>
                                <Input
                                  name="sc_longitude"
                                  type="text"
                                  placeholder="Enter Area Longitude"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.sc_longitude && errors.sc_longitude
                                  }
                                  defaultValue={form.sc_longitude}
                                />
                                {touched.sc_longitude &&
                                  errors.sc_longitude && (
                                    <FormFeedback>
                                      {errors.sc_longitude}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Number Of Classs{" "}
                                </Label>
                                <Input
                                  name="sc_noOfClassroom"
                                  type="number"
                                  min={1}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.sc_noOfClassroom || ""}
                                  invalid={
                                    touched.sc_noOfClassroom &&
                                    errors.sc_noOfClassroom
                                      ? true
                                      : false
                                  }
                                />
                                {touched.sc_noOfClassroom &&
                                errors.sc_noOfClassroom ? (
                                  <FormFeedback type="invalid">
                                    {errors.sc_noOfClassroom}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>

                            <Row className="mb-2">
                              <Col
                                md={2}
                                sm={4}
                                xs={6}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Row>
                                  <Col className="gap-3">
                                    <Label className="form-label">
                                      BoardingFacilities{" "}
                                    </Label>
                                    <div>
                                      <RBSForm.Check
                                        id="boardingFacilities-available"
                                        name="sc_boardingFacilities"
                                        type="radio"
                                        inline
                                        label="Yes"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp["sc_boardingFacilities"] = true
                                            values[
                                              "sc_boardingFacilities"
                                            ] = true
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                      <RBSForm.Check
                                        id="boardingFacilities-unavailable"
                                        name="sc_boardingFacilities"
                                        type="radio"
                                        inline
                                        label="No"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp[
                                              "sc_boardingFacilities"
                                            ] = false
                                            values[
                                              "sc_boardingFacilities"
                                            ] = false
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                    </div>

                                    {!!touched.sc_boardingFacilities &&
                                      !!errors.sc_boardingFacilities && (
                                        <FormFeedback>
                                          {errors.sc_boardingFacilities}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>

                              <Col
                                md={2}
                                sm={4}
                                xs={6}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Row>
                                  <Col className="gap-3">
                                    <Label className="form-label">
                                      SanitaryFacilities{" "}
                                    </Label>
                                    <div>
                                      <RBSForm.Check
                                        id="sanitaryFacilities-available"
                                        name="sc_sanitaryFacilities"
                                        type="radio"
                                        inline
                                        label="Yes"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp["sc_sanitaryFacilities"] = true
                                            values[
                                              "sc_sanitaryFacilities"
                                            ] = true
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                      <RBSForm.Check
                                        id="sanitaryFacilities-unavailable"
                                        name="sc_sanitaryFacilities"
                                        type="radio"
                                        inline
                                        label="No"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp[
                                              "sc_sanitaryFacilities"
                                            ] = false
                                            values[
                                              "sc_sanitaryFacilities"
                                            ] = false
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                    </div>

                                    {!!touched.sc_sanitaryFacilities &&
                                      !!errors.sc_sanitaryFacilities && (
                                        <FormFeedback>
                                          {errors.sc_sanitaryFacilities}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>

                              <Col
                                md={2}
                                sm={4}
                                xs={6}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Row>
                                  <Col className="gap-3">
                                    <Label className="form-label">
                                      ScienceLab{" "}
                                    </Label>
                                    <div>
                                      <RBSForm.Check
                                        id="scienceLab-available"
                                        name="sc_scienceLab"
                                        type="radio"
                                        inline
                                        label="Yes"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp["sc_scienceLab"] = true
                                            values["sc_scienceLab"] = true
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                      <RBSForm.Check
                                        id="scienceLab-unavailable"
                                        name="sc_scienceLab"
                                        type="radio"
                                        inline
                                        label="No"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp["sc_scienceLab"] = false
                                            values["sc_scienceLab"] = false
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                    </div>

                                    {!!touched.sc_scienceLab &&
                                      !!errors.sc_scienceLab && (
                                        <FormFeedback>
                                          {errors.sc_scienceLab}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>

                              <Col
                                md={2}
                                sm={4}
                                xs={6}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Row>
                                  <Col className="gap-3">
                                    <Label className="form-label">
                                      SchoolBus{" "}
                                    </Label>
                                    <div>
                                      <RBSForm.Check
                                        id="schoolBus-available"
                                        name="sc_schoolBus"
                                        type="radio"
                                        inline
                                        label="Yes"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp["sc_schoolBus"] = true
                                            values["sc_schoolBus"] = true
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                      <RBSForm.Check
                                        id="schoolBus-unavailable"
                                        name="sc_schoolBus"
                                        type="radio"
                                        inline
                                        label="No"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp["sc_schoolBus"] = false
                                            values["sc_schoolBus"] = false
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                    </div>

                                    {!!touched.sc_schoolBus &&
                                      !!errors.sc_schoolBus && (
                                        <FormFeedback>
                                          {errors.sc_schoolBus}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>

                              <Col
                                md={2}
                                sm={4}
                                xs={6}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Row>
                                  <Col className="gap-3">
                                    <Label className="form-label">
                                      SportingFacilities{" "}
                                    </Label>
                                    <div>
                                      <RBSForm.Check
                                        id="sportingFacilities-available"
                                        name="sc_sportingFacilities"
                                        type="radio"
                                        inline
                                        label="Yes"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp["sc_sportingFacilities"] = true
                                            values[
                                              "sc_sportingFacilities"
                                            ] = true
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                      <RBSForm.Check
                                        id="sportingFacilities-unavailable"
                                        name="sc_sportingFacilities"
                                        type="radio"
                                        inline
                                        label="No"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp[
                                              "sc_sportingFacilities"
                                            ] = false
                                            values[
                                              "sc_sportingFacilities"
                                            ] = false
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                    </div>

                                    {!!touched.sc_sportingFacilities &&
                                      !!errors.sc_sportingFacilities && (
                                        <FormFeedback>
                                          {errors.sc_sportingFacilities}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>

                              <Col
                                md={2}
                                sm={4}
                                xs={6}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Row>
                                  <Col className="gap-3">
                                    <Label className="form-label">
                                      StaffAccommodation{" "}
                                    </Label>
                                    <div>
                                      <RBSForm.Check
                                        id="staffAccommodation-available"
                                        name="sc_staffAccommodation"
                                        type="radio"
                                        inline
                                        label="Yes"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp["sc_staffAccommodation"] = true
                                            values[
                                              "sc_staffAccommodation"
                                            ] = true
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                      <RBSForm.Check
                                        id="staffAccommodation-unavailable"
                                        name="sc_staffAccommodation"
                                        type="radio"
                                        inline
                                        label="No"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp[
                                              "sc_staffAccommodation"
                                            ] = false
                                            values[
                                              "sc_staffAccommodation"
                                            ] = false
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                    </div>

                                    {!!touched.sc_staffAccommodation &&
                                      !!errors.sc_staffAccommodation && (
                                        <FormFeedback>
                                          {errors.sc_staffAccommodation}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="mb-3">
                              <Col sm={12} md={6} className="mt-2 mt-md-0">
                                <Row>
                                  <Col>
                                    <Label className="form-label">
                                      Address{" "}
                                    </Label>
                                    <Input
                                      name="sc_address"
                                      type="textarea"
                                      rows={6}
                                      placeholder="Enter Address"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      invalid={
                                        touched.sc_address && errors.sc_address
                                      }
                                      defaultValue={form.sc_address}
                                    />
                                    {touched.sc_address &&
                                      errors.sc_address && (
                                        <FormFeedback>
                                          {errors.sc_address}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>
                              <Col
                                md={2}
                                sm={4}
                                xs={6}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Row>
                                  <Col className="gap-3">
                                    <Label className="form-label">
                                      AssemblyHall{" "}
                                    </Label>
                                    <div>
                                      <RBSForm.Check
                                        id="assemblyHall-available"
                                        name="sc_assemblyHall"
                                        type="radio"
                                        inline
                                        label="Yes"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp["sc_assemblyHall"] = true
                                            values["sc_assemblyHall"] = true
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                      <RBSForm.Check
                                        id="assemblyHall-unavailable"
                                        name="sc_assemblyHall"
                                        type="radio"
                                        inline
                                        label="No"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp["sc_assemblyHall"] = false
                                            values["sc_assemblyHall"] = false
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                    </div>

                                    {!!touched.sc_assemblyHall &&
                                      !!errors.sc_assemblyHall && (
                                        <FormFeedback>
                                          {errors.sc_assemblyHall}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>

                              <Col
                                md={2}
                                sm={4}
                                xs={6}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Row>
                                  <Col className="gap-3">
                                    <Label className="form-label">
                                      LibraryFacilities{" "}
                                    </Label>
                                    <div>
                                      <RBSForm.Check
                                        id="libraryFacilities-available"
                                        name="sc_libraryFacilities"
                                        type="radio"
                                        inline
                                        label="Yes"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp["sc_libraryFacilities"] = true
                                            values[
                                              "sc_libraryFacilities"
                                            ] = true
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                      <RBSForm.Check
                                        id="libraryFacilities-unavailable"
                                        name="sc_libraryFacilities"
                                        type="radio"
                                        inline
                                        label="No"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp["sc_libraryFacilities"] = false
                                            values[
                                              "sc_libraryFacilities"
                                            ] = false
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                    </div>

                                    {!!touched.sc_libraryFacilities &&
                                      !!errors.sc_libraryFacilities && (
                                        <FormFeedback>
                                          {errors.sc_libraryFacilities}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>

                              <Col
                                md={2}
                                sm={4}
                                xs={6}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Row>
                                  <Col className="gap-3">
                                    <Label className="form-label">
                                      DiningFacilities{" "}
                                    </Label>
                                    <div>
                                      <RBSForm.Check
                                        id="diningFacilities-available"
                                        name="sc_diningFacilities"
                                        type="radio"
                                        inline
                                        label="Yes"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp["sc_diningFacilities"] = true
                                            values["sc_diningFacilities"] = true
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                      <RBSForm.Check
                                        id="diningFacilities-unavailable"
                                        name="sc_diningFacilities"
                                        type="radio"
                                        inline
                                        label="No"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp["sc_diningFacilities"] = false
                                            values[
                                              "sc_diningFacilities"
                                            ] = false
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                    </div>

                                    {!!touched.sc_diningFacilities &&
                                      !!errors.sc_diningFacilities && (
                                        <FormFeedback>
                                          {errors.sc_diningFacilities}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>
                            </Row>

                            <Row className="mb-3 text-center">
                              <Col>
                                <Button
                                  size="md"
                                  color="danger"
                                  type="button"
                                  disabled={submitLoading}
                                  className="mx-2"
                                  onClick={() => {
                                    props.history.push("/schools")
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  size="md"
                                  color="info"
                                  type="submit"
                                  disabled={submitLoading}
                                >
                                  {!isEdit ? "Save" : "Update"}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </Formik>
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

SchoolModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default SchoolModal
