import { getApiConfig } from "helpers/authHelper"
import { get, post, put, del } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllGESMember = () => {
  return get(url.GES_MEMBER, getApiConfig())
}

export const getGESMember = id => {
  return get(`${url.GES_MEMBER}/${id}`, getApiConfig())
}

export const createGESMember = data => {
  return post(url.GES_MEMBER, data, getApiConfig())
}

export const updateGESMember = (id, data) => {
  return put(`${url.GES_MEMBER}/${id}`, data, getApiConfig())
}

export const deleteGESMember = id => {
  return del(`${url.GES_MEMBER}/${id}`, getApiConfig())
}
