import { getApiConfig } from "helpers/authHelper"
import { get, post, put, del } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllContent = (id) => {
  return get(`${url.CONTENT_MANAGEMENT}?tp_id=${id}`, getApiConfig())
}

export const getContent = id => {
  return get(`${url.CONTENT_MANAGEMENT}/${id}`, getApiConfig())
}

export const createContent = data => {
  return post(url.CONTENT_MANAGEMENT, data, getApiConfig(true))
}

export const updateContent = (id, data) => {
  return put(`${url.CONTENT_MANAGEMENT}/${id}`, data, getApiConfig(true))
}

export const deleteContent = id => {
  return del(`${url.CONTENT_MANAGEMENT}/${id}`, getApiConfig())
}
