import { getApiConfig } from "helpers/authHelper"
import { get } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllClassRoom = school_id => {
  return get(
    `${url.CLASSROOM}/filterClass?cr_schoolId=${school_id}`,
    getApiConfig()
  )
}
