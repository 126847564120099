export default [
  {
    id: 1,
    region: "Andhra Pradesh",
  },
  {
    id: 2,
    region: "Assam",
  },
  {
    id: 3,
    region: "Bihar",
  },
  {
    id: 4,
    region: "Telangana",
  },
  {
    id: 5,
    region: "Gujarat",
  },
  {
    id: 6,
    region: "Maharashtra",
  },
]
