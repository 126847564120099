import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import Select from "react-select"
import { Label } from "reactstrap"
import { SaveToast } from "./SaveToast"
import { getAllCategories } from "helpers/backendHelpers/books"
import { defaultCCGSeparator } from "helpers/common_helper_functions"

const ContentCategoryDropDownAllSelectable = ({
  isRequired,
  hasTouched,
  hasErrors,
  fieldName,
  categoryValue,
  setFieldValue,
  setFieldTouched,
  mainCategorySelectDisabled,
  categorySelectDisabled,
  subCategorySelectDisabled,
  topicDisabled,
  handleContentCategoryChangeCallback,
}) => {
  const [allCategories, setallCategories] = useState([])

  useEffect(() => {
    prepareCCGOptions()
  }, [])

  const prepareCCGOptions = async () => {
    try {
      let { data } = await getAllCategories()
      let { topics } = data || {}
      topics = topics || []

      let tempCategories = []

      for (let mainCategory of topics) {
        let { cc_categoryName, category } = mainCategory

        const classNames = []

        for (let categories of category) {
          let { categoryName, subCategory } = categories

          let value =
            (cc_categoryName || "") + defaultCCGSeparator + (categoryName || "")
          let label = `${cc_categoryName}, ${categoryName}`
          classNames.push({
            value,
            label,
            isDisabled: !!categorySelectDisabled,
          })
        }

        tempCategories.push({
          label: cc_categoryName,
          options: classNames,
        })
      }

      setallCategories(
        tempCategories.sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        )
      )
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching categories"
      setallCategories([])
      return SaveToast({ message, type: "error" })
    }
  }

  const getSelectValue = () => {
    // Find category value
    for (const level of allCategories) {
      for (const value of level.options) {
        if (value.value === categoryValue) {
          return value
        }
      }
    }

    return ""
  }

  return (
    <>
      <Label className="form-label">
        Book Category
        {isRequired && <span className="text-danger">*</span>}
      </Label>
      <Select
        name={fieldName}
        placeholder="Select Book Category"
        value={getSelectValue()}
        onChange={value => {
          handleContentCategoryChangeCallback(value)
          setFieldValue(fieldName, value ? value.value : "")
        }}
        onBlur={evt => {
          setFieldTouched(fieldName, true)
        }}
        options={allCategories}
        isClearable
        invalid={hasTouched && hasErrors}
      />
      {hasTouched && hasErrors && (
        <div className="invalid-react-select-dropdown">{hasErrors}</div>
      )}
    </>
  )
}

ContentCategoryDropDownAllSelectable.propTypes = {
  isRequired: PropTypes.bool,
  hasTouched: PropTypes.bool || false,
  hasErrors: PropTypes.string || "",
  fieldName: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  mainCategorySelectDisabled: PropTypes.bool,
  categorySelectDisabled: PropTypes.bool,
  subCategorySelectDisabled: PropTypes.bool,
  topicDisabled: PropTypes.bool,
}

ContentCategoryDropDownAllSelectable.defaultProps = {
  isRequired: true,
  fieldName: "mainCategory",
  mainCategorySelectDisabled: false,
  categorySelectDisabled: false,
  subCategorySelectDisabled: false,
  topicDisabled: false,
}

export default ContentCategoryDropDownAllSelectable
