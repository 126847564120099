import { getApiConfig } from "helpers/authHelper"
import { get, post, put, del } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllContentTeamMembers = () => {
  return get(url.CONTENT_TEAM, getApiConfig())
}

export const getContentTeamMember = id => {
  return get(`${url.CONTENT_TEAM}/${id}`, getApiConfig())
}

export const createContentTeamMember = data => {
  return post(url.CONTENT_TEAM, data, getApiConfig(true))
}

export const updateContentTeamMember = (id, data) => {
  return put(`${url.CONTENT_TEAM}/${id}`, data, getApiConfig(true))
}

export const deleteContentTeamMember = id => {
  return del(`${url.CONTENT_TEAM}/${id}`, getApiConfig())
}
