import React from "react"
import PropTypes from "prop-types"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { IMAGE_URL } from "helpers/urlHelper"
const VideoModal = props => {
  const { isOpen, toggle, video } = props
  {
    if (video !== null)
      return (
        <>
          <Modal
            isOpen={isOpen}
            size="lg"
            role="dialog"
            autoFocus={true}
            centered={true}
            className="tutorModal"
            tabIndex="-1"
            toggle={toggle}
          >
            <ModalHeader toggle={toggle}>Watch Video</ModalHeader>
            <ModalBody>
              <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9">
                <iframe
                  title="test"
                  className="embed-responsive-item"
                  src={IMAGE_URL + "/" + video}
                />
              </div>
            </ModalBody>
          </Modal>
        </>
      )
  }
}

VideoModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default VideoModal
