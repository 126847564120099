import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useHistory, useLocation, useParams } from "react-router-dom"
import { isEmpty } from "lodash"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

// import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import { SaveToast } from "components/Common/SaveToast"

import TutorModal from "./ViewModal"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"

import { batches } from "../../common/data/batchData"
import { optionGroup as usersOptions } from "../../common/data/users"
import { tutors } from "../../common//data/tutorData"
import {
  SimpleStringValue,
  ArrayToStringValue,
} from "helpers/common_helper_functions"
import Select from "react-select"
import {
  deleteTrainingBatch,
  getTrainingBatchByTP,
} from "helpers/backendHelpers/trainingBatch"

const tutorsDropDown = tutors.map(tutor => {
  return tutor.fullName
})
function Batches(props) {
  document.title = "Batch Creation | LMS Ghana"

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [saved, setSaved] = useState(false)
  const [batch, setBatch] = useState(null)
  const [trainingBatch, setTrainingBatch] = useState([])
  const [loading, setLoading] = useState(false)
  const [batchDetais, setBatchDetais] = useState()
  const history = useHistory()
  const location = useLocation()
  const { tp_id } = useParams()

  const URL_REGEX =
    /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/gm

  useEffect(() => {
    fetchAllBatchByTP(tp_id)
  }, [])

  const fetchAllBatchByTP = async tp_id => {
    try {
      setLoading(true)
      const response = await getTrainingBatchByTP(tp_id)
      let { trainingBatch } = response.data || {}
      trainingBatch = trainingBatch || []
      setTrainingBatch(trainingBatch)
      setLoading(false)
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Content Teams"
      setTrainingBatch([])
      setLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleDeleteBatch = async () => {
    if (!batchDetais?.tb_id) {
      return SaveToast({ message: "Invalid Batch ID", type: "error" })
    }
    try {
      const response = await deleteTrainingBatch(batchDetais?.tb_id)
      const message = response?.message || "Batch Deleted Successfully"
      SaveToast({ message, type: "success" })
      setSaved(prevSaved => !prevSaved)
      setDeleteModal(false)
      fetchAllBatchByTP(tp_id)
      return
    } catch (error) {
      const message = error?.message || "There Was A Problem Deleting Batch"
      return SaveToast({ message, type: "error" })
    }
  }

  const toggleViewModal = () => {
    setModal1(!modal1)
    if (batch) {
      setBatch(null)
    }
  }

  const toggle = () => {
    if (modal) {
      setModal(false)
      setBatch(null)
    } else {
      setModal(true)
    }
  }

  const handleTutorClick = bVal => {
    const Batch = bVal
    setBatch({
      id: Batch.id,
      users: Batch.users,
      dateTime: Batch.dateTime,
      tutor: Batch.tutor,
      venue: Batch.venue,
      meetingLink: Batch.meetingLink,
    })
    setIsEdit(true)

    toggle()
  }
  const [deleteModal, setDeleteModal] = useState(false)

  const onClickDelete = batch => {
    setBatchDetais(batch)
    setDeleteModal(true)
  }

  const handleAddButtonClick = () => {
    // props.history.push("/batch-creation/add")
    history.push({
      pathname: `/batch-creation/add/${tp_id}`,
      state: { tp_id },
    })
  }
  const handleBackButtonClick = () => {
    // props.history.push("/batch-creation/add")
    history.push({
      pathname: `/training-program`,
    })
  }
  const columns = useMemo(
    () => [
      {
        Header: "Date And Time",
        accessor: "tb_dateTime",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Room URL",
        accessor: "tb_roomURL",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Tutor",
        accessor: "tu_id",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={{
                  pathname:
                    "/batch-creation/view/" + cellProps.row.original.tb_id,
                  state: {
                    tp_id,
                  },
                }}
                className="text-dark"
                onClick={() => {
                  setBatch(cellProps.row.original)
                  toggleViewModal()
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const batchData = cellProps.row.original
                  onClickDelete(batchData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      {/* <TutorModal isOpen={modal1} toggle={toggleViewModal} batch={batch} /> */}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteBatch}
        onCloseClick={() => setDeleteModal(false)}
      />
      {/* <div className="page-content">
        <div className="container-fluid"> */}
      {/* <Breadcrumbs title="Batch Creation" breadcrumbItem="Batch" /> */}
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={trainingBatch}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    isBackOption={true}
                    handleAddButtonClick={handleAddButtonClick}
                    handleBackButtonClick={handleBackButtonClick}
                    addButtonLabel={"Add Batch"}
                    customPageSize={10}
                    className="custom-header-css"
                    canExportCsv={true}
                    canPrint={false}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
Batches.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Batches
