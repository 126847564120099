import { SaveToast } from "components/Common/SaveToast"
import { Form, Formik } from "formik"
import { updateTrainingParticipant } from "helpers/backendHelpers/trainingParticipants"
import moment from "moment"
import React, { useState } from "react"
import DatePicker from "react-datepicker"
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"

const formInitialState = {
  tps_examDate: "",
  tps_score: "",
  tps_signedForm: { fileName: "", file: {} },
}

function AttestationFormModal({ tpsId, refreshParent, closeModal }) {
  const isOpen = tpsId != null
  const [isLoading, setIsLoading] = useState(false)

  const handleFormSubmit = async values => {
    if (!values.tps_score != !values.tps_examDate) {
      SaveToast({
        message: "Please Enter Both Score and Exam Date",
        type: "error",
      })
      return
    }

    const isBothDateScoreNotPresent = !values.tps_score && !values.tps_examDate
    const isBothDateScorePresent = values.tps_score && values.tps_examDate

    const data = {}
    data["tps_signedForm"] = values.tps_signedForm.file

    if (isBothDateScoreNotPresent) {
      data["tps_trainingStatus"] = 3
    } else if (isBothDateScorePresent) {
      data["tps_trainingStatus"] = 4
      data["tps_examDate"] = moment(values.tps_examDate).format("DD-MM-YYYY")
      data["tps_score"] = values.tps_score
    }

    try {
      setIsLoading(true)
      await updateTrainingParticipant(tpsId, data)

      setIsLoading(false)
      closeModal()
      refreshParent()
    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  }

  return (
    <Modal isOpen={isOpen} centered={true}>
      <ModalHeader color="dark" toggle={closeModal}>
        Upload Attestation
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={formInitialState}
          validationSchema={Yup.object({
            tps_score: Yup.string().matches(
              /^[0-9]+$/,
              "Please Enter a valid Score"
            ),
            tps_signedForm: Yup.mixed()
              .required("Please Select Pdf")
              .test("fileFormat", "Unsupported Format", value => {
                if (!value.file.type) return false
                return ["application/pdf"].includes(value.file.type)
              }),
          })}
          onSubmit={handleFormSubmit}
        >
          {({
            touched,
            errors,
            values,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
            setFieldTouched,
          }) => (
            <>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  handleSubmit(e)
                  return false
                }}
              >
                <Row>
                  <Col xs="12" md="12" className="mb-3">
                    <Label className="form-label">
                      Signed Document
                      <span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="tps_signedForm"
                      type="file"
                      accept=".pdf"
                      placeholder="Select Pdf file"
                      onChange={e => {
                        setFieldValue("tps_signedForm", {
                          fileName: e.target.value,
                          file: e.target.files[0],
                        })
                      }}
                      invalid={
                        !!touched.tps_signedForm && !!errors.tps_signedForm
                      }
                      defaultValue={values.tps_signedForm.fileName}
                    />
                    {!!touched.tps_signedForm && !!errors.tps_signedForm && (
                      <FormFeedback>{errors.tps_signedForm}</FormFeedback>
                    )}
                  </Col>

                  <Col xs="12" md="12" className="mb-3">
                    <Label className="form-label">Score</Label>
                    <Input
                      name="tps_score"
                      type="text"
                      placeholder="Enter Score"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      invalid={touched.tps_score && errors.tps_score}
                      defaultValue={values.tps_score}
                    />
                    {touched.tps_score && errors.tps_score && (
                      <FormFeedback>{errors.tps_score}</FormFeedback>
                    )}
                  </Col>

                  <Col xs="12" md="12" className="mb-3">
                    <Label className="form-label">Enter Exam Date</Label>
                    <DatePicker
                      name="tps_examDate"
                      onChange={e => {
                        setFieldValue("tps_examDate", e)
                      }}
                      placeholderText="Select Exam Date"
                      selected={
                        values.tps_examDate != undefined && values.tps_examDate
                      }
                      invalid={touched.tps_examDate && errors.tps_examDate}
                    />

                    {touched.tps_examDate && errors.tps_examDate && (
                      <FormFeedback>{errors.tps_examDate}</FormFeedback>
                    )}
                  </Col>

                  <Col xs="12" md="12" className="text-center">
                    <Col>
                      <Button
                        size="md"
                        color="danger"
                        type="button"
                        disabled={isLoading}
                        className="mx-2"
                        onClick={closeModal}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="md"
                        color="info"
                        type="submit"
                        disabled={isLoading}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

export default AttestationFormModal
