import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import ViewDetails from "./ViewDetails"
import Select from "react-select"
import UpdateModal from "../../components/Common/UpdateModal"
import { SaveToast } from "components/Common/SaveToast"
import { staffRole } from "../../common/data/dropdownVals"
import { getAllSchool } from "helpers/backendHelpers/school"

import {
  getSchoolStaff,
  updateSchoolStaff,
  createSchoolStaff,
} from "helpers/backendHelpers/schoolStaff"
import SubmitLoader from "components/Common/SubmitLoader"

const StaffMemberModal = props => {
  const [isEdit, setIsEdit] = useState(false)
  const [isView, setIsView] = useState(false)
  const [staffId, setStaffId] = useState(0)
  const [selectedSchool, setSelectedSchool] = useState({})
  const [updateModal, setUpdateModal] = useState(false)
  const [schoolDropdownValues, setSchoolDropdownValues] = useState([])
  const [submitLoading, setSubmitLoading] = useState(false)

  const [form, setForm] = useState({
    ss_staffRole: "",
    ss_email: "",
    ss_staffId: "",
    ss_schoolId: "",
    ss_fullName: "",
    ss_phoneNumber: "",
    ss_altPhoneNumber: "",
    ss_status: true,
  })
  const setSchoolDropDownValues = async id => {
    try {
      const response = await getAllSchool()
      let { schools } = response.data || {}
      let dropdownVals = schools.map(school => {
        return { value: school.sc_id, label: school.sc_schoolName }
      })
      dropdownVals = dropdownVals || []
      if (id) {
        const defVal = dropdownVals.find(item => item.value === id)
        defVal ? setSelectedSchool(defVal) : setSelectedSchool({})
      } else {
        setSchoolDropdownValues(dropdownVals)
      }
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Schools"

      setSchoolDropdownValues([])

      return SaveToast({ message, type: "error" })
    }
  }

  useEffect(() => {
    // Drop-Down Values for school
    setSchoolDropDownValues()

    document.getElementById("school-staff").classList.add("mm-active")
    let { type, id } = props.match.params || {}

    switch (type) {
      case "edit":
        setIsEdit(true)
        setIsView(false)
        setStaffId(parseInt(id))
        break
      case "view":
        setIsView(true)
        setIsEdit(false)
        setStaffId(parseInt(id))
        break
      case "add":
        break
      default:
        setIsView(false)
        setIsEdit(false)
        setStaffId(0)
        break
    }

    if (id) {
      fetchSchoolStaffForEdit(id)
    }
  }, [isEdit])

  const fetchSchoolStaffForEdit = async ss_id => {
    try {
      const response = await getSchoolStaff(ss_id)
      let { schoolStaff } = response.data
      setSchoolDropDownValues(schoolStaff.ss_schoolId)

      if (isEdit) {
        Object.keys(schoolStaff).forEach(key => {
          if (schoolStaff[key] === null) {
            delete schoolStaff[key]
          }
        })
        setForm(schoolStaff)
      }
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching School Staff Details"

      setForm(form)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddSchoolStaffSubmit = async data => {
    try {
      setSubmitLoading(true)
      const response = await createSchoolStaff(data)
      let message = response?.message || "SchoolStaff Added Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)
      props.history.push("/school-staff")
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Adding SchoolStaff"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleEditSchoolStaffSubmit = async (id, data) => {
    if (!id) {
      return SaveToast({
        message: "Please enter SchoolStaff Id",
        type: "error",
      })
    }
    try {
      setSubmitLoading(true)
      const response = await updateSchoolStaff(id, data)
      let message = response?.message || "SchoolStaff Updated Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)
      props.history.push("/school-staff")
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Updating SchoolStaff"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleUpdateMember = () => {
    setUpdateModal(false)
    SaveToast({ message: "Member Updated Successfully", type: "success" })
    props.history.push("/school-staff")
  }

  return (
    <React.Fragment>
      <UpdateModal
        show={updateModal}
        onUpdateClick={handleUpdateMember}
        onCloseClick={() => setUpdateModal(false)}
      />
      <div className="page-content">
        <div
          className="container-fluid"
          style={submitLoading ? { position: "relative", opacity: "0.8" } : {}}
        >
          {/* <Breadcrumbs title="School" breadcrumbItem="Staff" /> */}
          {submitLoading ? <SubmitLoader /> : <></>}
          {isView ? (
            <>
              <ViewDetails staffId={staffId} staffData={form} {...props} />
            </>
          ) : (
            <Card>
              {/* <CardHeader>
              <CardTitle>
                {isView ? (
                  <Label>Staff Member Details</Label>
                ) : (
                  <Label>{!isEdit ? "Enter" : "Update"} Details</Label>
                )}
              </CardTitle>
            </CardHeader> */}
              <CardBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={form}
                  validationSchema={Yup.object({
                    ss_staffRole: Yup.string()
                      .oneOf(staffRole, "Please Select Valid Staff Role")
                      .required("Please Select Staff Role"),
                    ss_email: Yup.string()
                      .required("Please Enter Email")
                      .email("Please Enter Valid Email"),
                    ss_schoolId: Yup.number().required("Please Select School"),
                    ss_staffId: Yup.string().required("Please Enter Staff Id"),
                    ss_fullName: Yup.string().required(
                      "Please Enter Your FullName"
                    ),
                    ss_phoneNumber: Yup.string()
                      .required("Please Enter Phone Number")
                      .matches(
                        /^[0-9]{10}$/,
                        "Please Enter Valid Phone Number"
                      ),
                    ss_altPhoneNumber: Yup.string()
                      .nullable()
                      .notRequired()
                      .matches(
                        /^[0-9]{10}$/,
                        "Please Enter Valid Phone Number"
                      ),
                    ss_status: Yup.bool().required("Please Select Status"),
                  })}
                  onSubmit={values => {
                    let staffData = {
                      ss_staffRole: values.ss_staffRole,
                      ss_email: values.ss_email,
                      ss_schoolId: values.ss_schoolId,
                      ss_schoolId: parseInt(values.ss_schoolId),
                      ss_staffId: values.ss_staffId,
                      ss_fullName: values.ss_fullName,
                      ss_phoneNumber: values.ss_phoneNumber,
                      ss_altPhoneNumber: values.ss_altPhoneNumber
                        ? values.ss_altPhoneNumber
                        : null,
                      ss_status: values.ss_status,
                    }

                    if (isEdit) {
                      return handleEditSchoolStaffSubmit(staffId, staffData)
                    } else {
                      return handleAddSchoolStaffSubmit(staffData)
                    }
                  }}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleSubmit,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                    handleChange,
                  }) => (
                    <>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          handleSubmit(e)
                          return false
                        }}
                      >
                        <Row>
                          <Col>
                            <Row className="mb-3">
                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">
                                  Staff Role{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="ss_staffRole"
                                  type="select"
                                  className="form-select"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.ss_staffRole || 0}
                                  invalid={
                                    touched.ss_staffRole && errors.ss_staffRole
                                      ? true
                                      : false
                                  }
                                >
                                  <option disabled value={0}>
                                    Select Staff Role
                                  </option>
                                  {staffRole.map(val => {
                                    return (
                                      <option key={val} value={val}>
                                        {val}
                                      </option>
                                    )
                                  })}
                                </Input>
                                {touched.ss_staffRole &&
                                  errors.ss_staffRole && (
                                    <FormFeedback>
                                      {errors.ss_staffRole}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Email <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="ss_email"
                                  type="email"
                                  placeholder="Enter Email"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={touched.ss_email && errors.ss_email}
                                  defaultValue={form.ss_email}
                                />
                                {touched.ss_email && errors.ss_email && (
                                  <FormFeedback>{errors.ss_email}</FormFeedback>
                                )}
                              </Col>
                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  School Name{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Select
                                  name="ss_schoolId"
                                  placeholder="Select School"
                                  value={selectedSchool}
                                  onChange={value => {
                                    setSelectedSchool(value)
                                    setFieldValue(
                                      "ss_schoolId",
                                      value ? value.value : ""
                                    )
                                  }}
                                  onBlur={evt => {
                                    setFieldTouched("ss_schoolId", true, true)
                                  }}
                                  options={schoolDropdownValues}
                                  isClearable
                                  invalid={
                                    touched.ss_schoolId && errors.ss_schoolId
                                  }
                                />
                                {touched.ss_schoolId && errors.ss_schoolId && (
                                  <div className="invalid-react-select-dropdown">
                                    {errors.ss_schoolId}
                                  </div>
                                )}
                              </Col>

                              <Col md={4} sm={6} xs={12} className="mt-2 mb-3">
                                <Label className="form-label">
                                  Staff Id{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="ss_staffId"
                                  type="string"
                                  placeholder="Enter Staff Id"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.ss_staffId && errors.ss_staffId
                                  }
                                  defaultValue={form.ss_staffId}
                                />
                                {touched.ss_staffId && errors.ss_staffId && (
                                  <FormFeedback>
                                    {errors.ss_staffId}
                                  </FormFeedback>
                                )}
                              </Col>

                              <Col md={4} sm={6} xs={12} className="mt-2 mb-3">
                                <Label className="form-label">
                                  Full Name{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="ss_fullName"
                                  type="string"
                                  placeholder="Enter Your Full Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.ss_fullName && errors.ss_fullName
                                  }
                                  defaultValue={form.ss_fullName}
                                />
                                {touched.ss_fullName && errors.ss_fullName && (
                                  <FormFeedback>
                                    {errors.ss_fullName}
                                  </FormFeedback>
                                )}
                              </Col>

                              <Col md={4} sm={6} xs={12} className="mt-2 mb-3">
                                <Label className="form-label">
                                  Phone Number{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="ss_phoneNumber"
                                  type="tel"
                                  placeholder="Enter Phone Number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.ss_phoneNumber &&
                                    errors.ss_phoneNumber
                                  }
                                  defaultValue={form.ss_phoneNumber}
                                />
                                {touched.ss_phoneNumber &&
                                  errors.ss_phoneNumber && (
                                    <FormFeedback>
                                      {errors.ss_phoneNumber}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col md={4} sm={6} xs={12} className="mt-2 mb-3">
                                <Label className="form-label">
                                  Alt. Phone Number
                                </Label>
                                <Input
                                  name="ss_altPhoneNumber"
                                  type="tel"
                                  placeholder="Enter Alt. Phone Number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.ss_altPhoneNumber &&
                                    errors.ss_altPhoneNumber
                                  }
                                  defaultValue={form.ss_altPhoneNumber}
                                />
                                {touched.ss_altPhoneNumber &&
                                  errors.ss_altPhoneNumber && (
                                    <FormFeedback>
                                      {errors.ss_altPhoneNumber}
                                    </FormFeedback>
                                  )}
                              </Col>
                            </Row>

                            <Row className="mb-3"></Row>

                            <Row className="mb-3 text-center">
                              <Col>
                                <Button
                                  size="md"
                                  color="danger"
                                  type="button"
                                  disabled={submitLoading}
                                  className="mx-2"
                                  onClick={() => {
                                    props.history.push("/school-staff")
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  size="md"
                                  color="info"
                                  type="submit"
                                  disabled={submitLoading}
                                >
                                  {!isEdit ? "Save" : "Update"}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </Formik>
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

StaffMemberModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default StaffMemberModal
