import { getApiConfig } from "helpers/authHelper"
import { get, post, put, del } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllTeamMember = () => {
  return get(url.TEAM_MEMBER, getApiConfig())
}

export const createTeamMember = data => {
  return post(url.TEAM_MEMBER, data, getApiConfig())
}

export const updateTeamMember = (id, data) => {
  return put(`${url.TEAM_MEMBER}/${id}`, data, getApiConfig())
}

export const deleteTeamMember = id => {
  return del(`${url.TEAM_MEMBER}/${id}`, getApiConfig())
}
