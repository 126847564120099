import React, { useMemo, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

// import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  SimpleDateFormate,
  SimpleStringValue,
} from "helpers/common_helper_functions"
import { SaveToast } from "components/Common/SaveToast"
import { Col, Row, UncontrolledTooltip, Card, CardBody } from "reactstrap"

import {
  getAllLiveSession,
  updateBlog,
  deleteBlog,
} from "helpers/backendHelpers/liveSession"
import { IMAGE_URL } from "helpers/urlHelper"
const LiveSession = props => {
  document.title = "Blogs | LMS Ghana"

  const [deleteModal, setDeleteModal] = useState(false)
  const [liveSessions, setLiveSessions] = useState([])
  const [liveSession, setLiveSession] = useState({})
  const [saved, setSaved] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchAllLiveSessions()
  }, [saved])

  const fetchAllLiveSessions = async () => {
    try {
      setLoading(true)
      const response = await getAllLiveSession()
      let { liveSession } = response.data || {}
      liveSession = liveSession || []
      setLiveSessions(liveSession)
      setLoading(false)
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching SchoolStaff"

      setLiveSessions([])
      setLoading(false)

      return SaveToast({ message, type: "error" })
    }
  }

  const onClickDelete = liveSession => {
    setLiveSession(liveSession)
    setDeleteModal(true)
  }

  const handleUpdateBlogStatus = async (id, status) => {
    if (!id) {
      return SaveToast({
        message: "Please Enter Live Session Id",
        type: "error",
      })
    }
    try {
      const response = await deleteBlog(id, {
        bl_status: status,
      })
      const message =
        response?.message || "SchoolStaff Status Updated Successfully"
      SaveToast({ message, type: "success" })

      setSaved(prevSaved => !prevSaved)
      return
    } catch (error) {
      const message =
        error?.message || "There Was A Problem Updating SchoolStaff Status"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleDeleteLiveSession = async () => {
    if (!liveSession.ls_id) {
      return SaveToast({ message: "Invalid Live Session ID", type: "error" })
    }
    try {
      const response = await deleteBlog(liveSession.ls_id)
      const message = response?.message || "Live Session Deleted Successfully"
      SaveToast({ message, type: "success" })
      setSaved(prevSaved => !prevSaved)
      setDeleteModal(false)
      return
    } catch (error) {
      const message =
        error?.message || "There was a problem deleting Live Session"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddButtonClick = () => {
    props.history.push("/blogs/add")
  }

  const columns = useMemo(
    () => [
      {
        Header: "Title",
        accessor: "ls_title",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Description",
        accessor: "ls_desc",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Room URL",
        accessor: "ls_roomURL",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Date",
        accessor: "ls_date",
        filterable: true,
        Cell: cellProps => {
          return <SimpleDateFormate {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {/* <Link
                to="#"
                className="text-dark"
                onClick={() => {
                  props.history.push(
                    "/school-staff/view/" + cellProps.row.original.ss_id
                  )
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link> */}
              {/* <Link
                to="#"
                className="text-success"
                onClick={() => {
                  props.history.push(
                    "/school-staff/edit/" + cellProps.row.original.ss_id
                  )
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link> */}
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const liveSessionData = cellProps.row.original
                  onClickDelete(liveSessionData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  const printColumns = useMemo(
    () => [
      {
        Header: "Role",
        accessor: "ss_staffRole",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "ss_email",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Full Name",
        accessor: "ss_fullName",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Phone Number",
        accessor: "ss_phoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "ss_status",
        disableFilters: true,
        Cell: cellProps => {
          return cellProps?.row?.original?.ss_status ? "Active" : "Inactive"
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteLiveSession}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="School" breadcrumbItem="Staff" /> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={liveSessions}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    addButtonLabel="Add Blog"
                    handleAddButtonClick={handleAddButtonClick}
                    customPageSize={10}
                    className="custom-header-css"
                    dataFetchLoading={loading}
                    canExportCsv={true}
                    canPrint={true}
                    printColumns={printColumns}
                    noDataMessage="No system activity found."
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default LiveSession
