import React, { useMemo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

//import components
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  SimpleStringValue,
  FreePaidValue,
} from "helpers/common_helper_functions"

import { Col, Row, UncontrolledTooltip, Card, CardBody } from "reactstrap"
import { SaveToast } from "components/Common/SaveToast"

import {
  getAllTrainingPrograms,
  deleteTrainingProgram,
} from "helpers/backendHelpers/trainingProgram"

const TrainingProgram = props => {
  //meta title
  document.title = "Training Program | LMS Ghana"

  //delete order
  const [deleteModal, setDeleteModal] = useState(false)
  const [saved, setSaved] = useState(false)
  const [loading, setLoading] = useState(false)
  const [trainingPrograms, setTrainingPrograms] = useState([])
  const [trainingProgram, setTrainingProgram] = useState({})

  useEffect(() => {
    fetchAllTrainingPrograms()
  }, [saved])

  const fetchAllTrainingPrograms = async () => {
    try {
      setLoading(true)
      const response = await getAllTrainingPrograms()
      let { trainingPrograms } = response.data || {}
      trainingPrograms = trainingPrograms || []
      setTrainingPrograms(trainingPrograms)
      setLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Schools"

      setTrainingPrograms([])
      setLoading(false)

      return SaveToast({ message, type: "error" })
    }
  }

  const onClickDelete = program => {
    setTrainingProgram(program)
    setDeleteModal(true)
  }

  const handleDeleteTrainingProgram = async () => {
    if (!trainingProgram.tp_id) {
      return SaveToast({ message: "Invalid Program ID", type: "error" })
    }
    try {
      const response = await deleteTrainingProgram(trainingProgram.tp_id)
      const message = response?.message || "Program Deleted Successfully"
      SaveToast({ message, type: "success" })
      setSaved(prevSaved => !prevSaved)
      setDeleteModal(false)
      return
    } catch (error) {
      const message = error?.message || "There Was A Problem Deleting Program"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddButtonClick = () => {
    props.history.push("/training-program/add")
  }

  const columns = useMemo(
    () => [
      {
        Header: "Program Title",
        accessor: "tp_programTitle",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Type",
        accessor: "tp_typeOfProgram",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Duration",
        accessor: "tp_duration",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "type",
        accessor: "tp_isFree",
        filterable: true,
        Cell: cellProps => {
          return <FreePaidValue {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={{
                  pathname: "/content-management",
                  state: {
                    tp_id: cellProps.row.original.tp_id,
                  },
                }}
                className="text-dark"
              >
                {/* <i className="bx bx bx-edit font-size-20" id='contenttooltip'></i> */}
                <i className="mdi mdi-file font-size-18" id="contenttooltip" />
                <UncontrolledTooltip placement="top" target="contenttooltip">
                  Content
                </UncontrolledTooltip>
              </Link>
              <Link
                to={{
                  pathname: "/batch-creation/" + cellProps.row.original.tp_id,
                  state: {
                    tp_id: cellProps.row.original.tp_id,
                  },
                }}
                className="text-dark"
              >
                <i
                  className="mdi mdi-google-classroom font-size-18"
                  id="batchtooltip"
                />
                <UncontrolledTooltip placement="top" target="batchtooltip">
                  Batch
                </UncontrolledTooltip>
              </Link>

              {/* <Link
                to="#"
                className="text-dark"
                onClick={() => {
                  props.history.push(
                    "/training-program/view/" + cellProps.row.original.tp_id
                  )
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link> */}
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  props.history.push(
                    "/training-program/edit/" + cellProps.row.original.tp_id
                  )
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const programData = cellProps.row.original
                  onClickDelete(programData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
      {
        Header: "Participants",
        accessor: "",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={{
                  pathname: `/training-participants/attendees`,
                  state: {
                    tp_id: cellProps.row.original.tp_id,
                    tps_trainingStatus: 1,
                  },
                }}
                className="text-dark"
              >
                <i
                  className="mdi mdi-account-multiple-plus font-size-18"
                  id="attendeestooltip"
                />

                <UncontrolledTooltip placement="top" target="attendeestooltip">
                  Attendees
                </UncontrolledTooltip>
              </Link>
              <Link
                to={{
                  pathname: `/training-participants/doc-uploaded`,
                  state: {
                    tp_id: cellProps.row.original.tp_id,
                    tps_trainingStatus: 3,
                  },
                }}
                className="text-dark"
              >
                <i
                  className="mdi mdi-file-upload font-size-18"
                  id="docuploadtooltip"
                />

                <UncontrolledTooltip placement="top" target="docuploadtooltip">
                  Document Uploaded
                </UncontrolledTooltip>
              </Link>
              <Link
                to={{
                  pathname: `/training-participants/exam-pass`,
                  state: {
                    tp_id: cellProps.row.original.tp_id,
                    tps_trainingStatus: 4,
                  },
                }}
                className="text-dark"
              >
                <i
                  className="bx bx-check-circle font-size-18 mt-1"
                  id="exampasstooltip"
                />
                {/* <i className="fa-sharp fa-solid fa-file-check" id="exampasstooltip"></i> */}
                <UncontrolledTooltip placement="top" target="exampasstooltip">
                  Exam Pass
                </UncontrolledTooltip>
              </Link>
              <Link
                to={{
                  pathname: `/training-participants/certified`,
                  state: {
                    tp_id: cellProps.row.original.tp_id,
                    tps_trainingStatus: 5,
                  },
                }}
                className="text-dark"
              >
                <i
                  className="bx bxs-badge-check font-size-18 mt-1"
                  id="certifiedtooltip"
                />

                <UncontrolledTooltip placement="top" target="certifiedtooltip">
                  Certified
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const getTrainingProgramData = async () => {
    const response = await getAllTrainingPrograms()
    return response.data.trainingPrograms
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTrainingProgram}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Training Program" breadcrumbItem="Program" /> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={trainingPrograms}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    addButtonLabel="Add Program"
                    handleAddButtonClick={handleAddButtonClick}
                    customPageSize={10}
                    className="custom-header-css"
                    canExportCsv={true}
                    canPrint={false}
                    dataFetchLoading={loading}
                    noDataMessage="No system activity found."
                    // CSV
                    csvFileName="trainingProgram.csv"
                    csvToDownload={getTrainingProgramData}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TrainingProgram
