import React from "react"
import { Link } from "react-router-dom"

const RoleId = cell => {
  return (
    <Link to="#" className="text-body fw-bold">
      {cell.value || ""}
    </Link>
  )
}

const PaymentMethod = cell => {
  let className = ""

  switch (cell.value) {
    case "Paypal":
      className = "fab fa-cc-paypal me-1"
      break
    case "COD":
      className = "fab fas fa-money-bill-alt me-1"
      break
    case "Mastercard":
      className = "fab fa-cc-mastercard me-1"
      break
    case "Visa":
      className = "fab fa-cc-visa me-1"
      break
    default:
      className = ""
  }

  return (
    <span>
      <i className={className} /> {cell.value}
    </span>
  )
}

export { RoleId, PaymentMethod }
