import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import ViewDetails from "./ViewDetails"
import Select from "react-select"
import { getUserTypeInfo } from "helpers/authHelper"
import { SaveToast } from "components/Common/SaveToast"
import { IMAGE_URL } from "helpers/urlHelper"

import SubmitLoader from "components/Common/SubmitLoader"
import {
  createTrainingExam,
  getTrainingExam,
  updateTrainingExam,
} from "helpers/backendHelpers/trainingExam"

import {
  getAllMainCategories,
  getAllCategories,
  getAllSubCategories,
  getAllTopics,
  getSubCategories,
} from "helpers/backendHelpers/category"

const AddEditAssigment = props => {
  const [isEdit, setIsEdit] = useState(false)
  const [isView, setIsView] = useState(false)
  const [mainCategoryValues, setMainCategoryValues] = useState([])
  const [categoryValues, setCategoryValues] = useState([])
  const [subCategoryValues, setSubCategoryValues] = useState([])
  const [topicValues, setTopicValues] = useState([])
  const [traininingExamId, setTrainingExamId] = useState(0)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [selectedRadio, setSelectedRadio] = useState(true)
  const [isDateRange, setIsDateRange] = useState(false)
  const [temp, setTemp] = useState(false)

  const [form, setForm] = useState({
    te_title: "",
    te_totalMarks: "",
    te_questionSetType: "dynamic",
    te_passingMarks: "",
    te_duration: "",
    te_markPerQue: "",
    te_category: "",
    te_subCategory: "",
    te_topic: "",
    te_desc: "",
    ans_image: { fileName: "", file: {} },
    te_isDateRange: false,
    te_startDate: "",
    te_endDate: "",
  })

  useEffect(() => {}, [])

  useEffect(() => {
    document.getElementById("training-exam").classList.add("mm-active")

    let { type, id } = props.match.params || {}
    fetchMainCategoryDropDownValues()
    fetchCategoryDropDownValues()
    fetchSubCategoryDropDownValues()
    fetchTopicDropDownValues()
    document.getElementById("type-false").checked = true
    switch (type) {
      case "edit":
        setIsEdit(true)
        setIsView(false)
        setTrainingExamId(parseInt(id))
        break
      case "view":
        setIsView(true)
        setIsEdit(false)
        setTrainingExamId(parseInt(id))
        break
      case "add":
        break
      default:
        setIsView(false)
        setIsEdit(false)
        setTrainingExamId(parseInt(id))
        break
    }

    if (id) {
      fetchTrainingExamDetailsForEdit(id)
    }
  }, [isEdit])

  const fetchMainCategoryDropDownValues = async () => {
    try {
      let response = await getAllMainCategories()
      let { mainCategories } = response.data || {}
      mainCategories = mainCategories || []
      let mainCategoryVals = mainCategories.map(mainCategory => {
        return {
          id: mainCategory.cc_id,
          value: mainCategory.cc_categoryName,
        }
      })
      setMainCategoryValues(mainCategoryVals)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching mainCategories"

      return SaveToast({ message, type: "error" })
    }
  }

  const fetchCategoryDropDownValues = async () => {
    try {
      let response = await getAllCategories()
      let { categories } = response.data || {}
      categories = categories || []
      let allCategory = []
      categories.map(category => {
        let item = category.category.map(data => {
          return {
            id: data.CategoryId,
            value: data.CategoryName,
          }
        })
        allCategory.push(...item)
      })
      setCategoryValues(allCategory)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching mainCategories"

      return SaveToast({ message, type: "error" })
    }
  }

  const fetchSubCategoryDropDownValues = async () => {
    try {
      let response = await getAllSubCategories()
      let { subCategories } = response.data || {}
      subCategories = subCategories || []
      let allSubCategory = []
      subCategories.map(subCategory => {
        let itemArr = []
        subCategory.category.map(data => {
          let item2 = data.subCategory.map(subData => {
            return {
              id: subData.subCateId,
              value: subData.subCateName,
            }
          })
          itemArr.push(...item2)
        })

        allSubCategory.push(...itemArr)
      })
      setSubCategoryValues(allSubCategory)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching mainCategories"

      return SaveToast({ message, type: "error" })
    }
  }

  const fetchTopicDropDownValues = async () => {
    try {
      let response = await getAllTopics()
      let { topics } = response.data || {}
      topics = topics || []
      let allTopicsData = []
      topics.map(topicItem => {
        let itemArr = []
        topicItem.category.map(data => {
          data.subCategory.map(subData => {
            let item2 = subData.topics.map(topicData => {
              return {
                id: topicData.topicId,
                value: topicData.topicName,
              }
            })
            itemArr.push(...item2)
          })
        })

        allTopicsData.push(...itemArr)
      })
      setTopicValues(allTopicsData)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching topics"

      return SaveToast({ message, type: "error" })
    }
  }

  const fetchTrainingExamDetailsForEdit = async ls_id => {
    try {
      let response = await getTrainingExam(ls_id)
      let { trainingExam } = response.data || {}
      trainingExam = trainingExam || {}
      // trainingExam["ans_image_old"] = trainingExam["ans_image"]
      // trainingExam["ans_image"] = { fileName: "", file: {} }
      if (trainingExam.te_isDateRange === true) {
        document.getElementById("type-true").checked = true
        setIsDateRange(true)
      } else if (trainingExam.te_isDateRange === false) {
        document.getElementById("type-false").checked = true
        setIsDateRange(false)
      }

      getAllSubCategoriesFromApi(trainingExam?.te_category)
      getAllTopicsFromApi(trainingExam?.te_subCategory)

      return setForm(trainingExam)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching freelance teacher details"
      setForm(form)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddAssigmentSubmit = async data => {
    try {
      setSubmitLoading(true)
      const response = await createTrainingExam(data)
      let message = response?.message || "Training Exam Added Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)

      props.history.push("/training-exam")
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Adding Training Exam"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleEditAssigmentSubmit = async (id, data) => {
    if (!id) {
      return SaveToast({
        message: "Please enter Training Exam Id",
        type: "error",
      })
    }
    try {
      setSubmitLoading(true)
      const response = await updateTrainingExam(id, data)
      let message = response?.message || "Training Exam Updated Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)

      props.history.push("/training-exam")
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Adding Training Exam"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const getAllSubCategoriesFromApi = async (filterCategory = "") => {
    try {
      let response = await getSubCategories()

      let { subCategories } = response.data
      let vals = []
      if (filterCategory) {
        subCategories.map(mainCategory => {
          let { category } = mainCategory
          category.map(Category => {
            let { subCategory } = Category
            if (Category.categoryName === filterCategory) {
              subCategory.map(cat => {
                vals.push({ name: cat.subCateName, value: cat.subCateName })
              })
            }
          })
        })
      } else {
        subCategories.map(mainCategory => {
          let { category } = mainCategory
          category.map(Category => {
            let { subCategory } = Category
            subCategory.map(cat => {
              vals.push({ name: cat.subCateName, value: cat.subCateName })
            })
          })
        })
        let tempVals = []
        vals.length > 0 &&
          vals.map((data, i) => {
            if (i < 4) {
              tempVals.push(data.value)
            }
          })
      }
      setSubCategoryValues(vals)
    } catch (error) {
      setError(error)
    }
  }

  const getAllTopicsFromApi = async (filterCategory = "") => {
    try {
      let response = await getAllTopics()
      let { topics } = response.data
      let vals = []
      if (filterCategory) {
        topics.map(mainCategory => {
          let { category } = mainCategory
          category.map(Category => {
            let { subCategory } = Category
            subCategory.map(subCat => {
              let { topics } = subCat
              if (subCat.subCateName === filterCategory) {
                topics.map(topic => {
                  vals.push({ name: topic.topicName, value: topic.topicName })
                })
              }
            })
          })
        })
      } else {
        topics.map(mainCategory => {
          let { category } = mainCategory
          category.map(Category => {
            let { subCategory } = Category
            subCategory.map(subCat => {
              let { topics } = subCat
              topics.map(topic => {
                vals.push({ name: topic.topicName, value: topic.topicName })
              })
            })
          })
        })
      }
      setTopicValues(vals)
    } catch (error) {
      setError(error)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <div
          className="container-fluid"
          style={submitLoading ? { position: "relative", opacity: "0.8" } : {}}
        >
          {submitLoading ? <SubmitLoader /> : <></>}
          {isView ? (
            <>
              <ViewDetails {...props} />
            </>
          ) : (
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={form}
                  validationSchema={Yup.object({
                    // te_endDate: Yup.string()
                    //   .required("Please Select Date")
                    //   .nullable(),
                    // te_startDate: Yup.string()
                    //   .required("Please Select Date")
                    //   .nullable(),
                    // te_desc: Yup.string().required(
                    //   "Please Enter Live Session Description"
                    // ),
                    te_category: Yup.string().required(
                      "Please Select Class/Grade"
                    ),
                    // asn_mainCategory: Yup.string().required(
                    //   "Please Main Category"
                    // ),
                    te_markPerQue: Yup.string().required(
                      "Please Marks Per Category"
                    ),
                    // te_questionSetType: Yup.string().required(
                    //   "Please Question Set Type Category"
                    // ),
                    te_subCategory: Yup.string().required(
                      "Please Select Subject"
                    ),
                    te_topic: Yup.string().required("Please Select Topic"),
                    te_title: Yup.string().required(
                      "Please Enter  Assigment Title"
                    ),
                    te_passingMarks: Yup.string().required(
                      "Please Enter  Pass Marks "
                    ),
                    // ans_image: Yup.mixed().nullable().notRequired(),
                    te_duration: Yup.string().required(
                      "Please Enter  Duration Title"
                    ),
                    te_desc: Yup.string().required("Please Enter  Description"),
                    te_totalMarks: Yup.string().required(
                      "Please Enter Total Marks"
                    ),
                  })}
                  onSubmit={values => {
                    let TrainingExam = values
                    if (isEdit) {
                      // TrainingExam["ans_image_old"] = form.ans_image_old
                      // TrainingExam["ans_image"] = form.ans_image.file
                      TrainingExam["te_isDateRange"] = isDateRange
                      TrainingExam["te_duration"] = TrainingExam["te_duration"]
                      TrainingExam["te_startDate"] = isDateRange
                        ? TrainingExam["te_startDate"]
                        : ""
                      TrainingExam["te_endDate"] = isDateRange
                        ? TrainingExam["te_endDate"]
                        : ""
                      return handleEditAssigmentSubmit(
                        traininingExamId,
                        TrainingExam
                      )
                    } else {
                      // TrainingExam["ans_image"] = form.ans_image.file
                      TrainingExam["te_isDateRange"] = isDateRange
                      TrainingExam["te_startDate"] = isDateRange
                        ? TrainingExam["te_startDate"]
                        : ""
                      TrainingExam["te_endDate"] = isDateRange
                        ? TrainingExam["te_endDate"]
                        : ""
                      return handleAddAssigmentSubmit(TrainingExam)
                    }
                  }}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleSubmit,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    setFieldTouched,
                  }) => (
                    <>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          handleSubmit(e)
                          return false
                        }}
                      >
                        <Row>
                          <Col>
                            <Row className="mb-3">
                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Title
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="te_title"
                                  type="text"
                                  placeholder="Enter Title"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={touched.te_title && errors.te_title}
                                  defaultValue={form.te_title}
                                />
                                {touched.te_title && errors.te_title && (
                                  <FormFeedback>{errors.te_title}</FormFeedback>
                                )}
                              </Col>
                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Total Marks
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="te_totalMarks"
                                  type="text"
                                  placeholder="Enter Toal Marks"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.te_totalMarks &&
                                    errors.te_totalMarks
                                  }
                                  defaultValue={form.te_totalMarks}
                                />
                                {touched.te_totalMarks &&
                                  errors.te_totalMarks && (
                                    <FormFeedback>
                                      {errors.te_totalMarks}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Marks Per Question
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="te_markPerQue"
                                  type="select"
                                  className="form-select"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.te_markPerQue || 0}
                                  invalid={
                                    touched.te_markPerQue &&
                                    errors.te_markPerQue
                                      ? true
                                      : false
                                  }
                                >
                                  <option value="0" disabled>
                                    Marks Per Question
                                  </option>
                                  <option value={1}>1</option>
                                  <option value={2}>2</option>
                                  <option value={3}>3</option>
                                  <option value={4}>4</option>
                                  <option value={5}>5</option>
                                  <option value={6}>6</option>
                                  <option value={7}>7</option>
                                  <option value={8}>8</option>
                                  <option value={9}>9</option>
                                  <option value={10}>10</option>
                                </Input>
                                {touched.te_markPerQue &&
                                errors.te_markPerQue ? (
                                  <FormFeedback type="invalid">
                                    {errors.te_markPerQue}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>

                            <Row className="mb-3">
                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Class/Grade
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="te_category"
                                  type="select"
                                  className="form-select"
                                  onChange={e => {
                                    handleChange(e)
                                    getAllSubCategoriesFromApi(e.target.value)
                                    values.te_subCategory = ""
                                    values.te_topic = ""
                                  }}
                                  onBlur={handleBlur}
                                  value={values.te_category || 0}
                                  invalid={
                                    touched.te_category && errors.te_category
                                      ? true
                                      : false
                                  }
                                >
                                  <option value="0" disabled>
                                    Select Class/Grade
                                  </option>
                                  {categoryValues.map(val => {
                                    return (
                                      <option key={val.id} value={val.value}>
                                        {val.value}
                                      </option>
                                    )
                                  })}
                                </Input>
                                {touched.te_category && errors.te_category ? (
                                  <FormFeedback type="invalid">
                                    {errors.te_category}
                                  </FormFeedback>
                                ) : null}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Subject
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="te_subCategory"
                                  type="select"
                                  className="form-select"
                                  onChange={e => {
                                    handleChange(e)
                                    getAllTopicsFromApi(e.target.value)
                                    values.te_topic = ""
                                  }}
                                  onBlur={handleBlur}
                                  value={values.te_subCategory || 0}
                                  invalid={
                                    touched.te_subCategory &&
                                    errors.te_subCategory
                                      ? true
                                      : false
                                  }
                                >
                                  <option value="0" disabled>
                                    Select Subject
                                  </option>
                                  {subCategoryValues.map(val => {
                                    return (
                                      <option key={val.id} value={val.value}>
                                        {val.value}
                                      </option>
                                    )
                                  })}
                                </Input>
                                {touched.te_subCategory &&
                                errors.te_subCategory ? (
                                  <FormFeedback type="invalid">
                                    {errors.te_subCategory}
                                  </FormFeedback>
                                ) : null}
                              </Col>

                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">Topics</Label>
                                <span className="text-danger">*</span>

                                <Input
                                  name="te_topic"
                                  type="select"
                                  className="form-select"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.te_topic || 0}
                                  invalid={
                                    touched.te_topic && errors.te_topic
                                      ? true
                                      : false
                                  }
                                >
                                  <option value="0" disabled>
                                    Select Topic
                                  </option>
                                  {topicValues.map(val => {
                                    return (
                                      <option key={val.id} value={val.value}>
                                        {val.value}
                                      </option>
                                    )
                                  })}
                                </Input>
                                {touched.te_topic && errors.te_topic ? (
                                  <FormFeedback type="invalid">
                                    {errors.te_topic}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>

                            <Row className="mb-3">
                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Duration (In Minutes)
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="te_duration"
                                  type="text"
                                  placeholder="Enter Duration"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.te_duration && errors.te_duration
                                  }
                                  defaultValue={form.te_duration}
                                />
                                {touched.te_duration && errors.te_duration && (
                                  <FormFeedback>
                                    {errors.te_duration}
                                  </FormFeedback>
                                )}
                              </Col>
                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-sm-0 mb-3"
                              >
                                <Label className="form-label">
                                  Question Set Type
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="te_questionSetType"
                                  type="select"
                                  className="form-select"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.te_questionSetType || "dynamic"}
                                  // defaultValue={form.te_questionSetType}
                                  invalid={
                                    touched.te_questionSetType &&
                                    errors.te_questionSetType
                                      ? true
                                      : false
                                  }
                                >
                                  <option value="0" disabled>
                                    Question Set Type
                                  </option>
                                  <option value="fixed">Fixed Set</option>
                                  <option value="dynamic">Dynamic Set</option>
                                </Input>
                                {touched.te_questionSetType &&
                                errors.te_questionSetType ? (
                                  <FormFeedback type="invalid">
                                    {errors.te_questionSetType}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Passing Marks
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="te_passingMarks"
                                  type="number"
                                  placeholder="Enter Passing Marks"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.te_passingMarks &&
                                    errors.te_passingMarks
                                  }
                                  defaultValue={form.te_passingMarks}
                                />
                                {touched.te_passingMarks &&
                                  errors.te_passingMarks && (
                                    <FormFeedback>
                                      {errors.te_passingMarks}
                                    </FormFeedback>
                                  )}
                              </Col>
                            </Row>

                            <Row className="mb-3">
                              <Col sm={12} md={6}>
                                <Row>
                                  <Col>
                                    <Label className="form-label">
                                      Description
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="te_desc"
                                      type="textarea"
                                      rows={6}
                                      placeholder="Enter Description"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      invalid={
                                        touched.te_desc && errors.te_desc
                                      }
                                      defaultValue={form.te_desc}
                                    />
                                    {touched.te_desc && errors.te_desc && (
                                      <FormFeedback>
                                        {errors.te_desc}
                                      </FormFeedback>
                                    )}
                                  </Col>
                                </Row>
                              </Col>
                              {/* <Col sm={12} md={6} className="mt-2 mt-md-0">
                                <Row>
                                  <Col>
                                    <Label className="form-label">
                                      Image
                                      {isEdit && form?.ans_image_old && (
                                        <>
                                          <span className="ms-2">
                                            (
                                            <a
                                              href={`${IMAGE_URL}/${form?.ans_image_old || ""
                                                }`}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              Saved Image
                                            </a>
                                            )
                                          </span>
                                        </>
                                      )}
                                    </Label>
                                    <Input
                                      name="ans_image_old"
                                      type="file"
                                      accept=".png, .jpg, .jpeg, .gif"
                                      placeholder="Select Profile Pic"
                                      onChange={e => {
                                        let tempForm = form
                                        tempForm["ans_image"]["fileName"] =
                                          e.target.value
                                        tempForm["ans_image"]["file"] =
                                          e.target.files[0]
                                        setForm(tempForm)
                                      }}
                                      // // onBlur={handleBlur}
                                      invalid={
                                        touched.ans_image && errors.ans_image
                                      }
                                      defaultValue={form.ans_image.fileName}
                                    />
                                    {touched.ans_image && errors.ans_image && (
                                      <FormFeedback>
                                        {errors.ans_image}
                                      </FormFeedback>
                                    )}
                                  </Col>
                                </Row>
                              </Col> */}
                            </Row>

                            <Row className="mb-3">
                              <Col
                                md={4}
                                sm={6}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label>Is Date Range</Label>
                                <br />
                                <Label className="form-label">True</Label>
                                &nbsp;&nbsp;
                                <Input
                                  id="type-true"
                                  name="te_isDateRange"
                                  type="radio"
                                  placeholder="Enter Title"
                                  onChange={event => {
                                    if (event.target.checked) {
                                      let temp = form
                                      temp["te_isDateRange"] = true
                                      values["te_isDateRange"] = true
                                      setIsDateRange(true)
                                      setForm(temp)
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.te_isDateRange &&
                                    errors.te_isDateRange
                                  }
                                  value={form.te_isDateRange}
                                />
                                <br />
                                <Label htmlFor="false" className="form-label">
                                  False
                                </Label>
                                &nbsp;&nbsp;
                                <Input
                                  id="type-false"
                                  name="te_isDateRange"
                                  type="radio"
                                  // value={false}
                                  placeholder="Enter Title"
                                  onClick={event => {
                                    if (event.target.checked) {
                                      let temp1 = form
                                      temp1["te_isDateRange"] = false
                                      values["te_isDateRange"] = false
                                      setIsDateRange(false)
                                      setForm(temp1)
                                      setTemp(!temp)
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  invalid={
                                    touched.te_isDateRange &&
                                    errors.te_isDateRange
                                  }
                                  value={form.te_isDateRange}
                                  // defaultChecked
                                />
                                {touched.te_isDateRange &&
                                  errors.te_isDateRange && (
                                    <FormFeedback>
                                      {errors.te_isDateRange}
                                    </FormFeedback>
                                  )}
                              </Col>
                              {isDateRange && (
                                <>
                                  <Col
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    className="mt-2 mt-md-0 mb-3"
                                  >
                                    <Label
                                      className="form-label"
                                      disabled={selectedRadio == "false"}
                                    >
                                      Start Date
                                    </Label>
                                    <Input
                                      name="te_startDate"
                                      type="date"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      // max={new Date().toJSON().slice(0, 10)}
                                      invalid={
                                        touched.te_startDate &&
                                        errors.te_startDate
                                      }
                                      value={values.te_startDate}
                                    />
                                    {touched.te_startDate &&
                                      errors.te_startDate && (
                                        <FormFeedback>
                                          {errors.te_startDate}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                  <Col
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    className="mt-2 mt-md-0 mb-3"
                                  >
                                    <Label className="form-label">
                                      End Date
                                    </Label>
                                    <Input
                                      name="te_endDate"
                                      type="date"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      min={new Date(values?.te_startDate)
                                        ?.toJSON()
                                        ?.slice(0, 10)}
                                      invalid={
                                        touched.te_endDate && errors.te_endDate
                                      }
                                      value={values.te_endDate}
                                    />
                                    {touched.te_endDate &&
                                      errors.te_endDate && (
                                        <FormFeedback>
                                          {errors.te_endDate}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </>
                              )}
                            </Row>

                            <Row className="mb-3 text-center">
                              <Col>
                                <Button
                                  size="md"
                                  color="danger"
                                  type="button"
                                  disabled={submitLoading}
                                  className="mx-2"
                                  onClick={() => {
                                    props.history.push("/Assignment")
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  size="md"
                                  color="info"
                                  type="submit"
                                  disabled={submitLoading}
                                >
                                  {!isEdit ? "Save" : "Update"}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </Formik>
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

AddEditAssigment.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default AddEditAssigment
