import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Row,
  Card,
  CardImg,
  CardBody,
  CardText,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import Notifications from "./Notifications"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import { getContentTeamMember } from "helpers/backendHelpers/contentTeam"
import { IMAGE_URL } from "helpers/urlHelper"
import { SaveToast } from "components/Common/SaveToast"

const ViewDetails = props => {
  const [memberId, setMemberId] = useState(0)
  const [teamMemberData, setMemberData] = useState(props.teamMemberData || {})
  const [activeAreaTab, setActiveAreaTab] = useState(1)
  const [isEffects, setisEffects] = useState(false)

  useEffect(() => {
    fetchTeamMemberForView()
  }, [])

  const fetchTeamMemberForView = async () => {
    let { id } = props.match.params || {}
    setMemberId(parseInt(id))

    if (id) {
      let teamMemberData = {}
      try {
        const response = await getContentTeamMember(id)
        teamMemberData = response.data.contentTeamMember
        setMemberData(teamMemberData)
      } catch (error) {
        let message =
          error?.message + "catch" || "There Was A Problem Fetching Team"
        return SaveToast({ message, type: "error" })
      }
    }
  }
  return (
    <React.Fragment>
      {isEffects ? (
        <Lightbox
          mainSrc={`${IMAGE_URL}/${teamMemberData?.ct_degreeCertificate}`}
          enableZoom={false}
          onCloseRequest={() => {
            setisEffects(!isEffects)
          }}
        />
      ) : null}
      <Row className="mt-3">
        <Col>
          <span className="text-info" style={{ fontSize: "2rem" }}>
            {teamMemberData.ct_fullName}
          </span>
        </Col>
        <Col className="text-end">
          <Button
            color="info"
            onClick={() => {
              props.history.push("/content-team")
            }}
          >
            Back
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} className="mb-3">
          <Card>
            <Row className="no-gutters align-items-center">
              <Col md={3}>
                <CardImg
                  className="img-fluid"
                  style={{ objectFit: "cover", maxHeight: "200px" }}
                  src={`${IMAGE_URL}/${teamMemberData?.ct_profilePic}`}
                  alt="Skote"
                />
              </Col>
              <Col md={9}>
                <CardBody>
                  <Row>
                    <Col md={4}>
                      <CardText>
                        Status:{" "}
                        <i
                          className={`mdi mdi-circle text-${
                            teamMemberData.ct_status ? "success" : "danger"
                          } align-middle me-1`}
                        />
                        {teamMemberData.ct_status ? "Active" : "Inactive"}
                      </CardText>
                      <CardText>{teamMemberData.ct_phoneNumber}</CardText>
                      <CardText>{teamMemberData.ct_email}</CardText>
                    </Col>
                    <Col md={4}>
                      <CardText>
                        Education: {teamMemberData.ct_education}
                      </CardText>
                      <CardText>{teamMemberData.ct_altPhoneNumber}</CardText>
                      <CardText>{teamMemberData.ct_address}</CardText>
                    </Col>
                    <Col md={4}>
                      <img
                        src={`${IMAGE_URL}/${teamMemberData?.ct_degreeCertificate}`}
                        alt="Degree_Certificate"
                        onClick={() => {
                          setisEffects(true)
                        }}
                        className="avatar-lg img-thumbnail"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Nav tabs>
        <NavItem>
          <NavLink
            className={activeAreaTab === 1 && "active"}
            onClick={() => {
              setActiveAreaTab(1)
            }}
          >
            Notifications
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeAreaTab}>
        <TabPane tabId={1}>
          <Row className="mt-3">
            <Col>
              <Card>
                <CardBody>
                  <Notifications />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </React.Fragment>
  )
}

ViewDetails.propTypes = {
  teamMemberData: PropTypes.object.isRequired,
}

export default ViewDetails
