import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

// import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import GESOfficeStaffModal from "./ViewModal"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
} from "reactstrap"

import { officeLevel } from "../../common/data/dropdownVals"
import {
  defaultRDCSeparator,
  SimpleStringValue,
} from "helpers/common_helper_functions"
import { SaveToast } from "components/Common/SaveToast"

import Select from "react-select"

import RegionDistrictCircuitAllSelectable from "common/data/Region-District-Circuit-All-Selectable"

import {
  createGESOfficeStaff,
  updateGESOfficeStaff,
  getAllGESOfficeStaff,
  getGESOfficeStaff,
  deleteGESOfficeStaff,
} from "helpers/backendHelpers/GESOfficeStaff"
import SubmitLoader from "components/Common/SubmitLoader"

const GESOfficeStaff = props => {
  //meta title
  document.title = "GES OfficeStaff | GES"

  const [modal, setModal] = useState(false)
  const [modal1, setModal1] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [save, setSave] = useState(false)

  const [gesOfficeStaffMember, setGESOfficeStaff] = useState({})
  const [gesOfficeStaffMembers, setGESOfficeStaffs] = useState([])

  const [deleteModal, setDeleteModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [submitLoading, setsubmitLoading] = useState(false)

  useEffect(() => {
    document.getElementById("ges-offices").classList.add("mm-active")
    getAllGESOfficeStaffMember()
  }, [save])

  const getAllGESOfficeStaffMember = async () => {
    try {
      let { id } = props.match.params || {}
      setLoading(true)
      let response = await getAllGESOfficeStaff(id)
      let { gesOfficeStaffs } = response.data || {}
      gesOfficeStaffs = gesOfficeStaffs || []
      setGESOfficeStaffs(gesOfficeStaffs)
      setLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching ges staff members"
      setGESOfficeStaffs([])
      setLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddGESStaffMemberSubmit = async data => {
    try {
      setsubmitLoading(true)
      let response = await createGESOfficeStaff(data)
      let message = response?.message || "GES Staff Member Created Successfully"
      SaveToast({ message, type: "success" })
      validation.resetForm()
      toggle()
      setSave(prevSave => !prevSave)
      setsubmitLoading(false)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem creating ges staff member"
      setsubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleEditGESStaffMemberSubmit = async (id, data) => {
    if (!id) {
      return SaveToast({
        message: "Please enter ges Staff member id",
        type: "error",
      })
    }

    try {
      setsubmitLoading(true)
      let response = await updateGESOfficeStaff(id, data)
      let message = response?.message || "GES Staff Member Updated Successfully"
      SaveToast({ message, type: "success" })

      validation.resetForm()
      toggle()
      setSave(prevSave => !prevSave)
      setsubmitLoading(false)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem creating ges staff member"
      setsubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleUpdateGESStaffMemberStatus = async (gs_id, gs_status) => {
    if (!gs_id) {
      return SaveToast({
        message: "Please enter ges staff member id",
        type: "error",
      })
    }

    try {
      let response = await updateGESOfficeStaff(gs_id, { gs_status })
      let message =
        response?.message || "GES Staff Member Status Updated Successfully"
      SaveToast({ message, type: "success" })

      setSave(prevSave => !prevSave)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem updating ges member status"
      return SaveToast({ message, type: "error" })
    }
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      gs_staffRole:
        (gesOfficeStaffMember && gesOfficeStaffMember.gs_staffRole) || 0,
      gs_gesOfficeId:
        (gesOfficeStaffMember && gesOfficeStaffMember.gs_gesOfficeId) || 0,
      gs_staffId:
        (gesOfficeStaffMember && gesOfficeStaffMember.gs_staffId) || 0,
      gs_fullName:
        (gesOfficeStaffMember && gesOfficeStaffMember.gs_fullName) || "",
      gs_email: (gesOfficeStaffMember && gesOfficeStaffMember.gs_email) || "",
      gs_phoneNumber:
        (gesOfficeStaffMember && gesOfficeStaffMember.gs_phoneNumber) || "",
      gs_altPhoneNumber:
        (gesOfficeStaffMember && gesOfficeStaffMember.gs_altPhoneNumber) || "",
      gs_status:
        (gesOfficeStaffMember && gesOfficeStaffMember.gs_status) || true,
    },
    validationSchema: Yup.object({
      gs_staffRole: Yup.string()
        .oneOf(officeLevel, "Please Select Valid staffRole Type")
        .required("Please Select staffRole Type"),
      gs_fullName: Yup.string().required("Please Enter Full Name"),
      gs_email: Yup.string().email().required("Please Enter Email"),
      gs_phoneNumber: Yup.string()
        .required("Please Enter Phone Number")
        .matches(/^[0-9]{10}$/, "Please Enter Valid Phone Number"),
      gs_altPhoneNumber: Yup.string()
        .notRequired()
        .matches(/^[0-9]{10}$/, "Please Enter Valid Phone Number"),
      gs_staffId: Yup.number().required("Please Enter staffId"),
      gs_status: Yup.bool().required("Please Select Status"),
    }),
    onSubmit: values => {
      let { id } = props.match.params || {}
      let GESStaffMember = {
        gs_staffRole: values.gs_staffRole,
        gs_fullName: values.gs_fullName,
        gs_email: values.gs_email,
        gs_phoneNumber: values.gs_phoneNumber,
        gs_altPhoneNumber: values.gs_altPhoneNumber,
        gs_staffId: values.gs_staffId,
        gs_gesOfficeId: id,
        gs_status: values.gs_status,
      }

      GESStaffMember.gs_altPhoneNumber =
        GESStaffMember.gs_altPhoneNumber === ""
          ? null
          : GESStaffMember.gs_altPhoneNumber

      if (isEdit) {
        delete GESStaffMember["gs_id"]
        return handleEditGESStaffMemberSubmit(
          gesOfficeStaffMember.gs_id,
          GESStaffMember
        )
      } else {
        return handleAddGESStaffMemberSubmit(GESStaffMember)
      }
    },
  })

  const toggleViewModal = () => {
    if (modal1) {
      setGESOfficeStaff({})
    }
    setModal1(!modal1)
  }

  const toggle = () => {
    if (modal) {
      setGESOfficeStaff(null)
    }
    setModal(!modal)
  }
  const handlegesOfficeStaffMemberClick = async arg => {
    const staffMember = arg

    setGESOfficeStaff({
      gs_id: staffMember.gs_id,
      gs_staffRole: staffMember.gs_staffRole,
      gs_fullName: staffMember.gs_fullName,
      gs_phoneNumber: staffMember.gs_phoneNumber,
      gs_altPhoneNumber: staffMember.gs_altPhoneNumber,
      gs_email: staffMember.gs_email,
      gs_staffId: staffMember.gs_staffId,
      gs_status: staffMember.gs_status,
    })
    setIsEdit(true)
    toggle()
  }

  const onClickDelete = member => {
    setGESOfficeStaff(member)
    setDeleteModal(true)
  }

  const handleDeleteGESOfficeStaff = async () => {
    if (!gesOfficeStaffMember.gs_id) {
      return SaveToast({
        message: "Invalid GES Staff Member ID",
        type: "error",
      })
    }

    try {
      const response = await deleteGESOfficeStaff(gesOfficeStaffMember.gs_id)
      let message = response?.message || "GES Staff Member Deleted Successfully"
      SaveToast({ message, type: "success" })

      setSave(prevSave => !prevSave)
      setDeleteModal(false)
      setGESOfficeStaff({})
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem deleting ges staff member"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleStaffMemberClicks = () => {
    setIsEdit(false)
    toggle()
  }

  const handleDeleteModalCloseClick = () => {
    setDeleteModal(false)
    setGESOfficeStaff({})
  }

  const columns = useMemo(
    () => [
      {
        Header: "Role",
        accessor: "gs_staffRole",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Full Name",
        accessor: "gs_fullName",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },

      {
        Header: "Email",
        accessor: "gs_email",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Phone Number",
        accessor: "gs_phoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Alt Phone Number",
        accessor: "gs_altPhoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "gs_status",
        disableFilters: true,
        Cell: cellProps => {
          const cellData = cellProps.row.original
          return (
            <div className="form-check form-switch form-switch-md">
              <input
                type="checkbox"
                className="form-check-input bg-dark"
                id={`gs_status_checkbox-${cellData.gs_id}`}
                name={`gs_status_checkbox-${cellData.gs_id}`}
                defaultChecked={cellData.gs_status}
                onChange={e => {
                  let { checked, name } = e.target
                  document.getElementById(name).checked = checked
                  return handleUpdateGESStaffMemberStatus(
                    cellData.gs_id,
                    checked
                  )
                }}
              />
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-dark"
                onClick={() => {
                  setGESOfficeStaff(cellProps.row.original)
                  toggleViewModal()
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const GESOfficeData = cellProps.row.original
                  handlegesOfficeStaffMemberClick(GESOfficeData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const GESOfficeData = cellProps.row.original
                  onClickDelete(GESOfficeData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const printColumns = useMemo(
    () => [
      {
        Header: "Role",
        accessor: "gs_staffRole",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Full Name",
        accessor: "gs_fullName",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "gs_email",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Phone Number",
        accessor: "gs_phoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Alt Phone Number",
        accessor: "gs_altPhoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "gs_status",
        disableFilters: true,
        Cell: cellProps => {
          return cellProps?.row?.original?.status ? "Active" : "Inactive"
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <GESOfficeStaffModal
        isOpen={modal1}
        toggle={toggleViewModal}
        staffMember={gesOfficeStaffMember}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteGESOfficeStaff}
        onCloseClick={handleDeleteModalCloseClick}
      />
      <div className="page-content">
        <div className="container-fluid">
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={gesOfficeStaffMembers}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddButtonClick={handleStaffMemberClicks}
                    addButtonLabel={"Add OfficeStaff Member"}
                    customPageSize={10}
                    className="custom-header-css"
                    canExportCsv={true}
                    dataFetchLoading={loading}
                    noDataMessage="No system activity found."
                    canPrint={true}
                    printColumns={printColumns}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            toggle={toggle}
            style={submitLoading ? { position: "relative" } : {}}
          >
            {submitLoading ? <SubmitLoader /> : <></>}
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Staff Member" : "Add Staff Member"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">
                        Full Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="gs_fullName"
                        type="text"
                        placeholder="Enter Full Name"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.gs_fullName || ""}
                        invalid={
                          validation.touched.gs_fullName &&
                          validation.errors.gs_fullName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.gs_fullName &&
                      validation.errors.gs_fullName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.gs_fullName}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        Select Role Type <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="gs_staffRole"
                        type="select"
                        id="staffRole-type"
                        className="form-select"
                        placeholder="Select Staff Role"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.gs_staffRole || 0}
                        invalid={
                          validation.touched.gs_staffRole &&
                          validation.errors.gs_staffRole
                            ? true
                            : false
                        }
                      >
                        <option disabled value={0}>
                          Select Role Type
                        </option>
                        {officeLevel.map(val => {
                          return (
                            <option key={val} value={val}>
                              {val}
                            </option>
                          )
                        })}
                      </Input>
                      {validation.touched.gs_staffRole &&
                      validation.errors.gs_staffRole ? (
                        <FormFeedback type="invalid">
                          {validation.errors.gs_staffRole}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        Email <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="gs_email"
                        type="email"
                        placeholder="Enter Email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.gs_email || ""}
                        invalid={
                          validation.touched.gs_email &&
                          validation.errors.gs_email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.gs_email &&
                      validation.errors.gs_email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.gs_email}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        Staff Id <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="gs_staffId"
                        type="string"
                        placeholder="Enter Staff Id"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.gs_staffId &&
                          validation.errors.gs_staffId
                        }
                        value={validation.values.gs_staffId || ""}
                      />
                      {validation.touched.gs_staffId &&
                        validation.errors.gs_staffId && (
                          <FormFeedback type="invalid">
                            {validation.errors.gs_staffId}
                          </FormFeedback>
                        )}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        Phone Number <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="gs_phoneNumber"
                        type="text"
                        placeholder="Enter PhoneNumber"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.gs_phoneNumber || ""}
                        invalid={
                          validation.touched.gs_phoneNumber &&
                          validation.errors.gs_phoneNumber
                            ? true
                            : false
                        }
                      />
                      {validation.touched.gs_phoneNumber &&
                      validation.errors.gs_phoneNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.gs_phoneNumber}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Alt. Phone Number</Label>
                      <Input
                        name="gs_altPhoneNumber"
                        type="text"
                        placeholder="Enter altPhoneNumber"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.gs_altPhoneNumber || ""}
                        invalid={
                          validation.touched.gs_altPhoneNumber &&
                          validation.errors.gs_altPhoneNumber
                            ? true
                            : false
                        }
                      />
                      {validation.touched.gs_altPhoneNumber &&
                      validation.errors.gs_altPhoneNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.gs_altPhoneNumber}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                        disabled={submitLoading}
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}
GESOfficeStaff.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default GESOfficeStaff
