import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { isEmpty } from "lodash"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"

//import components
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import { SaveToast } from "components/Common/SaveToast"
import { RoleId } from "./Column"
import { teamMemberRole } from "common/data/dropdownVals"

//redux
import TeamMemberModal from "./ViewModal"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Spinner,
} from "reactstrap"

import { SimpleStringValue } from "helpers/common_helper_functions"
import {
  createTeamMember,
  deleteTeamMember,
  getAllTeamMember,
  updateTeamMember,
} from "helpers/backendHelpers/katonTeam"
import SubmitLoader from "components/Common/SubmitLoader"

function KATonTeam() {
  //meta title
  document.title = "KATon Team | LMS Ghana"

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [saved, setSaved] = useState(false)

  const [teamMembers, setTeamMembers] = useState([])
  const [teamMember, setTeamMember] = useState({})

  const [viewModal, setViewModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const [loading, setLoading] = useState(false)
  const [submitLoading, setsubmitLoading] = useState(false)

  useEffect(() => {
    fetchKatonTeamMembers()
  }, [saved])

  const fetchKatonTeamMembers = async () => {
    try {
      setLoading(true)
      let response = await getAllTeamMember()
      let { teamMembers } = response.data || {}
      teamMembers = teamMembers || []
      setTeamMembers(teamMembers)
      setLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem fetching team members"
      setTeamMembers([])
      setLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddTeamMemberSubmit = async data => {
    try {
      setsubmitLoading(true)
      let response = await createTeamMember(data)
      let message = response?.message || "Team Member Created Successfully"
      SaveToast({ message, type: "success" })
      validation.resetForm()
      toggle()
      setSaved(prevSaved => !prevSaved)
      setsubmitLoading(false)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem creating team member"
      setsubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleEditTeamMemberSubmit = async (id, data) => {
    if (!id) {
      return SaveToast({
        message: "Please enter team member id",
        type: "error",
      })
    }

    try {
      setsubmitLoading(true)
      let response = await updateTeamMember(id, data)
      let message = response?.message || "Team Member Updated Successfully"
      SaveToast({ message, type: "success" })

      validation.resetForm()
      toggle()
      setSaved(prevSaved => !prevSaved)
      setsubmitLoading(false)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem creating team member"
      setsubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleUpdateTeamMemberStatus = async (at_id, at_status) => {
    if (!at_id) {
      return SaveToast({
        message: "Please enter team member id",
        type: "error",
      })
    }

    try {
      let response = await updateTeamMember(at_id, { at_status })
      let message =
        response?.message || "Team Member Status Updated Successfully"
      SaveToast({ message, type: "success" })

      setSaved(prevSaved => !prevSaved)
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem updating team member status"
      return SaveToast({ message, type: "error" })
    }
  }

  const passwordValidation = !isEdit
    ? Yup.string().min(6).required("Please Enter password")
    : Yup.string().min(6).optional("Please Enter password")

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      at_role: (teamMember && teamMember.at_role) || "",
      at_fullName: (teamMember && teamMember.at_fullName) || "",
      at_email: (teamMember && teamMember.at_email) || "",
      at_phoneNumber: (teamMember && teamMember.at_phoneNumber) || "",
      at_altPhoneNumber: (teamMember && teamMember.at_altPhoneNumber) || "",
      at_password: "",
    },
    validationSchema: Yup.object({
      at_role: Yup.string().required("Please Select Your Role"),
      at_fullName: Yup.string().required("Please Enter Your Full Name"),
      at_email: Yup.string().email().required("Please Enter Your Email"),
      at_phoneNumber: Yup.string().required("Please Enter Your Phone Number"),
      at_password: passwordValidation,
      at_altPhoneNumber: Yup.string()
        .nullable()
        .matches(new RegExp(/^[0-9]{10}$/), "Please Enter Valid Phone Number"),
    }),
    onSubmit: async values => {
      let teamMemberData = {
        at_role: values.at_role,
        at_fullName: values.at_fullName,
        at_email: values.at_email,
        at_phoneNumber: values.at_phoneNumber,
        at_altPhoneNumber: values.at_altPhoneNumber,
        at_password: values.at_password,
      }

      teamMemberData.at_altPhoneNumber =
        teamMemberData.at_altPhoneNumber === ""
          ? null
          : teamMemberData.at_altPhoneNumber
      teamMemberData.at_password =
        teamMemberData.at_password === "" ? null : teamMemberData.at_password
      if (isEdit) {
        if (!teamMemberData.at_password) {
          delete teamMemberData.at_password
        }
        return handleEditTeamMemberSubmit(teamMember?.at_id, teamMemberData)
      } else {
        return handleAddTeamMemberSubmit(teamMemberData)
      }
    },
  })

  const toggleViewModal = () => {
    if (viewModal) {
      setTeamMember({})
    }
    setViewModal(!viewModal)
  }

  const toggle = () => {
    if (modal) {
      setTeamMember({})
    }
    setModal(!modal)
  }

  const handleTeamMemberClick = arg => {
    const member = arg
    setTeamMember({
      at_id: member.at_id,
      at_role: member.at_role,
      at_fullName: member.at_fullName,
      at_phoneNumber: member.at_phoneNumber,
      at_altPhoneNumber: member.at_altPhoneNumber,
      at_email: member.at_email,
    })

    setIsEdit(true)
    toggle()
  }

  const onClickDelete = member => {
    setTeamMember(member)
    setDeleteModal(true)
  }

  const handleDeleteTeam = async () => {
    if (!teamMember.at_id) {
      return SaveToast({ message: "Invalid Team Member ID", type: "error" })
    }

    try {
      const response = await deleteTeamMember(teamMember.at_id)
      let message = response?.message || "Team Member Deleted Successfully"
      SaveToast({ message, type: "success" })

      setSaved(prevSave => !prevSave)
      setDeleteModal(false)
      setTeamMember({})
      return
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There was a problem deleting team member"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddButtonClick = () => {
    setIsEdit(false)
    toggle()
  }

  const handleDeleteModalCloseClick = () => {
    setDeleteModal(false)
    setTeamMember({})
  }

  const columns = useMemo(
    () => [
      {
        Header: "Role",
        accessor: "at_role",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: cellProps => {
          return <RoleId {...cellProps} />
        },
      },
      {
        Header: "Full Name",
        accessor: "at_fullName",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "at_email",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Phone Number",
        accessor: "at_phoneNumber",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "at_status",
        disableFilters: true,
        Cell: cellProps => {
          const cellData = cellProps.row.original
          return (
            <div className="form-check form-switch form-switch-md">
              <input
                type="checkbox"
                className="form-check-input bg-dark"
                id={`at_status_checkbox-${cellData.at_id}`}
                name={`at_status_checkbox-${cellData.at_id}`}
                defaultChecked={cellData.at_status}
                onChange={e => {
                  let { checked, name } = e.target
                  document.getElementById(name).checked = checked
                  return handleUpdateTeamMemberStatus(cellData.at_id, checked)
                }}
              />
            </div>
          )
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-dark"
                onClick={() => {
                  setTeamMember(cellProps.row.original)
                  toggleViewModal()
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
                <UncontrolledTooltip placement="top" target="viewtooltip">
                  View
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const teamData = cellProps.row.original
                  handleTeamMemberClick(teamData)
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const teamData = cellProps.row.original
                  onClickDelete(teamData)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const printColumns = useMemo(
    () => [
      {
        Header: "Role",
        accessor: "at_role",
        filterable: false,
        Cell: cellProps => {
          // return <RoleId {...cellProps} />
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Full Name",
        accessor: "at_fullName",
        filterable: false,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "at_email",
        filterable: false,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Phone Number",
        accessor: "at_phoneNumber",
        filterable: false,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Alt Phone Number",
        accessor: "at_altPhoneNumber",
        filterable: false,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "at_status",
        disableFilters: true,
        Cell: cellProps => {
          return cellProps?.row?.original?.at_status ? "Active" : "Inactive"
        },
      },
    ],
    []
  )

  return (
    <React.Fragment>
      <TeamMemberModal
        isOpen={viewModal}
        toggle={toggleViewModal}
        member={teamMember}
      />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteTeam}
        onCloseClick={handleDeleteModalCloseClick}
      />
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="KATon" breadcrumbItem="Team" /> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={teamMembers}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddButtonClick={handleAddButtonClick}
                    addButtonLabel={"Add Team Member"}
                    customPageSize={10}
                    className="custom-header-css"
                    dataFetchLoading={loading}
                    // canExportCsv={true}
                    canPrint={true}
                    printColumns={printColumns}
                    noDataMessage="No system activity found."
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Modal
            isOpen={modal}
            style={submitLoading ? { position: "relative" } : {}}
            toggle={toggle}
          >
            {submitLoading ? <SubmitLoader /> : <></>}

            <ModalHeader toggle={toggle} tag="h4">
              {!!isEdit ? "Edit Member" : "Add Member"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  validation.handleSubmit()
                  return false
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label className="form-label">
                        Role <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="at_role"
                        type="select"
                        className="form-select"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.at_role || "0"}
                        invalid={
                          validation.touched.at_role &&
                          validation.errors.at_role
                            ? true
                            : false
                        }
                      >
                        <option value={0} disabled>
                          Select Role
                        </option>
                        {teamMemberRole.map(role => {
                          return (
                            <option value={role} key={role}>
                              {role}
                            </option>
                          )
                        })}
                      </Input>
                      {validation.touched.at_role &&
                      validation.errors.at_role ? (
                        <FormFeedback type="invalid">
                          {validation.errors.at_role}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        Full Name<span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="at_fullName"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.at_fullName || ""}
                        invalid={
                          validation.touched.at_fullName &&
                          validation.errors.at_fullName
                            ? true
                            : false
                        }
                      />
                      {validation.touched.at_fullName &&
                      validation.errors.at_fullName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.at_fullName}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        Email<span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="at_email"
                        type="email"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.at_email || ""}
                        invalid={
                          validation.touched.at_email &&
                          validation.errors.at_email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.at_email &&
                      validation.errors.at_email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.at_email}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        Password
                        {!isEdit && <span className="text-danger">*</span>}
                      </Label>
                      <Input
                        name="at_password"
                        type="password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.at_password || ""}
                        invalid={
                          validation.touched.at_password &&
                          validation.errors.at_password
                            ? true
                            : false
                        }
                      />
                      {validation.touched.at_password &&
                      validation.errors.at_password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.at_password}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">
                        Phone Number<span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="at_phoneNumber"
                        type="text"
                        validate={{
                          required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.at_phoneNumber || ""}
                        invalid={
                          validation.touched.at_phoneNumber &&
                          validation.errors.at_phoneNumber
                            ? true
                            : false
                        }
                      />
                      {validation.touched.at_phoneNumber &&
                      validation.errors.at_phoneNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.at_phoneNumber}
                        </FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label className="form-label">Alt. Phone Number</Label>
                      <Input
                        name="at_altPhoneNumber"
                        type="text"
                        validate={{
                          required: { value: false },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.at_altPhoneNumber || ""}
                        invalid={
                          validation.touched.at_altPhoneNumber &&
                          validation.errors.at_altPhoneNumber
                            ? true
                            : false
                        }
                      />
                      {validation.touched.at_altPhoneNumber &&
                      validation.errors.at_altPhoneNumber ? (
                        <FormFeedback type="invalid">
                          {validation.errors.at_altPhoneNumber}
                        </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                        disabled={submitLoading}
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  )
}

KATonTeam.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default KATonTeam
