import { getApiConfig } from "helpers/authHelper"
import { get, post, put, del } from "../apiHelper"
import * as url from "../urlHelper"

export const getAllParticipantsByTP = (
  id,
  isEdit,
  batchId = "",
  page,
  limit
) => {
  return get(
    `${url.TRAINING_PARTICIPANTS}/getParticipants/${id}?isEdit=${isEdit}&limit=${limit}&page=${page}&batchId=${batchId}`,
    getApiConfig()
  )
}

export const getAllParticipantsByTPWithStatus = (
  tps_tp_id,
  tps_trainingStatus
) => {
  return get(
    `${url.TRAINING_PARTICIPANTS}/getTrainingParticipantsByTPWithStatus/get?tps_tp_id=${tps_tp_id}&tps_trainingStatus=${tps_trainingStatus}`,
    getApiConfig()
  )
}

export const getAllParticipantsByStatusWithPag = (
  tps_tp_id,
  tps_trainingStatus,
  page,
  limit
) => {
  return get(
    `${url.TRAINING_PARTICIPANTS}/getTPByStatusWithPag/get?tps_tp_id=${tps_tp_id}&tps_trainingStatus=${tps_trainingStatus}&page=${page}&limit=${limit}`,
    getApiConfig()
  )
}

export const getStudent = id => {
  return get(`${url.STUDENT}/${id}`, getApiConfig())
}

export const createStudent = data => {
  return post(url.STUDENT, data, getApiConfig(true))
}

export const updateStudent = (id, data) => {
  return put(`${url.STUDENT}/${id}`, data, getApiConfig(true))
}

export const updateTrainingParticipantData = (id, data) => {
  return put(`${url.TRAINING_PARTICIPANTS}/${id}`, data, getApiConfig(true))
}

export const createCertificate = id => {
  return get(
    `${url.TRAINING_PARTICIPANTS}/certificate/create?tps_id=${id}`,
    getApiConfig(true)
  )
}

export const deleteStudent = id => {
  return del(`${url.STUDENT}/${id}`, getApiConfig())
}
