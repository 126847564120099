import { del, post } from "./apiHelper"
import { getApiConfig } from "./authHelper"
import * as url from "./urlHelper"

export const loginUser = reqBody => {
  return post(url.USER_LOGIN, reqBody, {})
}

export const logoutUser = () => {
  return del(url.USER_LOGOUT, getApiConfig())
}
