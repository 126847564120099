import { getBooksCount } from "helpers/backendHelpers/books"
import { getStudentsCount } from "helpers/backendHelpers/student"
import { getTeachersCount } from "helpers/backendHelpers/teacher"
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"

const DashboardCounts = () => {
  const [teachersCount, setTeachersCount] = useState(null)
  const [studentsCount, setStudentsCount] = useState(null)
  const [booksCount, setBooksCount] = useState(null)

  // Loading teachers count
  useEffect(() => {
    const loadTeachersCount = async () => {
      try {
        const data = await getTeachersCount()
        setTeachersCount(data.data.teacherDetail)
      } catch (error) {
        console.log(error)
      }
    }

    loadTeachersCount()
  }, [])

  // Loading students count
  useEffect(() => {
    const loadStudentsCount = async () => {
      const data = await getStudentsCount()
      setStudentsCount(data.data.studentDetail)
    }

    loadStudentsCount()
  }, [])

  // Loading books count
  useEffect(() => {
    const loadBooksCount = async () => {
      const data = await getBooksCount()
      setBooksCount(data.data.bookCountDetail)
    }

    loadBooksCount()
  }, [])

  return (
    <Row>
      <Col xl="12">
        <Row>
          <Col md="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Teachers Online</p>
                    <h4 className="mb-0">
                      {teachersCount ? teachersCount.online : "-"}
                    </h4>
                  </div>
                  <div className="avatar-sm rounded-circle bg-dark align-self-center mini-stat-icon">
                    <span className="avatar-title rounded-circle bg-dark">
                      <i className={"bx bx-user font-size-24"}></i>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Students Online</p>
                    <h4 className="mb-0">
                      {studentsCount ? studentsCount.online : "-"}
                    </h4>
                  </div>
                  <div className="avatar-sm rounded-circle bg-dark align-self-center mini-stat-icon">
                    <span className="avatar-title rounded-circle bg-dark">
                      <i className={"bx bx-user font-size-24"}></i>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">E-Books Download</p>
                    <h4 className="mb-0">
                      {booksCount ? booksCount.eBook.download : "-"}
                    </h4>
                  </div>
                  <div className="avatar-sm rounded-circle bg-dark align-self-center mini-stat-icon">
                    <span className="avatar-title rounded-circle bg-dark">
                      <i className={"bx bx-download font-size-24"}></i>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Videos Download</p>
                    <h4 className="mb-0">
                      {booksCount ? booksCount.video.download : "-"}
                    </h4>
                  </div>
                  <div className="avatar-sm rounded-circle bg-dark align-self-center mini-stat-icon">
                    <span className="avatar-title rounded-circle bg-dark">
                      <i className={"bx bx-download font-size-24"}></i>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Total Teachers</p>
                    <h4 className="mb-0">
                      {teachersCount ? teachersCount.total : "-"}
                    </h4>
                  </div>
                  <div className="avatar-sm rounded-circle bg-dark align-self-center mini-stat-icon">
                    <span className="avatar-title rounded-circle bg-dark">
                      <i className={"bx bx-user-plus font-size-24"}></i>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Total Students</p>
                    <h4 className="mb-0">
                      {studentsCount ? studentsCount.total : "-"}
                    </h4>
                  </div>
                  <div className="avatar-sm rounded-circle bg-dark align-self-center mini-stat-icon">
                    <span className="avatar-title rounded-circle bg-dark">
                      <i className={"bx bx-user-plus font-size-24"}></i>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Total E-Books</p>
                    <h4 className="mb-0">
                      {booksCount ? booksCount.eBook.total : "-"}
                    </h4>
                  </div>
                  <div className="avatar-sm rounded-circle bg-dark align-self-center mini-stat-icon">
                    <span className="avatar-title rounded-circle bg-dark">
                      <i className={"bx bx-book font-size-24"}></i>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md="3">
            <Card className="mini-stats-wid">
              <CardBody>
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <p className="text-muted fw-medium">Total Videos</p>
                    <h4 className="mb-0">
                      {booksCount ? booksCount.video.total : "-"}
                    </h4>
                  </div>
                  <div className="avatar-sm rounded-circle bg-dark align-self-center mini-stat-icon">
                    <span className="avatar-title rounded-circle bg-dark">
                      <i className={"bx bx-video font-size-24"}></i>
                    </span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default DashboardCounts
