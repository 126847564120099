import React, { useMemo, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"

//import components
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  SimpleStringValue,
  FreePaidValue,
  ArrayToStringValue,
} from "helpers/common_helper_functions"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
  Button,
} from "reactstrap"
import { SaveToast } from "components/Common/SaveToast"

import {
  getAllTrainingPrograms,
  deleteTrainingProgram,
} from "helpers/backendHelpers/trainingProgram"
import {
  getAllParticipantsByStatusWithPag,
  getAllParticipantsByTPWithStatus,
  updateTrainingParticipantData,
} from "helpers/backendHelpers/participants"
import PDFModal from "pages/ContentManagement/ViewModal"
import AttentionFormModal from "pages/TrainingParticipants/AttentionFormViewModal"
import ExamScoreUpdateFormModal from "./ExamScoreUpdateFormModal"
const TrainingProgram = props => {
  //meta title
  document.title = "Training Program | LMS Ghana"
  const tp_id = props.location?.state?.tp_id
  const tps_trainingStatus = props.location?.state?.tps_trainingStatus
  //delete order
  const [deleteModal, setDeleteModal] = useState(false)
  const [saved, setSaved] = useState(false)
  const [loading, setLoading] = useState(false)
  const [trainingPrograms, setTrainingPrograms] = useState([])
  const [trainingParticipantData, setTrainingParticipantData] = useState({})
  const [trainingParticipants, setTrainingParticipants] = useState([])
  const [columnsToExport, setColumnsToExport] = useState([])
  const [pdfFile, setPdfFile] = useState("")
  const [modal1, setModal1] = useState(false)
  const [pageIndex, setPageIndex] = useState(1)
  const [pageCount, setPageCount] = useState(30)
  const [limit, setLimit] = useState(500)
  const [totalRecords, setTotalRecords] = useState(0)
  const [temp, setTemp] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [currentDate, setCurrentDate] = useState("")

  const [attestationModalTpsId, setAttestationModalTpsId] = useState(null)

  useEffect(() => {
    fetchAllTrainingPrograms()
    fetchAllTrainingParticipantsWithPag()
  }, [saved])

  useEffect(() => {
    fetchAllTrainingParticipantsWithPag()
  }, [pageIndex, limit, searchValue])

  useEffect(() => {
    const currentDate = new Date()
    const day = currentDate.getDate()
    const month = currentDate.toLocaleString("default", { month: "long" })
    const year = currentDate.getFullYear()
    const formattedDate = `${day}_${month}_${year}`
    setCurrentDate(formattedDate)
  }, [])

  useEffect(() => {
    const updatedParticipants = trainingParticipants.map(
      ({
        tps_updatedAt,
        tb_id,
        tps_OldDBId,
        tps_certificate,
        tps_createdAt,
        tps_examDate,
        tps_id,
        tps_score,
        tps_signFontFamily,
        tps_signText,
        tps_status,
        tps_tp_id,
        tps_trainingStatus,
        tps_userType,
        tps_userId,
        userType,
        ...rest
      }) => rest
    )

    const reArrangedParticipants = updatedParticipants.map(obj => ({
      username: obj.username,
      userEmail: obj.userEmail,
      userRegion: obj.userRegion,
      userDistrict: obj.userDistrict,
      "Staff Id": obj.staffId,
      tps_trainingOption: obj.tps_trainingOption,
      tps_isTrainingCompleted: obj.tps_isTrainingCompleted,
      tps_signedForm: obj.tps_signedForm,
      tps_attentionFormDate: obj.tps_attentionFormDate,
      userId: obj.userId,
    }))
    setColumnsToExport(reArrangedParticipants)
  }, [trainingParticipants])

  const fetchAllTrainingPrograms = async () => {
    try {
      setLoading(true)
      const response = await getAllTrainingPrograms()
      let { trainingPrograms } = response.data || {}
      trainingPrograms = trainingPrograms || []
      setTrainingPrograms(trainingPrograms)
      setLoading(false)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Schools"

      setTrainingPrograms([])
      setLoading(false)

      return SaveToast({ message, type: "error" })
    }
  }

  const fetchAllTrainingParticipantsWithPag = async () => {
    try {
      setLoading(true)
      const response = await getAllParticipantsByStatusWithPag(
        tp_id,
        tps_trainingStatus,
        pageIndex,
        limit
      )
      let { trainingParticipants } = response.data || {}
      setTotalRecords(trainingParticipants.count)
      let pageCount = Math.ceil(trainingParticipants.count / limit)
      setPageCount(pageCount)
      setTrainingParticipants(trainingParticipants.rows)
      setLoading(false)
    } catch (error) {
      const message = error?.message || "There Was A Problem Fetching Content"

      setLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const fetchColumnWiseSearchAPI = async query => {
    try {
      // setLoading(true)
      // const response = await getAllTeacherColumnWiseSearchAPI(
      //   pageIndex,
      //   limit,
      //   query
      // )
      // let { teachers } = response.data || {}
      // teachers = teachers || []
      // setTeachers(teachers.rows)
      // setTotalRecords(teachers.count)
      // let pageCount = Math.ceil(teachers.count / limit)
      // setPageCount(pageCount)
      // setTemp(!temp)
      // setLoading(false)
    } catch (error) {
      const message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Teachers"
      setLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }
  const toggleViewModal = () => {
    setModal1(!modal1)
    // pdfFile ? setPdfFile(null) : ""
  }

  const onClickUpdate = program => {
    setTrainingParticipantData(program)
    setDeleteModal(true)
  }

  const handleUpdateTrainingStatus = async () => {
    if (!trainingParticipantData.tps_id) {
      return SaveToast({ message: "Invalid Participant ID", type: "error" })
    }
    try {
      // 3 = Admin Approved
      let body = {
        tps_trainingStatus: 3,
      }
      const response = await updateTrainingParticipantData(
        trainingParticipantData.tps_id,
        body
      )
      const message =
        "Participant Approved Successfully, Now Participant Can Start Exam."
      SaveToast({ message, type: "success" })
      setSaved(prevSaved => !prevSaved)
      setDeleteModal(false)
      return
    } catch (error) {
      const message = error?.message || "There Was A Problem Deleting Program"
      return SaveToast({ message, type: "error" })
    }
  }

  const handleAddButtonClick = () => {
    props.history.push("/training-program/add")
  }

  const handleBackButtonClick = () => {
    // props.history.push("/batch-creation/add")
    props.history.push({
      pathname: `/training-program`,
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "username",
        filterable: true,
        Cell: cellProps => {
          return <ArrayToStringValue {...cellProps} />
        },
      },
      {
        Header: "Email",
        accessor: "userEmail",
        filterable: true,
        Cell: cellProps => {
          return <SimpleStringValue {...cellProps} />
        },
      },
      {
        Header: "Training Option",
        accessor: "tps_trainingOption",
        filterable: true,
        Cell: cellProps => {
          const option = cellProps?.row?.original?.tps_trainingOption
          let label

          if (option == 1) {
            label = "Recorded"
          } else if (option == 2) {
            label = "Online"
          } else if (option == 3) {
            label = "Physical "
          }
          return <SimpleStringValue {...cellProps} value={label} />
        },
      },

      {
        Header: "Signed Form",
        accessor: "tps_signedForm",
        filterable: true,
        Cell: cellProps => {
          return (
            <Button
              color="info"
              onClick={() => {
                setPdfFile(cellProps.row.original.tps_signedForm)
                toggleViewModal()
              }}
            >
              Read
            </Button>
          )
        },
      },

      {
        Header: "Exam Action",
        accessor: "",
        filterable: false,
        Cell: cellProps => {
          return (
            <Button
              color="info"
              onClick={() => {
                setAttestationModalTpsId(cellProps.row.original.tps_id)
              }}
            >
              Upload
            </Button>
          )
        },
      },
      // {
      //   Header: "Action",
      //   accessor: "",
      //   width: "40%",
      //   filterable: true,
      //   Cell: cellProps => {
      //     return (
      //       <Button
      //         color="info"
      //         onClick={() => {
      //           const participantData = cellProps.row.original
      //           onClickUpdate(participantData)
      //         }}
      //       >
      //         Approve Form
      //       </Button>
      //     )
      //   },
      // },
    ],
    []
  )

  return (
    <React.Fragment>
      <PDFModal
        isOpen={modal1}
        toggle={toggleViewModal}
        pdf={pdfFile}
        type="pdf"
        size="lg"
      />
      {/* <AttentionFormModal isOpen={modal1} toggle={toggleViewModal} /> */}
      <DeleteModal
        deleteMsg="Yes, Approve!"
        show={deleteModal}
        onDeleteClick={handleUpdateTrainingStatus}
        onCloseClick={() => setDeleteModal(false)}
      />

      {attestationModalTpsId ? (
        <ExamScoreUpdateFormModal
          tpsId={attestationModalTpsId}
          refreshParent={fetchAllTrainingParticipantsWithPag}
          closeModal={() => setAttestationModalTpsId(null)}
        />
      ) : null}

      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Training Program" breadcrumbItem="Program" /> */}
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    canExportCsv={true}
                    csvFileName={`Document_Upload_Participants.csv`}
                    csvLink="/api/v1/admin/training-participants/download?tps_trainingStatus=3"
                    data={trainingParticipants}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    isBackOption={true}
                    handleAddButtonClick={handleAddButtonClick}
                    handleBackButtonClick={handleBackButtonClick}
                    customPageSize={500}
                    className="custom-header-css"
                    canPrint={false}
                    dataFetchLoading={loading}
                    noDataMessage="No system activity found."
                    pageIndexFromApi={pageIndex}
                    setPageIndexFromApi={setPageIndex}
                    pageCountFromApi={pageCount}
                    setPageCount1={setPageCount}
                    totalRecords={totalRecords}
                    limit={limit}
                    setLimit={setLimit}
                    customPagination={true}
                    setSearchValue={setSearchValue}
                    fetchColumnWiseSearchAPI={fetchColumnWiseSearchAPI}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TrainingProgram
