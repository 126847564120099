import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Menu")} </li> */}
            <li>
              <Link to="/dashboard">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            <li id="books">
              <Link to="/#" className="has-arrow">
                <i className="fas fa-book"></i>
                <span>{props.t("Books")}</span>
              </Link>
              <ul className="sub-menu">
                <li id="e-book">
                  <Link to="/books/e-book">
                    {props.t("E-Book")}
                  </Link>
                </li>
                <li id="video">
                  <Link to="/books/video">
                    {props.t("Video")}
                  </Link>
                </li>
              </ul>
            </li>

            <li id="AssignmentQuestion">
              <Link to="/AssignmentQuestion">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("All Questions")}</span>
              </Link>
            </li>

            <li id="teachers">
              <Link to="/teachers">
                <i className="mdi mdi-teach"></i>
                <span>{props.t("Teachers")}</span>
              </Link>
            </li>

            {/* <li id="freelance-teacher">
              <Link to="/freelance-teacher">
                <i className="mdi mdi-teach"></i>
                <span>{props.t("Freelance Teacher")}</span>
              </Link>
            </li> */}

            <li id="students">
              <Link to="/students">
                <i className="fas fa-book-reader"></i>
                <span>{props.t("Students")}</span>
              </Link>
            </li>

            {/* <li id="premium-learner">
              <Link to="/premium-learner">
                <i className="fas fa-book-reader"></i>
                <span>{props.t("Premium Learner")}</span>
              </Link>
            </li> */}

            <li id="training">
              <Link to="/#" className="has-arrow">
                <i className="fas fa-chalkboard-teacher"></i>
                <span>{props.t("Training")}</span>
              </Link>
              <ul className="sub-menu">
                <li id="training-program">
                  <Link to="/training-program">
                    {props.t("Training Program")}
                  </Link>
                </li>
                <li id="training-questions">
                  <Link to="/training-questions">
                    {props.t("Training Questions")}
                  </Link>
                </li>
                <li id="tutor-management">
                  <Link to="/tutor-management">
                    {props.t("Tutor Management")}
                  </Link>
                </li>
              </ul>
            </li>

            <li id="schools">
              <Link to="/schools">
                <i className="bx bxs-school"></i>
                <span>{props.t("Schools")}</span>
              </Link>
            </li>

            <li id="school-staff">
              <Link to="/school-staff">
                <i className="mdi mdi-account-multiple-outline"></i>
                <span>{props.t("School Staff")}</span>
              </Link>
            </li>

            <li id="blogs">
              <Link to="/blogs">
                <i className="mdi mdi-account-multiple-outline"></i>
                <span>{props.t("Connect")}</span>
              </Link>
            </li>

            {/* <li id="live-session">
              <Link to="/live-session">
                <i className="mdi mdi-account-multiple-outline"></i>
                <span>{props.t("Live Session")}</span>
              </Link>
            </li> */}

            <li id="past-paper">
              <Link to="/past-paper">
                <i className="bx bx-buildings"></i>
                <span>{props.t("Past Paper")}</span>
              </Link>
            </li>

            <li id="categories">
              <Link to="/content-category">
                <i className="fas fa-border-all"></i>
                <span>{props.t("Content Category")}</span>
              </Link>
            </li>

            <li id="areas">
              <Link to="/areas">
                <i className="bx bx-map"></i>
                <span>{props.t("Manage Areas")}</span>
              </Link>
            </li>

            <li id="gesauthorities">
              <Link to="/gesauthorities">
                <i className="mdi mdi-account-multiple-outline"></i>
                <span>{props.t("GES Authorities")}</span>
              </Link>
            </li>

            <li id="ges-offices">
              <Link to="/gesoffices">
                <i className="bx bx-buildings"></i>
                <span>{props.t("GES Offices")}</span>
              </Link>
            </li>

            {/* <li id="content-team">
              <Link to="/content-team">
                <i className="bx bx-buildings"></i>
                <span>{props.t("Content Team")}</span>
              </Link>
            </li> */}

            <li id="termlySchemeMaster">
              <Link to="/termlySchemeMaster">
                <i className="bx bx-buildings"></i>
                <span>{props.t("Termly Schema")}</span>
              </Link>
            </li>

            <li>
              <Link to="/katon-team">
                <i className="bx bx-user-circle"></i>
                <span>{props.t("Admin team")}</span>
              </Link>
            </li>

            <li id="genre">
              <Link to="/genre">
                <i className="bx bx-user-circle"></i>
                <span>{props.t("Genre")}</span>
              </Link>
            </li>

            {/* <li>
              <Link to="/#" >
                <i className="bx bx-chat"></i>
                <span>{props.t("Chat")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" >
                <i className="bx bx-file"></i>
                <span className="badge rounded-pill bg-success float-end">
                  {props.t("New")}
                </span>
                <span>{props.t("File Manager")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-store"></i>
                <span>{props.t("Ecommerce")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Products")}</Link>
                </li>
                <li>
                  <Link to="/#">
                    {props.t("Product Detail")}
                  </Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Orders")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Customers")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Cart")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Checkout")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Shops")}</Link>
                </li>
                <li>
                  <Link to="/#">
                    {props.t("Add Product")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-bitcoin"></i>
                <span>{props.t("Crypto")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Wallet")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Buy/Sell")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Exchange")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Lending")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Orders")}</Link>
                </li>
                <li>
                  <Link to="/#">
                    {props.t("KYC Application")}
                  </Link>
                </li>
                <li>
                  <Link to="/#">{props.t("ICO Landing")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-envelope"></i>
                <span>{props.t("Email")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Inbox")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Read Email")} </Link>
                </li>
                <li>
                  <Link to="/#">
                    <span
                      className="badge rounded-pill badge-soft-success float-end"
                      key="t-new"
                    >
                      {props.t("New")}
                    </span>
                    <span key="t-email-templates">{props.t("Templates")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/#">
                        {props.t("Basic Action")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/#">
                        {props.t("Alert Email")}{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/#">
                        {props.t("Billing Email")}{" "}
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-receipt"></i>
                <span>{props.t("Invoices")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Invoice List")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Invoice Detail")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-briefcase-alt-2"></i>
                <span>{props.t("Projects")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Projects Grid")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Projects List")}</Link>
                </li>
                <li>
                  <Link to="/#">
                    {props.t("Project Overview")}
                  </Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Create New")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-task"></i>
                <span>{props.t("Tasks")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Task List")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Kanban Board")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Create Task")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bxs-user-detail"></i>
                <span>{props.t("Contacts")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("User Grid")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("User List")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Profile")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" >
                <span className="badge rounded-pill bg-success float-end">
                  {props.t("New")}
                </span>
                <i className="bx bxs-detail" />

                <span>{props.t("Blog")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Blog List")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Blog Grid")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Blog Details")}</Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">Pages</li>
            <li>
              <Link to="/#" >
                <i className="bx bx-user-circle"></i>
                <span className="badge rounded-pill bg-success float-end">
                  {props.t("New")}
                </span>
                <span>{props.t("Authentication")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Login")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Login 2")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Register")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Register 2")}</Link>
                </li>
                <li>
                  <Link to="/#">
                    {props.t("Recover Password")}
                  </Link>
                </li>
                <li>
                  <Link to="/#">
                    {props.t("Recover Password 2")}
                  </Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Lock Screen")}</Link>
                </li>
                <li>
                  <Link to="/#">
                    {props.t("Lock Screen 2")}
                  </Link>
                </li>
                <li>
                  <Link to="#">{props.t("Confirm Mail")}</Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Confirm Mail 2")}
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Email Verification")}
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    {props.t("Email Verification 2")}
                  </Link>
                </li>
                <li>
                  <Link to="/#">
                    {props.t("Two Step Verification")}
                  </Link>
                </li>
                <li>
                  <Link to="/#">
                    {props.t("Two Step Verification 2")}
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-file"></i>
                <span>{props.t("Utility")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="#">{props.t("Starter Page")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Maintenance")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Coming Soon")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Timeline")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("FAQs")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Pricing")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Error 404")}</Link>
                </li>
                <li>
                  <Link to="#">{props.t("Error 500")}</Link>
                </li>
              </ul>
            </li>

            <li className="menu-title">{props.t("Components")}</li>

            <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-tone" />
                  <span>{props.t("UI Elements")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="#">{props.t("Alerts")}</Link>
                  </li>
                  <li>
                    <Link to="#">{props.t("Buttons")}</Link>
                  </li>
                  <li>
                    <Link to="#">{props.t("Cards")}</Link>
                  </li>
                  <li>
                    <Link to="#">{props.t("Carousel")}</Link>
                  </li>
                  <li>
                    <Link to="#">{props.t("Dropdowns")}</Link>
                  </li>
                  <li>
                    <Link to="#">{props.t("OffCanvas")}</Link>
                  </li>
                  <li>
                    <Link to="#">{props.t("Grid")}</Link>
                  </li>
                  <li>
                    <Link to="#">{props.t("Images")}</Link>
                  </li>
                  <li>
                    <Link to="#">{props.t("Lightbox")}</Link>
                  </li>
                  <li>
                    <Link to="#">{props.t("Modals")}</Link>
                  </li>
                  <li>
                    <Link to="#">
                      {props.t("Range Slider")}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      {props.t("Session Timeout")}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      {props.t("Progress Bars")}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">{props.t("Placeholders")}</Link>
                  </li>
                  <li>
                    <Link to="#">
                      {props.t("Sweet-Alert")}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      {props.t("Tabs & Accordions")}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      {props.t("Typography")}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">{props.t("Toasts")}</Link>
                  </li>
                  <li>
                    <Link to="#">{props.t("Video")}</Link>
                  </li>
                  <li>
                    <Link to="#">{props.t("General")}</Link>
                  </li>
                  <li>
                    <Link to="#">{props.t("Colors")}</Link>
                  </li>
                  <li>
                    <Link to="#">{props.t("Rating")}</Link>
                  </li>
                  <li>
                    <Link to="#">
                      {props.t("Notifications")}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      {props.t("Breadcrumb")}
                    </Link>
                  </li>
                </ul>
              </li>

            <li>
              <Link to="/#" >
                <i className="bx bxs-eraser"></i>
                <span className="badge rounded-pill bg-danger float-end">
                  10
                </span>
                <span>{props.t("Forms")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Form Elements")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Form Layouts")}</Link>
                </li>
                <li>
                  <Link to="/#">
                    {props.t("Form Validation")}
                  </Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Form Advanced")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Form Editors")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Form File Upload")} </Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Form Xeditable")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Form Repeater")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Form Wizard")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Form Mask")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Transfer List")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-list-ul"></i>
                <span>{props.t("Tables")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Basic Tables")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Data Tables")}</Link>
                </li>
                <li>
                  <Link to="/#">
                    {props.t("Responsive Table")}
                  </Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Editable Table")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Drag & Drop Table")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bxs-bar-chart-alt-2"></i>
                <span>{props.t("Charts")}</span>
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Apex charts")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Chartist Chart")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Chartjs Chart")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("E Chart")}</Link>
                </li>
                <li>
                  <Link to="/#">
                    {props.t("Sparkline Chart")}
                  </Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Knob Chart")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Re Chart")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-aperture"></i>
                <span>{props.t("Icons")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Boxicons")}</Link>
                </li>
                <li>
                  <Link to="/#">
                    {props.t("Material Design")}
                  </Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Dripicons")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Font awesome")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-map"></i>
                <span>{props.t("Maps")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Google Maps")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Vector Maps")}</Link>
                </li>
                <li>
                  <Link to="/#">{props.t("Leaflet Maps")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow ">
                <i className="bx bx-share-alt"></i>
                <span>{props.t("Multi Level")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Level 1.1")}</Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {props.t("Level 1.2")}
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/#">{props.t("Level 2.1")}</Link>
                    </li>
                    <li>
                      <Link to="/#">{props.t("Level 2.2")}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
