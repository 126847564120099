import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap"
import { Form as RBSForm } from "react-bootstrap"
// import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useLocation } from "react-router-dom"
import { Form, Formik } from "formik"
import * as Yup from "yup"

import Select from "react-select"
import ViewDetails from "./ViewDetails"
import UpdateModal from "../../components/Common/UpdateModal"
import { SaveToast } from "components/Common/SaveToast"
import ContentCategoryDropDownAllSelectable from "components/Common/ManageContentCategoryDropDownAllSelectable"

import { IMAGE_URL } from "helpers/urlHelper"
import SubmitLoader from "components/Common/SubmitLoader"

import {
  getBook,
  createBook,
  updateBook,
  getAllGenres,
} from "helpers/backendHelpers/books"
import { defaultCCGSeparator } from "helpers/common_helper_functions"
import {
  language,
  whoCanReadBook,
  whoCanReadValues,
} from "common/data/dropdownVals"
import moment from "moment"
import { getAllTrainingPrograms } from "helpers/backendHelpers/trainingProgram"
import useAllContentCategories from "hooks/useAllContentCategories"

const TrainingProgramModal = props => {
  const [isEdit, setIsEdit] = useState(false)
  const [isView, setIsView] = useState(false)
  const [bookId, setBookId] = useState(0)
  const [updateModal, setUpdateModal] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [isBookFree, setIsBookFree] = useState(true)
  const [genreOptions, setGenreOptions] = useState([])
  const [trainingPrograms, setTrainingPrograms] = useState([])
  const [trainingProgramsList, setTrainingProgramsList] = useState([])
  const [trainingProgramValues, setTrainingProgramValues] = useState([])
  const [isTrainingSelected, setIsTrainingSelected] = useState(false)

  const [subjects, setSubjects] = useState([])
  const [topics, setTopics] = useState([])

  const getSubCategoryArray = useAllContentCategories()

  const { bookType } = props.match.params
  const isVideoForm = bookType === "video"
  const isEBookForm = bookType === "e-book"

  const [form, setForm] = useState({
    genre: "",
    bk_language: "English",
    bk_whoCanRead: [],
    tp_id: "",
    bk_title: "",
    bk_author: "",
    bk_description: "",
    bk_noOfPages: "",
    bk_publishedDate: moment(new Date()).format("yyyy-MM-DD"),
    bk_isFree: true,
    bk_isPhysicalAvailable: true,
    bk_price: "",
    bk_publisher: "",
    bk_edition: "",
    bk_mainCategory: "",
    bk_category: "",
    bk_subCategory: "",
    bk_topic: "",
    bk_audio: { fileName: "", file: {} },
    bk_epub: { fileName: "", file: {} },
    bk_preview: { fileName: "", file: {} },
    bk_pdf: { fileName: "", file: {} },
    bk_video: "",
    bk_previewVideo: { fileName: "", file: {} },
    categoryValue: "",
    old_tp_id: "",
    bk_videoDuration: "",
  })

  const setBookGenreDropDownValues = async () => {
    try {
      const response = await getAllGenres()
      let { genres } = response.data || {}
      let dropdownVals = genres.map(genres => {
        return { value: genres.bg_genreName, label: genres.bg_genreName }
      })
      dropdownVals = dropdownVals || []
      setGenreOptions(dropdownVals)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching BookGenre"

      setGenreOptions([])

      return SaveToast({ message, type: "error" })
    }
  }

  const setTrainingProgramDropDownValues = async () => {
    try {
      const response = await getAllTrainingPrograms()
      let { trainingPrograms } = response.data || {}
      setTrainingProgramsList(trainingPrograms)
      let dropdownVals = trainingPrograms.map(tp => {
        return { value: tp.tp_id, label: tp.tp_programTitle }
      })
      dropdownVals = dropdownVals || []
      setTrainingPrograms(dropdownVals)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching BookGenre"

      setTrainingPrograms([])

      return SaveToast({ message, type: "error" })
    }
  }

  useEffect(() => {
    async function fetchData() {
      // You can await here
      setBookGenreDropDownValues()
      await setTrainingProgramDropDownValues()

      if (isEBookForm) {
        document.getElementById("e-book").classList.add("mm-active")
      } else {
        document.getElementById("video").classList.add("mm-active")
      }

      let { type, id } = props.match.params || {}

      switch (type) {
        case "edit":
          setIsEdit(true)
          setIsView(false)
          setBookId(parseInt(id))
          break
        case "view":
          setIsView(true)
          setIsEdit(false)
          setBookId(parseInt(id))
          break
        case "add":
          document.getElementById("type-free").checked = true
          document.getElementById("physical-available").checked = true
          break
        default:
          setIsView(false)
          setIsEdit(false)
          setBookId(0)
          break
      }

      if (id) {
        fetchBookForEdit(id)
      }
      // ...
    }
    fetchData()
  }, [isEdit])

  const fetchBookForEdit = async bk_id => {
    try {
      const response = await getBook(bk_id)
      let { book } = response.data || {}

      book = book || {}
      book["bk_audio_old"] = book["bk_audio"]
      book["bk_pdf_old"] = book["bk_pdf"]
      book["old_tp_id"] = book?.tp_id

      book["bk_previewVideo_old"] = book["bk_previewVideo"]
      book["bk_preview_old"] = book["bk_preview"]
      book["bk_epub_old"] = book["bk_epub"]

      book["bk_audio"] = { fileName: "", file: {} }
      book["bk_pdf"] = { fileName: "", file: {} }
      book["bk_previewVideo"] = { fileName: "", file: {} }
      book["bk_preview"] = { fileName: "", file: {} }
      book["bk_epub"] = { fileName: "", file: {} }

      //check if book is of type free then set default value for price rather than 0 coming from database
      book["bk_price"] = book["bk_price"] === 0 ? "" : book["bk_price"]
      book["bk_video"] =
        book["bk_video"] === null ? "" : book["bk_video"]?.slice(17)

      book["bk_author"] = book["bk_author"] === null ? "" : book["bk_author"]
      book["bk_videoDuration"] =
        book["bk_videoDuration"] === null ? "" : book["bk_videoDuration"]

      book["bk_publisher"] =
        book["bk_publisher"] === null ? "" : book["bk_publisher"]
      book["tp_id"] = book["tp_id"] === null ? "" : book["tp_id"]
      book["bk_noOfPages"] =
        book["bk_noOfPages"] === null ? "" : book["bk_noOfPages"]
      book["bk_edition"] = book["bk_edition"] === null ? "" : book["bk_edition"]
      book["bk_topic"] = book["bk_topic"] ?? ""

      let { bk_mainCategory, bk_category, bk_subCategory } = book

      let categoryValue = ""
      if (bk_mainCategory || bk_category) {
        categoryValue =
          (bk_mainCategory || "") + defaultCCGSeparator + (bk_category || "")
      } else {
        categoryValue = ""
      }

      book["categoryValue"] = categoryValue
      if (book?.bk_genre?.length) {
        book.bk_genre = book.bk_genre.map(genre => {
          return { label: genre, value: genre }
        })
      }

      book["bk_subCategory"] = bk_subCategory

      book.genre = book.bk_genre

      if (book?.bk_whoCanRead?.length) {
        book.bk_whoCanRead = book.bk_whoCanRead.map(data => {
          return { label: data, value: data }
        })
      }

      let tempTpIds = []
      if (book?.tp_id?.length) {
        book?.tp_id.map(bk => {
          let isTpIdExist =
            trainingProgramsList &&
            trainingProgramsList.length > 0 &&
            trainingProgramsList?.filter(tp => tp.tp_id == bk)
          if (isTpIdExist.length > 0) {
            tempTpIds.push({
              label: isTpIdExist[0].tp_programTitle,
              value: isTpIdExist[0].tp_id,
            })
          }
        })
      }
      book["tp_id"] = tempTpIds
      let trainingProgram =
        trainingProgramsList.length > 0 &&
        trainingProgramsList.filter(tp => tp.tp_id == book["tp_id"])

      let isTrainingSelected = book?.bk_whoCanRead?.filter(
        data => data.value == "Training"
      )
      if (isTrainingSelected.length > 0) {
        setIsTrainingSelected(true)
      }
      if (isEdit) {
        if (book.bk_isFree === true) {
          document.getElementById("type-free").checked = true
          setIsBookFree(true)
        } else if (book.bk_isFree === false) {
          document.getElementById("type-paid").checked = true
          setIsBookFree(false)
        }
        if (book.bk_isPhysicalAvailable === true) {
          document.getElementById("physical-available").checked = true
        } else if (book.bk_isPhysicalAvailable === false) {
          document.getElementById("physical-unavailable").checked = true
        }
      }

      // Assigning the subject list
      if (book["bk_mainCategory"] && book["bk_category"]) {
        const newSubjects = getSubCategoryArray.getSubjects(
          book["bk_mainCategory"],
          book["bk_category"]
        )
        setSubjects(newSubjects)

        if (book["bk_subCategory"]) {
          const newTopics = getSubCategoryArray.getTopics(
            book["bk_mainCategory"],
            book["bk_category"],
            book["bk_subCategory"]
          )
          setTopics(newTopics)
        }
      }

      return setForm(book)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Fetching Book Details"
      setForm(form)
      return SaveToast({ message, type: "error" })
    }
  }

  useEffect(() => {
    if (trainingProgramsList && trainingProgramsList.length > 0 && isEdit) {
      fetchBookForEdit(bookId)
    }
  }, [trainingProgramsList])

  const handleBookSubmit = async data => {
    try {
      setSubmitLoading(true)
      const response = await createBook(data)
      let message = response?.message || "Book Added Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)
      props.history.push(`/books/${bookType}`)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Adding Book"
      setSubmitLoading(false)
      return SaveToast({ message, type: "error" })
    }
  }

  const handleEditBookSubmit = async (id, data) => {
    if (!id) {
      return SaveToast({
        message: "Please enter Book Id",
        type: "error",
      })
    }
    try {
      setSubmitLoading(true)
      const response = await updateBook(id, data)
      let message = response?.message || "Book Updated Successfully"
      SaveToast({ message, type: "success" })
      setSubmitLoading(false)
      props.history.push(`/books/${bookType}`)
    } catch (error) {
      let message =
        error?.response?.data?.message ||
        error?.message ||
        "There Was A Problem Editing Book"
      setSubmitLoading(false)
      return SaveToast({ message: message, type: "error" })
    }
  }

  const handleUpdateBook = () => {
    setUpdateModal(false)
    SaveToast({ message: "Book Updated Successfully", type: "success" })
    props.history.push(`/books/${bookType}`)
  }

  const handleContentCategoryChangeCallback = value => {
    const [levelName, className] = value?.value?.split(defaultCCGSeparator) || [
      "",
      "",
    ]

    const newSubjects = getSubCategoryArray.getSubjects(levelName, className)
    setSubjects(newSubjects)
  }

  const handleSubjectChange = (subjectName, categoryValue) => {
    const [levelName, className] = categoryValue?.split(
      defaultCCGSeparator
    ) || ["", ""]

    const newTopics = getSubCategoryArray.getTopics(
      levelName,
      className,
      subjectName
    )
    setTopics(newTopics)
  }

  return (
    <React.Fragment>
      <UpdateModal
        show={updateModal}
        onUpdateClick={handleUpdateBook}
        onCloseClick={() => setUpdateModal(false)}
      />
      <div className="page-content">
        <div
          className="container-fluid"
          style={submitLoading ? { position: "relative", opacity: "0.8" } : {}}
        >
          {submitLoading ? <SubmitLoader /> : <></>}
          {isView ? (
            <>
              <ViewDetails programData={form} {...props} bookType={bookType} />
            </>
          ) : (
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize={true}
                  initialValues={form}
                  validationSchema={Yup.object({
                    genre: Yup.mixed().test(
                      "invalidInput",
                      "Please Select Genre",
                      value => {
                        if (value) {
                          return value.length
                        } else {
                          return false
                        }
                      }
                    ),
                    bk_whoCanRead: Yup.mixed().test(
                      "invalidInput",
                      "Please Select Who Can Read",
                      value => {
                        if (value) {
                          return value.length
                        } else {
                          return false
                        }
                      }
                    ),
                    tp_id:
                      isTrainingSelected &&
                      Yup.string().required("Please Select Training Program"),
                    bk_subCategory: Yup.string()
                      .oneOf(subjects, "Please select valid Subject")
                      .required("Please Select Subject Of Book"),
                    // bk_topic: Yup.string().when([], {
                    //   is: () => isVideoForm,
                    //   then: Yup.string().required("Please Select Video Topic"),
                    //   otherwise: Yup.string().notRequired(),
                    // }),
                    bk_language: Yup.string()
                      .oneOf(language, "Please select valid language")
                      .required("Please Select Language Of Book"),
                    bk_title: Yup.string().required(
                      "Please Enter Title Of Book"
                    ),
                    bk_author: Yup.string("Please Enter Author Of Book")
                      .optional()
                      .nullable(),
                    bk_description: Yup.string().required(
                      "Please Enter Description Of Book"
                    ),
                    bk_noOfPages: Yup.number("Please Enter Number Of Pages")
                      .optional()
                      .min(1, "Number Of Pages Should More Than 1")
                      .nullable(),
                    bk_publishedDate: Yup.date().required(
                      "Please Select Published Date"
                    ),
                    bk_price: !isBookFree
                      ? Yup.number()
                          .required("Please Enter Price Of Book")
                          .min(1, "Book Price Can not Be Less Than 1")
                      : Yup.mixed().optional(),

                    bk_edition: Yup.string("Please Enter Edition Of Book")
                      .optional()
                      .nullable(),
                    bk_audio: Yup.mixed()
                      .optional()

                      .test("fileFormat", "Unsupported Format", value => {
                        if (!form?.["bk_audio"]?.file?.type)
                          return (
                            isEdit || form["bk_audio"].fileName === "" || false
                          )
                        return [
                          "audio/mpeg",
                          "audio/mp4",
                          "audio/x-wav",
                        ].includes(form?.["bk_audio"].file.type)
                      })
                      .nullable(),
                    bk_epub: Yup.mixed()
                      .test("fileFormat", "Unsupported Format", value => {
                        if (!form?.["bk_epub"]?.file?.type)
                          return (
                            isEdit || form["bk_epub"].fileName === "" || false
                          )

                        return [
                          "application/pdf",
                          "application/epub+zip",
                        ].includes(form?.["bk_epub"].file.type)
                      })
                      .nullable(),
                    bk_preview: Yup.mixed()
                      .required("Please Enter Preview Of Book")
                      .test(
                        "fileNotSelected",
                        "Please Select Cover Image",
                        value => {
                          return isEdit || !!form?.["bk_preview"]?.file?.type
                        }
                      )
                      .test("fileFormat", "Unsupported Format", value => {
                        if (!form?.["bk_preview"]?.file?.type)
                          return isEdit || false

                        return [
                          "image/png",
                          "image/jpg",
                          "image/jpeg",
                          "image/gif",
                        ].includes(form?.["bk_preview"].file.type)
                      }),
                    bk_pdf: Yup.mixed()
                      .optional()
                      .test("fileFormat", "Unsupported Format", value => {
                        if (!form?.["bk_pdf"]?.file?.type)
                          return (
                            isEdit || form["bk_pdf"].fileName === "" || false
                          )

                        return ["application/pdf"].includes(
                          form?.["bk_pdf"].file.type
                        )
                      })
                      .nullable(),
                    bk_video: Yup.string("Please Enter Valid Book Video")
                      .optional()
                      .nullable(),
                    bk_previewVideo: Yup.mixed()
                      .optional()
                      .test("fileFormat", "Unsupported Format", value => {
                        if (!form?.["bk_previewVideo"]?.file?.type)
                          return (
                            isEdit ||
                            form["bk_previewVideo"].fileName === "" ||
                            false
                          )
                        return [
                          "video/mp4",
                          "video/webm",
                          "video/ogg",
                          "video/x-msvideo",
                          "video/x-flv",
                          "video/3gpp",
                        ].includes(form?.["bk_previewVideo"].file.type)
                      })
                      .nullable(),
                    categoryValue: Yup.mixed().test(
                      "invalidInput",
                      "Please Select Book Category",
                      value => {
                        return !!value
                      }
                    ),
                  })}
                  onSubmit={values => {
                    let [bk_mainCategory, bk_category] =
                      (values?.categoryValue + "" || "")?.split(
                        defaultCCGSeparator
                      ) || []

                    bk_mainCategory = bk_mainCategory || null
                    bk_category = bk_category || null

                    values.bk_subCategory = values.bk_subCategory || null
                    // bk_topic = bk_topic || null // removed feature

                    if (isBookFree) {
                      values.bk_price = null
                    }

                    let { genre: genreArray } = values
                    genreArray = genreArray.map(genre => {
                      return genre.label
                    })
                    let { bk_whoCanRead: whoCanRead } = values
                    whoCanRead = whoCanRead.map(data => {
                      return data.value
                    })

                    let { tp_id: tpIds } = values
                    if (tpIds) {
                      tpIds = tpIds.map(data => {
                        return data.value
                      })
                    }

                    // optional field
                    values.bk_video =
                      values.bk_video === "" ? null : values.bk_video
                    values.bk_videoDuration =
                      values.bk_videoDuration === ""
                        ? null
                        : values.bk_videoDuration

                    values.bk_author =
                      values.bk_author === "" ? null : values.bk_author
                    values.bk_publisher =
                      values.bk_publisher === "" ? null : values.bk_publisher
                    values.bk_noOfPages =
                      values.bk_noOfPages === "" ? null : values.bk_noOfPages
                    values.bk_edition =
                      values.bk_edition === "" ? null : values.bk_edition

                    values.bk_genre = genreArray.toString()

                    values.bk_whoCanRead = values.bk_whoCanRead
                      ?.map(item => item.value)
                      .sort()
                      .join(",")

                    values.tp_id = isTrainingSelected ? values.tp_id : ""
                    values.old_tp_id = form?.old_tp_id ? form?.old_tp_id : ""
                    let bookData = values

                    if (isEdit) {
                      bookData["bk_mainCategory"] = bk_mainCategory
                      bookData["bk_category"] = bk_category
                      // bookData["bk_topic"] = bk_topic // This is removed feature
                      bookData["bk_audio_old"] = form.bk_audio_old
                      bookData["bk_pdf_old"] = form.bk_pdf_old
                      bookData["bk_previewVideo_old"] = form.bk_previewVideo_old
                      bookData["bk_preview_old"] = form.bk_preview_old
                      bookData["bk_epub_old"] = form.bk_epub_old
                      bookData["bk_audio"] = form.bk_audio.file
                      bookData["bk_pdf"] = form.bk_pdf.file
                      bookData["bk_previewVideo"] = form.bk_previewVideo.file
                      bookData["bk_preview"] = form.bk_preview.file
                      bookData["bk_epub"] = form.bk_epub.file

                      delete bookData["bk_id"]
                      return handleEditBookSubmit(bookId, bookData)
                    } else {
                      bookData["bk_mainCategory"] = bk_mainCategory
                      bookData["bk_category"] = bk_category
                      // bookData["bk_subCategory"] = bk_subCategory
                      // bookData["bk_topic"] = bk_topic // This is removed feature
                      bookData["bk_audio"] = form.bk_audio.file
                      bookData["bk_pdf"] = form.bk_pdf.file
                      bookData["bk_previewVideo"] = form.bk_previewVideo.file
                      bookData["bk_preview"] = form.bk_preview.file
                      bookData["bk_epub"] = form.bk_epub.file
                      return handleBookSubmit(bookData)
                    }
                  }}
                >
                  {({
                    touched,
                    errors,
                    values,
                    handleSubmit,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                    handleChange,
                    validateOnChange,
                  }) => (
                    <>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          handleSubmit(e)
                          return false
                        }}
                      >
                        <Row>
                          <Col>
                            <Row className="mb-3">
                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <ContentCategoryDropDownAllSelectable
                                  isRequired={true}
                                  fieldName="categoryValue"
                                  hasTouched={!!touched.categoryValue}
                                  hasErrors={errors.categoryValue}
                                  categoryValue={values.categoryValue}
                                  setFieldValue={setFieldValue}
                                  setFieldTouched={setFieldTouched}
                                  handleContentCategoryChangeCallback={
                                    handleContentCategoryChangeCallback
                                  }
                                />
                              </Col>

                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">
                                  Subject <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="bk_subCategory"
                                  type="select"
                                  className="form-select"
                                  onChange={e => {
                                    handleSubjectChange(
                                      e.target.value,
                                      values.categoryValue
                                    )
                                    handleChange(e)
                                  }}
                                  onBlur={handleBlur}
                                  value={values.bk_subCategory}
                                  disabled={values.categoryValue === ""}
                                  invalid={
                                    touched.bk_subCategory &&
                                    errors.bk_subCategory
                                      ? true
                                      : false
                                  }
                                >
                                  <option key={0} value={0}>
                                    Select Subject
                                  </option>
                                  {subjects.map(val => {
                                    return (
                                      <option key={val} value={val}>
                                        {val}
                                      </option>
                                    )
                                  })}
                                </Input>
                                {!!touched.bk_subCategory &&
                                  !!errors.bk_subCategory && (
                                    <FormFeedback>
                                      {errors.bk_subCategory}
                                    </FormFeedback>
                                  )}
                              </Col>

                              {isVideoForm ? (
                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">
                                    Topic <span className="text-danger"></span>
                                  </Label>
                                  <Input
                                    name="bk_topic"
                                    type="select"
                                    className="form-select"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.bk_topic}
                                    disabled={values.bk_subCategory === ""}
                                    invalid={
                                      touched.bk_topic && errors.bk_topic
                                        ? true
                                        : false
                                    }
                                  >
                                    <option key={0} value={0}>
                                      Select Topic
                                    </option>
                                    {topics.map(val => {
                                      return (
                                        <option key={val} value={val}>
                                          {val}
                                        </option>
                                      )
                                    })}
                                  </Input>
                                  {/* {!!touched.bk_topic && !!errors.bk_topic && (
                                    <FormFeedback>
                                      {errors.bk_topic}
                                    </FormFeedback>
                                  )} */}
                                </Col>
                              ) : null}

                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">
                                  Genre <span className="text-danger">*</span>
                                </Label>

                                <Select
                                  name="genre"
                                  options={genreOptions}
                                  value={values.genre}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  onChange={value => {
                                    setFieldValue("genre", value ? value : "")
                                  }}
                                  onBlur={evt => {
                                    setFieldTouched("genre", true, true)
                                  }}
                                  className="react-select-container"
                                  classNamePrefix="select2-selection"
                                  invalid={!!touched.genre && !!errors.genre}
                                  placeholder="Type to search..."
                                  isMulti
                                  isClearable
                                  isSearchable
                                />
                                {!!touched.genre && !!errors.genre ? (
                                  <div className="invalid-react-select-dropdown">
                                    {errors.genre}
                                  </div>
                                ) : null}
                              </Col>

                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">
                                  Langauge{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="bk_language"
                                  type="select"
                                  className="form-select"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.bk_language}
                                  invalid={
                                    touched.bk_language && errors.bk_language
                                      ? true
                                      : false
                                  }
                                >
                                  {language.map(val => {
                                    return (
                                      <option key={val} value={val}>
                                        {val}
                                      </option>
                                    )
                                  })}
                                </Input>
                                {!!touched.bk_language &&
                                  !!errors.bk_language && (
                                    <FormFeedback>
                                      {errors.bk_language}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">
                                  Who Can Read{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Select
                                  name="bk_whoCanRead"
                                  options={whoCanReadValues}
                                  value={values.bk_whoCanRead}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  onChange={value => {
                                    setFieldValue(
                                      "bk_whoCanRead",
                                      value ? value : ""
                                    )
                                    let isTrainingSelected = value.filter(
                                      data => data.value == "Training"
                                    )
                                    isTrainingSelected.length > 0
                                      ? setIsTrainingSelected(true)
                                      : setIsTrainingSelected(false)
                                  }}
                                  onBlur={evt => {
                                    setFieldTouched("bk_whoCanRead", true, true)
                                  }}
                                  className="react-select-container"
                                  classNamePrefix="select2-selection"
                                  invalid={
                                    !!touched.bk_whoCanRead &&
                                    !!errors.bk_whoCanRead
                                  }
                                  placeholder="Type to search..."
                                  isMulti
                                  isClearable
                                  isSearchable
                                />
                                {!!touched.bk_whoCanRead &&
                                !!errors.bk_whoCanRead ? (
                                  <div className="invalid-react-select-dropdown">
                                    {errors.bk_whoCanRead}
                                  </div>
                                ) : null}
                              </Col>
                              {isTrainingSelected && (
                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">
                                    Select Training Program{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="tp_id"
                                    type="select"
                                    className="form-select"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.tp_id}
                                    invalid={
                                      touched.tp_id && errors.tp_id
                                        ? true
                                        : false
                                    }
                                  >
                                    <option value="">
                                      Select Training Program
                                    </option>
                                    {trainingPrograms.map(val => {
                                      return (
                                        <option
                                          key={val.value}
                                          value={val.value}
                                        >
                                          {val.label}
                                        </option>
                                      )
                                    })}
                                  </Input>
                                  {!!touched.tp_id && !!errors.tp_id && (
                                    <FormFeedback>{errors.tp_id}</FormFeedback>
                                  )}
                                </Col>
                              )}

                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">
                                  Book Title{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="bk_title"
                                  type="text"
                                  rows={6}
                                  placeholder="Enter Book Title"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    !!touched.bk_title && !!errors.bk_title
                                  }
                                  value={values.bk_title}
                                />
                                {!!touched.bk_title && !!errors.bk_title && (
                                  <FormFeedback>{errors.bk_title}</FormFeedback>
                                )}
                              </Col>
                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">Author</Label>
                                <Input
                                  name="bk_author"
                                  type="text"
                                  rows={6}
                                  placeholder="Enter Book Author Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    !!touched.bk_author && !!errors.bk_author
                                  }
                                  value={values.bk_author}
                                />
                                {!!touched.bk_author && !!errors.bk_author && (
                                  <FormFeedback>
                                    {errors.bk_author}
                                  </FormFeedback>
                                )}
                              </Col>
                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">
                                  Number Of Pages{" "}
                                </Label>
                                <Input
                                  name="bk_noOfPages"
                                  type="number"
                                  rows={6}
                                  placeholder="Enter Number Of Pages"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    !!touched.bk_noOfPages &&
                                    !!errors.bk_noOfPages
                                  }
                                  value={values.bk_noOfPages}
                                />
                                {!!touched.bk_noOfPages &&
                                  !!errors.bk_noOfPages && (
                                    <FormFeedback>
                                      {errors.bk_noOfPages}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">
                                  Published Date{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="bk_publishedDate"
                                  type="date"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    !!touched.bk_publishedDate &&
                                    !!errors.bk_publishedDate
                                  }
                                  value={values.bk_publishedDate}
                                />
                                {!!touched.bk_publishedDate &&
                                  !!errors.bk_publishedDate && (
                                    <FormFeedback>
                                      {errors.bk_publishedDate}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">Edition</Label>
                                <Input
                                  name="bk_edition"
                                  type="text"
                                  rows={6}
                                  placeholder="Enter Book Edition"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    !!touched.bk_edition && !!errors.bk_edition
                                  }
                                  value={values.bk_edition}
                                />
                                {!!touched.bk_edition &&
                                  !!errors.bk_edition && (
                                    <FormFeedback>
                                      {errors.bk_edition}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">Publisher </Label>
                                <Input
                                  name="bk_publisher"
                                  type="text"
                                  rows={6}
                                  placeholder="Enter Book Publisher Name"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    !!touched.bk_publisher &&
                                    !!errors.bk_publisher
                                  }
                                  value={values.bk_publisher}
                                />
                                {!!touched.bk_publisher &&
                                  !!errors.bk_publisher && (
                                    <FormFeedback>
                                      {errors.bk_publisher}
                                    </FormFeedback>
                                  )}
                              </Col>

                              {isEBookForm ? (
                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">
                                    Epub
                                    {isEdit && form?.bk_epub_old && (
                                      <>
                                        <span className="ms-2">
                                          (
                                          <a
                                            href={`${IMAGE_URL}/${
                                              form?.bk_epub_old || ""
                                            }`}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            Saved Epub
                                          </a>
                                          )
                                        </span>
                                      </>
                                    )}
                                  </Label>
                                  <Input
                                    name="bk_epub"
                                    type="file"
                                    accept=".pdf, .epub"
                                    placeholder="Select Epub file"
                                    onChange={e => {
                                      let tempForm = form
                                      tempForm["bk_epub"]["fileName"] =
                                        e.target.value
                                      tempForm["bk_epub"]["file"] =
                                        e.target.files[0]
                                      setForm(tempForm)
                                    }}
                                    // onBlur={handleBlur}
                                    invalid={
                                      !!touched.bk_epub && !!errors.bk_epub
                                    }
                                    defaultValue={form.bk_epub.fileName}
                                  />
                                  {!!touched.bk_epub && !!errors.bk_epub && (
                                    <FormFeedback>
                                      {errors.bk_epub}
                                    </FormFeedback>
                                  )}
                                </Col>
                              ) : null}

                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">
                                  Book Audio
                                  {isEdit && form?.bk_audio_old && (
                                    <>
                                      <span className="ms-2">
                                        (
                                        <a
                                          href={`${IMAGE_URL}/${
                                            form?.bk_audio_old || ""
                                          }`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          Saved Book Audio
                                        </a>
                                        )
                                      </span>
                                    </>
                                  )}
                                </Label>
                                <Input
                                  name="bk_audio"
                                  type="file"
                                  accept=".mp3,.ogg,.aifc,.wav,.aifc,.aiff"
                                  placeholder="Select Book Audio"
                                  onChange={e => {
                                    let tempForm = form
                                    tempForm["bk_audio"]["fileName"] =
                                      e.target.value
                                    tempForm["bk_audio"]["file"] =
                                      e.target.files[0]
                                    setForm(tempForm)
                                  }}
                                  // onBlur={handleBlur}
                                  invalid={
                                    !!touched.bk_audio && !!errors.bk_audio
                                  }
                                  defaultValue={form.bk_audio.fileName}
                                />
                                {!!touched.bk_audio && !!errors.bk_audio && (
                                  <FormFeedback>{errors.bk_audio}</FormFeedback>
                                )}
                              </Col>

                              {isEBookForm ? (
                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">
                                    Book Pdf
                                    {isEdit && form?.bk_pdf_old && (
                                      <>
                                        <span className="ms-2">
                                          (
                                          <a
                                            href={`${IMAGE_URL}/${
                                              form?.bk_pdf_old || ""
                                            }`}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            Saved Book Pdf
                                          </a>
                                          )
                                        </span>
                                      </>
                                    )}
                                  </Label>
                                  <Input
                                    name="bk_pdf"
                                    type="file"
                                    accept=".pdf"
                                    placeholder="Select Book Pdf"
                                    onChange={e => {
                                      let tempForm = form
                                      tempForm["bk_pdf"]["fileName"] =
                                        e.target.value
                                      tempForm["bk_pdf"]["file"] =
                                        e.target.files[0]
                                      setForm(tempForm)
                                    }}
                                    // onBlur={handleBlur}
                                    invalid={
                                      !!touched.bk_pdf && !!errors.bk_pdf
                                    }
                                    defaultValue={form.bk_pdf.fileName}
                                  />
                                  {!!touched.bk_pdf && !!errors.bk_pdf && (
                                    <FormFeedback>{errors.bk_pdf}</FormFeedback>
                                  )}
                                </Col>
                              ) : null}

                              {isVideoForm ? (
                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">
                                    Book Video
                                  </Label>
                                  <Input
                                    name="bk_video"
                                    type="text"
                                    rows={6}
                                    placeholder="Enter Book Video File Name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      !!touched.bk_video && !!errors.bk_video
                                    }
                                    value={values.bk_video}
                                  />
                                  {!!touched.bk_video && !!errors.bk_video && (
                                    <FormFeedback>
                                      {errors.bk_video}
                                    </FormFeedback>
                                  )}
                                </Col>
                              ) : null}

                              {isVideoForm ? (
                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">
                                    Book Video Duration (hh:mm:ss)
                                  </Label>
                                  <Input
                                    name="bk_videoDuration"
                                    type="text"
                                    rows={6}
                                    placeholder="Enter Book Video Duration in hh::mm::ss format"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      !!touched.bk_videoDuration &&
                                      !!errors.bk_videoDuration
                                    }
                                    value={values.bk_videoDuration}
                                  />
                                  {!!touched.bk_videoDuration &&
                                    !!errors.bk_videoDuration && (
                                      <FormFeedback>
                                        {errors.bk_videoDuration}
                                      </FormFeedback>
                                    )}
                                </Col>
                              ) : null}

                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Label className="form-label">
                                  Book Preview Video
                                  {isEdit && form?.bk_previewVideo_old && (
                                    <>
                                      <span className="ms-2">
                                        (
                                        <a
                                          href={`${IMAGE_URL}/${
                                            form?.bk_previewVideo_old || ""
                                          }`}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          Saved video
                                        </a>
                                        )
                                      </span>
                                    </>
                                  )}
                                </Label>
                                <Input
                                  name="bk_previewVideo"
                                  type="file"
                                  accept=".mp4, .mpeg, .x-msvideo"
                                  placeholder="Select Content Video"
                                  onChange={e => {
                                    let tempForm = form
                                    tempForm["bk_previewVideo"]["fileName"] =
                                      e.target.value
                                    tempForm["bk_previewVideo"]["file"] =
                                      e.target.files[0]
                                    setForm(tempForm)
                                  }}
                                  invalid={
                                    !!touched.bk_previewVideo &&
                                    !!errors.bk_previewVideo
                                  }
                                  defaultValue={form.bk_previewVideo.fileName}
                                />
                                {!!touched.bk_previewVideo &&
                                  !!errors.bk_previewVideo && (
                                    <FormFeedback>
                                      {errors.bk_previewVideo}
                                    </FormFeedback>
                                  )}
                              </Col>

                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Row>
                                  <Col className="mt-2 mt-md-0 mb-3">
                                    <Label className="form-label">
                                      Cover Image
                                      <span className="text-danger">*</span>
                                      {isEdit && form?.bk_preview_old && (
                                        <>
                                          <span className="ms-2">
                                            (
                                            <a
                                              href={`${IMAGE_URL}/${
                                                form?.bk_preview_old || ""
                                              }`}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              Saved BookPreview
                                            </a>
                                            )
                                          </span>
                                        </>
                                      )}
                                    </Label>
                                    <Input
                                      name="bk_preview"
                                      type="file"
                                      accept=".png, .jpg, .jpeg, .gif"
                                      placeholder="Enter Book Preview"
                                      onChange={e => {
                                        let tempForm = form
                                        tempForm["bk_preview"]["fileName"] =
                                          e.target.value
                                        tempForm["bk_preview"]["file"] =
                                          e.target.files[0]
                                        setForm(tempForm)
                                      }}
                                      invalid={
                                        !!touched.bk_preview &&
                                        !!errors.bk_preview
                                      }
                                      defaultValue={form.bk_preview.fileName}
                                    />
                                    {!!touched.bk_preview &&
                                      !!errors.bk_preview && (
                                        <FormFeedback>
                                          {errors.bk_preview}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>

                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Row>
                                  <Col className="gap-3">
                                    <Label className="form-label">
                                      Physical Available{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <div>
                                      <RBSForm.Check
                                        id="physical-available"
                                        name="bk_isPhysicalAvailable"
                                        type="radio"
                                        inline
                                        label="Yes"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp[
                                              "bk_isPhysicalAvailable"
                                            ] = true
                                            values[
                                              "bk_isPhysicalAvailable"
                                            ] = true
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                      <RBSForm.Check
                                        id="physical-unavailable"
                                        name="bk_isPhysicalAvailable"
                                        type="radio"
                                        inline
                                        label="No"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp[
                                              "bk_isPhysicalAvailable"
                                            ] = false
                                            values[
                                              "bk_isPhysicalAvailable"
                                            ] = false
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                    </div>

                                    {!!touched.bk_isPhysicalAvailable &&
                                      !!errors.bk_isPhysicalAvailable && (
                                        <FormFeedback>
                                          {errors.bk_isPhysicalAvailable}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>

                              <Col md={4} sm={6} xs={12} className="mb-3">
                                <Row>
                                  <Col className="gap-3">
                                    <Label className="form-label">
                                      Type{" "}
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <div>
                                      <RBSForm.Check
                                        id="type-free"
                                        name="bk_isFree"
                                        type="radio"
                                        inline
                                        label="Free"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp["bk_isFree"] = true
                                            values["bk_isFree"] = true
                                            setIsBookFree(true)
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                      <RBSForm.Check
                                        id="type-paid"
                                        name="bk_isFree"
                                        type="radio"
                                        inline
                                        label="Paid"
                                        onChange={event => {
                                          if (event.target.checked) {
                                            let temp = form
                                            temp["bk_isFree"] = false
                                            values["bk_isFree"] = false
                                            setIsBookFree(false)
                                            setForm(temp)
                                          }
                                        }}
                                      />
                                    </div>

                                    {!!touched.bk_isFree &&
                                      !!errors.bk_isFree && (
                                        <FormFeedback>
                                          {errors.bk_isFree}
                                        </FormFeedback>
                                      )}
                                  </Col>
                                </Row>
                              </Col>

                              {!isBookFree ? (
                                <Col md={4} sm={6} xs={12} className="mb-3">
                                  <Label className="form-label">
                                    Book Price{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    name="bk_price"
                                    type="number"
                                    rows={6}
                                    placeholder="Enter Book Price "
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    invalid={
                                      !!touched.bk_price && !!errors.bk_price
                                    }
                                    value={values.bk_price}
                                  />
                                  {!!touched.bk_price && !!errors.bk_price && (
                                    <FormFeedback>
                                      {errors.bk_price}
                                    </FormFeedback>
                                  )}
                                </Col>
                              ) : (
                                <></>
                              )}

                              <Col
                                md={6}
                                sm={12}
                                xs={12}
                                className="mt-2 mt-md-0 mb-3"
                              >
                                <Label className="form-label">
                                  Description{" "}
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="bk_description"
                                  type="textarea"
                                  rows={6}
                                  placeholder="Enter Book Description"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  invalid={
                                    !!touched.bk_description &&
                                    !!errors.bk_description
                                  }
                                  value={values.bk_description}
                                />
                                {!!touched.bk_description &&
                                  !!errors.bk_description && (
                                    <FormFeedback>
                                      {errors.bk_description}
                                    </FormFeedback>
                                  )}
                              </Col>
                            </Row>

                            <Row className="mb-3 text-center">
                              <Col>
                                <Button
                                  size="md"
                                  color="danger"
                                  type="button"
                                  name="cancelButton"
                                  disabled={submitLoading}
                                  className="mx-2"
                                  onClick={() => {
                                    props.history.push(`/books/${bookType}`)
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  size="md"
                                  color="info"
                                  type="submit"
                                  name="saveEditButton"
                                  disabled={submitLoading}
                                >
                                  {!isEdit ? "Save" : "Update"}
                                </Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </Formik>
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

TrainingProgramModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default TrainingProgramModal
