import { classes } from "./classroom"

const teamMemberRole = ["Sub Admin", "HR Operation", "Accounts"]
const classRoomType = [
  "KG 1",
  "KG 2",
  "Primary 1",
  "Primary 2",
  "Primary 3",
  "Primary 4",
  "Primary 5",
  "Primary 6",
  "JHS 1",
  "SHS 1",
  "SHS 2",
  "SHS 3",
  "Level 1",
  "Level 2",
  "Level 3",
  "Level 4",
]
const classRoomType1 = [
  {
    label: "LOWER PRIMARY",
    options: [
      { label: "Primary 1", value: "Primary 1" },
      { label: "Primary 2", value: "Primary 2" },
      { label: "Primary 3", value: "Primary 3" },
    ],
  },
  {
    label: "UPPER PRIMARY",
    options: [
      { label: "Primary 4", value: "Primary 4" },
      { label: "Primary 5", value: "Primary 5" },
      { label: "Primary 6", value: "Primary 6" },
    ],
  },
  {
    label: "JHS",
    options: [
      { label: "JHS 1", value: "JHS 1" },
      { label: "JHS 2", value: "JHS 2" },
      { label: "JHS 3", value: "JHS 3" },
    ],
  },
  {
    label: "SHS - BUSINESS",
    options: [
      { label: "SHS 1 - Business", value: "SHS 1 - Business" },
      { label: "SHS 2 - Business", value: "SHS 2 - Business" },
      { label: "SHS 3 - Business", value: "SHS 3 - Business" },
    ],
  },
  {
    label: "TVET - ENGINEERING",
    options: [
      { label: "TVET 1 - Engineering", value: "TVET 1 - Engineering" },
      { label: "TVET 2 - Engineering", value: "TVET 2 - Engineering" },
      { label: "TVET 3 - Engineering", value: "TVET 3 - Engineering" },
    ],
  },
  {
    label: "SHS - GENERAL ARTS",
    options: [
      { label: "SHS 1 - General Arts", value: "SHS 1 - General Arts" },
      { label: "SHS 2 - General Arts", value: "SHS 2 - General Arts" },
      { label: "SHS 3 - General Arts", value: "SHS 3 - General Arts" },
    ],
  },
  {
    label: "SHS - GENERAL SCIENCE",
    options: [
      { label: "SHS 1 - General Science", value: "SHS 1 - General Science" },
      { label: "SHS 3 - General Science", value: "SHS 3 - General Science" },
      { label: "SHS 2 - General Science", value: "SHS 2 - General Science" },
    ],
  },
  {
    label: "SHS - HOME ECONOMICS",
    options: [
      { label: "SHS 2 - Home Economics", value: "SHS 2 - Home Economics" },
      { label: "SHS 3 - Home Economics", value: "SHS 3 - Home Economics" },
      { label: "SHS 1 - Home Economics", value: "SHS 1 - Home Economics" },
    ],
  },
  {
    label: "SHS - VISUAL ARTS",
    options: [
      { label: "SHS 1 - Visual Arts", value: "SHS 1 - Visual Arts" },
      { label: "SHS 2 - Visual Arts", value: "SHS 2 - Visual Arts" },
      { label: "SHS 3 - Visual Arts", value: "SHS 3 - Visual Arts" },
    ],
  },
  {
    label: "SHS - AGRICULTURAL SCIENCE",
    options: [
      {
        label: "SHS 1 - Agricultural Science",
        value: "SHS 1 - Agricultural Science",
      },
      {
        label: "SHS 2 - Agricultural Science",
        value: "SHS 2 - Agricultural Science",
      },
      {
        label: "SHS 3 - Agricultural Science",
        value: "SHS 3 - Agricultural Science",
      },
    ],
  },
  {
    label: "TVET - CONSTRUCTION",
    options: [
      { label: "TVET 1 - Construction", value: "TVET 1 - Construction" },
      { label: "TVET 2 - Construction", value: "TVET 2 - Construction" },
      { label: "TVET 3 - Construction", value: "TVET 3 - Construction" },
    ],
  },
  {
    label: "TVET - HOSPITALITY AND TOURISM",
    options: [
      {
        label: "TVET 1 - Hospitality And Tourism",
        value: "TVET 1 - Hospitality And Tourism",
      },
      {
        label: "TVET 2 - Hospitality And Tourism",
        value: "TVET 2 - Hospitality And Tourism",
      },
      {
        label: "TVET 3 - Hospitality And Tourism",
        value: "TVET 3 - Hospitality And Tourism",
      },
    ],
  },
]

const schoolType = [
  { value: "KG", label: "Kindergarten" },
  { value: "PR", label: "Primary School" },
  { value: "JHS", label: "Junior High School" },
  { value: "SHS", label: "Senior High School" },
  { value: "TVET", label: "Vocational and technical Education" },
]

const staffRole = [
  "School Head",
  "School Sub Head",
  "Accountant",
  "Human Resource",
  "Exam Department Head",
]

const language = [
  "English",
  "Hindi",
  "Gujarati",
  "Arabic",
  "Marathi",
  "Punjabi",
  "Spainish",
]

const whoCanReadBook = ["Student", "Teacher", "Training"]

const authorityType = [
  "Ministry Of Education",
  "Ghana Education System",
  "Regional Head Office",
  "District Head Office",
  "Circuit Office",
]

const officeLevel = [
  "Ministry Of Education",
  "Ghana Education System",
  "Regional Head Office",
  "District Head Office",
  "Circuit Office",
]

const programType = ["Physical", "Virtual"]

const areaType = [
  "Regional Head Office",
  "District Head Office",
  "Circuit Office",
]

// Student List
const classrooms = classes.map(cls => {
  return cls.class + "-" + cls.division
})

const bloodGroups = [
  "A+VE",
  "A-VE",
  "B+VE",
  "B-VE",
  "O+VE",
  "O-VE",
  "AB+VE",
  "AB-VE",
]

const bookCategoryType = ["Mathematics", "category2", "cate3"]

const bookGenereType = ["Val1"]

const countryCodes = [
  { label: "GHA", value: 233 },
  { label: "IN", value: 91 },
  { label: "HK", value: 852 },
  { label: "GY", value: 592 },
]
const divisions = ["A", "B", "C", "D", "E", "F"]

const whoCanReadValues = [
  { label: "Student", value: "Student" },
  { label: "Teacher", value: "Teacher" },
  { label: "Training", value: "Training" },
  { label: "Individual Student", value: "IndividualStudent" },
]

const subjectsMaster = [
  { value: "Agricultural Mechanization", label: "Agricultural Mechanization" },
  { value: "Animal Husbandry", label: "Animal Husbandry" },
  {
    value: "Architectural Draughtsmanship",
    label: "Architectural Draughtsmanship",
  },
  { value: "Autobody Repairs", label: "Autobody Repairs" },
  {
    value: "Automotive Engineering Technology",
    label: "Automotive Engineering Technology",
  },
  {
    value: "Basic Design And Technology",
    label: "Basic Design And Technology",
  },
  { value: "Basketry", label: "Basketry" },
  { value: "Beauty Therapy", label: "Beauty Therapy" },
  { value: "Biology", label: "Biology" },
  { value: "Building Construction", label: "Building Construction" },
  { value: "Business Management", label: "Business Management" },
  {
    value: "Catering And Hospitality Management",
    label: "Catering And Hospitality Management",
  },
  { value: "Ceramics", label: "Ceramics" },
  { value: "Chemistry", label: "Chemistry" },
  {
    value: "Christian Religious Studies",
    label: "Christian Religious Studies",
  },
  { value: "Clothing And Textiles", label: "Clothing And Textiles" },
  { value: "Core Mathematics", label: "Core Mathematics" },
  { value: "Core Mathematics Generic", label: "Core Mathematics Generic" },
  {
    value: "Computer Hardware & Software",
    label: "Computer Hardware & Software",
  },
  { value: "Cost Accounting", label: "Cost Accounting" },
  { value: "Creative Arts", label: "Creative Arts" },
  {
    value: "Crop Husbandry And Horticulture",
    label: "Crop Husbandry And Horticulture",
  },
  { value: "Economics", label: "Economics" },
  {
    value: "Electronics Engineering Technology",
    label: "Electronics Engineering Technology",
  },
  {
    value: "Elective Information Communication Technology",
    label: "Elective Information Communication Technology",
  },
  { value: "Elective Mathematics", label: "Elective Mathematics" },
  { value: "Electrical Engineering", label: "Electrical Engineering" },
  { value: "English Language", label: "English Language" },
  { value: "English Language Generic", label: "English Language Generic" },
  { value: "Entreprenueship Generic", label: "Entreprenueship Generic" },
  { value: "Financial Accounting", label: "Financial Accounting" },
  { value: "Food And Nutrition", label: "Food And Nutrition" },
  { value: "French", label: "French" },
  { value: "Fisheries", label: "Fisheries" },
  { value: "Furniture Technology", label: "Furniture Technology" },
  { value: "Forestry", label: "Forestry" },
  { value: "General Agriculture", label: "General Agriculture" },
  { value: "General Knowledge In Arts", label: "General Knowledge In Arts" },
  { value: "Geography", label: "Geography" },
  { value: "Ghanaian Language", label: "Ghanaian Language" },
  { value: "Government", label: "Government" },
  { value: "Graphic Designs", label: "Graphic Designs" },
  { value: "History", label: "History" },
  {
    value: "Heavy Duty Operations (Forklift)",
    label: "Heavy Duty Operations (Forklift)",
  },
  {
    value: "Information Communication And Technology",
    label: "Information Communication And Technology",
  },
  { value: "Integrated Science", label: "Integrated Science" },
  { value: "Integrated Science Generic", label: "Integrated Science Generic" },
  { value: "Ict Generic", label: "Ict Generic" },
  { value: "Industrial Mechanics", label: "Industrial Mechanics" },
  { value: "Jewellery", label: "Jewellery" },
  { value: "Languages", label: "Languages" },
  { value: "Literature", label: "Literature" },
  { value: "Leather Works", label: "Leather Works" },
  { value: "Leather Works Technology", label: "Leather Works Technology" },
  { value: "Management In Living", label: "Management In Living" },
  {
    value: "Mechanical Engineering Technology",
    label: "Mechanical Engineering Technology",
  },
  { value: "Music", label: "Music" },
  { value: "Our World Our People", label: "Our World Our People" },
  { value: "Physical Education", label: "Physical Education" },
  { value: "Picture Making", label: "Picture Making" },
  { value: "Physics", label: "Physics" },
  {
    value: "Plumbing And Gas Technology",
    label: "Plumbing And Gas Technology",
  },
  {
    value: "Religious And Moral Education",
    label: "Religious And Moral Education",
  },
  {
    value: "Refrigeration And Air Conditioning",
    label: "Refrigeration And Air Conditioning",
  },
  { value: "Social Studies", label: "Social Studies" },
  { value: "Social Studies Generic", label: "Social Studies Generic" },
  { value: "Textile Technology", label: "Textile Technology" },
  { value: "Textiles", label: "Textiles" },
  { value: "Tourism Management", label: "Tourism Management" },
  { value: "Visual Arts", label: "Visual Arts" },
  { value: "Welding And Fabrication", label: "Welding And Fabrication" },
  { value: "Wood Technology", label: "Wood Technology" },
]

const subjects = [
  "Agricultural Mechanization",
  "Animal Husbandry",
  "Architectural Draughtsmanship",
  "Autobody Repairs",
  "Automotive Engineering Technology",
  "Basic Design And Technology",
  "Basketry",
  "Beauty Therapy",
  "Biology",
  "Building Construction",
  "Business Management",
  "Catering And Hospitality Management",
  "Ceramics",
  "Chemistry",
  "Christian Religious Studies",
  "Clothing And Textiles",
  "Core Mathematics",
  "Core Mathematics Generic",
  "Computer Hardware & Software",
  "Cost Accounting",
  "Creative Arts",
  "Crop Husbandry And Horticulture",
  "Economics",
  "Electronics Engineering Technology",
  "Elective Information Communication Technology",
  "Elective Mathematics",
  "Electrical Engineering",
  "English Language",
  "English Language Generic",
  "Entreprenueship Generic",
  "Financial Accounting",
  "Food And Nutrition",
  "French",
  "Fisheries",
  "Furniture Technology",
  "Forestry",
  "General Agriculture",
  "General Knowledge In Arts",
  "Geography",
  "Ghanaian Language",
  "Government",
  "Graphic Designs",
  "History",
  "Heavy Duty Operations (Forklift)",
  "Information Communication And Technology",
  "Integrated Science",
  "Integrated Science Generic",
  "Ict Generic",
  "Industrial Mechanics",
  "Jewellery",
  "Languages",
  "Literature",
  "Leather Works",
  "Leather Works Technology",
  "Management In Living",
  "Mechanical Engineering Technology",
  "Music",
  "Our World Our People",
  "Physical Education",
  "Picture Making",
  "Physics",
  "Plumbing And Gas Technology",
  "Religious And Moral Education",
  "Refrigeration And Air Conditioning",
  "Social Studies",
  "Social Studies Generic",
  "Textile Technology",
  "Textiles",
  "Tourism Management",
  "Visual Arts",
  "Welding And Fabrication",
  "Wood Technology",
]

const section = [
  { label: "Section A", value: "section a" },
  { label: "Section B", value: "section b" },
  { label: "Section C", value: "section c" },
  { label: "Section D", value: "section d" },
  { label: "Section E", value: "section e" },
]

const certificateList = [
  { label: "BA", value: "BA" },
  { label: "BEd", value: "BEd" },
  { label: "BSc", value: "BSc" },
  { label: "HND", value: "HND" },
  { label: "MA", value: "MA" },
  { label: "MEd", value: "MEd" },
  { label: "MPhil", value: "MPhil" },
  { label: "MSc", value: "MSc" },
  { label: "PGDE", value: "PGDE" },
]

const levels = [
  {
    label: "LOWER PRIMARY",
    value: "LOWER PRIMARY",
  },
  {
    label: "UPPER PRIMARY",
    value: "UPPER PRIMARY",
  },
  {
    label: "JHS",
    value: "JHS",
  },
  {
    label: "SHS - Business",
    value: "SHS - Business",
  },
  {
    label: "SHS - General Arts",
    value: "SHS - General Arts",
  },
  {
    label: "SHS - General Science",
    value: "SHS - General Science",
  },
  {
    label: "SHS - Home Economics",
    value: "SHS - Home Economics",
  },
  {
    label: "SHS - Visual Arts",
    value: "SHS - Visual Arts",
  },
  {
    label: "SHS - Agricultural Science",
    value: "SHS - Agricultural Science",
  },
  {
    label: "TVET - Engineering",
    value: "TVET - Engineering",
  },
  {
    label: "TVET - Construction",
    value: "TVET - Construction",
  },
  {
    label: "TVET - Hospitality And Tourism",
    value: "TVET - Hospitality And Tourism",
  },
]

const yearsOfExperience = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
  { label: "6", value: "6" },
  { label: "7", value: "7" },
  { label: "8", value: "8" },
  { label: "9", value: "9" },
  { label: "10", value: "10" },
  { label: "11", value: "11" },
  { label: "12", value: "12" },
  { label: "13", value: "13" },
  { label: "14", value: "14" },
  { label: "15", value: "15" },
]

const languages = [
  { label: "Dagbani", value: "Dagbani" },
  { label: "Dangme", value: "Dangme" },
  { label: "English", value: "English" },
  { label: "Ewe", value: "Ewe" },
  { label: "French", value: "French" },
  { label: "Ga", value: "Ga" },
  { label: "Hausa", value: "Hausa" },
  { label: "Twi", value: "Twi" },
]

export {
  teamMemberRole,
  classRoomType,
  schoolType,
  staffRole,
  authorityType,
  officeLevel,
  programType,
  areaType,
  classrooms,
  bloodGroups,
  bookCategoryType,
  whoCanReadBook,
  bookGenereType,
  language,
  countryCodes,
  divisions,
  whoCanReadValues,
  classRoomType1,
  subjectsMaster,
  subjects,
  section,
  certificateList,
  levels,
  yearsOfExperience,
  languages,
}
